// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

import Vue from 'vue';
import Buefy from 'buefy';
import VueClipboards from 'vue-clipboards';
import VTooltip from 'v-tooltip';
import '@fortawesome/fontawesome-pro/css/fontawesome.css';
import '@fortawesome/fontawesome-pro/css/regular.css';
import '@fortawesome/fontawesome-pro/css/solid.css';
import '@fortawesome/fontawesome-pro/css/light.css';
import { library as faLibrary } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { faChevronDown, faChevronUp, faTimes, faPlus as falPlus } from '@fortawesome/pro-light-svg-icons';
import { faChevronDown as farChevronDown, faEdit, faSortAmountDown, faSlidersH, faExclamationCircle, faSignIn, faEllipsisH, faTimes as farTimes, faCheck, faArchive, faMinusHexagon as farMinusHexagon, faArrowLeft, faFileImport, faBook as farBook } from '@fortawesome/pro-regular-svg-icons';
import { faTrash, faPlus, faAngleRight, faAngleLeft, faDoNotEnter, faCircle, faPencil, faChartBar as fasChart, faCalendar as fasCalendar, faMinusHexagon, faExchangeAlt as fasExchangeAlt } from '@fortawesome/pro-solid-svg-icons';

import App from './App';
import router from './router';
import store from './store/store';
import { cmToFt, titleize, kgToLbs, formatDate, currency, capitalize, formatDuration } from './filters';
import CONFIG from './env-config';

import ModalConfirm from './components/layout/modals/ModalConfirm';

faLibrary.add(faEdit, faTrash, faPlus, faAngleRight, faAngleLeft, faDoNotEnter, faSortAmountDown, faSlidersH, faCircle, faPencil,
  faChevronDown, faChevronUp, faTimes, faExclamationCircle, faSignIn, faEllipsisH, farTimes, farChevronDown, fasChart, fasCalendar,
  faMinusHexagon, faCheck, faArchive, fasExchangeAlt, farMinusHexagon, faArrowLeft, falPlus, faFileImport, farBook);

Vue.component('fa-icon', FontAwesomeIcon);
Vue.component('fa-layers', FontAwesomeLayers);

Vue.config.productionTip = false;

Vue.prototype.$confirm = new Vue(ModalConfirm);

Vue.use(Buefy, { defaultIconPack: 'fas' });
Vue.use(VTooltip, {
  popover: {
    defaultPopperOptions: {
      modifiers: {
        preventOverflow: { enabled: false },
        hide: { enabled: false },
      },
    },
  },
});
Vue.use(VueClipboards);
Vue.filter('formatDate', formatDate);
Vue.filter('cmToFt', cmToFt);
Vue.filter('kgToLbs', kgToLbs);
Vue.filter('currency', currency);
Vue.filter('capitalize', capitalize);
Vue.filter('formatDuration', formatDuration);
Vue.filter('titleize', titleize);
let app;
Vue.prototype.$firebaseApp = initializeApp(CONFIG.firebaseConfig);
const auth = getAuth(Vue.prototype.$firebaseApp);
onAuthStateChanged(auth, (user) => {
  store.dispatch('updateFirebaseUser', { user }).then(() => {
    // Only initialize the app once after we have logged in and set users and clubs
    if (!app) {
      app = new Vue({
        el: '#app',
        router,
        store,
        render: h => h(App),
      });
    }
  });
});
// Kicker for build 2024-04-18
