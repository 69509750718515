import Vue from 'vue';
import Router from 'vue-router';
// import multiguard from 'vue-router-multiguard';
import Home from '@/components/Home';
import Login from '@/components/auth/Login';
import EmptyState from '@/components/EmptyState';
import Users from '@/components/users/Users';
import Clubs from '@/components/clubs/Clubs';
import ClubView from '@/components/clubs/ClubView';
import Summary from '@/components/onboarding/Summary';
import PlanView from '@/components/onboarding/plans/PlanView';
import UserScheduleView from '@/components/onboarding/reports/UserScheduleView';
import DigitalDocuments from '@/components/digital_documents/DigitalDocuments';
import TeamAccountsSummary from '@/components/team_accounts/TeamAccountsSummary';
import ActivitiesMain from '@/components/activities/ActivitiesMain';
import ProcessingVolume from '../components/processing_volume/ProcessingVolume';
import ActivitiesIndividualSummary from '../components/activities/ActivitiesIndividualSummary';
import Tickets from '../components/tickets/Tickets';
import Contracts from '../components/contracts/Contracts';
import UnderWriting from '../components/underwriting/UnderWriting';
import Usage from '../components/usage/Usage';
import MerchantAccounts from '../components/merchant/MerchantAccounts';
import BackgroundChecks from '../components/background_checks/BackgroundChecks';
import FinancialTracking from '../components/financial_tracking/FinancialTracking';
import ClubFinancialTracking from '../components/financial_tracking/ClubFinancialTracking';
import TeamAccountsOnboarding from '../components/team_accounts_onboarding/TeamAccountsOnboarding';
import { AuthGuard, LoggedInGuard, IsCstAdminGuard, IsCstSalesGuard } from './guards';


Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: Home,
      beforeEnter: AuthGuard,
      beforeRouteUpdate: AuthGuard,
      meta: { title: 'PlayMetrics CS Tracker' },
      children: [
        { path: '',
          name: 'emptyState',
          component: EmptyState,
        },
        { path: 'users',
          name: 'users',
          component: Users,
          beforeEnter: IsCstAdminGuard,
        },
        { path: 'clubs',
          name: 'clubs',
          component: Clubs,
          beforeEnter: IsCstSalesGuard,
        },
        { path: 'clubs/club-contacts',
          name: 'ClubContacts',
          component: Clubs,
          beforeEnter: IsCstSalesGuard,
          props: { defaultTab: 'Club Contacts' },
        },
        { path: 'clubs/:club/',
          name: 'ClubViewSummary',
          component: ClubView,
        },
        { path: 'clubs/:club/activities',
          name: 'ClubViewActivities',
          component: ClubView,
          props: { defaultTab: 'Activities' },
        },
        { path: 'clubs/:club/usage',
          name: 'ClubViewUsage',
          component: ClubView,
          props: { defaultTab: 'Usage' },
        },
        { path: 'clubs/:club/tickets',
          name: 'ClubViewTickets',
          component: ClubView,
          props: { defaultTab: 'Tickets' },
        },
        { path: 'clubs/:club/contacts',
          name: 'ClubViewContacts',
          component: ClubView,
          props: { defaultTab: 'Contacts' },
        },
        { path: '/onboarding',
          name: 'OnboardingSummaryPlans',
          component: Summary,
        },
        {
          path: '/onboarding/reports',
          name: 'OnboardingSummaryReports',
          component: Summary,
          props: { defaultTab: 'Reports' },
        },
        { path: '/onboarding/:plan/',
          name: 'PlanViewDetails',
          component: PlanView,
        },
        { path: '/onboarding/:plan/activities',
          name: 'PlanViewActivities',
          component: PlanView,
          props: { defaultTab: 'Activities' },
        },
        { path: '/onboarding/:plan/notes',
          name: 'PlanViewNotes',
          component: PlanView,
          props: { defaultTab: 'Notes' },
        },
        { path: '/onboarding/reports/:userId/:dateRange',
          name: 'UserScheduleView',
          component: UserScheduleView,
        },
        { path: '/tickets',
          name: 'TicketsSummary',
          component: Tickets,
          beforeEnter: IsCstSalesGuard,
        },
        { path: '/tickets/reports',
          name: 'TicketsReports',
          component: Tickets,
          beforeEnter: IsCstSalesGuard,
          props: { defaultTab: 'Reports' },
        },
        { path: '/contracts',
          name: 'Contracts',
          component: Contracts,
        },
        { path: '/underwriting',
          name: 'UnderWriting',
          component: UnderWriting,
        },
        { path: '/activities',
          name: 'Activities',
          component: ActivitiesMain,
        },
        { path: '/activities/reports',
          name: 'ActivitiesReports',
          component: ActivitiesMain,
          props: { defaultTab: 'Reports' },
        },
        { path: '/activities/:activities/club-summary',
          name: 'ActivitiesIndividualSummary',
          component: ActivitiesIndividualSummary,
        },
        { path: '/usage',
          name: 'Usage',
          component: Usage,
        },
        { path: '/merchant-accounts',
          name: 'Merchant Accounts',
          component: MerchantAccounts,
        },
        { path: '/processing-volume',
          name: 'Processing Volume',
          component: ProcessingVolume,
        },
        { path: '/financial-tracking',
          name: 'Financial Tracking',
          component: FinancialTracking,
        },
        { path: '/financial-tracking/:club/',
          name: 'ClubFinancialTracking',
          component: ClubFinancialTracking,
        },
        { path: '/digital-documents',
          name: 'Digital Documents',
          component: DigitalDocuments,
        },
        { path: '/onboarding-team-accounts',
          name: 'Team Accounts',
          component: TeamAccountsOnboarding,
        },
        {
          path: '/team-accounts',
          name: 'TeamAccounts',
          component: TeamAccountsSummary,
          props: { defaultTab: 'Accounts' },
        },
        {
          path: '/team-accounts/cardholders',
          name: 'TeamAccountCardholders',
          component: TeamAccountsSummary,
          props: { defaultTab: 'Cards' },
        },
        {
          path: '/team-accounts/debits',
          name: 'TeamAccountDebits',
          component: TeamAccountsSummary,
          props: { defaultTab: 'Debits' },
        },
        {
          path: '/team-accounts/credits',
          name: 'TeamAccountCredits',
          component: TeamAccountsSummary,
          props: { defaultTab: 'Credits' },
        },
        { path: '/background-checks',
          name: 'Background Checks',
          component: BackgroundChecks,
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      beforeEnter: LoggedInGuard,
      beforeRouteUpdate: LoggedInGuard,
    },
  ],
});
