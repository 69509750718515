<template>
  <div> <spinner v-if="loading"/>
  <FullScreenModal v-else modal-class="roster-counts-modal" ref="modal">
    <template slot="header">
      <FormModalHeader
        title="View Roster Counts"
        wide-form
        is-all-white
        :actions="['exportCSV']"
        @dismiss="dismissModal">
        <template slot="exportCSV">
          <b-button type="is-primary" @click="exportCSV"><span>Export as CSV</span></b-button>
        </template>
      </FormModalHeader>
    </template>
    <div class="roster-counts">
      <div class="columns roster-counts__info--name-container">
        <div class="column">
          <b-field label="Club:">
            <strong class="roster-counts__info--club-name"> {{ contract.club_name }} </strong>
          </b-field>
        </div>
      </div>
      <div class="columns roster-counts__info">
        <div class="column">
          <b-field label="Range">
            <b-select v-model="range" expanded disabled>
              <option> {{ range }} </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="View">
            <b-select v-model="interval" expanded disabled>
              <option> {{ interval }} </option>
            </b-select>
          </b-field>
        </div>
      </div>
      <div class="columns roster-counts__charts">
        <div class="column is-half">
          <div v-if="compActualArray.length">
            <highcharts ref="highcharts" :options="compOptions"/>
          </div> <div v-else class="content has-text-grey has-text-centered">
            <p>No Recent Recreational Player Counts Found</p>
          </div>
        </div>
        <div class="column is-half">
          <div v-if="recActualArray.length">
            <highcharts ref="highcharts" :options="recOptions"/>
          </div> <div v-else class="content has-text-grey has-text-centered">
            <p>No Recent Recreational Player Counts Found</p>
          </div>
        </div>
      </div>
    </div>
  </FullScreenModal> </div>
</template>

<script>
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import { genComponent } from 'vue-highcharts';
import api from '@/http-playmetrics';
import FormModalHeader from '@/components/layout/modals/FormModalHeader';
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import Spinner from '@/components/common/Spinner';

highchartsMore(Highcharts);

export default {

  components: {
    FormModalHeader, FullScreenModal, highcharts: genComponent('Highcharts', Highcharts), Spinner,
  },
  data() {
    return {
      range: 'Lifetime',
      interval: 'Weekly',
      loading: false,
      recActualArray: [],
      compActualArray: [],
      dateArray: [],
      contract: {
        id: '',
        club_id: '',
        name: '',
        size: '',
        sport: '',
        renew_date: null,
        sign_date: null,
        rec_player_cap: 0,
        rec_player_median: 0,
        competitive_player_cap: 0,
        competitive_player_median: 0,
        sales_contact_user_name: '',
        isArchived: false,
      },
    };
  },
  computed: {
    recCapArray() {
      return this.getCapArray(this.contract.rec_player_cap);
    },
    compCapArray() {
      return this.getCapArray(this.contract.competitive_player_cap);
    },
    recOptions() {
      return {
        chart: {
          showAxes: true,
          borderColor: 'grey',
          borderRadius: '1rem',
          borderWidth: 1,
          spacingTop: 50,
        },

        credits: {
          enabled: false,
        },

        title: {
          text: '<strong> Recreation Roster Counts </strong>',
          y: -15,
          margin: 30,
          style: { color: 'is-black' },
        },

        subtitle: {
          style: {
            fontSize: '1rem',
            color: 'is-black',
          },
          useHTML: true,
          y: 25,
          text: `<div class="graph-heading"><span class="graph-subtitle">Median Roster Count: <strong class="has-text-info-dark">${this.contract.rec_player_median}</strong></span>
<span class="graph-subtitle">Contract Cap: <strong class="has-text-info-dark">${this.contract.rec_player_cap}</strong></span></div>`,
        },

        xAxis: {
          startOnTick: true,
          title: { text: 'Week of' },
          categories: this.dateArray,
        },

        yAxis: {
          title: { text: 'Roster Count' },
          min: 0,
        },

        legend: {
          y: 30,
          floating: true,
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
        },

        series: [{
          name: 'Contract',
          data: this.recCapArray,
        },
        {
          name: 'Rec Count',
          data: this.recActualArray,
        }],
      };
    },
    compOptions() {
      return {
        chart: {
          borderColor: 'grey',
          borderWidth: 1,
          borderRadius: '1rem',
          showAxes: true,
          spacingTop: 50,
        },

        credits: {
          enabled: false,
        },

        title: {
          text: '<strong> Competitive Roster Counts </strong>',
          y: -15,
          margin: 30,
          style: { color: 'is-black' },
        },

        subtitle: {
          style: {
            fontSize: '1rem',
            color: 'is-black',
          },
          useHTML: true,
          y: 25,
          text: `<div class="graph-heading"><span class="graph-subtitle">Median Roster Count: <strong class="has-text-info-dark">${this.contract.competitive_player_median}</strong></span>
<span class="graph-subtitle">Contract Cap: <strong class="has-text-info-dark">${this.contract.competitive_player_cap}</strong></span></div>`,
        },

        xAxis: {
          startOnTick: true,
          title: { text: 'Week of' },
          categories: this.dateArray,
        },

        yAxis: {
          title: { text: 'Roster Count' },
          min: 0,
        },

        legend: {
          y: 30,
          floating: true,
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
        },

        series: [{
          name: 'Contract',
          data: this.compCapArray,
        },
        {
          name: 'Comp Count',
          data: this.compActualArray,
        }],
      };
    },
  },
  methods: {
    fetchPlayerCounts() {
      api().get(`/cst/clubs/${this.contract.club_id}/roster_counts`).then((res) => {
        res.data.forEach((element) => {
          this.recActualArray.push(element.active_rec_players);
          this.compActualArray.push(element.active_competitive_players);
          this.dateArray.push(element.captured_on);
        });
      });
    },
    exportCSV() {
      api().serverURL(`/cst/clubs/${this.contract.club_id}/roster_counts.csv`).then((url) => {
        window.open(url, '_blank');
      });
    },
    tabChange(tab) {
      this.activeTab = tab;
    },
    getCapArray(cap) {
      const arr = [];
      for (let i = 0; i < this.recActualArray.length; i += 1) {
        arr.push(cap);
      }
      return arr;
    },
    showModal(con) {
      if (con) {
        this.loading = true;
        this.contract = con;
      } else {
        this.contract = {
          id: null,
          club_id: null,
          name: null,
          size: null,
          sport: null,
          renew_date: null,
          sign_date: null,
          rec_player_cap: null,
          rec_player_median: null,
          competitive_player_cap: null,
          competitive_player_median: null,
          sales_contact_user_name: null,
          isArchived: null,
        };
      }
      this.fetchPlayerCounts();
      this.loading = false;
      this.$refs.modal.show();
    },
    dismissModal(options = { refreshData: false }) {
      this.refresh();
      this.$emit('dismissed', options);
      this.$refs.modal.dismiss();
    },
    refresh() {
      this.recActualArray = [];
      this.compActualArray = [];
      this.dateArray = [];
    },
  },
};
</script>

<style lang="sass" scoped>
.roster-counts-modal
  ::v-deep
    .graph-subtitle
      border-radius: 3px
      padding: 0.5rem 1rem
      margin-bottom: 1.5rem
      background-color: $white-ter
  .roster-counts
    &__info
      margin-left: 5rem
      margin-bottom: 1rem
      max-width: 400px !important
      &--name-container
        margin-left: 5rem
        max-width: 800px !important
        margin-top: 1rem
      &--club-name
        font-size: 18px
        block-size: max-content
    &__charts
      margin: 0rem 5rem
      &--label-container
        font-size: 1.25rem
        margin: 1.5rem auto 1.5rem
        text-align: center
.roster-counts-modal .columns .column
  flex-basis: auto
</style>
