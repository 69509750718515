<template>
  <Spinner v-if="loading"/>
  <div v-else>
    <div class="level-item">
      <div class="level-item week-picker">
        <WeekNavigationWithPicker
          v-model="currentDate"
          has-prev
          has-next />
      </div>
    </div>
    <B-Table
      name="Schedule By Staff"
      v-if="tableRows.length"
      :searchable="false"
      :filterable="false"
      :bordered="true"
      :data="tableRows"
      class="staff-schedule-table">
      <template slot-scope="props">
        <b-table-column label=""
          field="user_name"
          :cell-class="isDayUser(props.row.user_name)"
          header-class="is-unselectable header-background">
          <div v-if="isUser(props.row.user_name)">
            <span>
              <a @click="viewUser(props.row.user_id)">
              {{ props.row.user_name }}
              </a>
            </span>
            <span> ({{ toHours(timeSpent(userActivitiesByDay.find(user => user.name === props.row.user_name))) }})</span>
          </div>
          <div v-else>{{ props.row.user_name | capitalize }}</div>
        </b-table-column>
        <b-table-column label="Club"
          field="club_name"
          :cell-class="isUser(props.row.user_name)"
          header-class="is-unselectable header-background">
          {{ props.row.club_name | capitalize }}
        </b-table-column>
        <b-table-column label="Status"
          field="club_status"
          :cell-class="isUser(props.row.user_name)"
          header-class="is-unselectable header-background">
          {{ props.row.club_status | capitalize }}
        </b-table-column>
        <b-table-column label="Category"
          field="category"
          :cell-class="isUser(props.row.user_name)"
          header-class="is-unselectable header-background">
          {{ props.row.category | capitalize }}
        </b-table-column>
        <b-table-column label="Milestone"
          field="milestone"
          :cell-class="isUser(props.row.user_name)"
          header-class="is-unselectable header-background">
          {{ props.row.milestone | capitalize }}
        </b-table-column>
        <b-table-column label="Role"
          field="role"
          :cell-class="isUser(props.row.user_name)"
          header-class="is-unselectable header-background">
          {{ props.row.role | capitalize }}
        </b-table-column>
        <b-table-column label="Hours"
          field="hours"
          :cell-class="isUser(props.row.user_name)"
          header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ toHours(props.row.hours) }} </div>
        </b-table-column>
      </template>
    </B-Table>
    <template v-else>
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>No Activities this Week</p>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import WeekNavigationWithPicker from '@/components/onboarding/reports/WeekNavigationWithPicker';

export default {
  components: { WeekNavigationWithPicker, Spinner },
  data() {
    return {
      loading: false,
      activities: [],
      currentDate: new Date(),
      weekDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    };
  },
  created() {
    this.fetchActivities();
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    users() {
      return this.$store.getters.users;
    },
    week() {
      const arr = [];
      const dt = moment(this.currentDate).startOf('isoWeek');
      for (let i = 0; i < 5; i += 1) {
        arr.push((_.cloneDeep(dt).add(i, 'd')));
      }
      return arr;
    },
    mappedActivities() {
      return this.activities.filter(activity => activity.meeting_date).map(activity => ({ ...activity,
        club_name: this.clubsByID(activity.club_id).name,
        club_status: this.clubsByID(activity.club_id).config.onboarding_status,
      }));
    },
    mappedUsers() {
      return this.users.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        activities: this.activitiesByUser(user.id),
      })).filter(user => user.activities.length !== 0).sort((user1, user2) => ((user1.name < user2.name) ? -1 : 1));
    },
    userActivitiesByDay() {
      return this.mappedUsers.map(user => ({
        id: user.id,
        name: user.name,
        activities: [
          this.activitiesByDay(user, 0),
          this.activitiesByDay(user, 1),
          this.activitiesByDay(user, 2),
          this.activitiesByDay(user, 3),
          this.activitiesByDay(user, 4),
        ],
      })).filter(user => _.some(user.activities, act => act.length));
    },
    tableRows() {
      let arr = [];
      if (this.userActivitiesByDay.length) {
        for (let i = 0; i < this.userActivitiesByDay.length; i += 1) {
          arr = [...arr, ...this.getRows(this.userActivitiesByDay[i])];
        }
      }
      return arr;
    },
  },
  methods: {
    fetchActivities() {
      this.loading = true;
      return api().post('/cst/activities/search').then((res) => {
        this.activities = res.data;
        this.loading = false;
      });
    },
    viewUser(id) {
      if (id) {
        this.$router.push({ name: 'UserScheduleView', params: { userId: id, dateRange: 'default' } });
      }
    },
    isUser(name) {
      return this.userActivitiesByDay.filter(u => u.name === name).length ? 'is-user' : '';
    },
    isDayUser(name) {
      return this.mappedUsers.map(user => user.name.toLowerCase()).includes(name.toLowerCase()) ? 'is-user' : 'is-day';
    },
    toHours(m) {
      if (typeof m === 'string') return m;
      if (m) {
        const hrs = Math.round((m / 60) * 100) / 100;
        return Math.floor(hrs) === hrs ? `${hrs}.00 hrs` : `${hrs} hrs`;
      } else return '-';
    },
    timeSpent(user) {
      return user.activities.reduce((acc, day) => acc + day.reduce((accum, act) => accum + act.duration, 0), 0);
    },
    activitiesByUser(id) {
      return [...this.mappedActivities.filter(activity => activity.lead_user_id === id),
        ...this.mappedActivities.filter(activity => activity.secondary_user_ids?.includes(id))];
    },
    activitiesByDay(user, day) {
      return user.activities.filter(act => this.week[day].isSame(act.meeting_date, 'day'));
    },
    clubsByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    getRows(u) {
      const rows = [];
      rows.push({ user_id: u.id, user_name: u.name, club_name: '', club_status: '', category: '', milestone: '', role: '', hours: '' });
      for (let i = 0; i < 5; i += 1) {
        if (u.activities[i].length) {
          for (let j = 0; j < u.activities[i].length; j += 1) {
            rows.push({
              user_name: j === 0 ? this.weekDays[i] : '',
              club_name: u.activities[i][j].club_name ? u.activities[i][j].club_name : '-',
              club_status: u.activities[i][j].club_status ? u.activities[i][j].club_status : '-',
              category: u.activities[i][j].category ? u.activities[i][j].category : '-',
              milestone: u.activities[i][j].milestone ? u.activities[i][j].milestone : '-',
              role: u.activities[i][j].lead_user_id === u.id ? 'primary' : 'secondary',
              hours: u.activities[i][j].duration,
            });
          }
        }
      }
      return rows;
    },
  },
};
</script>
<style lang="sass" scoped>
.week-picker
  margin-top: 1rem
::v-deep .staff-schedule-table
  font-family: $family-fira
  font-weight: $weight-normal
  .header-background
    width: 175px
    font-family: $family-headers
    font-size: 1.1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
  .is-user
    background-color: #ebedf9
    font-family: $family-headers
    font-weight: $weight-bold
    color: $black
    border-color: #ebedf9
    padding: .75rem 0 .75rem .75rem
  .is-day
    color: $black
    background-color: $white-bis
    font-weight: $weight-medium
</style>
