<template>
  <div class="column is-narrow filter-column">
    <div class="filter-container filter-header" :class="{empty: !title}">
      <p v-if="title" class="filter-title is-size-4">{{ title }}</p>
    </div>
    <template>
      <div class="content">
        <slot name="content"/>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    title: { type: String, default: 'Filter' },
  },
};
</script>

<style lang="sass" scoped>
.filter-title
  font-family: $family-headers
  font-weight: $weight-medium
  font-size: $size-4
  padding-right: 0.75rem
.filter-header
  //box-shadow: 0 1px 0 0 $grey-lighter
  padding-bottom: 1.5rem
  &.empty
    box-shadow: none
    padding-bottom: 0
    margin-top: -1.25rem
</style>
