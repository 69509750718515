<template>
  <spinner v-if="loading"/>
  <div v-else>
    <div class="level-item">
      <div class="level-item week-picker">
        <WeekNavigationWithPicker
          v-model="currentDate"
          has-prev
          has-next />
      </div>
    </div>
    <TablePaginated
      name="Staff Daily Summary"
      v-if="tableRows.length"
      :searchable="false"
      :filterable="false"
      bordered
      :showPagination="false"
      :data="tableRows"
      class="daily-summary-table">
      <template slot="header-left">
        <b-switch v-model="onboardingOnly">Show only onboarding activities</b-switch>
      </template>
      <template slot="header-right">
        <div class="view-selector">
          <strong> View hours as: </strong>
          <b-select
            style="margin-left: 1rem"
            placeholder="Primary"
            v-model="currentView">
            <option
              v-for="view in views"
              :value="view"
              :key="view"
            >
              {{ view }}
            </option>
          </b-select>
        </div>
      </template>
      <template slot-scope="props">
        <b-table-column class="user-name-column"
          label=""
          field="user_name"
          header-class="is-unselectable header-background"
          :cell-class="cellBackground(0, props.row.is_header)">
          <a @click="viewUser(props.row.id)"> {{ props.row.user_name | capitalize }} </a>
        </b-table-column>

        <b-table-column :label="`Mon, ${formatDayofWeek(0)}`"
          field="monday"
          header-class="is-unselectable header-background"
          :cell-class="cellBackground(props.row.monday, props.row.is_header)"
          centered
          :class="isSelectable(props.row.id, props.row.monday)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ `Mon, ${formatDayofWeek(0)}` }} </p>
            <StatusTag v-if="dayOfWeek === 'monday'" :status="'today'" />
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.monday)"
            @click="showModal(props.row.id, props.row.monday,
            userByID(props.row.id).name, userByID(props.row.id).activities[0], week[0])"> <div class="has-text-right">
            {{ toHours(props.row.monday) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.monday) }} </div>
        </b-table-column>

        <b-table-column :label="`Tue, ${formatDayofWeek(1)}`"
          field="tuesday"
          header-class="is-unselectable header-background"
          :cell-class="cellBackground(props.row.tuesday, props.row.is_header)"
          centered
          :class="isSelectable(props.row.id, props.row.tuesday)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ `Tue, ${formatDayofWeek(1)}` }} </p>
            <StatusTag v-if="dayOfWeek === 'tuesday'" :status="'today'" />
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.tuesday)"
            @click="showModal(props.row.id, props.row.tuesday,
            userByID(props.row.id).name, userByID(props.row.id).activities[1], week[1])"> <div class="has-text-right">
            {{ toHours(props.row.tuesday) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.tuesday) }} </div>
        </b-table-column>

        <b-table-column :label="`Wed, ${formatDayofWeek(2)}`"
          field="wednesday"
          header-class="is-unselectable header-background"
          :cell-class="cellBackground(props.row.wednesday, props.row.is_header)"
          centered
          :class="isSelectable(props.row.id, props.row.wednesday)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ `Wed, ${formatDayofWeek(2)}` }} </p>
            <StatusTag v-if="dayOfWeek === 'wednesday'" :status="'today'" />
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.wednesday)"
            @click="showModal(props.row.id, props.row.wednesday,
            userByID(props.row.id).name, userByID(props.row.id).activities[2], week[2])"> <div class="has-text-right">
            {{ toHours(props.row.wednesday) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.wednesday) }} </div>
        </b-table-column>

        <b-table-column :label="`Thu, ${formatDayofWeek(3)}`"
          field="thursday"
          header-class="is-unselectable header-background"
          :cell-class="cellBackground(props.row.thursday, props.row.is_header)"
          centered
          :class="isSelectable(props.row.id, props.row.thursday)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ `Thu, ${formatDayofWeek(3)}` }} </p>
            <StatusTag v-if="dayOfWeek === 'thursday'" :status="'today'" />
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.thursday)"
            @click="showModal(props.row.id, props.row.thursday,
            userByID(props.row.id).name, userByID(props.row.id).activities[3], week[3])"> <div class="has-text-right">
            {{ toHours(props.row.thursday) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.thursday) }} </div>
        </b-table-column>

        <b-table-column :label="`Fri, ${formatDayofWeek(4)}`"
          field="friday"
          header-class="is-unselectable header-background"
          :cell-class="cellBackground(props.row.friday, props.row.is_header)"
          centered
          :class="isSelectable(props.row.id, props.row.friday)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ `Fri, ${formatDayofWeek(4)}` }} </p>
            <StatusTag v-if="dayOfWeek === 'friday'" :status="'today'" />
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.friday)"
            @click="showModal(props.row.id, props.row.friday,
            userByID(props.row.id).name, userByID(props.row.id).activities[4], week[4])"> <div class="has-text-right">
            {{ toHours(props.row.friday) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.friday) }} </div>
        </b-table-column>
      </template>
    </TablePaginated>
    <template v-else>
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>No Activities this Week</p>
        </div>
      </section>
    </template>
    <DayUserActivitiesModal ref="userActivities"/>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import StatusTag from '@/components/underwriting/StatusTag';
import TablePaginated from '@/components/layout/TablePaginated';
import DayUserActivitiesModal from '@/components/onboarding/reports/DayUserActivitiesModal';
import WeekNavigationWithPicker from '@/components/onboarding/reports/WeekNavigationWithPicker';

export default {
  components: { TablePaginated, WeekNavigationWithPicker, StatusTag, Spinner, DayUserActivitiesModal },
  data() {
    return {
      onboardingOnly: false,
      currentView: 'Overall',
      views: ['Overall', 'Primary', 'Secondary'],
      loading: false,
      activities: [],
      today: new Date(),
      currentDate: new Date(),
      weekDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    };
  },
  created() {
    this.fetchActivities();
  },
  computed: {
    users() {
      return this.$store.getters.users;
    },
    week() {
      const arr = [];
      const dt = moment(this.currentDate).startOf('isoWeek');
      for (let i = 0; i < 5; i += 1) {
        arr.push((_.cloneDeep(dt).add(i, 'd')));
      }
      return arr;
    },
    dayOfWeek() {
      const curr = moment(this.today);
      if (this.week[0].isSame(curr, 'day')) return 'monday';
      if (this.week[1].isSame(curr, 'day')) return 'tuesday';
      if (this.week[2].isSame(curr, 'day')) return 'wednesday';
      if (this.week[3].isSame(curr, 'day')) return 'thursday';
      if (this.week[4].isSame(curr, 'day')) return 'friday';
      else return '';
    },
    mappedActivities() {
      return this.onboardingOnly ? this.activities.filter(activity => activity.meeting_date && activity.category !== 'meeting')
        : this.activities.filter(activity => activity.meeting_date);
    },
    mappedUsers() {
      return this.users.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        activities: this.activitiesByUser(user.id),
      })).filter(user => user.activities[0]?.length !== 0 || user.activities[1]?.length !== 0)
        .sort((user1, user2) => ((user1.name < user2.name) ? -1 : 1));
    },
    userActivitiesByDay() {
      return this.mappedUsers.map(user => ({
        id: user.id,
        name: user.name,
        activities: [
          this.activitiesByDay(user, 0),
          this.activitiesByDay(user, 1),
          this.activitiesByDay(user, 2),
          this.activitiesByDay(user, 3),
          this.activitiesByDay(user, 4),
        ],
      }));
    },
    tableRows() {
      const arr = [];
      arr.push({
        is_header: true,
        user_name: 'Total',
        monday: this.userActivitiesByDay.reduce((acc, user) => acc + this.timeSpent(user.activities[0]), 0),
        tuesday: this.userActivitiesByDay.reduce((acc, user) => acc + this.timeSpent(user.activities[1]), 0),
        wednesday: this.userActivitiesByDay.reduce((acc, user) => acc + this.timeSpent(user.activities[2]), 0),
        thursday: this.userActivitiesByDay.reduce((acc, user) => acc + this.timeSpent(user.activities[3]), 0),
        friday: this.userActivitiesByDay.reduce((acc, user) => acc + this.timeSpent(user.activities[4]), 0),
      });
      const users = this.userActivitiesByDay.map(u => ({
        is_header: false,
        id: u.id,
        user_name: u.name,
        monday: this.timeSpent(u.activities[0]),
        tuesday: this.timeSpent(u.activities[1]),
        wednesday: this.timeSpent(u.activities[2]),
        thursday: this.timeSpent(u.activities[3]),
        friday: this.timeSpent(u.activities[4]),
      }));
      return [...users, ...arr];
    },
  },
  methods: {
    fetchActivities() {
      this.loading = true;
      return api().post('/cst/activities/search').then((res) => {
        this.activities = res.data;
        this.loading = false;
      });
    },
    isSelectable(id, day) {
      return this.users.find(user => user.id === id) && this.toHours(day) !== '-' ? 'user-name-column' : '';
    },
    userByID(id) {
      return this.userActivitiesByDay.find(user => user.id === id);
    },
    viewUser(id) {
      if (id) {
        this.$router.push({ name: 'UserScheduleView', params: { userId: id, dateRange: 'daily' } });
      }
    },
    cellBackground(mins, header) {
      if (header) return 'is-first';
      if (mins > 240) return 'is-hours-danger';
      if (mins > 150) return 'is-hours-warning';
      return '';
    },
    toHours(m) {
      if (typeof m === 'string') return m;
      if (m) {
        const hrs = Math.round((m / 60) * 100) / 100;
        return Math.floor(hrs) === hrs ? `${hrs}.00 hrs` : `${hrs} hrs`;
      } else return '-';
    },
    timeSpent(activities) {
      return activities.length ? activities.reduce((acc, activity) => acc + activity.duration, 0) : 0;
    },
    formatDayofWeek(day) {
      return this.week[day].format('MMM D');
    },
    activitiesByUser(id) {
      return [this.mappedActivities.filter(activity => activity.lead_user_id === id),
        this.mappedActivities.filter(activity => activity.secondary_user_ids?.includes(id))];
    },
    activitiesByDay(user, day) {
      if (this.currentView === 'Primary') {
        return user.activities[0]?.filter(act => this.week[day].isSame(act.meeting_date, 'day'));
      } else if (this.currentView === 'Secondary') {
        return user.activities[1]?.filter(act => this.week[day].isSame(act.meeting_date, 'day'));
      } else {
        return [...user.activities[0]?.filter(act => this.week[day].isSame(act.meeting_date, 'day')),
          ...user.activities[1]?.filter(act => this.week[day].isSame(act.meeting_date, 'day'))];
      }
    },
    showModal(id, time, name, activities, date) {
      this.$refs.userActivities.showModal(id, time, name, activities, date);
    },
  },
};
</script>
<style lang="sass" scoped>
.user-name-column:hover
  background-color: $white-ter
.week-picker
  margin-top: 1rem
.view-selector
  display: flex
  align-items: center
  justify-content: flex-end
::v-deep .daily-summary-table
  font-family: $family-fira
  font-weight: $weight-normal
  thead th:nth-child(n+7)
    display: none
  .is-hours-danger
    background-color: rgba($danger, 0.1)
  .is-hours-warning
    background-color: rgba(#FFC000, 0.25)
  .is-first
    background-color: #ebedf9
    font-weight: $weight-medium
  .header-background
    width: 175px
    background-color: $blue-slate
    padding: .75rem 0 .75rem 1.25rem
    .header-font
      margin-right: 1rem
      font-family: $family-headers
      font-size: 1rem
      color: $white !important
</style>
