<template>
  <div class="main-nav__wrapper">
    <nav>
      <ul class="main-nav__nav">
        <b-collapse
          :open="false"
          animation="slide"
          v-if="onboardingLinks.length">
          <template #trigger="props">
              <div
                  class="header-height"
                  role="button"
                  :class="{collapsed: !props.open}"
                  :aria-expanded="props.open"
              >
                  <li class="nav-header">
                      {{ 'Onboarding' }}
                   <span>
                    <fa-icon class="icon-right1" :icon="['fal', props.open ? 'chevron-up' : 'chevron-down']" />
                  </span>
                </li>
              </div>
          </template>
          <div>
              <div>
                <li
                  v-for="link in onboardingLinks"
                  :key="link.name"
                  class="nav-item">
                  <a
                    :class="{ active: isSiteActive(link.url) }"
                    @click="redirect(link.url)">
                    {{ link.name }}
                  </a>
                </li>
              </div>
          </div>
      </b-collapse>
      <b-collapse
          :open="false"
          animation="slide"
          v-if="analysisLinks.length">
          <template #trigger="props">
              <div
                  class="header-height"
                  role="button"
                  :class="{collapsed: !props.open}"
                  :aria-expanded="props.open"
              >
                  <p class="nav-header">
                      {{ 'Analysis' }}
                      <span>
                        <fa-icon class="icon-right1" :icon="['fal', props.open ? 'chevron-up' : 'chevron-down']" />
                      </span>
                  </p>
              </div>
          </template>
          <div>
              <div>
                <li
                  v-for="link in analysisLinks"
                  :key="link.name"
                  class="nav-item">
                  <a
                    :class="{ active: isSiteActive(link.url) }"
                    @click="redirect(link.url)">
                    {{ link.name }}
                  </a>
                </li>
              </div>
          </div>
      </b-collapse>
      <b-collapse v-if="adminLinks.length"
          :open="false"
          animation="slide">
          <template #trigger="props">
              <div
                  class="header-height"
                  role="button"
                  :class="{collapsed: !props.open}"
                  :aria-expanded="props.open"
              >
                  <p class="nav-header">
                      {{ 'Administration' }}
                      <span>
                        <fa-icon class="icon-right1" :icon="['fal', props.open ? 'chevron-up' : 'chevron-down']" />
                      </span>
                  </p>
              </div>
          </template>
          <div>
              <div>
                <li
                  v-for="link in adminLinks"
                  :key="link.name"
                  class="nav-item">
                  <a
                    :class="{ active: isSiteActive(link.url) }"
                    @click="redirect(link.url)">
                    {{ link.name }}
                  </a>
                </li>
              </div>
          </div>
      </b-collapse>
      </ul>
    </nav>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  computed: {
    headers() {
      const headers = ['Onboarding', 'Analysis', 'Admin'];
      return headers;
    },
    onboardingLinks() {
      const links = [];
      if (this.canAccess(['product', 'success', 'operations'])) {
        links.push();
      }
      if (this.canAccess(['product', 'success', 'sales'])) {
        links.push({
          name: 'Summary',
          url: '/onboarding',
        },
        {
          name: 'Underwriting',
          url: '/underwriting',
        });
      }
      if (this.canAccess(['product', 'success', 'operations'])) {
        links.push({
          name: 'Activities',
          url: '/activities',
        },
        {
          name: 'Team Accounts',
          url: '/onboarding-team-accounts',
        });
      }
      return links;
    },
    analysisLinks() {
      const links = [];
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        links.push({
          name: 'Contracts',
          url: '/contracts',
        });
      }
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        links.push({
          name: 'Digital Documents',
          url: '/digital-documents',
        });
      }
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        links.push({
          name: 'Team Accounts',
          url: '/team-accounts',
        });
      }
      links.push({
        name: 'Usage',
        url: '/usage',
      });
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        links.push({
          name: 'Processing Volume',
          url: '/processing-volume',
        });
      }
      if (this.canAccess(['product', 'operations']) || this.isCstAdmin) {
        links.push({
          name: 'Financial Tracking',
          url: '/financial-tracking',
        });
      }
      if (this.canAccess(['product', 'success', 'operations'])) {
        links.push({
          name: 'Tickets',
          url: '/tickets',
        });
      }
      return links;
    },
    adminLinks() {
      const links = [];
      if (this.canAccess(['product', 'success', 'marketing'])) {
        links.push({
          name: 'Clubs',
          url: '/clubs',
        });
      }
      if (this.isCstAdmin) {
        links.push({
          name: 'Users',
          url: '/users',
        });
      }
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        links.push({
          name: 'Merchant Accounts',
          url: '/merchant-accounts',
        });
      }
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        links.push({
          name: 'Background Checks',
          url: '/background-checks',
        });
      }
      return links;
    },
    currentRoutePath() {
      return this.$route.path;
    },
    isCstAdmin() {
      return this.$store.getters.isCstAdmin;
    },
  },
  methods: {
    redirect(url) {
      if (this.isSiteEqualToURL(url)) {
        // If trying to click a link that's already active, close the navbar
        this.$store.dispatch('hideSidenav');
      } else {
        this.$router.push(url);
      }
    },
    isSiteEqualToURL(url) {
      return this.currentRoutePath === url;
    },
    isSiteActive(url) {
      return _.endsWith(this.currentRoutePath, url);
    },
    canAccess(depts) {
      return this.$store.getters.canAccess(depts);
    },
  },
};
</script>

<style lang="sass" scoped>
$indicator-size: 6px
.main-nav__wrapper
  flex: 1 1 auto
  overflow-y: auto
  -ms-overflow-style: -ms-autohiding-scrollbar
  nav
    .main-nav__nav
      .nav-header
        font-family: $family-headers
        font-size: $size-6
        font-weight: $weight-semibold
        letter-spacing: 0.5px
        color: $sidenav-item-text-color
        padding: 0.75rem 1rem 0.75rem 2rem
        margin-top: 1rem
        &:hover
          color: $sidenav-item-highlight-color
        &.active
          color: $sidenav-item-active-color
      .icon-right1
        padding-top: 0.3rem
        float: right
        right: 0
        font-size: 0.75rem
      .header-height
        height: 2.75rem
      .collapsible
        background-color: #eee
        color: #444
        cursor: pointer
        padding: 18px
        width: 100%
        border: none
        text-align: left
        outline: none
        font-size: 15px

      .nav-item
        font-family: $family-headers
        font-size: $size-6
        font-weight: $weight-medium
        letter-spacing: 0.5px
        color: $sidenav-item-text-color
        padding: 0.5rem .5rem 0.5rem 3.25rem
        a
          position: relative
          display: block
          color: $sidenav-item-text-color
          transition: color 0.15s ease-in-out
          &:hover
            color: $sidenav-item-highlight-color
          &:active,
          &.is-active,
          &.active
            color: $sidenav-item-active-color
        .disabled
          color: $grey-light
          cursor: default
          &:hover
            color: $grey-light
        .indicator
          position: absolute
          right: calc(100% + .3rem)
          top: calc(50% - #{$indicator-size}/2)
          background-color: $orange
          border-radius: 100%
          width: $indicator-size
          height: $indicator-size
        .secondary-items-label
          opacity: 0.4
          margin-bottom: 0.5rem
        .secondary-item
          margin-left: .75rem
          padding: 0.5rem 0 0.5rem .75rem
          border-left: 1px solid #5A718E
          text-transform: none
          .secondary-detail
            font-size: $size-8
            font-weight: $weight-medium
            font-style: italic
          &.disabled
            color: rgba($white, 0.4)
            position: relative
            .lock-icon
              position: absolute
              left: -1.5rem
              .b-tooltip
                &:after
                  max-width: 11rem
</style>
