<template>
  <app-modal
    v-if="isAppOutOfDate"
    ref="updateModal"
    :use-mobile-modal="true">
    <div class="has-text-centered" style="padding: 2rem 2rem">
      <img src="/static/images/layout/tom-brady-soccer-ball.svg">
      <p class="is-size-5 has-text-weight-bold" style="margin-top:1rem">IT'S TIME TO UPDATE</p>
      <p class="has-text-weight-medium" style="margin-top:1rem">
        Looks like you're using an old version of the PlayMetrics App ({{ appVersion }}).
        Please update now to access new features, updated designs, and improved performance.
      </p>
      <div style="margin-top:1rem">
        <a
          :href="storeURL"
          class="button is-primary"
          target="_blank"
          @click="$clickExternalLink($event, storeURL)">
          Update PlayMetrics
        </a>
      </div>
      <div style="margin-top:1rem">
        <button class="button has-text-link is-outline is-borderless" @click="$refs.updateModal.dismissModal()">
          Remind me Later
        </button>
      </div>
    </div>
  </app-modal>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';

export default {
  components: {
    'app-modal': Modal,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
      showModal: true,
      MAXIMUM_MOBILE_PIXELS: 768,
      MAXIMUM_TABLET_PIXELS: 1023,
    };
  },

  computed: {
    device() {
      return this.$store.getters.device;
    },
    storeURL() {
      if (navigator.userAgent.includes('PlayMetricsiOS_')) {
        return 'https://itunes.apple.com/us/app/playmetrics/id1450640824';
      } else if (navigator.userAgent.includes('PlayMetricsAndroid_')) {
        return 'https://play.google.com/store/apps/details?id=com.playmetrics&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1';
      }
      return null;
    },
    isAppOutOfDate() {
      // Example PlayMetricsiOS_20.2.1
      if (!this.appVersion) return false;
      if (navigator.userAgent.includes('PlayMetricsiOS_')) {
        const version = parseFloat(this.appVersion.replace(/.([^.]*)$/, '$1'));
        return version < 23.3;
      } else if (navigator.userAgent.includes('PlayMetricsAndroid_')) {
        return this.androidAppVersion < 55;
      }
      return false;
    },
    appVersion() {
      if (navigator.userAgent.includes('PlayMetricsiOS_')) {
        return this.iOSAppVersion;
      } else if (navigator.userAgent.includes('PlayMetricsAndroid_')) {
        return this.androidAppVersion;
      }
      return null;
    },
    iOSAppVersion() {
      const iOSAppVersion = navigator.userAgent.match(/PlayMetricsiOS_(\d+(\.\d)*)/);
      if (iOSAppVersion) return iOSAppVersion[1];
      return null;
    },
    androidAppVersion() {
      const androidAppVersion = navigator.userAgent.match(/PlayMetricsAndroid_(\d+(\.\d)*)/);
      if (androidAppVersion) return androidAppVersion[1];
      return null;
    },
  },

  mounted() {
    const windowWidth = navigator.userAgent.includes('PlayMetricsiOS_') ? document.documentElement.clientWidth : window.innerWidth;
    this.$store.dispatch('setDevice', this.calculateDevice(windowWidth));
    if (this.$refs.updateModal) {
      this.$refs.updateModal.showModal();
    }
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('orientationchange', this.handleWindowResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('orientationchange', this.handleWindowResize);
  },

  methods: {
    calculateDevice(width) {
      if (width <= this.MAXIMUM_MOBILE_PIXELS) {
        return 'mobile';
      }
      if (width <= this.MAXIMUM_TABLET_PIXELS) {
        return 'tablet';
      }
      return 'desktop';
    },
    handleWindowResize(event) {
      if (navigator.userAgent.includes('PlayMetricsiOS_')) {
        // eslint-disable-next-line no-restricted-globals
        if (window.orientation === 90 || window.orientation === -90) {
          // eslint-disable-next-line no-restricted-globals
          this.windowWidth = screen.height;
        } else {
          // eslint-disable-next-line no-restricted-globals
          this.windowWidth = screen.width;
        }
      } else {
        this.windowWidth = event.currentTarget.innerWidth;
      }
      const calculatedDevice = this.calculateDevice(this.windowWidth);
      if (calculatedDevice !== this.device) {
        this.$store.dispatch('setDevice', calculatedDevice);
      }
    },
  },
};
</script>
