<template>
  <div>
    <FullScreenModal ref="modal">
      <template slot="header">
        <FormModalHeader
          :title="editing ? 'Edit Onboarding Activity' : 'New Onboarding Activity'"
          :wide-form="false"
          :actions="modalActions"
          @dismiss="dismiss"
        >
          <template slot="save">
            <b-button type="is-primary" @click="save">
              <span v-if="editing">Save Activity</span>
              <span v-else>Create Activity</span>
            </b-button>
          </template>
          <template slot="delete">
            <b-button type="ghost" @click="deleteActivity">
              <fa-icon class="is-danger" :icon="['far', 'trash-alt']" style="margin-right: .5rem"/>
              <span>Delete</span>
            </b-button>
          </template>
        </FormModalHeader>
      </template>
      <div v-if="activity" class="centered-tool activity-form">
        <div class="activity-form__section">
          <div class="activity-form__section__label">Meeting Details</div>
          <div class="columns">
            <div class="column is-two-fifths">
              <b-field :type="{'is-danger' : errors.club_id}" :message="errors.club_id" label="Club">
                <b-autocomplete expanded
                  :disabled="editing"
                  v-model="clubDataName"
                  placeholder="Search for Club"
                  :data="clubSearchFilter"
                  field="name"
                  @select="assignPlan($event)">
                </b-autocomplete>
              </b-field>
            </div>
            <div class="column">
              <b-field :type="{'is-danger' : errors.category}" :message="errors.category" label="Category">
                <b-select expanded v-model="activity.category" @input="activity.milestone = null">
                  <option
                    v-for="category in activityCategories"
                    :value="category.value"
                    :key="category.value"
                  >
                    {{ category.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field :type="{'is-danger' : errors.milestone}" :message="errors.milestone" label="Milestone">
                <b-select expanded :disabled="!activity.category" v-model="activity.milestone">
                  <option
                    v-for="milestone in milestones(activity.category)"
                    :value="milestone.value"
                    :key="milestone.value"
                  >
                    {{ milestone.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div class="activity-form__section">
          <div class="activity-form__section__label">Staff Details</div>
          <div class="columns">
            <div class="column is-one-quarter">
              <b-field :type="{'is-danger' : errors.lead_user_id}" :message="errors.lead_user_id" label="Lead">
                <AdminStaffDropdown
                  :disabled="!meetingDetailsComplete"
                  :departments="['product', 'success']"
                  :blacklist="activity.secondary_user_ids"
                  :value="activity.lead_user_id"
                  :isWebBuilderTeam="includeWebBuilders"
                  @input="activity.lead_user_id = $event.id"/>
              </b-field>
            </div>
              <b-field
                label="Secondary"
                v-for="(id, i) in staffTotal"
                :key="id"
                class="column is-one-quarter">
                <AdminStaffDropdown
                  :deselect="true"
                  :disabled="!meetingDetailsComplete"
                  :departments="['product', 'success']"
                  :blacklist="blacklistComp[i]"
                  :value="activity.secondary_user_ids[i]"
                  :isWebBuilderTeam="includeWebBuilders"
                  @input="blackListMethod($event.id, i)"/>
              </b-field>
            <div class="center-vertical">
              <a :class="addStaffDisabled" @click="addStaff">
                <div v-if="staffTotal < 3"
                  :class="addSecColor">
                  + Add Secondary Staff
                </div>
              </a>
            </div>
          </div>
        </div>
        <div class="activity-form__section">
          <div class="activity-form__section__label">Scheduling Details</div>
            <div class="columns">
              <div class="column is-two-fifths">
                <b-field :type="{'is-danger' : errors.meeting_date}" :message="errors.meeting_date">
                  <template slot="label">
                     <p class="label">Date <i>(optional)</i></p>
                  </template>
                  <DatePickerString
                    :disabled="!meetingDetailsComplete === undefined ? null : !meetingDetailsComplete"
                    :min-date="minDate"
                    v-model="activity.meeting_date"
                    icon-right="calendar"
                    placeholder ="mm/dd/yyyy"
                    editable/>
                </b-field>
              </div>
              <div class="column is-one-fifth">
                <b-field label="Hours" :type="{'is-danger' : errors.duration}" :message="errors.duration">
                  <b-input v-model="hours" @input="updateDuration()" :disabled="!meetingDetailsComplete"/>
                </b-field>
              </div>
              <div class="column is-one-fifth">
                <b-field label="Minutes" :type="{'is-danger' : errors.duration}" :message="errors.duration">
                  <b-select expanded
                    v-model="minutes"
                    @input="updateDuration()"
                    :disabled="!meetingDetailsComplete">
                    <option
                      v-for="time in timeMinutes"
                      :value="time.value"
                      :key="time.value"
                    >
                      {{ time.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
        <div>
          <ActivitiesNotes ref="activityNotesModal"
            @noteCreated="noteUpdated"
            @noteDeleted="noteUpdated"
            :activity="activity"/>
      </div>
    </div>
    </FullScreenModal>
  </div>
</template>

<script>
import moment from 'moment';
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import FormModalHeader from '@/components/layout/modals/FormModalHeader';
import AdminStaffDropdown from '@/components/users/AdminStaffDropdown';
import DatePickerString from '@/components/common/form-elements/DatePickerString';
import ActivitiesNotes from '@/components/activities/ActivitiesNotes';
import api from '@/http-playmetrics';

const optionalFields = ['secondary_user_ids', 'notes', 'meeting_date'];

export default {
  components: { FullScreenModal, FormModalHeader, AdminStaffDropdown, DatePickerString, ActivitiesNotes },
  data() {
    return {
      clubDataName: '',
      onboardingPlans: [],
      hours: null,
      minDate: new Date('2018-01-18'),
      minutes: null,
      loading: false,
      editing: false,
      activity: null,
      errors: {},
      staffTotal: 0,
      blacklistList: [],
    };
  },
  computed: {
    includeWebBuilders() {
      return this.activity.milestone === 'Website Builder';
    },
    addStaffDisabled() {
      if (!(this.meetingDetailsComplete)) {
        return 'disableClick';
      } else {
        return '';
      }
    },
    clubSearchFilter() {
      return this.clubSelect.filter(option =>
        this.clubName(option.id).toString().toLowerCase().indexOf(this.clubDataName.toLowerCase()) >= 0);
    },
    notesVal() {
      if (this.editing) {
        return this.activity;
      } else {
        return this.activityNotes;
      }
    },
    addSecColor() {
      if (!(this.meetingDetailsComplete)) {
        return 'column activity-form__section__add-secondary-staff__invalid';
      }
      return 'column activity-form__section__add-secondary-staff__valid';
    },
    modalActions() {
      if (this.editing) {
        return ['delete', 'save'];
      }
      return ['save'];
    },
    filterSecondaryUsers() {
      const secondaryUsers = this.activity.secondary_user_ids.filter(user => user !== 0);
      if (secondaryUsers.length > 0) {
        return secondaryUsers;
      }
      return null;
    },
    toSave() {
      return {
        club_id: this.activity.club_id,
        lead_user_id: this.activity.lead_user_id,
        secondary_user_ids: this.filterSecondaryUsers,
        meeting_date: this.activity.meeting_date,
        duration: this.activity.duration,
        milestone: this.activity.milestone,
        category: this.activity.category,
      };
    },
    notesData() {
      return {
        id: this.activity.id,
        notes: [...this.activityNotes.find(club => club.id === this.activity.club_id)?.notes],
      };
    },
    dateInvalid() {
      return (moment(this.activity.meeting_date).isBefore(moment(this.minDate)) && this.activity.meeting_date);
    },
    meetingDetailsComplete() {
      return !!(this.activity.club_id && this.activity.milestone && this.activity.category);
    },
    blacklistComp() {
      const userIds = this.activity.secondary_user_ids;
      return [
        [this.activity.lead_user_id, userIds[1], userIds[2]],
        [this.activity.lead_user_id, userIds[0], userIds[2]],
        [this.activity.lead_user_id, userIds[0], userIds[1]]];
    },
    clubs() {
      return this.$store.getters.clubs;
    },
    clubSelect() {
      return this.$store.getters.clubs.filter(club => !(club.is_deleted));
    },
    timeMinutes() {
      return [
        {
          name: '0 mins',
          value: 0,
        },
        {
          name: '15 mins',
          value: 15,
        },
        {
          name: '30 mins',
          value: 30,
        },
        {
          name: '45 mins',
          value: 45,
        },
      ];
    },
    activityCategories() {
      return this.$store.getters.activityOnboardingCategory;
    },
  },
  methods: {
    fetchActivity(id) {
      this.loading = true;
      api().get(`/cst/activities/${id}?populate=notes`).then((res) => {
        this.activity = res.data;

        this.staffTotal = 1;
        this.minutes = 0;
        this.hours = 0;
        this.errors = [];
        if (this.activity) {
          this.editing = true;
          this.staffTotal = this.activity.secondary_user_ids.length;
          this.minutes = this.getMinutes(this.activity.duration);
          this.hours = this.getHours(this.activity.duration);
          this.clubDataName = this.clubName(this.activity.club_id);
        }
        api().post('/cst/club_onboarding_plans/search?populate=users').then((res2) => {
          this.onboardingPlans = res2.data.map(plan => ({
            ...plan,
            club_name: this.clubName(plan.club_id),
          }));
          this.loading = false;
        });
      });
    },
    assignPlan(plan) {
      if (plan) {
        const onboardingPlan = this.onboardingPlans.find(o => o.club_id === plan.id) ?
          this.onboardingPlans.find(o => o.club_id === plan.id) : null;
        if (onboardingPlan) {
          this.activity.lead_user_id = onboardingPlan.cs_lead_user_id;
          this.activity.secondary_user_ids[0] = onboardingPlan.cs_secondary_user_id;
        } else {
          this.activity.lead_user_id = 0;
          this.activity.secondary_user_ids[0] = 0;
        }
        this.activity.club_id = plan.id;
      } else {
        this.activity = {
          club_id: null,
          category: null,
          milestone: null,
          lead_user_id: 0,
          secondary_user_ids: [0, 0, 0],
          duration: 0,
          meeting_date: null,
        };
      }
    },
    noteUpdated(id) {
      if (this.editing) {
        this.fetchActivity(id);
      }
    },
    clubName(id) {
      return this.clubs.find(club => club.id === id)?.name || '';
    },
    activityUserUpdate() {
      if (!this.editing) {
        const onboardingPlan = this.onboardingPlans.find(plan => plan.club_id === this.activity.club_id);
        if (onboardingPlan) {
          this.activity.lead_user_id = onboardingPlan.cs_lead_user_id;
          this.activity.secondary_user_ids[0] = onboardingPlan.cs_secondary_user_id;
        } else {
          this.activity.lead_user_id = 0;
          this.activity.secondary_user_ids = [0, 0, 0];
        }
      }
    },
    deleteActivity() {
      this.$confirm.showModal({
        confirmText: 'Yes, Delete Activity',
        message: `You are about to delete this onboarding activity for
                  <strong>${this.clubName(this.activity.club_id)}'s</strong>. Are you sure
                  you want to continue?`,
        title: 'Delete Onboarding Activity',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => {
          api().delete(`/cst/activities/${this.activity.id}`).then(() => {
            this.$store.commit('setNotification', { toast: true, success: true, message: 'Activity Deleted' });
            this.dismiss();
          });
        },
      });
    },
    updateDuration() {
      this.activity.duration = (this.hours * 60) + this.minutes;
    },
    blackListMethod(id, i) {
      this.$set(this.activity.secondary_user_ids, i, id);
    },
    addStaff() {
      if (this.staffTotal < 3) {
        this.staffTotal += 1;
        this.activity.secondary_user_ids[this.staffTotal - 1] = 0;
      }
    },
    isRequiredField(key) {
      return !(optionalFields.includes(key));
    },
    isEmptyField(key) {
      return ((typeof this.activity[key] === 'object' && this.activity[key] === null) || (typeof this.activity[key] === 'number' && !this.activity[key]));
    },
    validate() {
      Object.keys(this.activity).forEach((key) => {
        if (this.dateInvalid && key === 'meeting_date') {
          this.$set(this.errors, key, 'Value must be MM/DD/YYYY or later');
        } else if (key === 'lead_user_id') {
          if (this.isEmptyField(key) && this.meetingDetailsComplete) {
            this.$set(this.errors, key, 'This is a required field');
          } else {
            this.$delete(this.errors, key);
          }
        } else if (key === 'duration') {
          if (this.isEmptyField(key) && this.meetingDetailsComplete) {
            this.$set(this.errors, key, 'This is a required field');
          } else {
            this.$delete(this.errors, key);
          }
        } else if (this.isRequiredField(key)) {
          if (this.isEmptyField(key)) {
            this.$set(this.errors, key, 'This is a required field');
          } else {
            this.$delete(this.errors, key);
          }
        } else {
          this.$delete(this.errors, key);
        }
      });
    },
    async show(id) {
      if (id) {
        await this.fetchActivity(id);
      } else {
        this.clubDataName = '';
        this.editing = false;
        this.activity = {
          club_id: null,
          category: null,
          milestone: null,
          lead_user_id: 0,
          secondary_user_ids: [0, 0, 0],
          duration: 0,
          meeting_date: null,
        };
      }
      this.$refs.modal.show();
    },
    getHours(time) {
      return Math.floor(time / 60);
    },
    getMinutes(time) {
      return time % 60;
    },
    dismiss(options = { refreshData: false, saving: false }) {
      if (this.editing && !options.saving) this.$refs.activityNotesModal.deleteNewNotes();
      this.$refs.modal.dismiss();
      this.$emit('dismissed', options);
    },
    saveNew(activityUrl) {
      this.postActivity(activityUrl);
    },
    saveExisting(activityUrl) {
      this.postActivity(activityUrl);
    },
    postActivityNotes(id) {
      if (!this.editing) {
        this.$refs.activityNotesModal.submitNote(id);
      }
    },
    postActivity(url) {
      api().post(url, this.toSave).then((res) => {
        this.postActivityNotes(res.data.id);
        this.$store.commit('setNotification', { toast: true, success: true, message: 'Activity updated' });
        this.dismiss({ refreshData: true, saving: true });
      });
    },
    save() {
      this.validate();
      if (Object.keys(this.errors).length === 0) {
        if (this.editing) {
          this.saveExisting(`/cst/activities/${this.activity.id}`);
        } else {
          this.saveNew('/cst/activities/');
        }
      }
    },
    milestones(category) {
      return this.$store.getters.activityMilestones[category] || [];
    },
  },
};

</script>

<style lang="sass" scoped>
.activity-form
  &__section
    margin-bottom: 3rem
    &__label
      font-family: $family-headers
      font-weight: $weight-semibold
      font-size: $size-4
      margin-bottom: 1rem
    &__add-secondary-staff
      position: relative
      top: 2.5rem
      font-weight: $weight-medium
      &__valid
        color: $blue-brand
      &__invalid
        color: $grey
.center-vertical
  display: flex
  align-items: center
  justify-content: flex-start
  margin-top: 1rem
.disableClick
  pointer-events: none
</style>
