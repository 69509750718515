<template>
  <div v-if="loading">
    <spinner style="display: flex; justify-content: center; margin-top: 3rem; margin-bottom: 3rem;"/>
  </div>
  <div v-else>
    <PageNavigation page-title="Usage"/>
    <div class="selector-container">
      <ViewSelector
        v-model="currentView"
        :options="['Summary', 'Setup', 'Registrations', 'Operations', 'Communications']"
      />
    </div>
    <SummaryView v-if="currentView === 'Summary'" :data="mappedStats" :summary="summaryStats"/>
    <RegistrationsView v-if="currentView === 'Registrations'" :data="mappedStats"/>
    <CommunicationsView v-if="currentView === 'Communications'" :data="mappedStats"/>
    <OperationsView v-if="currentView === 'Operations'" :data="mappedStats"/>
    <SetupView v-if="currentView === 'Setup'" :data="mappedStats"/>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
// import _ from 'lodash';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import ViewSelector from '@/components/common/ViewSelector';
import RegistrationsView from '@/components/usage/RegistrationsView';
import CommunicationsView from '@/components/usage/CommunicationsView';
import OperationsView from '@/components/usage/OperationsView';
import SetupView from '@/components/usage/SetupView';
import SummaryView from '@/components/usage/SummaryView';


export default {
  components: { Spinner,
    PageNavigation,
    ViewSelector,
    RegistrationsView,
    OperationsView,
    SetupView,
    CommunicationsView,
    SummaryView },
  data() {
    return {
      kpiStats: null,
      currentView: 'Summary',
      clubs: [],
      plans: [],
      summaryStats: [],
      mappedStats: [],
      loading: false,
    };
  },
  computed: {
    clubsWithOnboarding() {
      return this.clubs.filter(club => club.config.onboarding_status || club.config.onboarding_type);
    },
  },
  created() {
    this.fetchPlans();
    this.fetchClubs();
  },
  methods: {
    plansByID(id) {
      return this.plans.find(plan => plan.club_id === id);
    },
    getPlanStaff(plan) {
      return [plan.cs_lead_user, plan.cs_secondary_user];
    },
    fetchClubs() {
      this.loading = true;
      api().post('/cst/clubs/search').then((res) => {
        this.clubs = res.data;
        this.fetchkpiStats();
      });
    },
    fetchkpiStats() {
      api().post('/cst/usage/search', [10000, 10001, 10002, 10003, 10004, 10005, 10006, 10007, 10008, 10009, 10010, 10011, 10012]).then((res) => {
        this.kpiStats = res.data;
        this.mapStats();
        this.fetchSummaryStats();
      });
    },
    fetchSummaryStats() {
      api().get('/cst/usage/summary').then((res) => {
        this.summaryStats = res.data;
        this.loading = false;
      });
    },
    fetchPlans() {
      api().post('/cst/club_onboarding_plans/search?populate=users').then((res) => {
        this.plans = res.data;
      });
    },
    mapStats() {
      this.mappedStats = this.clubs.filter(club => Object.keys(this.kpiStats.active_teams).includes(`${club.id}`)).map(club => ({
        id: club.id,
        name: club.name,
        size: club.size,
        customer_since: club.config.customer_since,
        onboarding_status: this.plansByID(club.id)?.status,
        onboarding_size: club.config.onboarding_type ? club.config.onboarding_type : '',
        cs_lead_user: this.plansByID(club.id)?.cs_lead_user_id,
        cs_secondary_user: this.plansByID(club.id)?.cs_secondary_user_id,
        sales_contact_user_id: club.config.sales_contact_user_id,
        rec_player_cap: club.config.rec_player_cap ? club.config.rec_player_cap : 0,
        comp_player_cap: club.config.competitive_player_cap ? club.config.competitive_player_cap : 0,
        accounts: this.kpiStats.total_accounts?.[club.id],
        teams: this.kpiStats.active_teams?.[club.id],
        players: this.kpiStats.active_team_players?.[club.id],
        leagues: this.kpiStats.active_leagues?.[club.id],
        programs: this.kpiStats.open_programs?.[club.id],
        transactions: this.kpiStats.new_transactions?.[club.id],
        revenue: this.kpiStats.new_revenue?.[club.id],
        events: this.kpiStats.new_events?.[club.id],
        messages: this.kpiStats.new_messages?.[club.id],
        chats: this.kpiStats.new_chat_messages?.[club.id],
        forms: this.kpiStats.open_form_requests?.[club.id],
      }));
    },
  },
};
</script>

<style lang="sass" scoped>
.spinner
  display: flex
  justify-content: center
  align-content: space-between
.selector-container
  display: flex
  align-items: flex-end
  justify-content: space-between
</style>
