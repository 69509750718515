<template>
  <div>
    <PageNavigation
      page-title="Team Accounts"
      :active-tab="activeTab"
      :tabs="tabs"
      @tabClicked="tabClicked($event)"/>
    <div
      v-if="activeTab === 'Accounts'">
        <AccountTab :clubs="filteredClubs"
          :financial-account-status-by-club-id="financialAccountStatusByClubId"
          :team-accounts-by-club-id="teamAccountsByClubId"
          :merchant-accounts-by-club-id="merchantAccountsByClubId"
          :loading="loading" />
    </div>
    <div
      v-if="activeTab === 'Cards'">
        <CardholdersTab :clubs-by-id="clubsById"
          :merchant-accounts-by-club-id="merchantAccountsByClubId"
          :loading="loading" />
    </div>
    <div
      v-if="activeTab === 'Debits'">
        <DebitsTab :clubs-by-id="clubsById" :loading="loading" />
    </div>
    <div
      v-if="activeTab === 'Credits'">
        <CreditsTab :clubs-by-id="clubsById" :loading="loading" />
    </div>
  </div>
</template>

<script>
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import AccountTab from '@/components/team_accounts/TeamAccountsAccount';
import CardholdersTab from '@/components/team_accounts/TeamAccountsCardholders';
import DebitsTab from '@/components/team_accounts/TeamAccountsDebits';
import CreditsTab from '@/components/team_accounts/TeamAccountsCredits';
import api from '@/http-playmetrics';
import _ from 'lodash';

export default {
  components: { AccountTab, PageNavigation, CardholdersTab, DebitsTab, CreditsTab },

  props: {
    defaultTab: { type: String, default: 'Accounts' },
  },

  data() {
    return {
      loading: false,
      clubs: [],
      teamAccounts: [],
      financialAccountStatus: [],
      merchantAccounts: [],
    };
  },
  computed: {
    activeTab() {
      return this.defaultTab;
    },
    tabs() {
      return [{ name: 'Accounts' }, { name: 'Cards' }, { name: 'Debits' }, { name: 'Credits' }];
    },
    teamAccountsByClubId() {
      return _.groupBy(this.teamAccounts, ta => ta.club_id);
    },
    financialAccountStatusByClubId() {
      return _.keyBy(this.financialAccountStatus, fas => fas.club_id);
    },
    merchantAccountsByClubId() {
      return _.groupBy(this.merchantAccounts, ma => ma.club_id);
    },
    clubsById() {
      return _.keyBy(this.clubs, club => club.id);
    },
    filteredClubs() {
      return this.clubs.filter(club => !club.is_test_club && !!this.teamAccountsByClubId[club.id]);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      Promise.all([
        this.fetchClubs(),
        this.fetchFinancialAccountStatus(),
        this.fetchTeamAccounts(),
        this.fetchMerchantAccounts(),
      ]).finally(() => {
        this.loading = false;
      });
    },
    fetchClubs() {
      return api().post('/cst/clubs/search?populate=contracts,sports,onboarding_plans,onboarding_plans:activities,onboarding_plans:users').then((res) => {
        if (res.status === 200) {
          this.clubs = res.data;
        }
      });
    },
    fetchTeamAccounts() {
      return api().post('cst/team_accounts/search').then((res) => {
        if (res.status === 200) {
          this.teamAccounts = res.data?.filter(ta => !ta.archived_at);
        }
      });
    },
    fetchFinancialAccountStatus() {
      return api().post('cst/merchant_financial_account/status').then((res) => {
        if (res.status === 200) {
          this.financialAccountStatus = res.data;
        }
      });
    },
    fetchMerchantAccounts() {
      return api().post('/cst/merchant_accounts/search').then((res) => {
        this.merchantAccounts = res.data;
      });
    },
    tabChange(tab) {
      this.activeTab = tab;
    },
    tabClicked($event) {
      if ($event === this.activeTab) return;
      const map = {
        Accounts: 'TeamAccounts',
        Cards: 'TeamAccountCardholders',
        Debits: 'TeamAccountDebits',
        Credits: 'TeamAccountCredits',

      };
      const route = map[$event] || 'TeamAccounts';
      this.$router.push({ name: route });
    },
    matchesName(tableData, searchQuery) {
      return tableData?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase() ?? '');
    },
  },
};
</script>
