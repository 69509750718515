<template>
  <div>
    <a v-if="hasFiltersActive" @click="clearFilters" class="filter-link">Clear Filter</a>
    <FilterSection
      label="State"
      defaultOpen>
      <b-field>
        <div>
          <div class="tags">
            <a v-for="st in states"
              :key="st"
              :class="filters.state.allow.includes(st) ? 'is-primary' : ''"
              class="tag is-medium is-white is-rounded is-outlined"
              @click="tagSelected(st, 'state')">
              {{ st }}
            </a>
          </div>
        </div>
      </b-field>
    </FilterSection>
    <FilterSection
      label="Sport"
      defaultOpen>
      <b-field>
        <div>
          <div class="tags">
            <a v-for="s in sports"
              :key="s.value"
              :class="filters.sport.allow.includes(s.value) ? 'is-primary' : ''"
              class="tag is-medium is-white is-rounded is-outlined"
              @click="tagSelected(s.value, 'sport')">
              {{ s.name }}
            </a>
          </div>
        </div>
      </b-field>
    </FilterSection>
    <FilterSection
      label="Risk Rating"
      defaultOpen>
      <b-field v-for="rr in risk_ratings"
      :key="rr.value">
        <b-checkbox v-model="filters.risk_rating.allow" :native-value="rr.value">
          {{ rr.name }}
        </b-checkbox>
      </b-field>
    </FilterSection>
    <FilterSection
      class="filter-comparison"
      label="Expected APV"
      defaultOpen>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.expected_apv.allow.comparison">
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="amount"
          v-model.number="filters.expected_apv.allow.number"
          type="number"/>
      </b-field>
    </FilterSection>
    <FilterSection
      class="filter-comparison"
      label="Expected Prepayment Coefficient"
      defaultOpen>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.expected_prepayment_coefficient.allow.comparison">
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="amount"
          v-model.number="filters.expected_prepayment_coefficient.allow.number"
          type="number"/>
      </b-field>
    </FilterSection>
    <FilterSection
      label="Advanced"
      defaultOpen>
      <p class="label">Show clubs with missing:</p>
      <b-field>
        <b-checkbox v-model="filters.advanced.allow" :native-value="'risk_rating'">Risk Rating</b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="filters.advanced.allow" :native-value="'expected_apv'">Expected APV</b-checkbox>
      </b-field>
      <b-field>
        <b-checkbox v-model="filters.advanced.allow"
        :native-value="'expected_prepayment_coefficient'">Expected Prepayment Coefficient</b-checkbox>
      </b-field>
    </FilterSection>
  </div>
</template>

<script>
import FilterSection from '@/components/common/filter/FilterSection';
import _ from 'lodash';

export default {
  components: {
    FilterSection,
  },
  props: {
    states: { type: Array, default: () => [] },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('clubFinancialTracking')),
    };
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'clubFinancialTracking' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('clubFinancialTracking'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'clubFinancialTracking', filters: _.cloneDeep(this.filters) });
    },
    tagSelected(tag, type) {
      if (this.filters[type].allow.includes(tag)) {
        this.filters[type].allow = _.without(this.filters[type].allow, tag);
      } else {
        this.filters[type].allow = _.concat(this.filters[type].allow, tag);
      }
    },
  },
  computed: {
    sports() {
      return this.$store.getters.clubSports;
    },
    risk_ratings() {
      return this.$store.getters.clubRiskRating;
    },
    advanced() {
      return [
        { name: 'Risk Rating', value: 'risk_rating' },
        { name: 'Expected APV', value: 'apv' },
        { name: 'Expected Prepayment Coefficient', value: 'epc' },
      ];
    },
    hasFiltersActive() {
      return !_.isEmpty(this.activeFilters);
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('clubFinancialTracking');
    },
  },
};
</script>

<style lang="sass" scoped>
.filter-link
  position: relative
  top: -1.25rem
  margin-left: 1.25rem
  color: $black-ter
  font-size: $size-6
  font-weight: $weight-normal
  text-align: left
  text-decoration: underline
.filter-comparison
  .comparison
    margin-right: 0.5rem !important
  .count
    width: 100px
    text-align: right
  .tag:hover
    text-decoration: none
</style>
