<template>
  <div>
    <Modal
      ref="modal"
      title="Export Crossbar Data">
      <template>
        <p class="mb-5">{{ exportText }}</p>
        <p class="has-text-weight-semibold mb-3">Select Export Data</p>
          <b-field class="mb-5">
            <b-radio class="mr-5"
               v-model="exportSelect"
               name="Payments"
               native-value="payment">Payments</b-radio>
            <b-radio class="mr-5"
               v-model="exportSelect"
               name="Refunds"
               native-value="refund">Refunds</b-radio>
            <b-radio v-model="exportSelect" name="Chargebacks" native-value="dispute">Chargebacks</b-radio>
          </b-field>
      </template>
      <template slot="footer">
        <div>
          <b-button
            v-if="disabled"
            tag="a"
            class="is-ghost button is-primary"
            :disabled="disabled"
            :loading="loading">
            <span class="mr-2">
              <fa-icon
                :icon="['far', 'sign-in']"
                :transform="{ rotate: 90 }"/>
            </span>
            Download as .CSV
          </b-button>
          <b-button
            v-else
            tag="a"
            class="is-ghost button is-primary"
            :loading="exportURL === ''"
            :href="exportURL"
            target="_blank"
            @click="dismissModal">
            <span class="mr-2">
              <fa-icon
                :icon="['far', 'sign-in']"
                :transform=" {rotate: 90 }"/>
            </span>
            Download as .CSV
          </b-button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import api from '@/http-playmetrics';

export default {
  components: {
    Modal,
  },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      exportSelect: 'refund',
      allURL: '',
    };
  },
  computed: {
    exportText() {
      return 'Generate a .csv file of Crossbar financial tracking data for the last 12 months';
    },
    exportURL() {
      if (this.disabled) {
        return '';
      }
      return `${this.allURL}&txn_type=${this.exportSelect}`;
    },
  },
  methods: {
    handleSave(event) {
      this.clubIDS = event;
    },
    async showModal() {
      this.$refs.modal.showModal();
      api().serverURL('cst/crossbar/export.csv').then((url) => {
        this.allURL = url;
      });
    },
    dismissModal() {
      this.$refs.modal.dismissModal();
    },
  },
};
</script>
