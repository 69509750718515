<template>
  <Spinner v-if="loading"/>
  <div v-else>
    <div class="view-selector">
      <strong> View hours as: </strong>
      <b-select
        style="margin-left: 1rem"
        placeholder="Primary"
        v-model="currentView">
        <option
          v-for="view in views"
          :value="view"
          :key="view"
        >
          {{ view }}
        </option>
      </b-select>
    </div>
    <TablePaginated
      name="Staff Daily Summary"
      v-if="tableRows.length"
      :searchable="false"
      :filterable="false"
      bordered
      :data="tableRows"
      :showPagination="false"
      class="onboarding-summary-table">
      <template slot-scope="props">
        <b-table-column label="Staff"
          field="user_name"
          class="user-name-column"
          header-class="is-unselectable header-background">
          <a @click="viewUser(props.row.id)"> {{ props.row.user_name | capitalize }} </a>
        </b-table-column>
        <b-table-column label="Total Clubs" field="total_clubs" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.total_clubs }} </div>
        </b-table-column>
        <b-table-column label="Not Started" field="clubs_not_started" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.clubs_not_started }} </div>
        </b-table-column>
        <b-table-column label="Active" field="clubs_active" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.clubs_active }} </div>
        </b-table-column>
        <b-table-column label="Onboarding" field="clubs_onboarding" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.clubs_onboarding }} </div>
        </b-table-column>
        <b-table-column label="Weekly Calls" field="clubs_weekly_calls" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.clubs_weekly_calls }} </div>
        </b-table-column>
        <b-table-column label="Monitoring" field="clubs_monitoring" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.clubs_monitoring }} </div>
        </b-table-column>
        <b-table-column label="Onboarding Remaining" field="remaining_onboarding" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.remaining_onboarding }} </div>
        </b-table-column>
        <b-table-column label="Onboarding This Week" field="week_onboarding_time" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.week_onboarding_time }} </div>
        </b-table-column>
        <b-table-column label="Meetings This Week" field="week_meeting_time" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.week_meeting_time }} </div>
        </b-table-column>
      </template>
    </TablePaginated>
    <template v-else>
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>No Activities this Week</p>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';

export default {
  components: { TablePaginated, Spinner },
  data() {
    return {
      currentView: 'Overall',
      views: ['Overall', 'Primary', 'Secondary'],
      onboardingActs: ['setup', 'registration', 'operations', 'website'],
      loading: false,
      plans: [],
    };
  },
  created() {
    this.fetchPlans();
  },
  computed: {
    users() {
      const depUsers = this.$store.getters.usersByDepartments(['product', 'success']);
      depUsers.push(...this.$store.getters.webBuilderUsers);
      return depUsers.filter(staff => staff.first_name || staff.last_name);
    },
    filteredPlans() {
      return this.plans.filter(plan => !(plan.archived_at || plan.status === 'completed'));
    },
    activities() {
      const arr = [];
      this.filteredPlans.forEach(plan => arr.push(plan.activities));
      return arr.flat();
    },
    week() {
      return [moment().startOf('week'), moment().endOf('week')];
    },
    mappedUsers() {
      return this.users.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        plans: this.plansByUser(user.id),
      })).sort((user1, user2) => ((user1.name < user2.name) ? -1 : 1));
    },
    tableRows() {
      return this.mappedUsers.map(user => ({
        id: user.id,
        user_name: user.name,
        total_clubs: user.plans.length ? user.plans.length : '-',
        clubs_not_started: this.countPlans(user.plans, 'not started'),
        clubs_active: this.countPlans(user.plans, 'completed'),
        clubs_onboarding: this.countPlans(user.plans, 'onboarding'),
        clubs_weekly_calls: this.countPlans(user.plans, 'weekly calls'),
        clubs_monitoring: this.countPlans(user.plans, 'monitoring'),
        remaining_onboarding: this.toHours(this.remainingActivites(user.id)),
        week_onboarding_time: this.toHours(this.getWeekTime('onboarding', user.id)),
        week_meeting_time: this.toHours(this.getWeekTime('meeting', user.id)),
      }));
    },
    clubs() {
      return this.$store.getters.clubs;
    },
  },
  methods: {
    viewUser(id) {
      this.$router.push({ name: 'UserScheduleView', params: { userId: id, dateRange: 'default' } });
    },
    countPlans(plans, status) {
      if (!plans.length) return '-';
      else return plans.filter(plan => plan.status === status).length ? plans.filter(plan => plan.status === status).length : '-';
    },
    toHours(m) {
      if (m) {
        const hrs = Math.floor(m / 60);
        const mins = m % 60;
        return hrs ? `${hrs} hrs ${mins} mins` : `${mins} mins`;
      } else return '-';
    },
    getTime(activities) {
      return activities.length ? activities.reduce((acc, activity) => acc + activity.duration, 0) : 0;
    },
    timeSpent(activities) {
      return activities.length ? activities.reduce((acc, activity) => acc + activity.duration, 0) : 0;
    },
    getWeekTime(type, id) {
      const userActivities = this.activitiesByUser(id);
      let thisWeeksActivities;
      if (type === 'onboarding') {
        thisWeeksActivities = userActivities.filter(act => this.onboardingActs.includes(act.category)
          && moment(act.meeting_date).isSameOrAfter(this.week[0], 'day')
          && moment(act.meeting_date).isSameOrBefore(this.week[1], 'day'));
      } else {
        thisWeeksActivities = userActivities.filter(act => moment(act.meeting_date).isSameOrAfter(this.week[0], 'day')
          && moment(act.meeting_date).isSameOrBefore(this.week[1], 'day'));
      }
      return this.timeSpent(thisWeeksActivities);
    },
    remainingActivites(id) {
      const userActivities = this.activitiesByUser(id);
      const remaining = userActivities.filter((activity) => {
        if (!activity.meeting_date) return true;
        if (moment(activity.meeting_date).isSameOrAfter(moment.now(), 'day')) return true;
        return false;
      });
      return this.timeSpent(remaining);
    },
    plansByUser(id) {
      if (this.currentView === 'Primary') {
        return this.filteredPlans.filter(plan => plan.cs_lead_user_id === id);
      } else if (this.currentView === 'Secondary') {
        return this.filteredPlans.filter(plan => plan.cs_secondary_user_id === id);
      } else {
        return this.filteredPlans.filter(plan => plan.cs_lead_user_id === id || plan.cs_secondary_user_id === id);
      }
    },
    activitiesByUser(id) {
      if (this.currentView === 'Primary') {
        return this.activities.filter(activity => activity.lead_user_id === id);
      } else if (this.currentView === 'Secondary') {
        return this.activities.filter(activity => activity.secondary_user_ids?.includes(id));
      } else {
        return [...this.activities.filter(activity => activity.lead_user_id === id),
          ...this.activities.filter(activity => activity.secondary_user_ids?.includes(id))];
      }
    },
    fetchPlans() {
      api().post('/cst/club_onboarding_plans/search?populate=users,activities').then((res) => {
        this.plans = res.data;
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.user-name-column:hover
  background-color: $white-ter
.view-selector
  margin-top: 1rem
  display: flex
  align-items: center
  justify-content: flex-end
::v-deep .onboarding-summary-table
  font-family: $family-fira
  font-weight: $weight-normal
  .header-background
    vertical-align: bottom
    width: 175px
    font-family: $family-headers
    font-size: 1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
</style>
