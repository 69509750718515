<template>
  <div class="main-nav__footer">

    <template>
      <b-dropdown aria-role="list" class="main-nav__footer-section" @active-change="accountOpen = $event">
        <div slot="trigger" class="level is-mobile">
          <div class="level-left">
            {{ accountName }}
          </div>
          <div class="level-right">
            <fa-icon v-if="accountOpen" :icon="['fal', 'chevron-up']" />
            <fa-icon v-else :icon="['fal', 'chevron-down']" />
          </div>
        </div>

        <b-dropdown-item :has-link="true" aria-role="listitem">
          <a
            @click="logOut();">
            Sign Out
          </a>
        </b-dropdown-item>
      </b-dropdown>
    </template>


    <div class="main-nav__footer-section copyright">
      &copy; 2020-{{ year }} PlayMetrics, LLC. All Rights Reserved
    </div>

  </div>
</template>

<script>

export default {
  data() {
    return {
      accountOpen: false,
    };
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    accountName() {
      if (this.user.last_name !== '') {
        let firstName = '';
        if (this.user.first_name) {
          firstName = this.user.first_name.length > 1 ? `${this.user.first_name.slice(0, 1)}.` : this.user.first_name;
        }
        return `${firstName} ${this.user.last_name}`;
      }
      return this.user.email;
    },
    year() {
      return new Date().getFullYear();
    },
  },

  methods: {
    logOut() {
      this.$store.dispatch('logOut');
    },
  },
};
</script>

<style lang="sass">
.main-nav__footer
  width: 100%
  color: $header-color
  .main-nav__footer-section
    color: $white
    border-top: 1px solid rgba(90, 113, 142, 0.4)
    font-family: $family-headers
    font-size: $size-7
    font-weight: $weight-bold
    width: 100%
    margin-left: 0 !important
    .dropdown-trigger
      width: 100%
    .level,
    > a
      display: flex
      padding: $size-6 $size-6 $size-6 $size-5
      color: $white
      cursor: pointer
    .app-logo
      max-width: 100px
      &.mobile-logo
        max-width: 120px
    .menu-icon
      color: #57E451
      width: 1rem
      margin-right: .15rem
    &.copyright
      font-size: $size-8
      font-weight: $weight-medium
      opacity: 0.6
      padding: $size-6 .5rem
      &.mobile-copyright
        text-align: center
        padding: $size-4 $size-5 $size-3
    .dropdown-menu
      width: $sidenav-width
      left: $size-7
      top: auto
      bottom: 90%
      .dropdown-content
        .has-link a
          color: $black
          font-family: $family-fira
          font-size: 0.95rem
          font-weight: $weight-normal
          padding-right: $size-6
          .dropdown-item-level
            display: flex
            justify-content: space-between
            align-items: baseline
            .user-name
              font-family: $family-headers
              font-weight: $weight-bold
              font-size: $size-7
              color: $grey
              padding-left: .75rem
              text-overflow: ellipsis
              overflow: hidden
    .mobile-user-name
      margin-left: 1rem
      text-transform: none
      font-weight: $weight-medium
      opacity: 0.6
</style>
