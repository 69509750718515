<template>
  <div
    :style="isMobile ? 'margin-right: 1rem' : ''"
    style="margin-bottom: 0"
    class="field is-grouped">
    <b-dropdown
      v-if="hasDropdownCtas"
      :style="isMobile ? 'margin-left: 1rem' : ''"
      position="is-bottom-left"
      aria-role="menu">
      <button
        slot="trigger"
        class="button is-primary is-outlined"
        role="button">
        <span>More Actions</span>
        <span class="icon">
          <fa-icon :icon="['far', 'chevron-down']" :transform="'shrink-3'"/>
        </span>
      </button>
      <template v-for="(action, idx) in dropdownCtas">
        <hr
          v-if="action.divider"
          :key="idx"
          class="dropdown-divider"
          aria-role="menuitem">
        <b-dropdown-item
          v-else
          :key="idx"
          has-link
          aria-role="menuitem">
          <a
            :href="action.href"
            :class="action.classes || 'has-text-link'"
            style="display: flex; align-items: center"
            @click="$emit('ctaClicked', action)">
            <span
              v-if="action.icon || action.icon_src"
              style="display: inline-flex; align-items: center; width: 1rem; margin-right: 0.5rem">
              <fa-icon
                v-if="action.icon"
                :icon="icon(action)"
                :transform="action.icon_transform" />
              <img
                v-if="action.icon_src"
                :src="action.icon_src"
                width="12.5px"
                height="12.5px">
            </span>
            {{ action.text }}
          </a>
        </b-dropdown-item>
      </template>
    </b-dropdown>
    <div
      v-for="(cta, idx) in buttonCtas"
      :key="idx"
      :style="isMobile ? 'margin-bottom: .5rem' : ''"
    >
      <p class="control is-marginless">
        <a
          v-if="typeof(cta) === 'string'"
          :class="[{
            'is-primary': (idx + 1 === buttonCtas.length) && !cta.nodefault,
            'is-link is-outlined': (idx + 1 !== buttonCtas.length) && !cta.nodefault,
          }, cta.classes]"
          class="button"
          style="margin-left:1rem"
          @click="$emit('ctaClicked', cta)">
          <span>{{ cta }}</span>
        </a>
        <a
          v-else-if="cta._blank"
          :href="cta._blank"
          :title="cta.title"
          :class="cta.classes"
          class="button is-primary is-inverted"
          style="margin-left:1rem"
          target="_blank">
          <fa-icon :icon="icon(cta)"/>
        </a>
        <a
          v-else-if="cta.icon || cta.text"
          :title="cta.title"
          :class="[{ 'is-inverted': !hasClasses(cta), 'is-primary': !hasColor(cta) }, cta.classes]"
          :href="cta.href"
          class="button"
          style="margin-left:1rem"
          @click="$emit('ctaClicked', cta)">
          <fa-icon
            v-if="cta.icon"
            :icon="icon(cta)"
            :transform="cta.icon_transform"/>
          <span
            v-if="cta.text"
            :style="cta.icon ? 'margin-left: .75rem' : ''">{{ cta.text }}</span>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  name: 'PageNavigationCta',
  props: {
    ctas: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  computed: {
    buttonCtas() {
      return (this.ctas || []).filter(cta => !cta.dropdown_item);
    },
    dropdownCtas() {
      return (this.ctas || []).filter(cta => !!cta.dropdown_item);
    },
    hasDropdownCtas() {
      return this.dropdownCtas.length > 0;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
  },

  methods: {
    hasClasses(cta) {
      return !_.isEmpty(cta.classes);
    },
    hasColor(cta) {
      return _.includes(cta.classes, 'is-dark') || _.includes(cta.classes, 'is-superadmin-primary');
    },
    icon(cta) {
      if (typeof cta.icon === 'string') {
        return ['far', cta.icon];
      }
      return cta.icon;
    },
  },
};
</script>
