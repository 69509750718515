<template>
  <div>
    <Spinner v-if="loading"/>
    <div v-else>
      <b-tabs
      v-model="activeTab"
      type="is-toggle"
      @input="viewChangeHandler"
      class="b-tabs-no-content">
        <b-tab-item label="Clubs"/>
        <b-tab-item label="Staff"/>
        <b-tab-item label="Time"/>
      </b-tabs>
      <div class="selector-container">
        <ViewSelector
        :options="views"
        v-model="currentView"/>
      </div>
      <ActivitiesSummary
      v-if="currentView === 'Summary by Club' && activeTab === 0"
      :activities="activities"
      :onboardingPlans="onboardingPlans"
      :defaultDateRange="dateRange"
      @date="dateRangeChanged"/>
      <StaffScheduleByStaffView v-if="currentView === 'Weekly Schedule (Staff)'" />
      <StaffScheduleByDayView v-if="currentView === 'Weekly Schedule (Day)'" />
      <StaffDailySummaryView v-if="currentView === 'Daily Summary'" />
      <StaffWeeklySummaryView v-if="currentView === 'Weekly Summary'" />
      <StaffTimeByStaff v-if="currentView === 'Time by Staff'"/>
      <TimeCharts v-if="activeTab === 2" :view="currentView"/>
    </div>
  </div>
</template>

<script>
import ViewSelector from '@/components/common/ViewSelector';
import ActivitiesSummary from '@/components/activities/ActivitiesSummary';
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import _ from 'lodash';
import TimeCharts from '@/components/activities/TimeCharts';
import StaffScheduleByStaffView from '@/components/activities/StaffScheduleByStaffView';
import StaffScheduleByDayView from '@/components/activities/StaffScheduleByDayView';
import StaffDailySummaryView from '@/components/activities/StaffDailySummaryView';
import StaffWeeklySummaryView from '@/components/activities/StaffWeeklySummaryView';
import StaffTimeByStaff from './StaffTimeByStaff';

const clubTab = 0;
const staffTab = 1;
const timeTab = 2;

const defaultDateRange = () => ({
  startDate: moment().startOf('week').toDate(),
  endDate: moment().endOf('week').toDate(),
});

export default {
  components: { ViewSelector,
    ActivitiesSummary,
    Spinner,
    TimeCharts,
    StaffDailySummaryView,
    StaffWeeklySummaryView,
    StaffScheduleByStaffView,
    StaffScheduleByDayView,
    StaffTimeByStaff,
  },
  async mounted() {
    await this.fetchData();
  },
  data() {
    return {
      loading: true,
      currentView: 'Summary by Club',
      clubsOptions: ['Summary by Club'],
      staffOptions: ['Weekly Summary', 'Daily Summary', 'Weekly Schedule (Day)', 'Weekly Schedule (Staff)', 'Time by Staff'],
      timeOptions: ['Total Time Spent', 'Total Time Spent by Staff', 'Total Time Spent by Type'],
      activeTab: 0,
      onboardingPlans: [],
      activities: [],
      dateRange: defaultDateRange(),
    };
  },
  computed: {
    views() {
      switch (this.activeTab) {
        case clubTab:
          return this.clubsOptions;
        case staffTab:
          return this.staffOptions;
        case timeTab:
          return this.timeOptions;
        default:
          return [];
      }
    },
  },
  methods: {
    viewChangeHandler() {
      switch (this.activeTab) {
        case clubTab:
          this.currentView = this.clubsOptions[0];
          break;
        case staffTab:
          this.currentView = this.staffOptions[0];
          break;
        case timeTab:
          this.currentView = this.timeOptions[0];
          break;
        default:
          this.currentView = null;
          break;
      }
    },
    dateRangeChanged(dr) {
      this.dateRange = _.cloneDeep(dr);
    },
    async fetchData() {
      return Promise.all([
        this.fetchOnboardingPlans(),
        this.fetchActivities(),
      ]).then(() => {
        this.loading = false;
      });
    },
    fetchActivities() {
      this.loading = true;
      api().post('/cst/activities/search?populate=notes').then((res) => {
        if (res.status === 200) {
          this.activities = res.data;
        }
      });
    },
    fetchOnboardingPlans() {
      api().post('cst/club_onboarding_plans/search').then((res) => {
        if (res.status === 200) {
          this.onboardingPlans = res.data;
        }
      });
    },
  },
};
</script>
