<template>
  <div class="note">
    <div class="note__content">
      <div class="activity-link" v-if="note.context_type === 'ClubTrainingActivity'">
        {{ activityLink(note.context_id) | capitalize }}
      </div>
      <div class="note__content__header">{{ createdByUser || 'user' }} commented:</div>
      <div class="note__content__body">{{ note.note }}</div>
    </div>
    <div class="note__details">
      <div>{{ note.created_at | formatDate("ll z") }}</div>
      <div>{{ note.created_at | formatDate("h:mm A")}}</div>
    </div>
    <div v-if="!readonly" @click="$emit('delete', note)" class="note__delete" >
      <fa-icon :icon="['far', 'trash-alt']"/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    note: { type: Object, required: true },
    activities: { type: Array, default: () => [] },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    createdByUser() {
      const user = this.$store.getters.users.find(usr => usr.id === this.note.created_by_user_id);
      return user ? `${user?.first_name} ${user?.last_name}` : '';
    },
  },
  methods: {
    activityLink(id) {
      const activity = this.activities?.find(act => act.id === id);
      return `${activity?.category} - ${activity?.milestone}`;
    },
  },
};
</script>

<style lang="sass" scoped>
.note
  display: grid
  grid-template-columns: 1fr 120px 50px
  background-color: rgba($yellow, 0.4)
  padding: 1.25rem
  margin-top: 2rem
  border-radius: 0.5rem
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.15)
  &__content
    max-width: 60ch
    .activity-link
      color: $blue-brand
      font-weight: 700
    &__header
      font-family: $family-montserrat
      font-weight: 800
    &__body
       white-space: pre-line
  &__details
    align-self: center
    border-left: 1px solid $blue-brand
    padding-left: 1rem
  &__delete
    padding: 0 5px
    cursor: pointer
    justify-self: end

</style>
