// state
const state = {
  onboardingReports: [],
};

const getters = {
  onboardingReports(state) {
    return state.onboardingReports;
  },

  onboardingReportStatus() {
    return [
      { name: 'Not Started', value: 'not started' },
      { name: 'Onboarding', value: 'onboarding' },
      { name: 'Weekly Calls', value: 'weekly calls' },
      { name: 'Monitoring', value: 'monitoring' },
      { name: 'Completed', value: 'completed' },
    ];
  },
  onboardingHeatlhOverallStatuses() {
    return [
      { name: 'No Concerns', value: '' },
      { name: 'No Concerns With Comments', value: 'no concerns with comments' },
      { name: 'Concerns', value: 'concerns' },
      { name: 'At Risk', value: 'at risk' },
    ];
  },
};

export default {
  state,
  getters,
};
