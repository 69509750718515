<template>
<div>
  <FilterTray
    title="Filter Clubs"
    :filters-active="filtersActive"
    class="clubs-filter"
    @clear-filters="clearFilters"
  >
    <FilterSection label="Club Size" collapsible>
      <b-field v-for="s in sizes" :key="s.value">
        <b-checkbox  v-model="filters.size.allow" :native-value="s.value"> {{s.name}} </b-checkbox>
      </b-field>
    </FilterSection>
    <FilterSection label="Club State" collapsible>
      <b-field>
        <div>
          <div class="tags">
            <a
              v-for="st in states"
              :key="st"
              :class="selected.includes(st) ? 'is-primary' : ''"
              class="tag is-medium is-white is-rounded is-outlined"
              @click="tagSelected(st)">
              {{ st }}
            </a>
          </div>
        </div>
      </b-field>
    </FilterSection>
    <FilterSection label="Club Sport" collapsible>
      <b-field v-for="s in sports" :key="s.value">
        <b-checkbox v-model="filters.sport.allow"  :native-value="s.value">{{s.name}}</b-checkbox>
      </b-field>
    </FilterSection>
    <FilterSection label="Customer Health" collapsible>
      <b-field v-for="h in healthStatus" :key="h.value">
        <b-checkbox v-model="filters.health_status.allow" :native-value="h.value">
          {{h.name}}
        </b-checkbox>
      </b-field>
    </FilterSection>
    <FilterSection label="Onboarding Type" collapsible>
      <b-field v-for="ot in onboardingTypes" :key="ot.value">
        <b-checkbox v-model="filters.onboarding_type.allow" :native-value="ot.value">
          {{ot.name}}
        </b-checkbox>
      </b-field>
    </FilterSection>
    <FilterSection label="Onboarding Status" collapsible>
      <b-field v-for="os in onboardingStatuses" :key="os.value">
        <b-checkbox v-model="filters.onboarding_status.allow" :native-value="os.value">
          {{os.name}}
        </b-checkbox>
      </b-field>
    </FilterSection>
    <FilterSection label="Features" collapsible>
      <b-field v-for="af in additionalFeatures" :key="af.value">
        <b-checkbox v-model="filters[af.value].allow" :native-value="true">
          {{af.name}}
        </b-checkbox>
      </b-field>
    </FilterSection>
    <FilterSection label="Player Counts" class="player-counts" collapsible>
      <p class="label">Recreation</p>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.rec_player_cap.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="count"
          v-model.number="filters.rec_player_cap.allow.number"
          type="number"/>
      </b-field>
      <p class="label">Competitive</p>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.competitive_player_cap.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="count"
          v-model.number="filters.competitive_player_cap.allow.number"
          type="number"/>
      </b-field>
    </FilterSection>
    <FilterSection label="Advanced" collapsible>
        <b-switch @input="$emit('showTestClubs', $event)">
          Show Test Clubs
        </b-switch>
    </FilterSection>
  </FilterTray>
  </div>
</template>
<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterSection,
    FilterTray,
  },
  props: {
    filtersActive: { type: Boolean, required: true },
    states: { type: Array, required: true },
  },
  data() {
    return {
      selected: [],
      filters: _.cloneDeep(this.$store.getters.filtersFor('club')),
    };
  },
  computed: {
    types() {
      return this.$store.getters.clubTypes;
    },
    sports() {
      return this.$store.getters.clubSports;
    },
    sizes() {
      return this.$store.getters.clubSizes;
    },
    healthStatus() {
      return this.$store.getters.clubCustomerHealth;
    },
    salesContacts() {
      return this.$store.getters.users.filter(u => u.admin_permissions.includes('cst_tool.dept_sales'));
    },
    onboardingStatuses() {
      return this.$store.getters.clubOnboardingStatuses;
    },
    onboardingTypes() {
      return this.$store.getters.clubOnboardingTypes;
    },
    additionalFeatures() {
      return this.$store.getters.clubAdditionalFeatures;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    tagSelected(tag) {
      if (this.selected.includes(tag)) {
        this.selected = _.without(this.selected, tag);
      } else {
        this.selected = _.concat(this.selected, tag);
      }
      this.$emit('input', tag);
    },
    clearFilters() {
      this.selected = [];
      this.$emit('input', 'clear');
      this.$store.dispatch('clearFiltersFor', { name: 'club' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('club'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'club', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>

<style lang="sass" scoped>
.player-counts
  .comparison
    margin-right: 0.5rem !important
  .count
    width: 100px
    text-align: right
  .tag:hover
    text-decoration: none
</style>
