<template>
  <div>
    <Spinner v-if="loading"/>
    <template v-else>
      <div class="table-options-container">
        <div class="table-options">
          <TimeframeSelector
          v-model="timeframeSelected"
          :options="views"
          @yearOverYearToggle="$event => overlayToggle[currentView] = $event"
          :hasToggle="true"/>
        </div>
      </div>
      <Highcharts ref="highcharts" :options="chartOptions"/>
      <b-switch v-if="currentView === 'total_time_spent_by_staff'" v-model="showFormerStaff">Show Former Staff</b-switch>
    </template>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import moment from 'moment';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import { genComponent } from 'vue-highcharts';
import _ from 'lodash';
import Spinner from '@/components/common/Spinner';
import TimeframeSelector from '@/components/tickets/TimeframeSelector';


const hardCodedUsersById = {
  38629: 'Montana Crowder',
};

// REMOVE AFTER NORMALIZING IN DB
const normalizeOnboardingType = (obt) => {
  if (!obt) return 'self';
  if (obt === 'Full Service') return 'full';
  if (obt === 'Mid Service') return 'mid';
  if (obt === 'Self Service') return 'self';
  return obt;
};

highchartsMore(Highcharts);

export default {
  components: { Highcharts: genComponent('Highcharts', Highcharts), Spinner, TimeframeSelector },
  props: {
    view: { type: String, required: true },
  },
  data() {
    return {
      showFormerStaff: false,
      overlayToggle: {
        total_time_spent: false,
        total_time_spent_by_staff: false,
        total_time_spent_by_type: false,
      },
      views: [
        { name: '12 Months', value: 'month' },
        { name: '12 Weeks', value: 'week' },
      ],
      viewBy: {
        total_time_spent: 'month',
        total_time_spent_by_staff: 'month',
        total_time_spent_by_type: 'month',
      },
      activities: [],
      loading: true,
      startingDate: {
        total_time_spent: moment().startOf('month').add(-1, 'year'),
        total_time_spent_by_staff: moment().startOf('month').add(-1, 'year'),
        total_time_spent_by_type: moment().startOf('month').add(-1, 'year'),
      },
    };
  },
  created() {
    this.fetchActivities();
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    clubInfoByID() {
      const ret = {};
      this.clubs.forEach((club) => {
        ret[club.id] = { id: club.id, onboarding_type: club.config.onboarding_type };
      });
      return ret;
    },
    currentStaff() {
      return this.$store.getters.users.map(user => user.id);
    },
    uniqStaff() {
      if (!this.showFormerStaff) {
        return _.intersection(_.union(_.flatten(this.getActivityData('uniqStaff', this.viewBy[this.currentView], this.startingDate[this.currentView]))), this.currentStaff);
      } else {
        return _.union(_.flatten(this.getActivityData('uniqStaff', this.viewBy[this.currentView], this.startingDate[this.currentView])));
      }
    },
    viewOverlay() {
      if (this.overlayToggle[this.currentView]) {
        return 'prev';
      } else {
        return 'now';
      }
    },
    timeframeSelected: {
      get() {
        return { view: this.viewBy[this.currentView], date: this.startingDate[this.currentView].toDate() };
      },
      set(value) {
        this.viewBy[this.currentView] = value.view;
        this.startingDate[this.currentView] = moment(value.date);
      },
    },
    currentView() {
      switch (this.view) {
        case 'Total Time Spent': return 'total_time_spent';
        case 'Total Time Spent by Staff': return 'total_time_spent_by_staff';
        case 'Total Time Spent by Type': return 'total_time_spent_by_type';
        default: return null;
      }
    },
    dottedLineValues() {
      const ret = {
        now: {
          week: [{
            value: 0,
            dashStyle: 'Solid',
          }, {
            dashStyle: 'ShortDash',
          }],
          month: [{
            value: 0,
            dashStyle: 'Solid',
          }, {
            dashStyle: 'ShortDash',
          }],
        },
        prev: {
          week: [{
            value: 12,
            dashStyle: 'Solid',
          }, {
            dashStyle: 'ShortDash',
          }],
          month: [{
            value: 12,
            dashStyle: 'Solid',
          }, {
            dashStyle: 'ShortDash',
          }],
        },
      };
      for (let i = 0; i < 13; i += 1) {
        if (!ret.now.month[0].value) {
          ret.now.month[0].value = moment(this.byStartingDate.now.month).add(i, 'month').isSameOrAfter(moment().startOf('month')) ? i - 1 : 0;
        }
        if (!ret.now.week[0].value) {
          ret.now.week[0].value = moment(this.byStartingDate.now.week).add(i, 'week').isSameOrAfter(moment().startOf('week')) ? i - 1 : 0;
        }
        if (ret.prev.month[0].value === 12) {
          ret.prev.month[0].value = moment(this.byStartingDate.prev.month).add(i, 'month').isSameOrAfter(moment().startOf('month')) ? i - 1 : 12;
        }
        if (ret.prev.week[0].value === 12) {
          ret.prev.week[0].value = moment(this.byStartingDate.prev.week).add(i, 'week').startOf('week')
            .isSameOrAfter(moment().startOf('week')) ? i - 1 : 12;
        }
      }
      return ret;
    },
    byStartingDate() {
      return {
        now: {
          month: moment(this.startingDate[this.currentView]).startOf('month'),
          week: moment(this.startingDate[this.currentView]).startOf('week'),
        },
        prev: {
          month: moment(this.startingDate[this.currentView]).startOf('month').add(-1, 'year'),
          week: moment(this.startingDate[this.currentView]).startOf('week').add(-1, 'year').startOf('week'),
        },
      };
    },
    activitiesMap() {
      const map = {
        month: new Map(),
        week: new Map(),
      };
      this.activities.forEach((activity) => {
        const when = moment(activity.meeting_date, 'YYYY-MM-DD');
        if (!when.isValid()) {
          return;
        }
        const month = map.month.get(this.getKey(when, 'month')) ?? {
          self: 0,
          mid: 0,
          full: 0,
          not_in_system: 0,
          totalTimeSpent: 0,
          staffData: new Map(),
          uniqStaff: [],
        };
        const week = map.week.get(this.getKey(when, 'week')) ?? {
          self: 0,
          mid: 0,
          full: 0,
          not_in_system: 0,
          totalTimeSpent: 0,
          staffData: new Map(),
          uniqStaff: [],
        };
        const userList = _.union([activity.lead_user_id], activity.secondary_user_ids);
        userList.forEach((user) => {
          if (!week.uniqStaff.includes(user)) {
            week.uniqStaff.push(user);
          }
          if (!month.uniqStaff.includes(user)) {
            month.uniqStaff.push(user);
          }
          const userMonthData = month.staffData.get(user) ?? { staffTime: 0 };
          const userWeekData = week.staffData.get(user) ?? { staffTime: 0 };
          userMonthData.staffTime += activity.duration;
          userWeekData.staffTime += activity.duration;
          month.staffData.set(user, userMonthData);
          week.staffData.set(user, userWeekData);
        });
        if (this.clubInfoByID[activity.club_id]) {
          month[normalizeOnboardingType(this.clubInfoByID[activity.club_id].onboarding_type)] += activity.duration;
          week[normalizeOnboardingType(this.clubInfoByID[activity.club_id].onboarding_type)] += activity.duration;
        }
        month.totalTimeSpent += activity.duration;
        week.totalTimeSpent += activity.duration;
        map.month.set(this.getKey(when, 'month'), month);
        map.week.set(this.getKey(when, 'week'), week);
      });
      return map;
    },
    graphCategories() {
      const ret = {
        month: [],
        week: [],
      };
      for (let i = 0; i < 13; i += 1) {
        ret.month.push(moment(this.byStartingDate.now.month).add(i, 'month').format('MMM'));
        ret.week.push(moment(this.byStartingDate.now.week).add(i, 'week').format('MMM DD'));
      }
      return ret;
    },
    chartOptions() {
      switch (this.currentView) {
        case 'total_time_spent': return _.merge({
          title: {
            text: '<strong> Total Time Spent </strong>',
          },
        },
        { series: this.totalSeries[this.viewOverlay][this.viewBy[this.currentView]],
          xAxis: { categories: this.graphCategories[this.viewBy[this.currentView]] },
        },
        this.baseChartOptions);
        case 'total_time_spent_by_staff': return _.merge({
          title: {
            text: '<strong> Total Time Spent By Staff </strong>',
          },
        },
        {
          series: this.staffSeries[this.viewOverlay][this.viewBy[this.currentView]],
          xAxis: { categories: this.graphCategories[this.viewBy[this.currentView]] },
        },
        this.baseChartOptions);
        case 'total_time_spent_by_type': return _.merge({
          title: {
            text: '<strong> Total Time Spent by Type</strong>',
          },
        },
        {
          series: this.typeSeries[this.viewOverlay][this.viewBy[this.currentView]],
          xAxis: { categories: this.graphCategories[this.viewBy[this.currentView]] },
        },
        this.baseChartOptions);
        default: return null;
      }
    },
    baseChartOptions() {
      return {
        chart: {
          showAxes: true,
          spacingTop: 50,
          plotBorderWidth: 1,
          plotBorderColor: 'black',
        },
        tooltip: {
          formatter() {
            const time = this.y;
            const hours = Math.floor(time);
            const minutes = Math.round((60 * time) % 60);
            const timeFormatted = `${hours > 1 ? `${hours} hrs` : ''}${hours === 1 ? `${hours} hr` : ''} ${minutes} min${minutes === 1 ? '' : 's'}`;
            return `${timeFormatted} spent on the month of ${this.x}`;
          },
        },
        legend: {
          alignColumns: true,
          verticalAlign: 'bottom',
          floating: false,
          layout: 'horizontal',
        },
        credits: {
          enabled: false,
        },
        title: {
          text: '<strong> Total Time Spent </strong>',
          y: -15,
          margin: 30,
          style: { color: 'is-black' },
        },
        xAxis: {
          startOnTick: true,
        },
        yAxis: {
          title: { text: 'Weekly Capacity (Hours)' },
          min: 0,
        },
      };
    },
    totalSeries() {
      return {
        now: {
          month: [{
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: `${this.startingDate[this.currentView].format('YYYY')} - ${moment(this.startingDate[this.currentView]).add(1, 'year').format('YYYY')}`,
            data: this.getActivityData('totalTimeSpent', 'month', this.byStartingDate.now.month).map(a => a / 60),
          }],
          week: [{
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: this.startingDate[this.currentView].format('YYYY'),
            data: this.getActivityData('totalTimeSpent', 'week', this.byStartingDate.now.week).map(a => a / 60),
          }],
        },
        prev: {
          month: [{
            zones: this.dottedLineValues.prev.month,
            zoneAxis: 'x',
            name: `${moment(this.startingDate[this.currentView]).add(-1, 'year').format('YYYY')} - ${this.startingDate[this.currentView].format('YYYY')}`,
            data: this.getActivityData('totalTimeSpent', 'month', this.byStartingDate.prev.month).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: `${this.startingDate[this.currentView].format('YYYY')} - ${moment(this.startingDate[this.currentView]).add(1, 'year').format('YYYY')}`,
            data: this.getActivityData('totalTimeSpent', 'month', this.byStartingDate.now.month).map(a => a / 60),
          }],
          week: [{
            zones: this.dottedLineValues.prev.month,
            zoneAxis: 'x',
            name: moment(this.startingDate[this.currentView]).add(-1, 'year').format('YYYY'),
            data: this.getActivityData('totalTimeSpent', 'week', this.byStartingDate.prev.week).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: this.startingDate[this.currentView].format('YYYY'),
            data: this.getActivityData('totalTimeSpent', 'week', this.byStartingDate.now.week).map(a => a / 60),
          }],
        },
      };
    },
    typeSeries() {
      return {
        now: {
          month: [{
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: 'Full',
            data: this.getActivityData('full', 'month', this.byStartingDate.now.month).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: 'Mid',
            data: this.getActivityData('mid', 'month', this.byStartingDate.now.month).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: 'Self',
            data: this.getActivityData('self', 'month', this.byStartingDate.now.month).map(a => a / 60),
          }],
          week: [{
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: 'Full',
            data: this.getActivityData('full', 'week', this.byStartingDate.now.week).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: 'Mid',
            data: this.getActivityData('mid', 'week', this.byStartingDate.now.week).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: 'Self',
            data: this.getActivityData('self', 'week', this.byStartingDate.now.week).map(a => a / 60),
          }],
        },
        prev: {
          month: [{
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: 'Full',
            data: this.getActivityData('full', 'month', this.byStartingDate.now.month).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: 'Mid',
            data: this.getActivityData('mid', 'month', this.byStartingDate.now.month).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: 'Self',
            data: this.getActivityData('self', 'month', this.byStartingDate.now.month).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.prev.month,
            zoneAxis: 'x',
            name: 'Full (Previous)',
            data: this.getActivityData('full', 'month', this.byStartingDate.prev.month).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.prev.month,
            zoneAxis: 'x',
            name: 'Mid (Previous)',
            data: this.getActivityData('mid', 'month', this.byStartingDate.prev.month).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.prev.month,
            zoneAxis: 'x',
            name: 'Self (Previous)',
            data: this.getActivityData('self', 'month', this.byStartingDate.prev.month).map(a => a / 60),
          }],
          week: [{
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: 'Full',
            data: this.getActivityData('full', 'week', this.byStartingDate.now.week).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: 'Mid',
            data: this.getActivityData('mid', 'week', this.byStartingDate.now.week).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: 'Self',
            data: this.getActivityData('self', 'week', this.byStartingDate.now.week).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.prev.week,
            zoneAxis: 'x',
            name: 'Full (Previous)',
            data: this.getActivityData('full', 'week', this.byStartingDate.prev.week).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.prev.week,
            zoneAxis: 'x',
            name: 'Mid (Previous)',
            data: this.getActivityData('mid', 'week', this.byStartingDate.prev.week).map(a => a / 60),
          }, {
            zones: this.dottedLineValues.prev.week,
            zoneAxis: 'x',
            name: 'Self (Previous)',
            data: this.getActivityData('self', 'week', this.byStartingDate.prev.week).map(a => a / 60),
          }],
        },
      };
    },
    staffSeries() {
      const staffList = this.uniqStaff;
      return {
        now: {
          month: staffList.map(user => ({
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: this.getUserName(user) ?? user,
            data: this.getActivityData('staffData', 'month', this.byStartingDate.now.month, user, 'staffTime').map(a => a / 60),
          })),
          week: staffList.map(user => ({
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: this.getUserName(user) ?? user,
            data: this.getActivityData('staffData', 'week', this.byStartingDate.now.week, user, 'staffTime').map(a => a / 60),
          })),
        },
        prev: {
          month: staffList.map(user => ({
            zones: this.dottedLineValues.prev.month,
            zoneAxis: 'x',
            name: this.getUserName(user) ?? user,
            data: this.getActivityData('staffData', 'month', this.byStartingDate.prev.month, user, 'staffTime').map(a => a / 60),
          })).concat(staffList.map(user => ({
            zones: this.dottedLineValues.now.month,
            zoneAxis: 'x',
            name: `${this.getUserName(user) ?? user} Current`,
            data: this.getActivityData('staffData', 'month', this.byStartingDate.now.month, user, 'staffTime').map(a => a / 60),
          }))),
          week: staffList.map(user => ({
            zones: this.dottedLineValues.prev.week,
            zoneAxis: 'x',
            name: this.getUserName(user) ?? user,
            data: this.getActivityData('staffData', 'week', this.byStartingDate.prev.week, user, 'staffTime').map(a => a / 60),
          })).concat(staffList.map(user => ({
            zones: this.dottedLineValues.now.week,
            zoneAxis: 'x',
            name: `${this.getUserName(user) ?? user} Current`,
            data: this.getActivityData('staffData', 'week', this.byStartingDate.now.week, 'staffTime').map(a => a / 60),
          }))),
        },
      };
    },
  },
  methods: {
    getUserName(id) {
      const u = this.$store.getters.users.find(user => user.id === id);
      if (u) {
        return `${u.first_name} ${u.last_name}`;
      } else if (id === 38629) {
        return hardCodedUsersById[id];
      }
      return null;
    },
    fetchActivities() {
      api().post('/cst/activities/search').then((res) => {
        if (res.status === 200) {
          this.activities = res.data;
        }
        this.loading = false;
      });
    },
    getKey(date, unit) {
      const when = moment(date).startOf(unit);
      return when.year() * 1000 + when.dayOfYear();
    },
    getActivityData(target, dateIncrement, startingDate, name = undefined, staffStat = undefined) {
      const nullreplace = target === 'uniqStaff' ? [] : 0;
      const ret = [];
      const finalSegment = moment(startingDate).add(13, dateIncrement);
      for (
        let segment = moment(startingDate).startOf(dateIncrement);
        segment.isBefore(finalSegment);
        segment.add(1, dateIncrement)
      ) {
        if (name) {
          ret.push(this.activitiesMap[dateIncrement]
            .get(this.getKey(segment, dateIncrement))?.[target]?.get(name)?.[staffStat] ?? nullreplace);
        } else {
          ret.push(this.activitiesMap[dateIncrement]
            .get(this.getKey(segment, dateIncrement))?.[target] ?? nullreplace);
        }
      }
      return ret;
    },
  },
};
</script>

<style lang="sass" scoped>
.table-options-container
  width: 100%
  display: flex
  justify-content: flex-end
  .table-options
    display: flex
    flex-direction: column
    align-items: flex-start
    padding-bottom: 1rem
    gap: 1rem
</style>
