<template>
  <div>
    <app-modal
      ref="modal"
      :title="title"
      :has-datepicker="hasDatepicker"
      :is-all-white="isAllWhite">
      <template>
        <message-box
          v-if="infoMessage"
          type="is-blue-sky"
          :message="infoMessage"
          message-classes="has-text-weight-medium"
          :icon="{icon: ['fas', 'info-circle'], type: 'info'}"
          :is-vcentered="false" />
        <p v-if="exportText" style="margin-bottom: 3rem">{{ exportText }}</p>
        <slot />
      </template>
      <template slot="footer">
        <div v-if="!customFooter">
          <a
            v-if="disabled"
            :disabled="disabled"
            :loading="exportURL === ''"
            class="button is-primary">
            <span style="margin-right:.5rem">
              <fa-icon
                :icon="['far', 'sign-in']"
                :transform="{ rotate: 90 }" />
            </span>
            Download as .CSV
          </a>
          <a
            v-else
            :loading="exportURL === ''"
            :href="exportURL"
            target="_blank"
            class="button is-primary"
            @click="dismissModal">
            <span style="margin-right:.5rem">
              <fa-icon
                :icon="['far', 'sign-in']"
                :transform="{ rotate: 90 }" />
            </span>
            Download as .CSV
          </a>
        </div>
        <slot name="footer"/>
      </template>
    </app-modal>
  </div>
</template>

<script>
import api from '@/http-playmetrics';
import Modal from '@/components/layout/modals/Modal';

export default {
  components: {
    'app-modal': Modal,
  },

  props: {
    serverUrl: { type: String, required: true },
    serverEndpoint: { type: String, default: null },
    type: { type: String, required: true },
    hasDatepicker: { type: Boolean, default: false },
    isAllWhite: { type: Boolean, default: false },
    showFilters: { type: Boolean, default: false },
    filterData: { type: Object, default: null },
    customMessage: { type: String, default: null },
    customTitle: { type: String, default: null },
    disabled: { type: Boolean, default: false },
    queryString: { type: String, default: null },
    customFooter: { type: Boolean, default: false },
    infoMessage: { type: String, default: null },
  },

  data() {
    return {
      allURL: '',
    };
  },

  computed: {
    title() {
      let title = '';
      if (this.customTitle) {
        title = this.customTitle;
      } else {
        title = `Export ${this.type}`;
      }
      return this.$options.filters.titleize(title);
    },
    exportText() {
      if (this.customMessage != null) {
        return this.customMessage;
      }
      return `Generate a ${this.showFilters ? 'custom' : ''} .csv file to edit your ${this.type} in bulk.`;
    },
    exportURL() {
      if (this.disabled) {
        return '';
      }
      if ((this.filterData || this.queryString) && this.allURL) {
        return `${this.allURL}&data=${JSON.stringify(this.filterData)}${this.queryString || ''}`;
      }
      return this.allURL;
    },
    isDisabled() {
      return (this.disabled || (this.exportURL === ''));
    },
  },

  watch: {
    serverEndpoint() {
      if (this.serverUrl && this.serverEndpoint && this.allURL) {
        api().serverURL(`${this.serverUrl}/${this.serverEndpoint}`).then((url) => {
          this.allURL = url;
        });
      }
    },
  },

  methods: {
    showModal(serverUrl, serverEndpoint) {
      this.$refs.modal.showModal();
      const u = serverUrl || this.serverUrl;
      const endpoint = serverEndpoint || this.serverEndpoint || `all-${this.type.replace(/ /g, '-')}.csv`;
      api().serverURL(`${u}/${endpoint}`).then((url) => {
        this.allURL = url;
      });
    },
    dismissModal(options = { refreshData: false }) {
      this.$refs.modal.dismissModal();
      this.$emit('dismissModal', options);
    },
  },
};
</script>
