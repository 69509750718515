const state = {
  underwritingPlan: null,
};

const getters = {
  underwritingStatus() {
    return [
      { name: 'Not Submitted', value: 'Not Submitted' },
      { name: 'In Review', value: 'In Review' },
      { name: 'Approved', value: 'Approved' },
      { name: 'Canceled', value: 'Canceled' },
    ];
  },
  kycStatus() {
    return [
      { name: 'Not Submitted', value: 'Not Submitted' },
      { name: 'In Progress', value: 'In Progress' },
      { name: 'In Review', value: 'In Review' },
      { name: 'Approved', value: 'Approved' },
    ];
  },
  dueDate() {
    return [
      { name: 'Anytime', value: null },
      { name: 'In the past', value: 'past' },
      { name: 'Next 14 days', value: 'next 14 days' },
      { name: 'Next 30 days', value: 'next 30 days' },
      { name: 'Next 3 months', value: 'next 3 months' },
    ];
  },
  targetDate() {
    return [
      { name: 'Anytime', value: null },
      { name: 'In the past', value: 'past' },
      { name: 'Next 14 days', value: 'next 14 days' },
      { name: 'Next 30 days', value: 'next 30 days' },
      { name: 'Next 3 months', value: 'next 3 months' },
    ];
  },
  onboardingType() {
    return [
      { name: 'Full', value: 'full' },
      { name: 'Mid', value: 'mid' },
      { name: 'Self', value: 'self' },
    ];
  },
};

const mutations = {
  setUnderwritingPlans(state, payload) {
    state.underwritingPlan = payload;
  },
};

export default {
  state,
  getters,
  mutations,
};
