<template>
    <Modal
      ref="modal"
      :wide-modal="true"
      modal-class="set-system-modal"
      title="Set System Values"
      is-all-white >
      <div class="columns form-section">
        <div class="column">
          <div class="form-section__label is-half">Payment</div>
            <b-field label="Annual Payment Concern">
                <b-numberinput v-model="values.annual_payment_concern_percentage"
                  step="0.01"
                  :controls="false"
                  custom-class="has-text-left"
                  class="percent-icon"
                  icon-pack="fas"
                  icon-right="percent"
                  size="is-small"
                  style="color: #d3d4d4"/>
               </b-field>
               <b-field label="Annual Payment Risk">
                <b-numberinput v-model="values.annual_payment_risk_percentage"
                  step="0.01"
                  :controls="false"
                  custom-class="has-text-left"
                  class="percent-icon"
                  icon-pack="fas"
                  icon-right="percent"
                  size="is-small"
                  style="color: #d3d4d4"/>
                </b-field>
                <b-field label="Monthly Payment Concern">
                  <b-numberinput v-model="values.monthly_payment_concern_percentage"
                    step="0.01"
                    :controls="false"
                    custom-class="has-text-left"
                    class="percent-icon"
                    icon-pack="fas"
                    icon-right="percent"
                    size="is-small"
                    style="color: #d3d4d4"/>
                </b-field>
                <b-field label="Monthly Payment Risk">
                <b-numberinput v-model="values.monthly_payment_risk_percentage"
                step="0.01"
                :controls="false"
                custom-class="has-text-left"
                class="percent-icon"
                icon-pack="fas"
                icon-right="percent"
                size="is-small"
                style="color: #d3d4d4"/>
            </b-field>
        </div>
        <div class="column">
          <div class="form-section__label is-half">Refund</div>
            <b-field label="Annual Refund Concern">
                <b-numberinput v-model="values.annual_refund_concern_percentage"
                  step="0.01"
                  :controls="false"
                  custom-class="has-text-left"
                  class="percent-icon"
                  icon-pack="fas"
                  icon-right="percent"
                  size="is-small"
                  style="color: #d3d4d4"/>
               </b-field>
               <b-field label="Annual Refund Risk">
                <b-numberinput v-model="values.annual_refund_risk_percentage"
                  step="0.01"
                  :controls="false"
                  custom-class="has-text-left"
                  class="percent-icon"
                  icon-pack="fas"
                  icon-right="percent"
                  size="is-small"
                  style="color: #d3d4d4"/>
                </b-field>
                <b-field label="Monthly Refund Concern">
                  <b-numberinput v-model="values.monthly_refund_concern_percentage"
                    step="0.01"
                    :controls="false"
                    custom-class="has-text-left"
                    class="percent-icon"
                    icon-pack="fas"
                    icon-right="percent"
                    size="is-small"
                    style="color: #d3d4d4"/>
                </b-field>
                <b-field label="Monthly Refund Risk">
                  <b-numberinput v-model="values.monthly_refund_risk_percentage"
                    step="0.01"
                    :controls="false"
                    custom-class="has-text-left"
                    class="percent-icon"
                    icon-pack="fas"
                    icon-right="percent"
                    size="is-small"
                    style="color: #d3d4d4"/>
                 </b-field>
          </div>
          <div class="column">
          <div class="form-section__label is-half">Chargeback</div>
            <b-field label="Annual Chargeback Concern">
                <b-numberinput v-model="values.annual_chargeback_concern_percentage"
                  step="0.01"
                  :controls="false"
                  custom-class="has-text-left"
                  class="percent-icon"
                  icon-pack="fas"
                  icon-right="percent"
                  size="is-small"
                  style="color: #d3d4d4"/>
               </b-field>
               <b-field label="Annual Chargeback Risk">
                <b-numberinput v-model="values.annual_chargeback_risk_percentage"
                  step="0.01"
                  :controls="false"
                  custom-class="has-text-left"
                  class="percent-icon"
                  icon-pack="fas"
                  icon-right="percent"
                  size="is-small"
                  style="color: #d3d4d4"/>
                </b-field>
                <b-field label="Monthly Chargeback Concern">
                  <b-numberinput v-model="values.monthly_chargeback_concern_percentage"
                    step="0.01"
                    :controls="false"
                    custom-class="has-text-left"
                    class="percent-icon"
                    icon-pack="fas"
                    icon-right="percent"
                    size="is-small"
                    style="color: #d3d4d4"/>
                </b-field>
                <b-field label="Monthly Chargeback Risk">
                  <b-numberinput v-model="values.monthly_chargeback_risk_percentage"
                    step="0.01"
                    :controls="false"
                    custom-class="has-text-left"
                    class="percent-icon"
                    icon-pack="fas"
                    icon-right="percent"
                    size="is-small"
                    style="color: #d3d4d4"/>
                 </b-field>
          </div>

      </div>

      <template slot="footer">
        <div class="has-text-right">
        <b-button @click="resetValues()">Reset Values</b-button>
        <b-button type="is-primary" @click="setSystemValues()">Save Changes</b-button>
        </div>
      </template>
    </Modal>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import { cloneDeep, isEmpty } from 'lodash';
import useToast from 'jseminor/composables/toast';

const { success: successToast } = useToast();

export default {
  components: {
    Modal,
  },
  data() {
    return {
      values: {},
      defaultValues: {
        annual_payment_concern_percentage: 125,
        annual_payment_risk_percentage: 150,
        monthly_payment_concern_percentage: 50,
        monthly_payment_risk_percentage: 75,

        annual_refund_concern_percentage: 5,
        annual_refund_risk_percentage: 10,
        monthly_refund_concern_percentage: 1,
        monthly_refund_risk_percentage: 3,

        annual_chargeback_concern_percentage: 0.5,
        annual_chargeback_risk_percentage: 1,
        monthly_chargeback_concern_percentage: 0.2,
        monthly_chargeback_risk_percentage: 0.5,
      },
    };
  },
  computed: {
  },
  methods: {
    setSystemValues() {
      this.$emit('new_values', this.values);
      this.finishUpdate();
    },
    finishUpdate() {
      successToast('System Values Set');
      this.dismissModal({ refreshData: true });
    },
    showModal(currentValues) {
      if (isEmpty(currentValues)) {
        this.values = cloneDeep(this.defaultValues);
      } else {
        this.values = currentValues;
      }
      this.$refs.modal.showModal();
    },
    resetValues() {
      this.values = cloneDeep(this.defaultValues);
    },
    dismissModal() {
      this.$emit('refresh');
      this.$refs.modal.dismissModal();
    },
  },
};
</script>
<style lang="sass" scoped>
.set-system-modal
  .form-section
    margin-bottom: 2rem
    &__label
      font-family: $family-headers
      font-weight: $weight-bold
      font-size: $size-6
      margin-bottom: 0.75rem
</style>
