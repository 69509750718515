<template>
  <div>
    <spinner v-if="loading" />
    <FullScreenModal  v-else ref="modal">
      <template slot="header">
        <FormModalHeader
          :title="editing ? 'Edit Onboarding Plan' : 'New Onboarding Plan'"
          wide-form
          :actions="modalActions"
          @dismiss="dismiss"
        >
          <template slot="save">
            <b-button type="is-primary" :disabled="saving" @click="save">
              <span v-if="editing">Save Changes</span>
              <span v-else>Create Plan</span>
            </b-button>
          </template>
          <template slot="archive">
            <b-button icon-pack="far"
              icon-left="archive"
              v-if="!archived"
              outlined
              type="is-danger"
              @click="archive">
              Archive Plan
            </b-button>
            <b-button icon-left="redo"
              v-else
              outlined
              type="is-primary"
              @click="restore">
              Restore Plan
            </b-button>
          </template>
        </FormModalHeader>
      </template>
      <div v-if="plan" class="centered-tool plan-form">
        <div class="plan-form__section">
          <p class="plan-form__section__label">Club Details</p>
          <div class="columns">
            <div class="column">
              <b-field :type="{'is-danger' : errors.club_id}"
                       :message="errors.club_id"
                       label="Club">
                <b-autocomplete
                  v-if="!editing"
                  v-model="clubName"
                  :data="filteredClubsWithoutPlans"
                  field="name"
                  placeholder="Search for Club"
                  @select="assignClub($event)">
                  <template #empty>No results found</template>
                </b-autocomplete>
                <b-input :value="club.name" v-else disabled/>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Club Type">
                <b-input  :value="club.type | capitalize" disabled />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Onboarding Type">
                <b-input :value="club.config.onboarding_type | capitalize" disabled />
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-two-fifths">
              <b-field label="Sales Rep">
                <b-input :value="clubSalesContactName(club.config.sales_contact_user_id)" disabled />
              </b-field>
            </div>
            <div class="column">
              <b-field label="Contract Rec Players">
                <b-input
                  :value="club.config.rec_player_cap ? club.config.rec_player_cap.toLocaleString() : ''"
                  disabled />
              </b-field>
            </div>
            <div class="column is-one-third">
              <b-field label="Contract Competitive Players">
                <b-input
                  :value="club.config.competitive_player_cap ? club.config.competitive_player_cap.toLocaleString() : ''"
                  disabled />
              </b-field>
            </div>
          </div>
        </div>
        <div class="plan-form__section">
          <p class="plan-form__section__label">Customer Success Staff Details</p>
          <p class="plan-form__section__sub-label">Main Staff</p>
          <div class="columns plan-form__section__sub-section" >
            <div class="column is-one-quarter">
              <b-field :type="{'is-danger' : errors.cs_lead_user_id}"
                       :message="errors.cs_lead_user_id"
                       label="Lead">
                <AdminStaffDropdown
                  deselect
                  :departments="['product', 'success']"
                  :blacklist="[plan.cs_secondary_user_id]"
                  :value="plan.cs_lead_user_id"
                  @input="plan.cs_lead_user_id = $event.id"/>
              </b-field>
            </div>
            <div class="column is-one-quarter">
              <b-field :type="{'is-danger' : errors.cs_secondary_user_id}"
                       :message="errors.cs_secondary_user_id"
                       label="Secondary">
                <AdminStaffDropdown
                  deselect
                  :departments="['product', 'success']"
                  :blacklist="[plan.cs_lead_user_id]"
                  :value="plan.cs_secondary_user_id"
                  @input="plan.cs_secondary_user_id = $event.id"/>
              </b-field>
            </div>
          </div>
          <div class="columns plan-form__section__sub-section">
            <div class="column" style="margin-right: 2rem">
              <p class="plan-form__section__sub-label">Setup Staff</p>
              <div class="columns">
                <div class="column">
                  <b-field :type="{'is-danger' : errors.setup_lead_user_id}"
                           :message="errors.setup_lead_user_id"
                           label="Lead">
                    <AdminStaffDropdown
                      deselect
                      :departments="['product', 'success']"
                      :blacklist="[plan.setup_secondary_user_id]"
                      :value="plan.setup_lead_user_id"
                      @input="plan.setup_lead_user_id = $event.id"/>
                  </b-field>
                </div>
                <div class="column">
                  <b-field :type="{'is-danger' : errors.setup_secondary_user_id}"
                           :message="errors.setup_secondary_user_id"
                           label="Secondary">
                    <AdminStaffDropdown
                      deselect
                      :departments="['product', 'success']"
                      :blacklist="[plan.setup_lead_user_id]"
                      :value="plan.setup_secondary_user_id"
                      @input="plan.setup_secondary_user_id = $event.id"/>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="column">
              <p class="plan-form__section__sub-label">Registration Staff</p>
              <div class="columns">
                <div class="column">
                  <b-field :type="{'is-danger' : errors.registration_lead_user_id}"
                           :message="errors.registration_lead_user_id"
                           label="Lead">
                    <AdminStaffDropdown
                      deselect
                      :departments="['product', 'success']"
                      :blacklist="[plan.registration_secondary_user_id]"
                      :disabled="!hasRegistrationStatus"
                      :value="plan.registration_lead_user_id"
                      @input="plan.registration_lead_user_id = $event.id"/>
                  </b-field>
                </div>
                <div class="column">
                  <b-field :type="{'is-danger' : errors.registration_secondary_user_id}"
                           :message="errors.registration_secondary_user_id"
                           label="Secondary">
                    <AdminStaffDropdown
                      deselect
                      :departments="['product', 'success']"
                      :blacklist="[plan.registration_lead_user_id]"
                      :disabled="!hasRegistrationStatus"
                      :value="plan.registration_secondary_user_id"
                      @input="plan.registration_secondary_user_id = $event.id"/>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="columns plan-form__section__sub-section">
            <div class="column" style="margin-right: 2rem">
              <p class="plan-form__section__sub-label">Operations Staff</p>
              <div class="columns">
                <div class="column" >
                  <b-field :type="{'is-danger' : errors.operations_lead_user_id}"
                           :message="errors.operations_lead_user_id"
                           label="Lead">
                    <AdminStaffDropdown
                      deselect
                      :departments="['product', 'success']"
                      :blacklist="[plan.operations_secondary_user_id]"
                      :value="plan.operations_lead_user_id"
                      @input="plan.operations_lead_user_id = $event.id"/>
                  </b-field>
                </div>
                <div class="column">
                  <b-field :type="{'is-danger' : errors.operations_secondary_user_id}"
                           :message="errors.operations_secondary_user_id"
                           label="Secondary">
                    <AdminStaffDropdown
                      deselect
                      :departments="['product', 'success']"
                      :blacklist="[plan.operations_lead_user_id]"
                      :value="plan.operations_secondary_user_id"
                      @input="plan.operations_secondary_user_id = $event.id"/>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="column">
              <p class="plan-form__section__sub-label">Team Accounts</p>
              <div class="columns">
                <div class="column">
                  <b-field :type="{'is-danger' : errors.team_accounts_lead_user_id}"
                           :message="errors.team_accounts_lead_user_id"
                           label="Lead">
                    <AdminStaffDropdown
                      deselect
                      :departments="['product', 'success']"
                      :blacklist="[plan.team_accounts_secondary_user_id]"
                      :value="plan.team_accounts_lead_user_id"
                      @input="plan.team_accounts_lead_user_id = $event.id"/>
                  </b-field>
                </div>
                <div class="column">
                  <b-field :type="{'is-danger' : errors.team_accounts_secondary_user_id}"
                           :message="errors.team_accounts_secondary_user_id"
                           label="Secondary">
                    <AdminStaffDropdown
                      deselect
                      :departments="['product', 'success']"
                      :blacklist="[plan.team_accounts_lead_user_id]"
                      :value="plan.team_accounts_secondary_user_id"
                      @input="plan.team_accounts_secondary_user_id = $event.id"/>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
          <div class="columns plan-form__section__sub-section">
            <div class="column">
              <p class="plan-form__section__sub-label">Website Staff</p>
              <div class="columns">
                <div class="column is-one-quarter">
                  <b-field :type="{'is-danger' : errors.website_lead_user_id}"
                           :message="errors.website_lead_user_id"
                           label="Lead">
                    <AdminStaffDropdown
                      deselect
                      is-web-builder-team
                      :disabled="!hasWebsiteStatus"
                      :value="plan.website_lead_user_id"
                      @input="plan.website_lead_user_id = $event.id"/>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="plan-form__section">
          <p class="plan-form__section__label">Onboarding Status Details</p>
          <div class="columns">
            <div class="column">
              <b-field label="Overall Status">
                <b-select v-model="plan.status">
                  <option
                    v-for="status in $store.getters.onboardingPlanStatuses"
                    :value="status.value"
                    :key="status.value"
                  >
                    {{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Setup Status">
                <b-select v-model="plan.setup_status">
                  <option
                    v-for="status in $store.getters.onboardingPlanStatuses"
                    :value="status.value"
                    :key="status.value"
                  >
                    {{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Registration Status">
                <b-select v-if="hasRegistrationStatus" v-model="plan.registration_status" :disabled="!hasRegistrationStatus">
                  <option
                    v-for="status in $store.getters.onboardingPlanStatuses"
                    :value="status.value"
                    :key="status.value"
                  >
                    {{ status.name }}
                  </option>
                </b-select>
                <b-select disabled value="n/a" v-else>
                  <option value="n/a">Not Applicable</option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Operations Status">
                <b-select v-model="plan.operations_status">
                  <option
                    v-for="status in $store.getters.onboardingPlanStatuses"
                    :value="status.value"
                    :key="status.value"
                  >
                    {{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Team Accounts">
                <b-select v-model="plan.team_accounts_status">
                  <option
                    v-for="status in $store.getters.onboardingPlanStatuses"
                    :value="status.value"
                    :key="status.value"
                  >
                    {{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Website Status">
                <b-select v-if="hasWebsiteStatus" v-model="plan.website_status" :disabled="!hasWebsiteStatus">
                  <option
                    v-for="status in $store.getters.onboardingPlanWebsiteStatuses"
                    :value="status.value"
                    :key="status.value"
                  >
                    {{ status.name }}
                  </option>
                </b-select>
                <b-select disabled value="n/a" v-else>
                  <option value="n/a">Not Applicable</option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </FullScreenModal>
  </div>

</template>

<script>
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import FormModalHeader from '@/components/layout/modals/FormModalHeader';
import AdminStaffDropdown from '@/components/users/AdminStaffDropdown';
import spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';

const optionalFields = [
  'cs_lead_user_id',
  'cs_secondary_user_id',
  'setup_lead_user_id',
  'setup_secondary_user_id',
  'registration_lead_user_id',
  'registration_secondary_user_id',
  'operations_lead_user_id',
  'operations_secondary_user_id',
  'website_lead_user_id',
  'team_accounts_lead_user_id',
  'team_accounts_secondary_user_id',
  'health_status',
  'health_notes',
  'health_updated_at',
  'health_updated_by_user_id',
]; // eslint-disable-line


export default {
  components: { FullScreenModal, FormModalHeader, AdminStaffDropdown, spinner },
  data() {
    return {
      plan: {},
      loading: false,
      editing: false,
      saving: false,
      club: {
        config: {},
      },
      errors: {},
      clubName: '',
    };
  },
  props: {
    plans: { type: Array, default: () => [] },
  },
  computed: {
    existingPlanClubIds() {
      return this.plans.map(plan => plan.club_id);
    },
    clubs() {
      const copyClubs = [...this.$store.getters.clubs];
      const activeClubs = copyClubs.filter(club => !club.is_test_club && !club.is_deleted);
      return activeClubs.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    },
    clubsWithoutPlans() {
      return this.clubs.filter(club => !this.existingPlanClubIds.includes(club.id));
    },
    filteredClubsWithoutPlans() {
      return this.clubsWithoutPlans.filter(option => option.name.toLowerCase().indexOf(this.clubName.toLowerCase()) >= 0);
    },
    archived() {
      return !!this.plan.archived_at;
    },
    modalActions() {
      if (this.editing) {
        return ['archive', 'save'];
      }
      return ['save'];
    },
    hasRegistrationStatus() {
      return this.club?.config?.enable_program_administration;
    },
    hasWebsiteStatus() {
      return !!this.club?.config?.enable_website_builder;
    },
    valid() {
      return Object.keys(this.errors).length === 0;
    },
    salesUsers() {
      return this.$store.getters.salesUsers;
    },
  },
  methods: {
    clubSalesContactName(id) {
      if (!id) return '';
      return this.salesUsers[id] || '';
    },
    isRequiredField(key) {
      return !(optionalFields.includes(key));
    },
    isEmptyField(key) {
      return (typeof this.plan[key] === 'number' && this.plan[key] === 0 && key !== 'registration_lead_user_id' && key !== 'website_lead_user_id') ||
        (this.hasRegistrationStatus && key === 'registration_lead_user_id' && this.plan[key] === 0) ||
        (this.hasWebsiteStatus && key === 'website_lead_user_id' && this.plan[key] === 0);
    },
    validate() {
      Object.keys(this.plan).forEach((key) => {
        if (this.isRequiredField(key)) {
          if (this.isEmptyField(key)) {
            this.$set(this.errors, key, 'This is a required field');
          } else {
            this.$delete(this.errors, key);
          }
        }
      });
    },
    show(plan) {
      this.loading = true;
      if (plan) {
        this.editing = true;
        this.plan = { ...plan };
        this.club = this.clubs.find(club => club.id === plan.club_id);
      } else {
        this.editing = false;
        this.plan = {
          club_id: 0,
          status: 'not started',
          setup_status: 'not started',
          registration_status: 'not applicable',
          operations_status: 'not started',
          team_accounts_status: 'not started',
          website_status: 'not applicable',
          cs_lead_user_id: 0,
          cs_secondary_user_id: 0,
          setup_lead_user_id: 0,
          setup_secondary_user_id: 0,
          operations_lead_user_id: 0,
          operations_secondary_user_id: 0,
          registration_lead_user_id: 0,
          registration_secondary_user_id: 0,
          team_accounts_lead_user_id: 0,
          team_accounts_secondary_user_id: 0,
          website_lead_user_id: 0,
        };
        this.club = {
          config: {},
        };
      }
      this.$refs.modal.show(true);
      this.loading = false;
    },
    dismiss(options = { refreshData: false }) {
      this.$refs.modal.dismiss();
      this.$emit('dismissed', options);
    },
    save() {
      this.saving = true;
      this.validate();
      if (!this.valid) {
        this.saving = false;
        return;
      }
      if (this.editing) {
        api()
          .post(`/cst/club_onboarding_plans/${this.plan.id}`, this.plan)
          .then((res) => {
            if (res.status === 200) {
              this.$store.commit('setNotification', { toast: true, success: true, message: 'Onboarding Plan Updated' });
              this.saving = false;
              this.dismiss({ refreshData: true });
            }
          });
      } else {
        api()
          .post('/cst/club_onboarding_plans', this.plan)
          .then(async (res) => {
            if (res.status === 200) {
              this.$store.commit('setNotification', { toast: true, success: true, message: 'Onboarding Plan Created' });
              await this.createDefaultActivities();
              this.saving = false;
              this.dismiss({ refreshData: true });
            }
          });
      }
    },
    archive() {
      this.$confirm.confirm({
        confirmText: 'Yes, Archive Plan',
        message: `You are about to archive the onboarding plan for <strong>${this.club.name}</strong>.
                  <br><br>
                  Are you sure you want to continue?`,
        title: 'Archive Onboarding Plan',
        type: 'is-danger',
        pack: 'far',
        icon: 'archive',
        onConfirm: () => {
          api()
            .post(`/cst/club_onboarding_plans/${this.plan.id}/archive`)
            .then((res) => {
              if (res.status === 200) {
                this.$store.commit('setNotification', { toast: true, success: true, message: 'Onboarding Plan Archived' });
                this.dismiss({ refreshData: true });
              }
            })
            .catch((err) => {
              this.$store.commit('setNotification', { toast: true, success: false, message: `${err}` });
              this.dismiss({ refreshData: true });
            });
        },
      });
    },
    restore() {
      this.$confirm.confirm({
        confirmText: 'Yes, Restore Plan',
        message: `You are about to restore the onboarding plan for <strong>${this.club.name}</strong>.
                  <br><br>
                  Are you sure you want to continue?`,
        title: 'Restore Onboarding Plan',
        type: 'is-primary',
        icon: 'redo',
        onConfirm: () => {
          api()
            .post(`/cst/club_onboarding_plans/${this.plan.id}/restore`)
            .then((res) => {
              if (res.status === 200) {
                this.$store.commit('setNotification', { toast: true, success: true, message: 'Onboarding Plan Restored' });
                this.dismiss({ refreshData: true });
              }
            })
            .catch((err) => {
              this.$store.commit('setNotification', { toast: true, success: false, message: `${err}` });
            });
        },
      });
    },
    assignClub(club) {
      if (club) {
        this.club = club;
        this.plan.club_id = club.id;
      } else {
        this.club = {
          config: {},
        };
        this.plan.club_id = undefined;
      }
      if (this.hasRegistrationStatus) {
        this.plan.registration_status = 'not started';
      } else {
        this.plan.registration_status = 'not applicable';
        this.$delete(this.errors, 'registration_lead_user_id');
        this.plan.registration_lead_user_id = 0;
        this.plan.registration_secondary_user_id = 0;
      }
      if (this.hasWebsiteStatus) {
        this.plan.website_status = 'not started';
      } else {
        this.$delete(this.errors, 'website_lead_user_id');
        this.plan.website_status = 'not applicable';
        this.plan.website_lead_user_id = 0;
      }
    },
    async createDefaultActivities() {
      const activities = [];
      const setupMilestones = ['Kickoff', 'Intro to PM', 'Club Setup'];
      setupMilestones.forEach((milestone) => {
        activities.push({
          lead_user_id: this.plan.setup_lead_user_id,
          secondary_user_ids: this.plan.setup_secondary_user_id !== 0 ?
            [this.plan.setup_secondary_user_id] : null,
          category: 'setup',
          milestone,
        });
      });
      if (this.club.config.enable_program_administration) {
        const registrationMilestones = ['PAI', 'PAII Competitive', 'PAII Recreation', 'PAIII'];
        registrationMilestones.forEach((milestone) => {
          activities.push({
            lead_user_id: this.plan.registration_lead_user_id,
            secondary_user_ids: this.plan.registration_secondary_user_id !== 0 ?
              [this.plan.registration_secondary_user_id] : null,
            category: 'registration',
            milestone,
          });
        });
      }
      const operationsMilestones = ['Field Scheduling', 'Directors', 'Forms'];
      operationsMilestones.forEach((milestone) => {
        activities.push({
          lead_user_id: this.plan.operations_lead_user_id,
          secondary_user_ids: this.plan.operations_secondary_user_id !== 0 ?
            [this.plan.operations_secondary_user_id] : null,
          category: 'operations',
          milestone,
        });
      });
      if (this.club.config.enable_website_builder) {
        activities.push({
          lead_user_id: this.plan.website_lead_user_id,
          category: 'website',
          milestone: 'Website Builder',
        });
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const activity of activities) {
        activity.club_id = this.club.id;
        activity.duration = 60;
        // eslint-disable-next-line no-await-in-loop
        await api().post('/cst/activities', activity)
          .then((res) => {
            if (res.status !== 200) {
              console.error(res.data);
            }
          });
      }
    },
  },
};

</script>

<style lang="sass">
.plan-form
  &__section
    margin-bottom: 3rem
    &__label
      font-family: $family-headers
      font-weight: $weight-semibold
      font-size: $size-4
      margin-bottom: 1rem
    &__sub-label
      font-family: $family-headers
      font-weight: $weight-semibold
      font-size: $size-5
      margin-bottom: 0.75rem
    &__sub-section
      margin-bottom: 1.5rem !important
</style>
