<template>
    <Modal
      ref="modal"
      title="Set Club Values"
      is-all-white >
      <div class="columns">
        <div class="column is-half">
          <strong> Club: </strong> <br>
          <strong class="club-name"> {{ club.name }} </strong>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field label="Risk Rating">
            <b-select expanded v-model="club.risk_rating">
              <option
                v-for="risks in riskOptions"
                :key="risks.value">
                {{ risks.key }}
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column is-half">
          <b-field label="Expected APV">
                <InputMoney
                  v-model="club.expected_apv"
                  icon-pack="fas"
                  icon="dollar-sign"
                  size="is-small"
                />
          </b-field>
        </div>
      </div>
      <div class="columns">
        <div class="column is-half">
          <b-field label="Expected Prepayment Coefficient">
            <b-numberinput v-model="club.expected_prepayment_coefficient"
              step="0.01"
              :controls="false"
              max="100"
              custom-class="has-text-left"
              class="percent-icon has-text-grey"
              icon-pack="fas"
              icon-right="percent"
              size="is-small"/>
          </b-field>
        </div>
      </div>
      <template slot="footer">
        <b-button type="is-primary" @click="saveChanges()">Save Changes</b-button>
      </template>
    </Modal>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import api from '@/http-playmetrics';
import InputMoney from '@/components/common/form-elements/InputMoney';
import useToast from 'jseminor/composables/toast';
import { SYSTEM_CROSSBAR, SYSTEM_PLAYMETRICS } from '@/models/FinancialTracker';

const { success: successToast, error: errorToast } = useToast();

export default {

  components: {
    Modal,
    InputMoney,
  },
  data() {
    return {
      club: {
        risk_rating: '',
        expected_apv: 0,
        expected_prepayment_coefficient: 0,
      },
    };
  },
  props: {
    system: { type: String, default: SYSTEM_PLAYMETRICS },
  },
  computed: {
    systemURL() {
      if (this.system === SYSTEM_CROSSBAR) {
        return '/cst/crossbar';
      }
      return '/cst/club_internals';
    },
    apvCheck() {
      if (typeof this.club.expected_apv === 'number') {
        return true;
      }
      return false;
    },
    riskOptions() {
      const risk = [
        {
          key: 'N/A',
          value: '',
        },
        {
          key: 'S1',
          value: 'S1',
        },
        {
          key: 'S2',
          value: 'S2',
        },
        {
          key: 'S3',
          value: 'S3',
        },
        {
          key: 'L1',
          value: 'L1',
        },
        {
          key: 'L2',
          value: 'L2',
        },
        {
          key: 'L3',
          value: 'L3',
        },
      ];
      return risk;
    },
  },
  methods: {
    saveChanges() {
      if (this.club.expected_prepayment_coefficient <= 100) {
        const internalFields = {
          risk_rating: this.club.risk_rating === 'N/A' ? '' : this.club.risk_rating,
          expected_apv: this.club.expected_apv,
          expected_prepayment_coefficient: this.club.expected_prepayment_coefficient * 100,
        };
        api().post(`${this.systemURL}/${this.club.id}`, internalFields)
          .then(() => this.finishUpdate())
          .catch((err) => {
            errorToast(`Could not retrieve Crossbar financial data. (${err})`);
          });
      }
    },
    finishUpdate() {
      successToast('Values Set');
      this.dismissModal(true);
    },
    showModal(club) {
      if (club) {
        this.club = {
          id: club.id,
          risk_rating: club.risk_rating,
          expected_apv: club.expected_apv,
          expected_prepayment_coefficient: club.expected_prepayment_coefficient / 100,
          name: club.name,
        };
      } else {
        this.club = {};
      }
      if (!this.apvCheck) {
        this.club.expected_apv = 0;
      }
      this.$refs.modal.showModal();
    },
    dismissModal(refreshData) {
      if (refreshData) {
        if (this.system === SYSTEM_CROSSBAR) {
          this.$emit('refreshCrossbar');
        } else {
          this.$emit('refreshPlayMetrics');
        }
      }
      this.$refs.modal.dismissModal();
    },
  },
};
</script>

<style lang="sass" scoped>
.club-name
  font-size: 18px
.is-italic
  display: flex
  font-style: italic
  justify-content: center
// .percent-icon
//   padding-left: 10rem
</style>
