<template>
  <div class="filter-section filter-container">
    <div
      class="level is-marginless is-mobile"
      :class="{ 'collapsible': collapsible }"
      @click="toggleOpen"
    >
      <div class="level-left filter-label">
        {{ label }}
        <span
          v-if="filterActive && !open"
          style="color: #57E451; margin-left: 0.25rem">
          <fa-icon
            :icon="['fas', 'circle']"
            transform="shrink-6"
            class="active-indicator" />
        </span>
      </div>
      <div v-if="collapsible" class="level-right">
        <a class="button is-text">
          <fa-icon :icon="['fas', open ? 'chevron-up' : 'chevron-down']"/>
        </a>
      </div>
    </div>
    <slot name="header"/>
    <div
      v-if="open"
      :class="{ 'collapsible': collapsible, 'label-only': label && !collapsible }"
      class="filter-body">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: { type: String, default: '' },
    collapsible: { type: Boolean, default: false },
    defaultOpen: { type: Boolean, default: false },
    filterActive: { type: Boolean, default: false },
  },

  data() {
    return {
      open: !this.collapsible || this.defaultOpen,
    };
  },

  methods: {
    toggleOpen() {
      if (this.collapsible) {
        this.open = !this.open;
      }
    },
  },
};
</script>

<style lang="sass" scoped>
  @import "~bulma/sass/utilities/mixins"
  .level.collapsible
    cursor: pointer
    user-select: none
  .filter-section
    padding-top: 1.5rem
    padding-bottom: 1.5rem
    box-shadow: inset 0 1px 0 0 $grey-lighter
    .filter-label
      font-family: $family-headers
      font-weight: $weight-bold
      font-size: $size-7
      color: $black
      text-transform: uppercase
      +touch
        font-weight: $weight-semibold
        font-size: $size-6
        text-transform: none
    .filter-body
      margin-top: 0
      &.collapsible
        margin-top: 1rem
      &.label-only
        margin-top: 1.5rem
      +touch
        font-weight: $weight-medium
</style>
