<template>
  <Spinner v-if="loading"/>
  <div v-else class="clubs">
    <TablePaginated
      name="Club Contacts"
      :data="tableRows"
      searchable
      :filterable="false"
      @queryUpdated="searchQuery = $event">
      <template slot="header-left">
          <span class="label">{{tableRows.length}} {{tableRows.length === 1 ? 'Club Contact' : 'Club Contacts'}} Found</span>
      </template>
      <template slot-scope="props">
        <b-table-column
          label="Contact Name"
          field="name"
          sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          label="Email Address"
          field="email"
          sortable>
          {{ props.row.email }}
        </b-table-column>
        <b-table-column centered label="Actions" field="actions">
            <b-dropdown
              position="is-bottom-left">
              <b-button
                aria-label="Actions"
                slot="trigger"
                class="is-text"
                has-icon-right>
                <fa-icon :icon="['fas', 'ellipsis-h']" />
              </b-button>
              <b-dropdown-item
                @click="launchRemoveContactModal(props.row)">
                Remove contact
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Club Contacts Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
  </div>
</template>

<script>
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';
import api from '@/http-playmetrics';

export default {
  components: { Spinner,
    TablePaginated,
  },
  props: {
    club: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      searchQuery: '',
      usersWithPermissions: [],
    };
  },
  computed: {
    contacts() {
      return this.usersWithPermissions.filter(user =>
        this.isContact(user));
    },
    tableRows() {
      return this.contacts.map(contact => ({
        id: contact.id,
        name: `${contact.first_name} ${contact.last_name}`,
        last_name: contact.last_name,
        email: contact.email,
      })).filter(contact => this.matchesQuery(contact))
        .sort((a, b) => a.last_name.localeCompare(b.last_name));
    },
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers() {
      api().get(`/cst/clubs/${this.club.id}/admins?populate=permissions`).then((res) => {
        this.usersWithPermissions = res.data;
      }).catch(() => {
        this.$store.commit('setNotification', { toast: true, success: false, message: 'Failed to fetch users' });
      });
    },
    matchesQuery(contact) {
      return (
        contact.name &&
        contact.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    },
    isContact(user) {
      if (user.current_role_permissions) {
        return user.current_role_permissions.includes('club_contact');
      }
      return false;
    },
    launchRemoveContactModal(contact) {
      this.$confirm.showModal({
        confirmText: 'Yes, Remove Contact',
        message: `You are about to remove <strong>${contact.name}</strong> as a club contact from <strong>${this.club.name}</strong>.
        Are you sure you want to do this?`,
        title: 'Confirm Contact Removal',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => this.removeContact(contact),
      });
    },
    removeContact(contact) {
      api({ showError: false }).delete(`/cst/clubs/${this.club.id}/admins/${contact.id}/club_contact`).then(() => {
        this.$store.commit('setNotification', { toast: true, success: true, message: 'Contact Removed' });
        this.fetchUsers();
      }).catch(() => {
        this.$store.commit('setNotification', { toast: true, success: false, message: 'Failed to remove contact' });
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.clubs-table
  &__actions
    display: flex
    justify-content: center
    align-items: center
    &--link
      color: inherit
</style>
