// getters
const getters = {

  onboardingPlanStatuses() {
    return [
      { name: 'Not Started', value: 'not started' },
      { name: 'Onboarding', value: 'onboarding' },
      { name: 'Weekly Calls', value: 'weekly calls' },
      { name: 'Monitoring', value: 'monitoring' },
      { name: 'Completed', value: 'completed' },
    ];
  },
  onboardingHealthStatus() {
    return [
      { name: 'No Concerns', value: '' },
      { name: 'No Concerns With Comments', value: 'no concerns with comments' },
      { name: 'Concerns', value: 'concerns' },
      { name: 'At Risk', value: 'at risk' },
    ];
  },
  onboardingPlanRegistrationStatuses() {
    return [
      { name: 'Not Started', value: 'not started' },
      { name: 'Onboarding', value: 'onboarding' },
      { name: 'Weekly Calls', value: 'weekly calls' },
      { name: 'Monitoring', value: 'monitoring' },
      { name: 'Completed', value: 'completed' },
      { name: 'Not Applicable', value: 'not applicable' },
    ];
  },
  onboardingPlanWebsiteStatuses() {
    return [
      { name: 'Not Started', value: 'not started' },
      { name: 'Onboarding', value: 'onboarding' },
      { name: 'Monitoring', value: 'monitoring' },
      { name: 'Completed', value: 'completed' },
      { name: 'Not Applicable', value: 'not applicable' },
    ];
  },
};

export default {
  getters,
};
