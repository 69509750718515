<template>
  <transition :name="transition">
    <div
      v-if="visible"
      :class="modalClass"
      class="full-screen-modal">
      <header class="modal-header">
        <slot name="header">
          <button
            class="button is-text close-button"
            @click="dismiss">
            <fa-icon :icon="['far', 'times']"/>
          </button>
          <div class="header-content level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <p class="is-size-4 has-text-weight-bold">{{ title }}</p>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <slot name="actions"/>
              </div>
            </div>
          </div>
        </slot>
      </header>
      <section ref="modalBody" class="modal-body">
        <slot/>
      </section>
      <footer v-if="this.$slots.footer" class="">
        <slot name="footer"/>
      </footer>
    </div>
  </transition>
</template>

<script>
export default {

  props: {
    title: { type: String, default: '' },
    showModal: { type: Boolean, default: false },
    modalClass: { type: String, default: '' },
    transition: { type: String, default: 'slide-up' },
  },

  data() {
    return {
      visible: this.showModal,
    };
  },

  destroyed() {
    this.$store.dispatch('preventScroll', false);
  },

  methods: {
    show() {
      this.visible = true;
      this.$store.dispatch('preventScroll');
      this.$emit('shown');
    },
    dismiss() {
      this.visible = false;
      this.$store.dispatch('preventScroll', false);
      this.$emit('dismissed');
    },
    modalBody() {
      return this.$refs.modalBody;
    },
  },

};
</script>

<style lang="sass" scoped>
.full-screen-modal
  z-index: 10
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: $white
  position: fixed
  display: flex
  flex-direction: column
  &.above-modal
    z-index: 50
  &.no-modal-body-overflow
    .modal-body
      overflow: hidden
  .modal-body
    flex: 1
    overflow: auto
    /*-webkit-overflow-scrolling: touch*/

.slide-up-enter-active,
.slide-up-leave-active
  transition: all .2s ease
.slide-up-enter,
.slide-up-leave-to
  transform: translateY(100vh)
  opacity: 0
</style>
