<template>
  <div
    class="message-box"
    :class="`${type} ${isErrorMessage && 'error-box'} ${isBox && 'is-box'}`">
    <div :class="`columns is-mobile ${isVcentered && 'is-vcentered'}`">
      <div
        v-if="icon"
        class="column is-narrow"
        :style="isVcentered ? 'padding-right: 0; line-height: 1' : 'padding-right: 0.25rem'">
        <fa-icon
          :icon="icon.icon"
          :class="`${icon.type && `has-text-${icon.type}`} ${isSmallText ? 'is-size-6' : 'is-size-16px'}`"
          :transform="isVcentered ? '' : 'down-1'" />
      </div>
      <div class="column message-column" :class="messageClasses">
        {{ message }}
        <slot name="message" />
      </div>
      <div
        v-if="$slots.default || $scopedSlots.default"
        class="column is-narrow"
        :style="isVcentered && 'display: flex; align-items: center'">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: { type: String, required: true },
    message: { type: String, default: null },
    messageClasses: { type: String, default: '' },
    icon: { type: Object, default: null },
    isVcentered: { type: Boolean, default: true },
    isBox: { type: Boolean, default: false },
  },
  computed: {
    isErrorMessage() {
      return this.type === 'is-error-message';
    },
    isSmallText() {
      return this.messageClasses && this.messageClasses.includes('is-size-7');
    },
  },
};
</script>

<style lang="sass" scoped>
.message-box
  border-radius: 3px
  padding: 1rem
  margin-bottom: 1.5rem
  background-color: $white-ter // default
  @each $name, $components in $colors
    $color: nth($components, 1)
    &.is-#{$name}
      background-color: rgba($color, 0.15)
  &.is-danger
    background-color: rgba($danger, 0.1)
  &.is-warning
    background-color: rgba($warning, 0.3)
  &.is-warning-light
    background-color: rgba($warning, 0.15)
  &.is-blue-light
    background-color: rgba($blue-sky, 0.25)
  .has-text-blue-light
    color: $blue-sky
  &.error-box
    background-color: rgba($warning, 0.3)
    .message-column
      color: $danger
      font-weight: $weight-bold
      font-family: $family-headers
  &.is-box
    box-shadow: 0 2px 3px rgba(48, 54, 82, 0.1), 0 0 0 1px rgba(48, 54, 82, 0.1)
</style>
