import _ from 'lodash';

const filterDeepArrayDefault = key => ({
  allow: [],
  active() {
    return this.allow.length > 0;
  },
  matches(obj) {
    return this.allow.some(ele => obj[key].includes(ele));
  },
});

// Default filter for dropdowns/selections
const filterScalarDefault = key => ({
  allow: null,
  active() {
    return !!this.allow;
  },
  matches(obj) {
    return this.allow === obj[key];
  },
});

// Default filter for checkbox options
const filterArrayDefault = key => ({
  allow: [],
  active() {
    return this.allow.length > 0;
  },
  matches(obj) {
    return this.allow.includes(obj[key]);
  },
});

// Default filter for booleans/toggles
const filterBooleanDefault = key => ({
  allow: false,
  active() {
    return this.allow;
  },
  matches(obj) {
    return obj[key];
  },
});

const filterComparisonDefault = key => ({
  allow: {
    comparison: '',
    number: 0,
  },
  active() {
    return this.allow.comparison !== '' && this.allow.number !== 0;
  },
  matches(obj) {
    const { comparison, number } = this.allow;
    switch (comparison) {
      case '': return obj;
      case 'less': return obj[key] < number;
      case 'equal': return obj[key] === number;
      case 'more': return obj[key] > number;
      default: return false;
    }
  },
});

// Set up set of filterable properties for a given context
const defaultClubsFilters = () => ({
  size: filterArrayDefault('size'),
  sport: filterArrayDefault('sport'),
  health_status: filterArrayDefault('health_status'),
  onboarding_type: filterArrayDefault('onboarding_type'),
  onboarding_status: filterArrayDefault('onboarding_status'),
  enable_program_administration: filterBooleanDefault('enable_program_administration'),
  enable_digital_documents: filterBooleanDefault('enable_digital_documents'),
  enable_website_builder: filterBooleanDefault('enable_website_builder'),
  rec_player_cap: filterComparisonDefault('rec_player_cap'),
  competitive_player_cap: filterComparisonDefault('competitive_player_cap'),
});
const defaultClubContactFilters = () => ({
  sport: filterArrayDefault('sport'),
});

const defaultClubFinancialTrackingFilter = () => ({
  state: filterArrayDefault('state'),
  sport: filterDeepArrayDefault('sport'),
  risk_rating: filterArrayDefault('risk_rating'),
  expected_apv: filterComparisonDefault('expected_apv'),
  expected_prepayment_coefficient: filterComparisonDefault('expected_prepayment_coefficient'),
  advanced: filterArrayDefault('advanced'),
});

const defaultOnboardingPlansFilters = () => ({
  club_size: [],
  club_sport: [],
  sales_contact_user_id: [],
  onboarding_type: [],
  health_status: [],
  setup_status: [],
  registration_status: [],
  operations_status: [],
  website_status: [],
  status: [],
  cs_staff: [],
  show_archived_players: false,
});

const defaultOnboardingReportsFilter = () => ({
  onboarding_report_status: filterArrayDefault('status'),
  percent_complete: filterComparisonDefault('percent_complete'),
  health_status: filterArrayDefault('health_status'),
  primary_cs_staff_member_id: filterScalarDefault('primary_cs_staff_member_id'),
});

const defaultOnboardingScheduleFilter = () => ({
  primary_cs_staff_member_id: filterScalarDefault('primary_cs_staff_member_id'),
});

const defaultFinancialTrackingFilter = () => ({
  risk_rating: filterArrayDefault('risk_rating'),
  expected_apv: filterComparisonDefault('expected_apv'),
  expected_prepay: filterComparisonDefault('expected_prepayment_coefficient'),
  actual_prepay: filterComparisonDefault('actual_prepayment_coefficient'),
  annual_pv: filterComparisonDefault('annual_processing_volume_percentage'),
  advanced: filterArrayDefault('advanced'),
  advancedR: filterArrayDefault('advancedR'),
  annual_rv: filterComparisonDefault('annual_revenue_volume_percentage'),
  annual_cbv: filterComparisonDefault('annual_chargeback_volume_percentage'),
  primary_vendor: filterArrayDefault('primary_vendor'),

});

const defaultUnderwritingPlanFilters = () => ({
  underwriting_status: [],
  kyc_status: [],
  dueDate: null,
  targetDate: null,
  onboarding_type: [],
  advancedToggle: false,
});

const defaultContractsFilters = () => ({
  health_status: [],
  size: [],
  sport: [],
  recCap: [],
  compCap: [],
  isArchived: false,
});

const defaultUserScheduleFilters = () => ({
  club_sizes: [],
  onboarding_type: [],
  onboarding_category: [],
  includeTBD: false,
  view_by: 'overall',
});

const defaultUsageFilters = () => ({
  size: [],
  onboarding_status: [],
  onboarding_size: [],
  summary_categories: [],
  summary_status: [],
  sales_contact_user_id: [],
  cs_staff: [],
});

const defaultTicketsFilters = () => ({
  size: [],
  sport: [],
});

const defaultActivitiesFilters = () => ({
  onboarding_type: [],
  category: [],
  milestone: [],
  cs_staff: [],
  club_type: 'customer',
  time_spent: {
    minutes: 0,
    hours: 0,
    duration: 0,
    status: null,
  },
});

const defaultOnboardingActivites = () => ({
  category: [],
  milestone: [],
  cs_staff: [],
  activity_date: {
    date: new Date(),
    date_range: [new Date(), new Date()],
    status: null,
  },
  time_spent: {
    minutes: 0,
    hours: 0,
    duration: 0,
    status: null,
  },
});

const defaultMerchantAccountFilters = () => ({
  vendor: filterArrayDefault('vendor'),
  viable: filterArrayDefault('viable'),
  country: filterArrayDefault('country'),
  type: filterArrayDefault('type'),
  context: filterArrayDefault('context'),
});

const defaultBackgroundCheckFilters = () => ({
  size: filterArrayDefault('size'),
  sport: filterArrayDefault('sport'),
});

const defaultTeamAccounts = () => ({
  size: filterArrayDefault('size'),
  sport: filterArrayDefault('sport'),
  open: filterComparisonDefault('open'),
  remaining: filterComparisonDefault('remaining'),
});

const defaultFiltersByContext = () => ({
  club: defaultClubsFilters(),
  clubContacts: defaultClubContactFilters(),
  onboardingPlans: defaultOnboardingPlansFilters(),
  underwritingPlan: defaultUnderwritingPlanFilters(),
  contract: defaultContractsFilters(),
  userSchedule: defaultUserScheduleFilters(),
  ticket: defaultTicketsFilters(),
  activities: defaultActivitiesFilters(),
  usage: defaultUsageFilters(),
  onboardingActivities: defaultOnboardingActivites(),
  merchantAccounts: defaultMerchantAccountFilters(),
  backgroundChecks: defaultBackgroundCheckFilters(),
  onboardingReports: defaultOnboardingReportsFilter(),
  onboardingSchedule: defaultOnboardingScheduleFilter(),
  clubFinancialTracking: defaultClubFinancialTrackingFilter(),
  financialTracking: defaultFinancialTrackingFilter(),
  teamAccounts: defaultTeamAccounts(),
});

// initial state
const getDefaultState = () => ({
  filterTrayOpen: false,
  perPage: 20,
  currentPageFor: {}, // global
  tableDataFor: {}, // by table name
  filters: defaultFiltersByContext(),
});

const state = getDefaultState();

const DEFAULT_TABLE_DATA = {
  filters: null,
  search: '',
  sort: null,
};

function getTableData(state, name) {
  return state.tableDataFor[name] || _.clone(DEFAULT_TABLE_DATA);
}

// getters
const getters = {
  filterTrayOpen(state) {
    return state.filterTrayOpen;
  },
  perPage(state) {
    return state.perPage;
  },
  currentPageFor(state) {
    return state.currentPageFor;
  },
  tableDataFor(state) {
    return name => getTableData(state, name);
  },
  tableSearchFor(state) {
    return name => getTableData(state, name).search;
  },
  tableSortFor(state) {
    return name => getTableData(state, name).sort;
  },
  filtersFor(state) {
    return name => state.filters[name];
  },
  activeFiltersFor(state) {
    return name => _.pick(state.filters[name], Object.keys(state.filters[name]).filter((k) => {
      if (typeof state.filters[name][k] === 'boolean') {
        return state.filters[name][k];
      }
      return !_.isEmpty(state.filters[name][k]);
    }));
  },
  activeFiltersForContext(state) {
    return context => _.pick(state.filters[context],
      Object.keys(state.filters[context]).filter(key => state.filters[context][key].active()));
  },
};

// mutations
const mutations = {
  toggleFilterTrayOpen(state) {
    state.filterTrayOpen = !state.filterTrayOpen;
  },
  setFilterTrayOpen(state, value) {
    state.filterTrayOpen = value;
  },
  setPerPage(state, payload) {
    state.perPage = payload;
  },
  setCurrentPageFor(state, { name, value }) {
    state.currentPageFor[name] = value;
  },
  setTableDataFor(state, values) {
    const name = values.name;
    const newValues = _.omit(values, 'name');
    state.tableDataFor[name] = _.assign(getTableData(state, name), newValues);
  },
  setFiltersFor(state, { name, filters }) {
    state.filters[name] = _.assign(state.filters[name], filters);
  },
  clearFilterTray(state) {
    state.perPage = 20;
    state.currentPageFor = {};
    state.tableDataFor = {};
    state.filters = defaultFiltersByContext();
  },
};

// actions
const actions = {
  toggleFilterTrayOpen(context) {
    context.commit('toggleFilterTrayOpen');
  },
  clearFiltersFor(context, { name }) {
    context.commit('setFiltersFor', {
      name,
      filters: defaultFiltersByContext()[name],
    });
  },
  resetFilterTrayState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
