<template>
  <Spinner v-if="loading"/>
  <div v-else class="clubs">
    <TablePaginated
      name="Club Contacts"
      :data="hasStateSelected ? clubsForSelectedStates : tableRows"
      searchable
      @queryUpdated="searchQuery = $event">
      <template slot="header-left">
          <span class="label">{{tableRows.length}} {{tableRows.length === 1 ? 'Club Contact' : 'Club Contacts'}} Found</span>
      </template>
      <template slot-scope="props">
        <b-table-column
          label="Contact Name"
          field="name"
          sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column
          label="Email Address"
          field="email"
          sortable>
          {{ props.row.email }}
        </b-table-column>
        <b-table-column
          label="Club Name"
          field="club_name"
          sortable>
          {{ props.row.club_name }}
        </b-table-column>
        <b-table-column
          label="State"
          field="club_state"
          sortable>
          {{ props.row.club_state }}
        </b-table-column>
        <b-table-column
          label="Sport"
          field="club_sport"
          sortable>
          {{ props.row.club_sport | capitalize }}
        </b-table-column>
        <b-table-column centered label="Actions" field="actions">
            <b-dropdown
              position="is-bottom-left">
              <b-button
                aria-label="Actions"
                slot="trigger"
                class="is-text"
                has-icon-right>
                <fa-icon :icon="['fas', 'ellipsis-h']" />
              </b-button>
              <b-dropdown-item
                @click="launchRemoveContactModal(props.row)">
                Remove contact
              </b-dropdown-item>
            </b-dropdown>
          </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Club Contacts Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <ClubContactsFilter
    :states="states"
    :sports="sports"
    :filters-active="hasActiveFilters"
    @input="selectState($event)"/>
  </div>
</template>

<script>
import { keyBy, isEmpty, without, concat } from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';
import ClubContactsFilter from './ClubContactsFilter';


export default {
  components: { Spinner,
    TablePaginated,
    ClubContactsFilter,
  },
  props: {
    clubs: { type: Array, default: () => [] },
  },
  data() {
    return {
      contacts: [],
      users: [],
      loading: false,
      searchQuery: '',
      selectedStates: [],
      states: [],
      sports: [],
    };
  },
  computed: {
    tableRows() {
      return this.contacts.map(contact => ({
        ...contact,
        club_name: this.clubsByID[contact.club_id]?.name,
        club_state: this.clubsByID[contact.club_id]?.config.state || '-',
        club_sport: this.clubsByID[contact.club_id]?.sports[0]?.sport,
      })).sort((a, b) => a.last_name.localeCompare(b.last_name))
        .filter(contact => this.matchesQuery(contact)
        && this.matchesSportFilter(contact.club_sport));
    },
    clubsForSelectedStates() {
      return this.tableRows.filter(contact => this.selectedStates.includes(contact.club_state));
    },
    clubsByID() {
      return keyBy(this.clubs, 'id');
    },
    usersByID() {
      return keyBy(this.users, 'id');
    },
    hasActiveFilters() {
      return !isEmpty(this.activeFilters) || this.hasStateSelected;
    },
    hasStateSelected() {
      return this.selectedStates.length > 0;
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('clubContacts');
    },
  },
  created() {
    Promise.all([this.fetchContacts(), this.fetchUsers()]);
  },
  watch: {
    contacts() {
      this.setStates();
      this.setSports();
    },
  },
  methods: {
    selectState(st) {
      if (st === 'clear') {
        this.selectedStates = [];
      } else if (this.selectedStates.includes(st)) {
        this.selectedStates = without(this.selectedStates, st);
      } else {
        this.selectedStates = concat(this.selectedStates, st);
      }
    },
    setStates() {
      const st = [];
      this.tableRows.forEach((row) => {
        if (row.club_state !== '-' && !st.includes(row.club_state)) st.push(row.club_state);
      });
      this.states = st.sort((state1, state2) => (state1 < state2 ? -1 : 1));
    },
    setSports() {
      const sports = [];
      this.tableRows.forEach((row) => {
        if (row.club_sport && row.club_sport !== '-' && !sports.includes(row.club_sport)) sports.push(row.club_sport);
      });
      this.sports = sports.sort((sport1, sport2) => (sport1 < sport2 ? -1 : 1));
    },
    fetchContacts() {
      this.loading = true;
      api().get('/cst/club_contacts').then((res) => {
        this.contacts = res.data;
        this.loading = false;
      }).catch(() => {
        this.$store.dispatch('setErrorToast', 'Failed to get contacts');
      });
    },
    fetchUsers() {
      this.loading = true;
      api().get('/cst/users').then((res) => {
        this.users = res.data;
        this.loading = false;
      }).catch(() => {
        this.$store.dispatch('setErrorToast', 'Failed to get users');
      });
    },
    matchesQuery(contact) {
      return (
        (contact.name &&
        contact.name.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
        (contact.club_name && contact.club_name.toLowerCase().includes(this.searchQuery.toLowerCase()))
      );
    },
    matchesSportFilter(clubSport) {
      if (!this.hasActiveFilters) return true;
      return this.activeFilters.sport ? this.activeFilters.sport.allow.includes(clubSport) : true;
    },
    launchRemoveContactModal(contact) {
      this.$confirm.showModal({
        confirmText: 'Yes, Remove Contact',
        message: `You are about to remove <strong>${contact.name}</strong> as a club contact from <strong>${contact.club_name}</strong>.
        Are you sure you want to do this?`,
        title: 'Confirm Contact Removal',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => this.removeContact(contact),
      });
    },
    removeContact(contact) {
      api({ showError: false }).delete(`/cst/clubs/${contact.club_id}/admins/${contact.id}/club_contact`).then(() => {
        this.$store.commit('setNotification', { toast: true, success: true, message: 'Contact Removed' });
        this.fetchContacts();
      }).catch(() => {
        this.$store.commit('setNotification', { toast: true, success: false, message: 'Failed to remove contact' });
      });
    },
    addClubContactDismissed() {
      this.fetchContacts();
    },
  },
};
</script>

<style lang="sass" scoped>
.clubs-table
  &__actions
    display: flex
    justify-content: center
    align-items: center
    &--link
      color: inherit
</style>
