<template>
  <div class="player-modal">
    <Modal
      ref="modal"
      title="Export Activities"
      is-all-white
      @dismiss="dismissModal">
    <template>
      <div class="centered-tool plan-form">
        <div class="plan-form__section">
          <p >“Generate a .csv file of your activities for the current time frame selected.”</p>
          <div style="margin-top: 1rem" class="columns">
          </div>
        </div>
      </div>
     </template>
     <template slot="footer">
        <a
          class="button is-primary"
          :href="exportURLWithParams"
          :class="{'is-loading': saving}"
          @click="save">
          Download as .CSV
        </a>
      </template>
    </Modal>
  </div>

</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import api from '@/http-playmetrics';
import _ from 'lodash';
import moment from 'moment';


export default {
  props: {
    defaultDateRange: { type: Object, required: true },
    tbdActive: { type: Boolean, default: false },
  },
  components: { Modal },
  data() {
    return {
      dateRange: _.cloneDeep(this.defaultDateRange),
      loading: false,
      editing: false,
      submitFlag: false,
      exportURL: '',
      startDate: null,
      endDate: null,
      tbdBool: _.cloneDeep(this.tbdActive),
    };
  },
  mounted() {
    this.exportFunc();
  },
  computed: {
    minDate() {
      return new Date('01-10-2018');
    },
    saving() {
      return null;
    },
    modalActions() {
      return ['save'];
    },
    dateRangeAsJSON() {
      return JSON.stringify(this.activitiesDate);
    },
    tbdAsJSON() {
      return JSON.stringify(this.tbdActive);
    },
    exportURLWithParams() {
      return `${this.exportURL}&data=${this.dateRangeAsJSON}`;
    },
    activitiesDate() {
      if (this.tbdActive) {
        return {
          start_date: moment(this.tbdCondition()).format('YYYY-MM-DD'),
          end_date: moment(this.tbdCondition()).format('YYYY-MM-DD'),
        };
      }
      return {
        start_date: moment(this.defaultDateRange.startDate).format('YYYY-MM-DD'),
        end_date: moment(this.defaultDateRange.endDate).format('YYYY-MM-DD'),
      };
    },
  },

  methods: {
    tbdCondition() {
      return new Date('12-12-2099');
    },
    save() {
      this.dismissModal();
    },
    showModal() {
      this.startDate = this.defaultDateRange.startDate;
      this.endDate = this.defaultDateRange.endDate;
      this.$refs.modal.showModal();
    },
    dismissModal(options = { refreshData: false }) {
      this.exportFunc();
      this.$refs.modal.dismissModal();
      this.$emit('dismissModal', options);
      this.startDate = this.dateRange.startDate;
      this.endDate = this.dateRange.endDate;
    },
    // this is a place holder for the csv
    exportFunc() {
      api().serverURL('/cst/activities/activities.csv').then((url) => {
        this.exportURL = url;
      });
    },
  },
};

</script>

<style></style>
