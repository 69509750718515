// state
const state = {
  onboardingReports: [],
};

const getters = {
  financialTracking(state) {
    return state.onboardingReports;
  },

  riskRating() {
    return [
      { name: 'Not Set', value: '-' },
      { name: 'N/A', value: 'N/A' },
      { name: 'S1', value: 'S1' },
      { name: 'S2', value: 'S2' },
      { name: 'S3', value: 'S3' },
      { name: 'L1', value: 'L1' },
      { name: 'L2', value: 'L2' },
      { name: 'L3', value: 'L3' },
    ];
  },
  advanced() {
    return [
      { name: 'Risk Rating', value: 'risk_rating' },
      { name: 'Expected APV', value: 'expected_apv' },
      { name: 'Expected Prepayment Coefficient', value: 'expected_prepayment_coefficient' },
    ];
  },
  advancedR() {
    return [
      { name: 'Risk Rating', value: 'risk_rating' },
      { name: 'Expected APV', value: 'expected_apv' },
    ];
  },
  vendors() {
    return [
      { name: 'Stripe', value: 'Stripe' },
      { name: 'WePay', value: 'WePay' },
    ];
  },
};

export default {
  state,
  getters,
};
