<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>
    <div v-else>
      <PageNavigation
        :page-title="club? club.name : ''"
        :tabs="tabs"
        :active-tab="activeTab"
        :bread-crumbs="breadCrumbs"
        @tabClicked="tabClicked($event)"
        @ctaClicked="cta($event)"
      >
        <template slot="left-bottom-slot">
          <StatusBadge v-if="plan.status" :status="plan.status"/>
          <span class="last-modified">- last modified on {{plan.status_updated_at | formatDate('ll z')}}</span>
        </template>
      </PageNavigation>
      <div v-if="activeTab === 'Details'">
        <PlanDetails :plan="plan"
          :club="club"
          :clubInternal="clubInternal"
          @healthUpdated="fetchHealthStatus"/>
      </div>
      <div v-if="activeTab === 'Activities'">
        <PlanActivities @noteUpdated="refresh"
          @activityDeleted="handleUpdate"
          @activityEdit="handleUpdate"
          :plan="plan" />
      </div>
      <div v-if="activeTab === 'Notes'">
        <PlanNotes @noteCreated="refresh" @noteDeleted="refresh" :plan="plan" />
      </div>
      <AddEditPlanModal @dismissed="editPlanDismissed" ref="editPlan"/>
      <AddEditActivityModal @dismissed="addPlanDismissed" :activityPull="plan" ref="addActivity"/>
    </div>

  </div>
</template>
<script>
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import PlanDetails from '@/components/onboarding/plans/PlanDetails';
import PlanActivities from '@/components/onboarding/plans/PlanActivities';
import PlanNotes from '@/components/onboarding/plans/PlanNotes';
import AddEditPlanModal from '@/components/onboarding/plans/AddEditPlanModal';
import AddEditActivityModal from '@/components/onboarding/plans/AddEditActivityModal';
import spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import StatusBadge from '@/components/onboarding/plans/StatusBadge';

export default {
  components: { StatusBadge,
    AddEditActivityModal,
    AddEditPlanModal,
    PlanNotes,
    PlanActivities,
    PlanDetails,
    PageNavigation,
    spinner },
  props: {
    defaultTab: { type: String, default: 'Details' },
  },
  data() {
    return {
      loading: true,
      plan: {},
      clubInternal: {},
    };
  },
  computed: {
    activeTab() {
      return this.defaultTab;
    },
    club() {
      return this.$store.getters.clubs.find(club => club.id === this.plan.club_id);
    },
    breadCrumbs() {
      return [
        { link: '/onboarding', name: 'Onboarding' },
        { link: '/onboarding/1', name: this.club?.name },
      ];
    },
    EDIT_PLAN() { return 'Edit Plan'; },
    detailsCtas() {
      const ret = [];
      if (this.$store.getters.canAccess(['product', 'success', 'operations'])) {
        ret.push(this.EDIT_PLAN);
      }
      return ret;
    },
    ADD_ACTIVITY() { return 'Add Activity'; },
    activitiesCtas() {
      const ret = [];
      if (this.$store.getters.canAccess(['product', 'success', 'operations'])) {
        ret.push(this.ADD_ACTIVITY);
      }
      return ret;
    },
    tabs() {
      return [
        { name: 'Details', ctas: this.detailsCtas },
        { name: 'Activities', ctas: this.activitiesCtas },
        { name: 'Notes' },
      ];
    },
  },
  created() {
    if (this.$route.params.activeTab) {
      this.activeTab = this.$route.params.activeTab;
    }
    this.fetchPlan();
  },
  methods: {
    assignPlan(plan) {
      this.plan = plan;
    },
    handleUpdate(options) {
      if (options.refreshData) {
        this.refresh();
      }
    },
    refresh() {
      this.fetchPlan();
    },
    fetchPlan() {
      this.loading = true;
      api()
        .get(`/cst/club_onboarding_plans/${this.$route.params.plan}?populate=users,notes,activities,activities:notes`)
        .then((res) => {
          if (res.status === 200) {
            this.plan = res.data;
            this.fetchHealthStatus();
          }
        });
      this.loading = false;
    },
    fetchHealthStatus() {
      api().get(`/cst/club_internals/${this.plan.club_id}/`)
        .then((res) => {
          this.clubInternal = res.data;
        });
    },
    cta($event) {
      if ($event === this.EDIT_PLAN) {
        this.$refs.editPlan.show(this.plan);
      }
      if ($event === this.ADD_ACTIVITY) {
        this.$refs.addActivity.show();
      }
    },
    editPlanDismissed(options) {
      if (options.refreshData) {
        this.refresh();
      }
    },
    addPlanDismissed(options) {
      if (options.refreshData) {
        this.refresh();
      }
    },
    tabClicked($event) {
      if ($event === this.activeTab) return;
      const map = {
        Activities: 'PlanViewActivities',
        Notes: 'PlanViewNotes',
      };
      const route = map[$event] || 'PlanViewDetails';
      this.$router.push({ name: route });
    },
  },
};
</script>

<style lang="sass" scoped>
.last-modified
  margin-left: 0.5rem
  color: $grey
</style>
