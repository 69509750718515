// state
const state = {
  usage: null,
};

// getters
const getters = {
  usage(state) {
    return state.usage;
  },
  onboardingStatus() {
    return [
      { name: 'Not Started', value: 'not started' },
      { name: 'Onboarding', value: 'onboarding' },
      { name: 'Weekly Calls', value: 'weekly calls' },
      { name: 'Monitoring', value: 'monitoring' },
      { name: 'Completed', value: 'completed' },
    ];
  },
  onboardingSize() {
    return [
      { name: 'Full Service', value: 'full' },
      { name: 'Mid Service', value: 'mid' },
      { name: 'Self Service', value: 'self' },
    ];
  },
  summaryCategory() {
    return [
      { name: 'Accounts', value: 'accounts' },
      { name: 'Teams', value: 'teams' },
      { name: 'Players', value: 'players' },
      { name: 'Programs', value: 'programs' },
      { name: 'Transactions', value: 'transactions' },
      { name: 'Revenue', value: 'revenue' },
      { name: 'Events', value: 'events' },
      { name: 'Messages', value: 'messages' },
      { name: 'Forms', value: 'forms' },
    ];
  },
  summaryStatus() {
    return [
      { name: 'Healthy', value: 'success' },
      { name: 'Concern', value: 'warning' },
      { name: 'At risk', value: 'danger' },
    ];
  },
};

export default {
  state,
  getters,
};
