<template>
  <DateNavigationWithPicker
    v-model="currentValue"
    :display-value="displayValue"
    :first-day-of-week="firstDayOfWeek"
    :has-prev="prevEnabled"
    :has-next="nextEnabled"
    :minimum-date="minimumDate"
    :maximum-date="maximumDate"
    @prev="handlePrev"
    @next="handleNext"
  />
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import DateNavigationWithPicker from './DateNavigationWithPicker';

export default {
  components: {
    DateNavigationWithPicker,
  },
  props: {
    value: { type: Date, required: true },
    displayFormat: { type: String, default: 'MMM D' },
    firstDayOfWeek: { type: Number, default: 1 },
    hasPrev: { type: Boolean, default: false },
    hasNext: { type: Boolean, default: false },
    minimumDate: { type: Date, default: null },
    maximumDate: { type: Date, default: null },
    byFiveWeeks: { type: Boolean, default: false },
  },
  data() {
    return {
      selectingDate: false,
    };
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    startOfWeek() {
      return moment(this.value).startOf('isoWeek');
    },
    endOfWeek() {
      return _.cloneDeep(this.startOfWeek).add(4, 'd');
    },
    endOfFiveWeeks() {
      return _.cloneDeep(this.startOfWeek).add(42, 'd');
    },
    displayValue() {
      return this.byFiveWeeks ? `Weeks of ${this.startOfWeek.format(this.displayFormat)} - ${this.endOfFiveWeeks.format(this.displayFormat)}`
        : `${this.startOfWeek.format(this.displayFormat)} - ${this.endOfWeek.format(this.displayFormat)}`;
    },
    prevEnabled() {
      return this.hasPrev && (!this.minimumDate || moment(this.minimumDate).isBefore(this.startOfWeek, 'day'));
    },
    nextEnabled() {
      return this.hasNext && (!this.maximumDate || moment(this.endOfWeek).isBefore(this.maximumDate, 'day'));
    },
  },
  methods: {
    changeWeekBy(amount) {
      this.currentValue = moment(this.startOfWeek).add(amount, 'week').toDate();
    },
    handlePrev() {
      this.changeWeekBy(-1);
    },
    handleNext() {
      this.changeWeekBy(1);
    },
  },
};
</script>
