// import _ from 'lodash';
import store from '@/store/store';

export const AuthGuard = (to, from, next) => {
  if (store.getters.user) {
    next();
  } else {
    store.commit('setLoginRedirect', to.path);
    next('/login');
  }
};

export const LoggedInGuard = (to, from, next) => {
  if (store.getters.user) {
    next('/');
  } else {
    next();
  }
};

export const IsCstAdminGuard = (to, from, next) => {
  if (store.getters.isCstAdmin) {
    next();
  } else {
    next('/');
  }
};

export const IsCstSalesGuard = (to, from, next) => {
  const permissions = store.getters.user.admin_permissions;
  const cstPermissions = permissions.filter(perm => perm.includes('cst_tool'));
  if (cstPermissions.length === 1 && cstPermissions.some(perm => perm.includes('sales'))) {
    next('/');
  } else {
    next();
  }
};
