<template>
  <div>
    <TablePaginated
      name="activitiesTable"
      hoverable
      :row-class="() => 'clickable'"
      :data="tbdfilter"
      :filters-active="hasFiltersActive"
      @select="viewClubActivities($event)"
      searchable
      @queryUpdated="searchQuery= $event">
      <template style="whitespace: nowrap" slot="header-left">
        <DateRangeDropdown
          :isTbd="tbdToggle"
          :isActivities="true"
          v-model="dateRange"
          @input="dateRangeChanged"
          :presets="[
            { name: 'This Week', display: 'This Week', range: thisWeek },
            { name: 'Last Week', display: 'Last Week', range: lastWeek },
            { name: 'Next Week', display: 'Next Week', range: nextWeek },
            { name: 'Last 7 Days', display: 'Last 7 Days', range: last7Days },
            { name: 'Next 7 Days', display: 'Next 7 Days', range: next7Days },
          ]"
        />
      </template>
      <template slot="table-header">
        <b-switch style="margin-bottom: 1rem" v-model="tbdToggle">Show TBD Activities Only</b-switch>
      </template>
      <template slot-scope="props">
        <b-table-column
          label="Club Name"
          field="name"
          header-class="is-unselectable"
          :custom-sort="sortByName"
          sortable>
          {{ clubName(props.row.club_id) }}
        </b-table-column>
        <b-table-column
          label="Onboarding Type"
          field="onboardingType"
          header-class="is-unselectable"
          :custom-sort="sortByType"
          sortable>
          {{ findOnboardingType(props.row.club_id) | capitalize }}
        </b-table-column>
        <b-table-column
          label="Onboarding Category"
          field="onboardingCategory"
          header-class="is-unselectable"
          :custom-sort="sortByCategory"
          sortable>
          {{ formatCategory(props.row.category) }}
        </b-table-column>
        <b-table-column
          label="Milestone"
          field="milestone"
          header-class="is-unselectable"
          sortable>
          {{ props.row.milestone }}
        </b-table-column>
        <b-table-column
          label="Date"
          field="date"
          header-class="is-unselectable"
          :custom-sort="sortByDate"
          sortable>
          {{ props.row.meeting_date ? (formatDate(props.row.meeting_date, 'MMM D, YYYY')) : "TBD" }}
        </b-table-column>
        <b-table-column
          label="Time Spent"
          field="timeSpent"
          header-class="is-unselectable"
          :custom-sort="sortByDuration"
          sortable>
          {{ formatDuration(props.row.duration) }}
        </b-table-column>
        <b-table-column
          label="Lead Staff"
          field="leadStaff"
          header-class="is-unselectable"
          :custom-sort="sortByUserLead"
          sortable>
          {{ userName(props.row.lead_user_id) ? userName(props.row.lead_user_id) : '-' }}
        </b-table-column>
        <b-table-column
          label="Secondary Staff"
          field="secondaryStaff"
          header-class="is-unselectable"
          :custom-sort="sortByUserSecondary"
          sortable>
          {{ props.row.secondary_user_ids.length > 0 ? userLists(props.row.secondary_user_ids) : '-' }}
        </b-table-column>
        <b-table-column
          label="Actions"
          field="actions"
          header-class="is-unselectable"
          centered>
          <div class="activities-table__actions">
            <a class="button is-text activities-table__actions__link" @click.stop="editActivity(props.row)" >
              <div class="is-size-6"><fa-icon :icon="['fas', 'pencil']"/></div>
            </a>
            <a class="button is-text activities-table__actions__link" @click.stop="deleteActivity(props.row)" >
              <div class="is-size-6">
                <fa-icon :icon="['fas', 'trash']" style="margin-right: 0.1rem"/>
              </div>
            </a>
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <ActivitiesFilter/>
    <AddEditActivitiesModal :activityNotes="activityNotes"
      @noteCreated="refresh"
      @noteDeleted="refresh"
      @dismissed="addEditDismissed"
      ref="addEditPlan"/>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import TablePaginated from '@/components/layout/TablePaginated';
import AddEditActivitiesModal from '@/components/activities/AddEditActivitiesModal';
import api from '@/http-playmetrics';
import ActivitiesFilter from '@/components/activities/ActivitiesFilter';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';

export default {
  components: { TablePaginated, ActivitiesFilter, AddEditActivitiesModal, DateRangeDropdown },

  data() {
    return {
      searchQuery: '',
      dateRange: _.cloneDeep(this.defaultDateRange),
      tbdToggle: false,
      activities: [],
      dataShown: false,
    };
  },

  props: {
    // activities: { type: Array, required: true },
    onboardingPlans: { type: Array, required: true },
    defaultDateRange: { type: Object, required: true },
  },
  created() {
    const dateRange = this.$store.getters.summaryDateRange;
    if (dateRange.startDate && dateRange.endDate) {
      this.dateRange = dateRange;
    } else {
      this.dateRange = _.cloneDeep(this.defaultDateRange);
    }
  },
  mounted() {
    this.fetchActivities();
  },
  computed: {
    thisWeek() {
      return {
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
      };
    },
    lastWeek() {
      this.dataDisplayed();
      return {
        startDate: moment().subtract(1, 'weeks').startOf('week').toDate(),
        endDate: moment().subtract(1, 'weeks').endOf('week').toDate(),
      };
    },
    nextWeek() {
      return {
        startDate: moment().add(1, 'weeks').startOf('week').toDate(),
        endDate: moment().add(1, 'weeks').endOf('week').toDate(),
      };
    },
    last7Days() {
      return {
        startDate: moment().subtract(7, 'days').toDate(),
        endDate: moment().toDate(),
      };
    },
    next7Days() {
      return {
        startDate: moment().toDate(),
        endDate: moment().add(7, 'days').toDate(),
      };
    },
    activityNotes() {
      return this.activities.map(activity => ({
        id: activity.club_id,
        notes: activity?.notes ? [...activity?.notes] : [],
      }));
    },
    filteredActivities() {
      return this.activities.map(activity => ({
        id: activity.id,
        club_id: activity.club_id,
        category: activity.category,
        milestone: activity.milestone,
        meeting_date: activity.meeting_date,
        duration: activity.duration,
        lead_user_id: activity.lead_user_id,
        secondary_user_ids: activity.secondary_user_ids,
        onboarding_status: this.findOnboardingPlan(activity.club_id)?.status || '',
        club_type: this.storedClubs.find(club => club.id === activity.club_id)?.type || 'customer',
      })).filter(activity => this.matchesFilter(activity) && this.searchMatch(activity) && this.matchesDate(activity)
        && this.matchesOnboardingType(activity));
    },
    tbdfilter() {
      this.tbdActive();
      if (this.tbd) {
        this.dataDisplayed();
      }
      if (this.tbdToggle) {
        return this.filteredActivities.filter(activity => !activity.meeting_date);
      }
      return this.filteredActivities.filter(activity => activity.meeting_date);
    },
    hasFiltersActive() {
      return this.isFiltersActive(this.$store.getters.activeFiltersFor('activities'));
    },
    storedUsers() {
      return this.$store.getters.users;
    },
    storedClubs() {
      return this.$store.getters.clubs;
    },
  },
  methods: {
    formatCategory(category) {
      return category.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    },
    dateRangeChanged() {
      this.$emit('date', this.dateRange);
    },
    dataDisplayed() {
      if (this.tbdfilter.length > 0) {
        this.dataShown = true;
      } else {
        this.dataShown = false;
      }
      this.$emit('dataDisplayed', this.dataShown);
    },
    tbdActive() {
      this.$emit('tbdActive', this.tbdToggle);
    },
    isFiltersActive(filters) {
      return Object.keys(filters).some((key) => {
        if (key === 'time_spent') {
          if (filters[key].status) {
            return true;
          }
          return false;
        }
        if (key === 'club_type') {
          return !(filters[key] === 'customer');
        }
        if (filters[key]) {
          return true;
        }
        return false;
      });
    },
    sortByName(a, b, isAsc) {
      if (this.clubName(a.club_id) > this.clubName(b.club_id)) return isAsc ? 1 : -1;
      if (this.clubName(a.club_id) < this.clubName(b.club_id)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByType(a, b, isAsc) {
      if (this.findOnboardingType(a.club_id).toLowerCase() >
        this.findOnboardingType(b.club_id).toLowerCase()) return isAsc ? 1 : -1;
      if (this.findOnboardingType(a.club_id).toLowerCase() <
        this.findOnboardingType(b.club_id).toLowerCase()) return isAsc ? -1 : 1;
      return 0;
    },
    sortByUserSecondary(a, b, isAsc) {
      if (this.userName(a.secondary_user_ids[0]) > this.userName(b.secondary_user_ids[0])) return isAsc ? 1 : -1;
      if (this.userName(a.secondary_user_ids[0]) < this.userName(b.secondary_user_ids[0])) return isAsc ? -1 : 1;
      return 0;
    },
    sortByCategory(a, b, isAsc) {
      if (a.category > b.category) return isAsc ? 1 : -1;
      if (a.category < b.category) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDuration(a, b, isAsc) {
      if (a.duration > b.duration) return isAsc ? 1 : -1;
      if (a.duration < b.duration) return isAsc ? -1 : 1;
      return 0;
    },
    sortByUserLead(a, b, isAsc) {
      if (this.userName(a.lead_user_id) > this.userName(b.lead_user_id)) return isAsc ? 1 : -1;
      if (this.userName(a.lead_user_id) < this.userName(b.lead_user_id)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDate(a, b, isAsc) {
      if (!a.meeting_date) return 1;
      if (!b.meeting_date) return -1;
      if (moment(a.meeting_date, 'YYYY-MM-DD').isBefore(moment(b.meeting_date, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.meeting_date, 'YYYY-MM-DD').isAfter(moment(b.meeting_date, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    searchMatch(activity) {
      return ((this.clubName(activity.club_id) &&
        this.clubName(activity.club_id).toLowerCase().includes(this.searchQuery.toLowerCase())) ||
          (this.userName(activity.lead_user_id).toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            this.secUserArray(activity.secondary_user_ids).some(act =>
              act.toLowerCase().includes(this.searchQuery.toLowerCase()))));
    },
    matchesFilter(activity) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('activities');
      return Object.keys(filters).filter(key =>
        typeof key !== 'boolean',
      ).every((key) => {
        if (key === 'onboarding_type' && filters[key].includes(this.findOnboardingType(activity.club_id))) {
          return true;
        } else if (key === 'cs_staff' && (filters[key].includes(activity.lead_user_id) || activity.secondary_user_ids.some(user => filters[key].includes(user)))) {
          return true;
        } else if (key === 'time_spent') {
          if (this.durationCheck(filters[key].status, filters[key].duration, activity.duration)) {
            return true;
          }
          return false;
        } else if (key === 'activity_date') {
          if (filters[key].status === null) {
            return true;
          } else if (filters[key].status === 'Before' && moment(activity.meeting_date).isSameOrBefore(moment(filters[key].date))) {
            return true;
          } else if (filters[key].status === 'After' && moment(activity.meeting_date).isSameOrAfter(moment(filters[key].date))) {
            return true;
          } else if (filters[key].status === 'Between' && moment(activity.meeting_date).isBetween(moment(filters[key].date_range[0]), moment(filters[key].date_range[1]))) {
            return true;
          }
          return false;
        } else if (filters[key].includes(activity[key])) {
          return true;
        } else {
          return false;
        }
      });
    },
    durationCheck(status, filterDuration, duration) {
      if (status === 'Longer Than') {
        return duration >= filterDuration;
      } else if (status === 'Shorter Than') {
        return duration <= filterDuration;
      } else if (!status) {
        return true;
      }
      return false;
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    formatDuration(time) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      return `${hours > 1 ? `${hours} hrs` : ''}${hours === 1 ? `${hours} hr` : ''} ${minutes} min${minutes === 1 ? '' : 's'}`;
    },
    findOnboardingType(id) {
      return this.storedClubs.find(club => club.id === id)?.config.onboarding_type;
    },
    userName(id) {
      if (id === 0) return '-';
      const userData = this.storedUsers.find(user => user.id === id);
      return userData ? `${userData?.first_name} ${userData?.last_name}` : '-';
    },
    userLists(ids) {
      const users = ids.map(id => this.userName(id));
      return users.join(', ');
    },
    secUserArray(ids) {
      const arr = [];
      ids.forEach((id, i) => {
        arr[i] = this.userName(id);
      });
      return arr;
    },
    clubName(id) {
      return this.storedClubs.find(club => club.id === id)?.name || '';
    },
    findOnboardingPlan(id) {
      return this.onboardingPlans.find(plan => plan.club_id === id);
    },
    matchesDate(activity) {
      return moment(activity.meeting_date).isBetween(moment(this.dateRange.startDate), moment(this.dateRange.endDate), 'day', '[]') || !activity.meeting_date;
    },
    matchesOnboardingType(activity) {
      const filter = this.$store.getters.filtersFor('activities');
      return activity.club_type === filter.club_type;
    },
    viewClubActivities(activity) {
      this.$store.commit('setSummaryDateRange', this.dateRange);
      this.$router.push({ name: 'ClubViewActivities', params: { club: activity.club_id, fromTab: 'activities' } });
    },
    refresh() {
      this.fetchActivities();
    },
    editActivity(activity) {
      this.$refs.addEditPlan.show(activity?.id);
    },
    deleteActivity(activity) {
      this.$confirm.showModal({
        confirmText: 'Yes, Delete Activity',
        message: `You are about to delete this onboarding activity for
                  <strong>${this.clubName(activity.club_id)}</strong>. Are you sure
                  you want to continue?`,
        title: 'Delete Onboarding Activity',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => {
          api().delete(`/cst/activities/${activity.id}/`).then(() => {
            this.$store.commit('setNotification', { toast: true, success: true, message: 'Activity Deleted' });
            this.dismissedModal();
          });
        },
      });
    },
    dismissedModal() {
      this.fetchActivities();
    },
    addEditDismissed(options) {
      if (options.refreshData) {
        this.fetchActivities();
      }
    },
    fetchActivities() {
      this.loading = true;
      api().post('/cst/activities/search').then((res) => {
        if (res.status === 200) {
          this.activities = res.data;
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.activities-table
  &__actions
    display: flex
    justify-content: center
    align-items: center
    &__link
      color: inherit
</style>
