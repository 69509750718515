import { render, staticRenderFns } from "./TeamAccountsTableTotalsHeader.vue?vue&type=template&id=242c77da&scoped=true"
var script = {}
import style0 from "./TeamAccountsTableTotalsHeader.vue?vue&type=style&index=0&id=242c77da&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242c77da",
  null
  
)

export default component.exports