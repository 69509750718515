<template>
  <div>
    <div>
      <div class="columns">
        <div class="column is-one-third">
          <div class="columns bis-box full-height" style="margin-top: 0; margin-bottom: 0;">
            <div class="column">
              <div class="has-text-weight-semibold is-font-mont">Total Time</div>
              <div class="has-text-weight-bold is-font-mont has-text-info is-size-5" style="margin-bottom: 1rem;">
                <p>{{ formatDuration(totalTime.total) }}</p>
              </div>
              <div class="activity-details">
                <p>Performed</p>
                <div class="dot-row"/>
                <p class="box-data" style="font-size: 1.15rem;">
                  {{ formatDuration(totalTime.performed) }}
                </p>
              </div>
              <div class="activity-details">
                <p>Scheduled</p>
                <div class="dot-row"/>
                <p class="box-data" style="font-size: 1.15rem;">
                  {{ formatDuration(totalTime.scheduled) }}
                </p>
              </div>
              <div class="activity-details">
                <p>Not Scheduled</p>
                <div class="dot-row"/>
                <p class="box-data" style="font-size: 1.15rem;">
                  {{ formatDuration(totalTime.notScheduled) }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-two-thirds">
          <div class="bis-box full-height">
            <div class="column">
              <div class="has-text-weight-semibold is-font-mont">Time by Category</div>
              <div class="columns" style="margin-top: 2rem;">
                <div class="column">
                  <div class="activity-details">
                    <p>Setup</p>
                    <div class="dot-row"/>
                    <p class="box-data" style="font-size: 1.15rem;">
                      {{ timeByCategory.setup > 0 ? formatDuration(timeByCategory.setup) : 'No Events' }}
                    </p>
                  </div>
                  <div class="activity-details">
                    <p>Registration</p>
                    <div class="dot-row"/>
                    <p class="box-data" style="font-size: 1.15rem;">
                      {{ timeByCategory.registration > 0 ? formatDuration(timeByCategory.registration) : 'No Events' }}
                    </p>
                  </div>
                  <div class="activity-details">
                    <p>Operations</p>
                    <div class="dot-row"/>
                    <p class="box-data" style="font-size: 1.15rem;">
                      {{ timeByCategory.operations > 0 ? formatDuration(timeByCategory.operations) : 'No Events' }}
                    </p>
                  </div>
                </div>
                <div class="column">
                  <div class="activity-details">
                    <p>Website</p>
                    <div class="dot-row"/>
                    <p class="box-data" style="font-size: 1.15rem;">
                      {{ timeByCategory.website > 0 ? formatDuration(timeByCategory.website) : 'No Events' }}
                    </p>
                  </div>
                  <div class="activity-details">
                    <p>Meeting</p>
                    <div class="dot-row"/>
                    <p class="box-data" style="font-size: 1.15rem;">
                      {{ timeByCategory.meeting > 0 ? formatDuration(timeByCategory.meeting) : 'No Events' }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TablePaginated class="activities-table"
      name="planActivies"
      style="margin-top: 1rem;"
      :data="filteredActivities"
      @queryUpdated="searchQuery= $event"
      :filters-active="filtersActive">
      <template slot="header-left">
        <h3 class="activities-table__table-title">Total Activities</h3>
      </template>
      <template slot-scope="props">
        <b-table-column
          label="Onboarding Category"
          field="onboardingCategory"
          header-class="is-unselectable"
          :custom-sort="sortByCategory"
          sortable
        >
          {{props.row.category | capitalize}}
        </b-table-column>
        <b-table-column
          label="Milestone"
          field="milestone"
          header-class="is-unselectable"
          sortable
        >
          {{props.row.milestone}}
        </b-table-column>
        <b-table-column
          label="Date"
          field="date"
          header-class="is-unselectable"
          :custom-sort="sortByDate"
          sortable
        >
          {{props.row.meeting_date ? (formatDate(props.row.meeting_date, 'MMM D, YYYY')) : "TBD" }}
        </b-table-column>
        <b-table-column
          label="Time Spent"
          field="timeSpent"
          header-class="is-unselectable"
          :custom-sort="sortByDuration"
          sortable
        >
          {{ formatDuration(props.row.duration) }}
        </b-table-column>
        <b-table-column
          label="Lead Staff"
          field="leadStaff"
          header-class="is-unselectable"
          :custom-sort="sortByUserLead"
          sortable
        >
          {{ staffByID(props.row.lead_user_id) }}
        </b-table-column>
        <b-table-column
          label="Secondary Staff"
          field="secondaryStaff"
          header-class="is-unselectable"
          :custom-sort="sortByUserSecondary"
          sortable
        >
          {{props.row.secondary_user_ids.length > 0 ? staffList(props.row.secondary_user_ids) : '-' }}
        </b-table-column>
        <b-table-column
          v-if="$store.getters.canAccess(['product', 'success', 'operations'])"
          label="Actions"
          field="actions"
          centered
        >
          <div class="activities-table__actions">
            <a class="activities-table__actions__link--edit"
            >
              <div @click.stop="editActivity(props.row)" class="is-size-6"><fa-icon :icon="['fas', 'pencil']" /></div>
            </a>
            <b-dropdown
              ref="dropdown"
              aria-role="list"
              position="is-bottom-left">
              <a
                class="button is-text"
                slot="trigger">
                <fa-icon :icon="['far', 'ellipsis-h']" />
              </a>
              <b-dropdown-item
                aria-role="listitem">
                <div class="columns is-mobile">
                  <div @click="deleteActivity(props.row)" class="column has-text-danger">
                    <fa-icon
                      :icon="['fas', 'trash']"
                      class="is-danger"
                      style="margin-right: 0.25rem"
                    />
                    Delete Activity
                  </div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Training Activities Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <OnboardingActivitiesFilter/>
    <AddEditActivityModal @noteUpdated="noteUpdated" @dismissed="dismissed" ref="addEditPlan"/>
  </div>
</template>
<script>
import moment from 'moment';
import TablePaginated from '@/components/layout/TablePaginated';
import OnboardingActivitiesFilter from '@/components/onboarding/plans/OnboardingActivitiesFilter';
import AddEditActivityModal from '@/components/onboarding/plans/AddEditActivityModal';
import api from '@/http-playmetrics';

export default {
  components: { TablePaginated, OnboardingActivitiesFilter, AddEditActivityModal },
  props: {
    plan: { type: Object, required: true },
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    timeByCategory() {
      const times = {
        setup: 0,
        registration: 0,
        operations: 0,
        website: 0,
        meeting: 0,
      };
      if (!this.plan.activities) {
        return times;
      }
      this.plan.activities.forEach((activity) => {
        times[activity.category] += activity.duration;
      });
      return times;
    },
    totalTime() {
      const time = {
        total: 0,
        performed: 0,
        scheduled: 0,
        notScheduled: 0,
      };
      if (!this.plan.activities) {
        return time;
      }
      this.plan.activities.forEach((activity) => {
        time[this.timeStatus(activity)] += activity.duration;
        time.total += activity.duration;
      });
      return time;
    },
    filtersActive() {
      const filters = this.$store.getters.activeFiltersFor('onboardingActivities');
      return this.isFiltersActive(filters);
    },
    storedClubs() {
      return this.$store.getters.clubs;
    },
    staff() {
      return this.$store.getters.users;
    },
    filteredActivities() {
      return this.plan?.activities?.filter(activity => this.matchesFilter(activity) && this.searchMatch(activity));
    },
  },
  methods: {
    timeStatus(activity) {
      if (moment(activity.meeting_date).isSameOrBefore(moment())) {
        return 'performed';
      } else if (moment(activity.meeting_date).isAfter(moment())) {
        return 'scheduled';
      } else {
        return 'notScheduled';
      }
    },
    isFiltersActive(filters) {
      return Object.keys(filters).some((key) => {
        if (key === 'activity_date' || key === 'time_spent') {
          if (filters[key].status) {
            return true;
          }
          return false;
        }
        if (filters[key]) {
          return true;
        }
        return false;
      });
    },
    sortByCategory(a, b, isAsc) {
      if (a.category > b.category) return isAsc ? 1 : -1;
      if (a.category < b.category) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDuration(a, b, isAsc) {
      if (a.duration > b.duration) return isAsc ? 1 : -1;
      if (a.duration < b.duration) return isAsc ? -1 : 1;
      return 0;
    },
    sortByUserLead(a, b, isAsc) {
      if (this.userName(a.lead_user_id) > this.userName(b.lead_user_id)) return isAsc ? 1 : -1;
      if (this.userName(a.lead_user_id) < this.userName(b.lead_user_id)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByUserSecondary(a, b, isAsc) {
      if (this.userName(a.secondary_user_ids[0]) > this.userName(b.secondary_user_ids[0])) return isAsc ? 1 : -1;
      if (this.userName(a.secondary_user_ids[0]) < this.userName(b.secondary_user_ids[0])) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDate(a, b, isAsc) {
      if (!a.meeting_date) return 1;
      if (!b.meeting_date) return -1;
      if (moment(a.meeting_date, 'YYYY-MM-DD').isBefore(moment(b.meeting_date, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.meeting_date, 'YYYY-MM-DD').isAfter(moment(b.meeting_date, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    noteUpdated() {
      this.$emit('noteUpdated');
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    editActivity(activity) {
      this.$refs.addEditPlan.show(activity);
    },
    dismissed(options = { refreshData: true }) {
      this.$emit('activityEdit', options);
    },
    dismissedModal(options = { refreshData: true }) {
      this.$emit('activityDeleted', options);
    },
    deleteActivity(activity) {
      this.$confirm.showModal({
        confirmText: 'Yes, Delete Activity',
        message: `You are about to delete this onboarding activity for
                  <strong>${this.clubName(activity.club_id)}</strong>. Are you sure
                  you want to continue?`,
        title: 'Delete Onboarding Activity',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => {
          api().delete(`/cst/activities/${activity.id}/`).then(() => {
            this.$store.commit('setNotification', { toast: true, success: true, message: 'Activity Deleted' });
            this.dismissedModal();
          });
        },
      });
    },
    secUserArray(ids) {
      const arr = [];
      ids.forEach((id, i) => {
        arr[i] = this.userName(id);
      });
      return arr;
    },
    userName(id) {
      const userData = this.staff.find(user => user.id === id);
      return `${userData?.first_name} ${userData?.last_name}`;
    },
    clubName(id) {
      return this.storedClubs.find(club => club.id === id)?.name || '';
    },
    searchMatch(activity) {
      return ((activity.category.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
        (activity.milestone.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
          (this.userName(activity.lead_user_id).toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            this.secUserArray(activity.secondary_user_ids).some(act =>
              act.toLowerCase().includes(this.searchQuery.toLowerCase()))));
    },
    durationCheck(status, filterDuration, duration) {
      if (status === 'Longer') {
        return duration >= filterDuration;
      } else if (status === 'Shorter') {
        return duration <= filterDuration;
      } else if (!status) {
        return true;
      }
      return false;
    },
    formatDuration(time) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      return `${hours} hr${hours === 1 ? '' : 's'} ${minutes} min${minutes === 1 ? '' : 's'}`;
    },
    matchesFilter(activity) {
      if (!this.filtersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('onboardingActivities');
      return Object.keys(filters).every((key) => {
        if (key === 'onboarding_type' && filters[key].includes(this.findOnboardingType(activity.club_id))) {
          return true;
        } else if (key === 'cs_staff' && (filters[key].includes(activity.lead_user_id) || activity.secondary_user_ids.some(user => filters[key].includes(user)))) {
          return true;
        } else if (key === 'time_spent') {
          if (this.durationCheck(filters[key].status, filters[key].duration, activity.duration)) {
            return true;
          }
          return false;
        } else if (key === 'activity_date') {
          if (filters[key].status === null) {
            return true;
          } else if (filters[key].status === 'Before' && moment(activity.meeting_date).isSameOrBefore(moment(filters[key].date))) {
            return true;
          } else if (filters[key].status === 'After' && moment(activity.meeting_date).isSameOrAfter(moment(filters[key].date))) {
            return true;
          } else if (filters[key].status === 'Between' && moment(activity.meeting_date).isBetween(moment(filters[key].date_range[0]), moment(filters[key].date_range[1]))) {
            return true;
          }
          return false;
        } else if (filters[key].includes(activity[key])) {
          return true;
        } else {
          return false;
        }
      });
    },
    staffByID(id) {
      const user = this.staff.find(staff => staff.id === id);
      return user ? `${user?.first_name} ${user?.last_name}` : '-';
    },
    staffList(ids) {
      const users = ids.map(id => this.staffByID(id));
      return users.join(', ');
    },
  },
};
</script>

<style lang="sass" scoped>
.activities-table
  &__actions
    display: flex
    justify-content: center
    align-items: center
    &__link
      color: inherit
      &--edit
        color: inherit
        margin-right: 1rem
  &__table-title
    font-family: $family-headers
    font-weight: $weight-semibold
    font-size: $size-4
.activity-details
  font-family: $family-headers
  font-weight: $weight-semibold
  font-size: $size-7
  display: flex
  align-items: baseline
  margin-bottom: .5rem
  .dot-row
    border-bottom: 2px dotted $black
    flex: 1
    margin: 0 0.25rem
.box-data
  font-family: $family-headers
  font-weight: $weight-medium
</style>
