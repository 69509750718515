<template>
  <div v-if="siteMovedTo" class="has-text-centered" style="margin-top:4rem">
    <h1>The CST tool has been moved to</h1>
    <a :href="siteMovedTo">{{siteMovedTo}}</a>
    <h1>Please update your bookmarks to point to the new URL</h1>
  </div>
  <div v-else :class="appClass">
    <Notifications />
    <template v-if="loading">
      <Spinner/>
    </template>
    <template v-else>
      <main @click="dismissFilterTray">
        <router-view class="full-height-view"></router-view>
      </main>
      <ReauthenticationModal />
    </template>
    <div id="modalConfirmContainer"/>
  </div>
</template>

<script>
import CONFIG from '@/env-config';
import store from '@/store/store';
import Spinner from '@/components/common/Spinner';
import ReauthenticationModal from '@/components/auth/ReauthenticationModal';
import Notifications from './components/layout/Notifications';

export default {
  components: {
    Notifications,
    ReauthenticationModal,
    Spinner,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    appClass() {
      return {
        noscroll: this.$store.getters.preventScroll,
      };
    },
    siteMovedTo() {
      const currentURL = `${window.location.protocol}//${window.location.host}/`;
      return currentURL === CONFIG.cstURL ? null : CONFIG.cstURL;
    },
  },
  async mounted() {
    this.$confirm.$mount('#modalConfirmContainer');
    await this.fetchInitialData();
  },
  methods: {
    dismissFilterTray() {
      this.$store.commit('setFilterTrayOpen', false);
    },
    fetchInitialData() {
      this.loading = true;
      return Promise.all(
        [store.dispatch('fetchUsers'),
          store.dispatch('searchClubs'),
        ],
      ).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="sass">
  // NOTE: webpack imports variables.sass into each component.
  // https://vue-loader.vuejs.org/en/configurations/pre-processors.html
  @import "~bulma"
  @import "./assets/styles/custom-styles"
  @import "~buefy/src/scss/buefy"
  .full-height-view
    min-height: 80vh
  .noscroll
    height: 100vh
    overflow-y: hidden
</style>
