var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('spinner'):_c('div',[_c('div',{staticClass:"level-item"},[_c('div',{staticClass:"level-item week-picker"},[_c('WeekNavigationWithPicker',{attrs:{"byFiveWeeks":true,"has-prev":"","has-next":""},model:{value:(_vm.selectedDate),callback:function ($$v) {_vm.selectedDate=$$v},expression:"selectedDate"}})],1)]),(_vm.tableRows.length)?_c('TablePaginated',{staticClass:"weekly-summary-table",attrs:{"name":"Staff Weekly Summary","searchable":false,"filterable":false,"showPagination":false,"defaultPerPage":_vm.tableRows.length,"bordered":"","scrollable":"","data":_vm.tableRows},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{staticClass:"user-name-column",attrs:{"field":"user_name","header-class":"is-unselectable header-background","cell-class":_vm.cellBackground(0, props.row.is_header),"width":"16rem"}},[(!(props.row.user_name === 'Total' || props.row.user_name === '% of Capacity' ||
          props.row.user_name.includes('Former User')))?_c('a',{on:{"click":function($event){return _vm.viewUser(props.row.id)}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(props.row.user_name))+" ")]):_c('div',[_vm._v(" "+_vm._s(props.row.user_name)+" ")])]),_c('b-table-column',{class:_vm.isSelectable(props.row.id, props.row.first_week),attrs:{"field":"first_week","centered":"","cell-class":_vm.cellBackground(props.row.first_week, props.row.is_header),"header-class":"is-unselectable header-background","label":`${_vm.formatWeek(0)}`,"width":"16rem"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"header-font"},[_vm._v(" "+_vm._s(_vm.formatWeek(0))+" ")]),_vm._v(" "),(_vm.week === 'first_week')?_c('div',{staticClass:"indicator"}):_vm._e()])]),(_vm.isSelectable(props.row.id, props.row.first_week))?_c('a',{on:{"click":function($event){_vm.showModal(props.row.id, props.row.first_week,
          props.row.user_name, _vm.activitiesForWeek(props.row.id, _vm.weeks[0]), _vm.weeks[0])}}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.first_week))+" ")])]):_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.first_week))+" ")])],2),_c('b-table-column',{class:_vm.isSelectable(props.row.id, props.row.second_week),attrs:{"field":"second_week","centered":"","cell-class":_vm.cellBackground(props.row.second_week, props.row.is_header),"header-class":"is-unselectable header-background","label":`${_vm.formatWeek(1)}`,"width":"16rem"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"header-font"},[_vm._v(" "+_vm._s(_vm.formatWeek(1))+" ")]),_vm._v(" "),(_vm.week === 'second_week')?_c('div',{staticClass:"indicator"}):_vm._e()])]),(_vm.isSelectable(props.row.id, props.row.second_week))?_c('a',{on:{"click":function($event){_vm.showModal(props.row.id, props.row.second_week,
          props.row.user_name, _vm.activitiesForWeek(props.row.id, _vm.weeks[1]), _vm.weeks[1])}}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.second_week))+" ")])]):_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.second_week))+" ")])],2),_c('b-table-column',{class:_vm.isSelectable(props.row.id, props.row.third_week),attrs:{"field":"third_week","centered":"","cell-class":_vm.cellBackground(props.row.third_week, props.row.is_header),"header-class":"is-unselectable header-background","label":`${_vm.formatWeek(2)}`,"width":"16rem"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"header-font"},[_vm._v(" "+_vm._s(_vm.formatWeek(2))+" ")]),_vm._v(" "),(_vm.week === 'third_week')?_c('div',{staticClass:"indicator"}):_vm._e()])]),(_vm.isSelectable(props.row.id, props.row.third_week))?_c('a',{on:{"click":function($event){_vm.showModal(props.row.id, props.row.third_week,
          props.row.user_name, _vm.activitiesForWeek(props.row.id, _vm.weeks[2]), _vm.weeks[2])}}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.third_week))+" ")])]):_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.third_week))+" ")])],2),_c('b-table-column',{class:_vm.isSelectable(props.row.id, props.row.fourth_week),attrs:{"field":"fourth_week","centered":"","cell-class":_vm.cellBackground(props.row.fourth_week, props.row.is_header),"header-class":"is-unselectable header-background","label":`${_vm.formatWeek(3)}`,"width":"16rem"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"header-font"},[_vm._v(" "+_vm._s(_vm.formatWeek(3))+" ")]),_vm._v(" "),(_vm.week === 'fourth_week')?_c('div',{staticClass:"indicator"}):_vm._e()])]),(_vm.isSelectable(props.row.id, props.row.fourth_week))?_c('a',{on:{"click":function($event){_vm.showModal(props.row.id, props.row.fourth_week,
          props.row.user_name, _vm.activitiesForWeek(props.row.id, _vm.weeks[3]), _vm.weeks[3])}}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.fourth_week))+" ")])]):_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.fourth_week))+" ")])],2),_c('b-table-column',{class:_vm.isSelectable(props.row.id, props.row.fifth_week),attrs:{"field":"fifth_week","centered":"","cell-class":_vm.cellBackground(props.row.fifth_week, props.row.is_header),"header-class":"is-unselectable header-background","label":`${_vm.formatWeek(4)}`,"width":"16rem"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"header-font"},[_vm._v(" "+_vm._s(_vm.formatWeek(4))+" ")]),_vm._v(" "),(_vm.week === 'fifth_week')?_c('div',{staticClass:"indicator"}):_vm._e()])]),(_vm.isSelectable(props.row.id, props.row.fifth_week))?_c('a',{on:{"click":function($event){_vm.showModal(props.row.id, props.row.fifth_week,
          props.row.user_name, _vm.activitiesForWeek(props.row.id, _vm.weeks[4]), _vm.weeks[4])}}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.fifth_week))+" ")])]):_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.fifth_week))+" ")])],2),_c('b-table-column',{class:_vm.isSelectable(props.row.id, props.row.sixth_week),attrs:{"field":"sixth_week","centered":"","cell-class":_vm.cellBackground(props.row.sixth_week, props.row.is_header),"header-class":"is-unselectable header-background","label":`${_vm.formatWeek(5)}`,"width":"16rem"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"header-font"},[_vm._v(" "+_vm._s(_vm.formatWeek(5))+" ")]),_vm._v(" "),(_vm.week === 'sixth_week')?_c('div',{staticClass:"indicator"}):_vm._e()])]),(_vm.isSelectable(props.row.id, props.row.sixth_week))?_c('a',{on:{"click":function($event){_vm.showModal(props.row.id, props.row.sixth_week,
          props.row.user_name, _vm.activitiesForWeek(props.row.id, _vm.weeks[5]), _vm.weeks[5])}}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.sixth_week))+" ")])]):_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.sixth_week))+" ")])],2),_c('b-table-column',{class:_vm.isSelectable(props.row.id, props.row.seventh_week),attrs:{"field":"seventh_week","centered":"","cell-class":_vm.cellBackground(props.row.seventh_week, props.row.is_header),"header-class":"is-unselectable header-background","label":`${_vm.formatWeek(6)}`,"width":"16rem"}},[_c('template',{slot:"header"},[_c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('p',{staticClass:"header-font"},[_vm._v(" "+_vm._s(_vm.formatWeek(6))+" ")]),_vm._v(" "),(_vm.week === 'seventh_week')?_c('div',{staticClass:"indicator"}):_vm._e()])]),(_vm.isSelectable(props.row.id, props.row.seventh_week))?_c('a',{on:{"click":function($event){_vm.showModal(props.row.id, props.row.seventh_week,
          props.row.user_name, _vm.activitiesForWeek(props.row.id, _vm.weeks[6]), _vm.weeks[6])}}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.seventh_week))+" ")])]):_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.seventh_week))+" ")])],2)]}}],null,false,2278224023)},[_c('template',{slot:"header-left"},[_c('b-switch',{model:{value:(_vm.onboardingOnly),callback:function ($$v) {_vm.onboardingOnly=$$v},expression:"onboardingOnly"}},[_vm._v("Show only onboarding activities")])],1),_c('template',{slot:"header-right"},[_c('div',{staticClass:"view-selector"},[_c('strong',[_vm._v(" View hours as: ")]),_c('b-select',{staticStyle:{"margin-left":"1rem"},attrs:{"placeholder":"Primary"},model:{value:(_vm.currentView),callback:function ($$v) {_vm.currentView=$$v},expression:"currentView"}},_vm._l((_vm.views),function(view){return _c('option',{key:view,domProps:{"value":view}},[_vm._v(" "+_vm._s(view)+" ")])}),0)],1)])],2):[_c('section',{staticClass:"section"},[_c('div',{staticClass:"content has-text-grey has-text-centered"},[_c('p',[_vm._v("No Activities this Week")])])])],_c('WeekUserActivitiesModal',{ref:"userActivities"})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }