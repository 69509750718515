// state
const state = {
  reportDateRange: {
    startDate: null,
    endDate: null,
  },
  summaryDateRange: {
    startDate: null,
    endDate: null,
  },
};

const getters = {
  reportsDateRange() {
    return {
      startDate: state.reportDateRange.startDate,
      endDate: state.reportDateRange.endDate,
    };
  },
  summaryDateRange() {
    return {
      startDate: state.summaryDateRange.startDate,
      endDate: state.summaryDateRange.endDate,
    };
  },
  activityOnboardingType() {
    return [
      { name: 'Full', value: 'full' },
      { name: 'Mid', value: 'mid' },
      { name: 'Self', value: 'self' },
    ];
  },
  activityOnboardingStatus() {
    return [
      { name: 'Not Started', value: 'not started' },
      { name: 'Onboarding', value: 'onboarding' },
      { name: 'Weekly Calls', value: 'weekly calls' },
      { name: 'Monitoring', value: 'monitoring' },
      { name: 'Completed', value: 'completed' },
    ];
  },
  activityOnboardingCategory() {
    return [
      { name: 'Setup', value: 'setup' },
      { name: 'Registration', value: 'registration' },
      { name: 'Operations', value: 'operations' },
      { name: 'Website', value: 'website' },
      { name: 'Meeting', value: 'meeting' },
      { name: 'Team Accounts', value: 'team_accounts' },
    ];
  },
  activityMilestones() {
    return {
      setup: [
        { name: 'Kickoff', value: 'Kickoff' },
        { name: 'Intro to PM', value: 'Intro to PM' },
        { name: 'Club Setup', value: 'Club Setup' },
      ],
      registration: [
        { name: 'PA I', value: 'PAI' },
        { name: 'PA II Competitive', value: 'PAII Competitive' },
        { name: 'PA II Recreation', value: 'PAII Recreation' },
        { name: 'PA III', value: 'PAIII' },
      ],
      operations: [
        { name: 'Field Scheduling', value: 'Field Scheduling' },
        { name: 'Directors', value: 'Directors' },
        { name: 'Forms', value: 'Forms' },
        { name: 'Leagues Training', value: 'Leagues Training' },
      ],
      website: [
        { name: 'Website Builder', value: 'Website Builder' },
      ],
      meeting: [
        { name: 'Sales', value: 'Sales' },
        { name: 'Weekly Check In', value: 'Weekly Check In' },
        { name: 'Add\'l Reg', value: 'Add\'l Reg' },
        { name: 'Add\'l Ops', value: 'Add\'l Ops' },
        { name: 'Add\'l Setup', value: 'Add\'l Setup' },
        { name: 'Add\'l Team Accounts', value: 'Add\'l Team Accounts' },
        { name: 'Support', value: 'Support' },
        { name: 'Status Update', value: 'Status Update' },
        { name: 'Customer Briefing', value: 'Customer Briefing' },
      ],
      team_accounts: [
        { name: 'Admin', value: 'Admin' },
        { name: 'Treasurer', value: 'Treasurer' },
      ],
    };
  },

};

// mutations
const mutations = {
  setActivitiesPlans(state, payload) {
    state.activityPlan = payload;
  },
  setReportDateRange(state, payload) {
    state.reportDateRange.startDate = payload.startDate;
    state.reportDateRange.endDate = payload.endDate;
  },
  setSummaryDateRange(state, payload) {
    state.summaryDateRange.startDate = payload.startDate;
    state.summaryDateRange.endDate = payload.endDate;
  },
};

export default {
  state,
  getters,
  mutations,
};
