<template>
  <div>
    <Spinner v-if="loading" />
    <template v-else class="plans-table">
      <TablePaginated
        name="onboardingPlans"
        :data="tableData"
        hoverable
        :default-sort="['id', 'desc']"
        :row-class="() => 'clickable'"
        :filters-active="hasFiltersActive"
        @queryUpdated="searchQuery=$event"
        @select="viewPlan(planByID($event.id))">
        <template slot="header-left">
          <div style="display: flex;flex-direction: column">
            <div style="display: flex;align-items: flex-end">
              <b-tabs
                v-model="activeTab"
                type="is-toggle"
                class="b-tabs-no-content">
                <b-tab-item label="Open" />
                <b-tab-item label="Completed" />
                <b-tab-item label="Prospect" />
              </b-tabs>
            </div>
            <b-switch v-model="showStaff" style="margin-top: 2rem">Show staff members</b-switch>
          </div>
        </template>
        <template slot-scope="props">
          <b-table-column
            :visible="!activeTab"
            header-class="is-unselectable"
            width="2rem"
            field="alert">
            <CustomerHealthModalPopover v-if="props.row.health_status" :customerHealth="props.row"/>
          </b-table-column>
          <b-table-column
            :visible="false"
            label="ID"
            field="id"
            sortable>
            {{ props.row.id }}
          </b-table-column>
          <b-table-column
            :class="showStaff ? 'plans-table__status-column' : ''"
            header-class="is-unselectable"
            label="Club Name"
            field="clubName"
            :custom-sort="sortByClubName"
            sortable>
            {{ props.row.club_name }}
          </b-table-column>
          <b-table-column
            :class="showStaff ? 'plans-table__status-column' : ''"
            header-class="is-unselectable"
            label="Size"
            field="size"
            :custom-sort="sortByClubSize"
            sortable>
            {{ props.row.club_size | capitalize }}
          </b-table-column>
          <b-table-column
            :class="showStaff ? 'plans-table__status-column' : ''"
            header-class="is-unselectable"
            label="Type"
            field="onboardingType"
            :custom-sort="sortByClubOnboardingType"
            sortable>
            {{ props.row.onboarding_type | capitalize }}
          </b-table-column>
          <b-table-column
            :class="showStaff ? 'plans-table__status-column' : ''"
            header-class="is-unselectable"
            label="Customer Since"
            field="customerSince"
            :custom-sort="sortByCustomerSince"
            sortable
          >
            {{ props.row.customer_since }}
          </b-table-column>
          <b-table-column
            :visible="!showStaff"
            header-class="is-unselectable"
            label="Overall Lead"
            field="overall_lead"
            :custom-sort="sortByOverallLead"
            sortable
          >
              {{props.row.cs_lead_user ? props.row.cs_lead_user.name : '&#8212;'}}
          </b-table-column>
          <b-table-column
            header-class="is-unselectable"
            :class="showStaff ? 'plans-table__status-column' : ''"
            label="Setup Status"
            field="setup_status"
            sortable>
            <div class="plans-table__status-section">
              <span>{{props.row.setup_status | capitalize}}</span>
              <template v-if="showStaff">
                <div class="plans-table__status-section__user primary">
                  {{props.row.setup_lead_user ? props.row.setup_lead_user.name : '&#8212;'}}
                </div>
                <div class="plans-table__status-section__user">
                  {{props.row.setup_secondary_user ? props.row.setup_secondary_user.name : ''}}
                </div>
              </template>
            </div>
          </b-table-column>
          <b-table-column
            header-class="is-unselectable"
            :class="showStaff ? 'plans-table__status-column' : ''"
            label="Registration Status"
            field="registration_status"
            sortable>
            <div v-if="props.row.registration_status !== 'not applicable'" class="plans-table__status-section">
              <span>{{props.row.registration_status | capitalize}}</span>
              <template v-if="showStaff">
                <div class="plans-table__status-section__user primary">
                  {{props.row.registration_lead_user ? props.row.registration_lead_user.name : '&#8212;'}}
                </div>
                <div class="plans-table__status-section__user">
                  {{props.row.registration_secondary_user ? props.row.registration_secondary_user.name : ''}}
                </div>
              </template>
            </div>
            <div v-else>&#8212;</div>
          </b-table-column>
          <b-table-column
            header-class="is-unselectable"
            :class="showStaff ? 'plans-table__status-column' : ''"
            label="Operations Status"
            field="operations_status"
            sortable>
            <div class="plans-table__status-section">
              <span>{{props.row.operations_status | capitalize}}</span>
              <template v-if="showStaff">
                <div class="plans-table__status-section__user primary">
                  {{props.row.operations_lead_user ? props.row.operations_lead_user.name : '&#8212;'}}
                </div>
                <div class="plans-table__status-section__user">
                  {{props.row.operations_secondary_user ? props.row.operations_secondary_user.name : ''}}
                </div>
              </template>
            </div>
          </b-table-column>
          <b-table-column
            header-class="is-unselectable"
            :class="showStaff ? 'plans-table__status-column' : ''"
            label="Website Status"
            field="website_status"
            sortable>
            <div v-if="props.row.website_status !== 'not applicable'" class="plans-table__status-section">
              <span>{{props.row.website_status | capitalize}}</span>
              <template v-if="showStaff">
                <div class="plans-table__status-section__user primary">
                  {{props.row.website_lead_user ? props.row.website_lead_user.name : '&#8212;'}}
                </div>
              </template>
            </div>
            <div v-else>&#8212;</div>
          </b-table-column>
          <b-table-column
            header-class="is-unselectable"
            :class="showStaff ? 'plans-table__status-column' : ''"
            label="Team Accounts"
            field="team_accounts"
            sortable>
            <div class="plans-table__status-section">
              <span>{{props.row.team_accounts_status | capitalize}}</span>
              <template v-if="showStaff">
                <div class="plans-table__status-section__user primary">
                  {{props.row.team_accounts_lead_user ? props.row.team_accounts_lead_user.name : '&#8212;'}}
                </div>
                <div class="plans-table__status-section__user">
                  {{props.row.team_accounts_secondary_user ? props.row.team_accounts_secondary_user.name : '&#8212;'}}
                </div>
              </template>
            </div>
          </b-table-column>
          <b-table-column
            header-class="is-unselectable"
            :class="showStaff ? 'plans-table__status-column' : ''"
            label="Overall Status"
            field="status"
            sortable>
            <div class="plans-table__status-section">
              <StatusBadge class="plans-table__status-section__badge" :status="props.row.status" />
              <template v-if="showStaff">
                <div class="plans-table__status-section__user primary">
                  {{props.row.cs_lead_user ? props.row.cs_lead_user.name : '&#8212;'}}
                </div>
                <div class="plans-table__status-section__user">
                  {{props.row.cs_secondary_user ? props.row.cs_secondary_user.name : ''}}
                </div>
              </template>
            </div>
          </b-table-column>
          <b-table-column
            v-if="$store.getters.canAccess(['product', 'success', 'operations'])"
            header-class="is-unselectable"
            centered
            label="Actions"
            field="actions">
            <div class="plans-table__actions">
              <a class="plans-table__actions__link--edit"
                 @click.stop="editPlan(plansByID(props.row.id))"
              >
                <div class="is-size-6"><fa-icon :icon="['fas', 'pencil']" /></div>
              </a>
              <b-dropdown
                ref="dropdown"
                aria-role="list"
                position="is-bottom-left"
                @click.native.stop>
                <a class="plans-table__actions__link"
                   slot="trigger">
                  <div class="is-size-6"><fa-icon :icon="['far', 'ellipsis-h']" /></div>
                </a>
                <b-dropdown-item
                  aria-role="listitem"
                  @click=" updateOnboardingHealth(clubInternalsByID[props.row.club_id])">
                  <div class="columns is-mobile">
                    <div class="column has-text-primary">
                      <fa-icon :icon="['fas', 'pencil']" />
                      Update Customer Health
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item
                  aria-role="listitem">
                  <div class="columns is-mobile">
                    <div v-if="!props.row.archived_at" @click="archivePlan(props.row)" class="column has-text-danger">
                      <fa-icon :icon="['far', 'archive']" style="margin-right: 0.25rem"/>
                      Archive Plan
                    </div>
                    <div v-else @click="restorePlan(props.row)" class="column has-text-primary">
                      <fa-icon :icon="['fas', 'redo']" />
                      Restore Plan
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No Onboarding Plans Found</p>
            </div>
          </section>
        </template>
      </TablePaginated>
    </template>
    <AddEditPlanModal @dismissed="$emit('edited', $event)" ref="editPlan"/>
    <UpdateOnboardingHealth @dismissed="$emit('healthUpdated', $event)" ref="onboardingHealth"/>
    <PlansFilter :filters-active="hasFiltersActive"/>
  </div>
</template>

<script>
import TablePaginated from '@/components/layout/TablePaginated';
import Spinner from '@/components/common/Spinner';
import AddEditPlanModal from '@/components/onboarding/plans/AddEditPlanModal';
import api from '@/http-playmetrics';
import StatusBadge from '@/components/onboarding/plans/StatusBadge';
import PlansFilter from '@/components/onboarding/plans/PlansFilter';
import CustomerHealthModalPopover from '@/components/common/CustomerHealthModalPopover';
import { formatDate } from '@/filters';
import _ from 'lodash';
import UpdateOnboardingHealth from './UpdateOnboardingHealth';

export default {
  components: { StatusBadge,
    AddEditPlanModal,
    TablePaginated,
    Spinner,
    PlansFilter,
    UpdateOnboardingHealth,
    CustomerHealthModalPopover },
  props: {
    loading: { type: Boolean, required: true },
    plans: { type: Array, required: true },
    clubInternals: { type: Array, required: true },
  },
  data() {
    return {
      searchQuery: '',
      activeTab: 0,
      showStaff: false,
    };
  },
  computed: {
    tableData() {
      switch (this.activeTab) {
        case 2: return this.prospectPlans;
        case 1: return this.closedPlans;
        case 0: return this.openPlans;
        default: return this.openPlans;
      }
    },
    clubs() {
      return this.$store.getters.clubs;
    },
    clubInternalsByID() {
      return this.clubInternals.reduce((acc, club) => ({ ...acc, [club.id]: club }), {});
    },
    plansWithClubFields() {
      return this.plans.map(plan => ({
        ...plan,
        club_name: this.clubName(plan.club_id),
        club_type: this.clubType(plan.club_id),
        club_size: this.clubSize(plan.club_id),
        health_status: this.clubInternalsByID[plan.club_id]?.health_status || '',
        health_notes: this.clubInternalsByID[plan.club_id]?.health_notes || '',
        health_updated_at: this.clubInternalsByID[plan.club_id]?.health_updated_at || '',
        health_updated_by_user_id: this.clubInternalsByID[plan.club_id]?.health_updated_by_user_id || 0,
        sales_contact_user_id: this.clubSalesContactUser(plan.club_id),
        club_sport: this.clubSport(plan.club_id),
        onboarding_type: this.clubOnboardingType(plan.club_id),
        customer_since: this.customerSince(plan.club_id),
      }));
    },
    filteredPlans() {
      return this.plansFilteredByArchivedStatus.filter(plan => this.matchesPlanFilter(plan) &&
        (this.matchesClubName(plan, this.searchQuery) || this.matchesPlanID(plan, this.searchQuery)));
    },
    plansFilteredByArchivedStatus() {
      if (this.$store.getters.activeFiltersFor('onboardingPlans').show_archived_players) {
        return this.plansWithClubFields.filter(plan => plan.archived_at);
      } else {
        return this.plansWithClubFields.filter(plan => !plan.archived_at);
      }
    },
    prospectPlans() {
      return this.filteredPlans.filter(plan => plan.club_type === 'prospect');
    },
    openPlans() {
      return this.filteredPlans.filter(plan => plan.status !== 'completed' && plan.club_type !== 'prospect');
    },
    closedPlans() {
      return this.filteredPlans.filter(plan => plan.status === 'completed' && plan.club_type !== 'prospect');
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('onboardingPlans'));
    },
  },
  methods: {
    updateOnboardingHealth(club) {
      this.$refs.onboardingHealth.showModal(club);
    },
    plansByID(id) {
      return this.plans.find(plan => plan.id === id);
    },
    viewPlan(plan) {
      this.$router.push({ name: 'PlanViewDetails', params: { plan: plan.id } });
    },
    editPlan(plan) {
      this.$refs.editPlan.show(plan);
    },
    archivePlan(plan) {
      this.$confirm.confirm({
        confirmText: 'Yes, Archive Plan',
        message: `You are about to archive the onboarding plan for <strong>${this.clubName(plan.club_id)}</strong>.
                  <br><br>
                  Are you sure you want to continue?`,
        title: 'Archive Onboarding Plan',
        type: 'is-danger',
        pack: 'far',
        icon: 'archive',
        onConfirm: () => {
          api()
            .post(`/cst/club_onboarding_plans/${plan.id}/archive`)
            .then((res) => {
              if (res.status === 200) {
                this.$store.commit('setNotification', { toast: true, success: true, message: 'Onboarding Plan Archived' });
                this.$emit('archived', { refreshData: true });
              }
            })
            .catch((err) => {
              this.$store.commit('setNotification', { toast: true, success: false, message: `${err}` });
            });
        },
      });
    },
    restorePlan(plan) {
      this.$confirm.confirm({
        confirmText: 'Yes, Restore Plan',
        message: `You are about to restore the onboarding plan for <strong>${this.clubName(plan.club_id)}</strong>.
                  <br><br>
                  Are you sure you want to continue?`,
        title: 'Restore Onboarding Plan',
        type: 'is-primary',
        icon: 'redo',
        onConfirm: () => {
          api()
            .post(`/cst/club_onboarding_plans/${plan.id}/restore`)
            .then((res) => {
              if (res.status === 200) {
                this.$store.commit('setNotification', { toast: true, success: true, message: 'Onboarding Plan Restored' });
                this.$emit('restored', { refreshData: true });
              }
            })
            .catch((err) => {
              this.$store.commit('setNotification', { toast: true, success: false, message: `${err}` });
            });
        },
      });
    },
    clubByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    planByID(id) {
      return this.plans.find(plan => plan.id === id);
    },
    clubName(id) {
      return this.clubByID(id)?.name || '';
    },
    clubType(id) {
      return this.clubByID(id)?.type || '';
    },
    clubSize(id) {
      return this.clubByID(id)?.size || '';
    },
    clubSalesContactUser(id) {
      return this.clubByID(id)?.config?.sales_contact_user_id || '';
    },
    clubSport(id) {
      return this.clubByID(id)?.sports[0]?.sport || '';
    },
    clubOnboardingType(id) {
      return this.clubByID(id)?.config.onboarding_type || '';
    },
    customerSince(id) {
      return formatDate(this.clubByID(id)?.created_at, 'll z') || '';
    },
    matchesClubName(plan, searchQuery) {
      const name = this.clubName(plan.club_id);
      return name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    matchesPlanID(plan, searchQuery) {
      return plan.id.toString().includes(searchQuery);
    },
    matchesPlanFilter(plan) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('onboardingPlans');
      return Object.keys(filters).filter(key => typeof filters[key] !== 'boolean').every(key => filters[key].includes(plan[key]) ||
        (key === 'cs_staff' && (filters[key].includes(plan.cs_lead_user_id) || filters[key].includes(plan.cs_secondary_user_id))));
    },
    sortByClubName(a, b, isAsc) {
      if (this.clubName(a.club_id) > this.clubName(b.club_id)) return isAsc ? 1 : -1;
      if (this.clubName(a.club_id) < this.clubName(b.club_id)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByClubSize(a, b, isAsc) {
      if (this.clubSize(a.club_id) > this.clubSize(b.club_id)) return isAsc ? 1 : -1;
      if (this.clubSize(a.club_id) < this.clubSize(b.club_id)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByClubOnboardingType(a, b, isAsc) {
      if (this.clubOnboardingType(a.club_id) > this.clubOnboardingType(b.club_id)) return isAsc ? 1 : -1;
      if (this.clubOnboardingType(a.club_id) < this.clubOnboardingType(b.club_id)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByCustomerSince(a, b, isAsc) {
      if (new Date(this.customerSince(a.club_id)) > new Date(this.customerSince(b.club_id))) return isAsc ? 1 : -1;
      if (new Date(this.customerSince(a.club_id)) < new Date(this.customerSince(b.club_id))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByOverallLead(a, b, isAsc) {
      if (a.cs_lead_user?.name > b.cs_lead_user?.name) return isAsc ? 1 : -1;
      if (a.cs_lead_user?.name < b.cs_lead_user?.name) return isAsc ? -1 : 1;
      return 0;
    },
  },
};
</script>

<style lang="sass" scoped>
.plans-table
  &__status-column
    vertical-align: top !important
  &__status-section
    white-space: nowrap
    &__badge
      margin-bottom: 0.25rem
    &__user
      font-size: 0.85rem
      font-style: italic
      margin: 0.25rem 0
      &.primary
        font-weight: 600
  &__actions
    display: flex
    justify-content: center
    align-items: center
    &__link
      color: inherit
      &--edit
        color: inherit
        margin-right: 1rem
</style>
