<template>
  <div>
    <FilterTray
      title="Filter Clubs"
      :filters-active="hasFiltersActive"
      @clear-filters="clearFilters"
    >
      <FilterSection label="Size" collapsible>
        <b-field v-for="s in sizes" :key="s.value">
          <b-checkbox v-model="filters.size" :native-value="s.value"> {{s.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Overall Onboarding Status" collapsible>
        <b-field v-for="s in onboardingStatus" :key="s.value">
          <b-checkbox v-model="filters.onboarding_status" :native-value="s.value"> {{s.name}} </b-checkbox>
        </b-field>
      </FilterSection>

      <FilterSection label="Customer Since" collapsible>
        <p class="has-text-danger" v-if="!checkInvalid">Invalid Date</p>
        <b-field :type="{'is-danger' : !checkInvalid}">
          <DatePickerString
            append-to-body
            :minDate="minDate"
            :maxDate="maxDate"
            :limitDateRange="true"
            v-model="customerSince"
            icon-right="calendar"
            placeholder="MM/DD/YYYY"
            @input="emitDate()"
            editable/>
          </b-field>
          <a class="clear-date" @click="clearDate">Clear Date</a>

      </FilterSection>
      <FilterSection label="Customer Success Staff" collapsible>
        <b-field v-for="s in csStaff" :key="s.id">
          <b-checkbox v-model="filters.cs_staff" :native-value="s.id">
            {{s.first_name}} {{s.last_name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Sales Rep" collapsible>
        <b-field v-for="sc in salesContacts" :key="sc.id">
          <b-checkbox
            v-model="filters.sales_contact_user_id"
            :native-value="sc.id">{{ `${sc.first_name} ${sc.last_name}` }}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection v-if="isSummary" label="Category and Status" collapsible>
        <b-field label="Category">
          <b-select v-model="filters.summary_categories" @input="hasCat(filters.summary_categories);">
            <option value=""></option>
            <option v-for="sc in summaryCategories" :key="sc.value"> {{sc.name}} </option>
          </b-select>
        </b-field>
        <b-field label="Status">
          <b-select v-model="filters.summary_status" @input="hasStatus(filters.summary_status);">
            <option value=""></option>
            <option v-for="ss in summaryStatus" :key="ss.value"> {{ss.name}} </option>
          </b-select>
        </b-field>
      </FilterSection>
      <FilterSection label="Onboarding Size" collapsible>
        <b-field v-for="os in onboardingSize" :key="os.value">
          <b-checkbox v-model="filters.onboarding_size" :native-value="os.value"> {{os.name}} </b-checkbox>
        </b-field>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';
import DatePickerString from '@/components/common/form-elements/DatePickerString';
import Utils from '@/models/Utils';


export default {
  components: {
    FilterSection,
    FilterTray,
    DatePickerString,
  },
  props: {
    isSummary: { type: Boolean, required: true },
  },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('usage')),
      customerSince: null,
    };
  },

  computed: {
    minDate() {
      return new Date('01-01-2020');
    },
    maxDate() {
      const date = new Date();
      return date;
    },
    checkInvalid() {
      const date = new Date();
      if (Utils.dateFromString(this.customerSince) > Utils.dateFromString('01-10-2018') && (Utils.dateFromString(this.customerSince) < date)) {
        return true;
      }
      if (this.customerSince == null) {
        return true;
      } else {
        return false;
      }
    },
    sizes() {
      return this.$store.getters.clubSizes;
    },
    onboardingStatus() {
      return this.$store.getters.onboardingStatus;
    },
    summaryCategories() {
      return this.$store.getters.summaryCategory;
    },
    summaryStatus() {
      return this.$store.getters.summaryStatus;
    },
    onboardingSize() {
      return this.$store.getters.onboardingSize;
    },
    salesContacts() {
      // can change to pass type param once thats in
      return this.$store.getters.users.filter(u => u.admin_permissions.includes('cst_tool.dept_sales'))
        .filter(staff => staff.first_name || staff.last_name);
    },
    csStaff() {
      return this.$store.getters.usersByDepartments(['product', 'success'])
        .filter(staff => staff.first_name || staff.last_name);
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('usage')) || this.customerSince !== null;
    },
    hasDateActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('usage')) || this.customerSince !== null;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    hasCat(cat) {
      this.$emit('category', cat);
    },
    hasStatus(status) {
      this.$emit('status', status);
    },
    emitDate() {
      if (this.customerSince) this.$emit('date', new Date(this.customerSince));
      else this.$emit('date', null);
    },
    clearFilters() {
      this.customerSince = null;
      this.emitDate();
      this.hasCat(null);
      this.hasStatus(null);
      this.$store.dispatch('clearFiltersFor', { name: 'usage' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('usage'));
      });
    },
    clearDate() {
      this.customerSince = null;
      this.$emit('date', null);
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'usage', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>

<style lang="sass" scoped>
.clear-date
  color: $black-ter
  font-size: $size-6
  font-weight: $weight-normal
  text-align: left
  text-decoration: underline
</style>
