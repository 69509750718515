<template>
  <div>
    <FilterTray
      title="Financial Filter"
      :filters-active="filtersActive"
      @clear-filters="clearFilters"
    >
    <FilterSection v-if="paymentViewable || refundsViewable || chargeBackViewable"
      label="Risk Rating"
      class="risk_rating"
      collapsible
      default-closed>
        <b-field v-for="h in riskRating" :key="h.value">
          <b-checkbox v-model="filters.risk_rating.allow" :native-value="h.value">
            {{h.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection v-if="paymentViewable || refundsViewable || chargeBackViewable"
        label="Expected APV"
        class="expected-apv"
        collapsible
        default-closed>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.expected_apv.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <InputMoney
          v-model.number="filters.expected_apv.allow.number"
          icon-pack="fas"
          icon="dollar-sign"
          size="is-small"
        />
      </b-field>
      </FilterSection>
      <FilterSection v-if="paymentViewable"
        label="Expected Prepayment Coefficient"
        class="expected-apv"
        collapsible>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.expected_prepay.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="count"
          v-model.number="filters.expected_prepay.allow.number"
          type="number"/>
      </b-field>
      </FilterSection>
      <FilterSection v-if="paymentViewable"
        label="Actual Prepayment Coefficient"
        class="expected-apv"
        collapsible>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.actual_prepay.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="count"
          v-model.number="filters.actual_prepay.allow.number"
          type="number"/>
      </b-field>
      </FilterSection>
      <FilterSection v-if="paymentViewable"
        label="Annual PV%"
        class="expected-apv"
        collapsible>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.annual_pv.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="count"
          v-model.number="filters.annual_pv.allow.number"
          type="number"/>
      </b-field>
      </FilterSection>
      <FilterSection v-if="refundsViewable"
        label="Annual RV%"
        class="expected-apv"
        collapsible>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.annual_rv.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="count"
          v-model.number="filters.annual_rv.allow.number"
          type="number"/>
      </b-field>
      </FilterSection>
      <FilterSection v-if="chargeBackViewable"
        label="Annual CBV%"
        class="expected-apv"
        collapsible>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.annual_cbv.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
        class="count"
          v-model.number="filters.annual_cbv.allow.number"
          type="number"/>
      </b-field>
      </FilterSection>
      <FilterSection v-if="paymentViewable"
        label="Advanced"
        class="advanced"
        collapsible
        default-closed>
        <p class="label">Show clubs with missing:</p>
        <b-field v-for="h in advanced" :key="h.value">
          <b-checkbox v-model="filters.advanced.allow" :native-value="h.value">
            {{h.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection v-if="refundsViewable || chargeBackViewable"
        label="Advanced"
        class="advancedR"
        collapsible
        default-closed>
        <p class="label">Show clubs with missing:</p>
        <b-field v-for="h in advancedR" :key="h.value">
          <b-checkbox v-model="filters.advancedR.allow" :native-value="h.value">
            {{h.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';
import InputMoney from '@/components/common/form-elements/InputMoney';

export default {
  components: {
    FilterSection,
    FilterTray,
    InputMoney,
  },
  props: {
    filtersActive: { type: Boolean, required: true },
    activeTab: { type: String, required: true },
  },
  data() {
    return {
      selected: [],
      filters: _.cloneDeep(this.$store.getters.filtersFor('financialTracking')),
      customerSince: null,
    };
  },
  computed: {
    riskRating() {
      return this.$store.getters.riskRating;
    },
    advanced() {
      return this.$store.getters.advanced;
    },
    advancedR() {
      return this.$store.getters.advancedR;
    },
    paymentViewable() {
      return this.activeTab === 'Payments';
    },
    refundsViewable() {
      return this.activeTab === 'Refunds';
    },
    chargeBackViewable() {
      return this.activeTab === 'Chargebacks';
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    tagSelected(tag) {
      if (this.selected.includes(tag)) {
        this.selected = _.without(this.selected, tag);
      } else {
        this.selected = _.concat(this.selected, tag);
      }
      this.$emit('input', tag);
    },
    setRiskRating(status) {
      this.$emit('risk_rating', status);
    },
    clearFilters() {
      this.$emit('input', 'clear');
      this.setRiskRating(null);
      this.$store.dispatch('clearFiltersFor', { name: 'financialTracking' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('financialTracking'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'financialTracking', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>

<style lang="sass" scoped>
.expected-apv
  .comparison
    margin-right: 0.5rem !important
  .count
    width: 100px
    text-align: right
  .tag:hover
    text-decoration: none
.clear-date
  margin-top: 0.6rem
  display: inline-block
  color: $black-ter
  font-size: $size-6
  font-weight: $weight-normal
  text-align: left
  text-decoration: underline
.percent-complete
  .comparison
    margin-right: 0.5rem !important
  .count
    width: 100px
    text-align: right
  .tag:hover
    text-decoration: none
</style>
