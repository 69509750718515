<template>
  <div>
    <div v-if="loading">
      <Spinner/>
    </div>
    <div v-else>
      <TeamAccountsFilter title="CARDS" :filtersActive="hasActiveFilters" showRemaining/>
      <TablePaginated
        name="Team Accounts Onboarding"
        :data="tableRows"
        searchable
        :filters-active="hasActiveFilters"
        @queryUpdated="searchQuery = $event"
        :filterable="true"
        :default-sort="['name']">
        <template slot-scope="props">
          <b-table-column label="Club Name"
            field="name"
            header-class="is-unselectable"
            sortable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Club Size"
            field="size"
            header-class="is-unselectable"
            sortable>
            {{ props.row.size | capitalize }}
          </b-table-column>
          <b-table-column label="Sport"
            field="sport"
            header-class="is-unselectable"
            sortable>
            {{ props.row.sport | capitalize}}
          </b-table-column>
          <b-table-column label="Contract Date"
            field="contract_date"
            header-class="is-unselectable"
            sortable>
            {{ props.row.contract_date | formatDate }}
          </b-table-column>
          <b-table-column label="Contracted"
            field="contracted"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.contracted }}
          </b-table-column>
          <b-table-column label="Pending"
            field="pending"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.pending }}
          </b-table-column>
          <b-table-column label="Ordered"
            field="ordered"
            header-class="is-unselectable"
            numeric
            sortable>
              {{ props.row.ordered }}
          </b-table-column>
          <b-table-column label="Activated"
            field="activated"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.activated }}
          </b-table-column>
          <b-table-column label="Canceled"
            field="canceled"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.canceled }}
          </b-table-column>
          <b-table-column label="Remaining"
            field="remaining"
            header-class="is-unselectable"
            numeric
            sortable>
            <span :class="getCellClass(props.row.remaining) ? 'has-text-danger' : ''">
              {{ props.row.remaining }}
            </span>
          </b-table-column>
        </template>
        <template slot="empty">
          No Team Accounts
        </template>
      </TablePaginated>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import TeamAccountsFilter from '@/components/team_accounts/TeamAccountsFilter';
import TablePaginated from '@/components/layout/TablePaginated';
import Spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';

export default {
  components: {
    TeamAccountsFilter,
    TablePaginated,
    Spinner,
  },
  props: {
    loading: { type: Boolean, required: true },
    clubsById: { type: Object, required: true },
    merchantAccountsByClubId: { type: Object, required: true },
  },
  data() {
    return {
      searchQuery: '',
      tableData: [],
    };
  },
  computed: {
    hasActiveFilters() {
      return !_.isEmpty(this.filtersActive);
    },
    filtersActive() {
      return this.$store.getters.activeFiltersForContext('teamAccounts');
    },
    tableRows() {
      return this.tableData.flatMap((report) => {
        const club = this.clubsById[report.club_id];
        if (!club || club.is_test_club) return {};
        return {
          id: club.id,
          name: club.name,
          size: club.size || '-',
          sport: club.sports?.length ? club.sports[0].sport : '-',
          contract_date: this.merchantAccountsByClubId[club.id]?.length ?
            this.merchantAccountsByClubId[club.id][0].contract_date : '',
          contracted: this.merchantAccountsByClubId[club.id]?.length ?
            this.merchantAccountsByClubId[club.id][0].num_cards_limit : 0,
          pending: report.invited || 0,
          ordered: report.ordered || 0,
          activated: report.active || 0,
          canceled: report.canceled || 0,
          remaining: this.remainingFinder(club, report) || 0,
        };
      },
      ).filter(club => this.matchesName(club, this.searchQuery) && this.matchesFilter(club));
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      return api().get('cst/team_accounts/summaries/cardholders').then((res) => {
        if (res.status === 200) {
          this.tableData = res.data;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    matchesFilter(club) {
      if (!this.hasActiveFilters) return true;
      return Object.keys(this.filtersActive).every(key => this.filtersActive[key].matches(club));
    },
    remainingFinder(club, report) {
      const contracted = this.merchantAccountsByClubId[club.id]?.length ?
        this.merchantAccountsByClubId[club.id][0].num_cards_limit : 0;
      const { invited, ordered, activated, canceled } = report;
      let ret = contracted;
      if (invited) {
        ret -= invited;
      }
      if (ordered) {
        ret -= ordered;
      }
      if (activated) {
        ret -= activated;
      }
      if (canceled) {
        ret -= canceled;
      }
      return ret;
    },
    matchesName(tableData, searchQuery) {
      return tableData?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase() ?? '');
    },
    getCellClass(value) {
      if (value < 0) {
        return 'is-danger';
      } else {
        return '';
      }
    },
  },
};
</script>
