export const SYSTEM_CROSSBAR = 'Crossbar';
export const SYSTEM_PLAYMETRICS = 'PlayMetrics';

export const KPI = {
  ID_ACTIVE_TEAMS: 10000,
  ID_ACTIVE_TEAM_PLAYERS: 10001,
  ID_ACTIVE_REC_PLAYERS: 10002,
  ID_ACTIVE_COMPETITIVE_PLAYERS: 10003,
  D_ACTIVE_LEAGUES: 10004,
  ID_OPEN_PROGRAMS: 10005,
  ID_NEW_TRANSACTIONS: 10006,
  ID_NEW_REVENUE: 10007,
  ID_NEW_EVENTS: 10008,
  ID_NEW_MESSAGES: 10009,
  ID_NEW_CHAT_MESSAGES: 10010,
  ID_TOTAL_ACCOUNTS: 10011,
  ID_OPEN_FORM_REQUESTS: 10012,
  ID_NEW_DIGITAL_DOCS: 10013,
  ID_UPCOMING_EVENTS: 10014,
  MONTHLY_PAYMENT_COUNT: 10015,
  MONTHLY_PAYMENT_AMOUNT: 10016,
  MONTHLY_REFUND_COUNT: 10017,
  MONTHLY_REFUND_AMOUNT: 10018,
  MONTHLY_DISPUTE_COUNT: 10019,
  MONTHLY_DISPUTE_AMOUNT: 10020,
};


