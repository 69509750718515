<template>
  <div>
    <b-tabs
      v-model="activeTab"
      type="is-toggle"
      @input="activeTab !== 2 ? currentView = 'Onboarding Summary' : currentView = 'Total Time Spent'"
      class="b-tabs-no-content">
      <b-tab-item label="Clubs" />
      <b-tab-item label="Staff" />
    </b-tabs>
      <div class="selector-container">
        <ViewSelector
          @input="setTableData"
          v-model="currentView"
          :options="getViews"
        />
      </div>
    <OnboardingSummaryClubView v-if="currentView === 'Onboarding Summary' && activeTab === 0" :clubInternals="clubInternals"/>
    <OnboardingScheduleClubView v-if="currentView === 'Onboarding Schedule'" />
    <MeetingTimeClubView v-if="currentView === 'Meeting Time'" />
    <StaffClubWorkload v-if="currentView === 'Club Workload'" />
    <StaffOnboardingSummaryView v-if="currentView === 'Onboarding Summary' && activeTab === 1" />
    <StaffOnboardingReports v-if="currentView === 'Staffing Plan'" />
    <StaffSummaryView v-if="currentView === 'Staff Summary'"/>
  </div>
</template>

<script>
import ViewSelector from '@/components/common/ViewSelector';
import OnboardingSummaryClubView from '@/components/onboarding/reports/OnboardingSummaryClubView';
import OnboardingScheduleClubView from '@/components/onboarding/reports/OnboardingScheduleClubView';
import MeetingTimeClubView from './MeetingTimeClubView';
import StaffClubWorkload from './StaffClubWorkload';
import StaffOnboardingSummaryView from './StaffOnboardingSummaryView';
import StaffOnboardingReports from './StaffOnboardingReports';
import StaffSummaryView from './StaffSummaryView';
// import Spinner from '@/components/common/Spinner';

export default {
  components: { ViewSelector,
    OnboardingSummaryClubView,
    OnboardingScheduleClubView,
    StaffClubWorkload,
    MeetingTimeClubView,
    StaffOnboardingReports,
    StaffOnboardingSummaryView,
    StaffSummaryView,
  },
  props: {
    clubInternals: { type: Array, required: true },
  },
  data() {
    return {
      activeTab: this.$store.getters.tableDataFor('onboarding_plans').activeTab || 0,
      currentView: this.$store.getters.tableDataFor('onboarding_plans').currentView ||
        (this.activeTab === 2 ? 'Total Time Spent' : 'Onboarding Summary'),
      staffOptions: ['Onboarding Summary', 'Club Workload', 'Staffing Plan', 'Staff Summary'],
      clubsOptions: ['Onboarding Summary', 'Onboarding Schedule', 'Meeting Time'],
    };
  },
  computed: {
    getViews() {
      if (this.activeTab === 1) return this.staffOptions;
      if (this.activeTab === 2) return this.timeOptions;
      else return this.clubsOptions;
    },
  },
  watch: {
    activeTab() {
      this.$store.commit('setTableDataFor', { name: 'onboarding_plans', activeTab: this.activeTab, currentView: null });
    },
  },
  methods: {
    setTableData(val) {
      this.$store.commit('setTableDataFor', { name: 'onboarding_plans', activeTab: this.activeTab, currentView: val });
    },
  },
};
</script>
<style lang="sass" scoped>
.selector-container
  display: flex
  align-items: flex-end
  justify-content: space-between
</style>
