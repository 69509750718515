<template>
 <spinner v-if="loading"/>
  <div v-else>
    <span>View stats for tickets created:</span>
    <TablePaginated
      name="Tickets"
      hoverable
      :row-class="() => 'clickable'"
      :data="searchedTickets"
      :filters-active="hasFiltersActive"
      class="table-top"
      searchable
      @select="viewClubTickets($event)"
      @queryUpdated="searchQuery = $event">
        <template slot="header-left">
          <div class="level" style="margin-bottom: 1.5rem; flex: 1">
            <div class="level-left">
              <DateRangeDropdown
               v-model="dateRange"
               :maxRange="365"
               :useMaxDate="false"
                 :presets="[
                  { name: 'Last 30 Days', display: 'Last 30 Days', range: last30Days },
                  { name: 'Last 3 Months', display: 'Last 3 Months', range: last3Months},
                  { name: 'This Year', display: 'This Year', range: thisYear },
                  { name: 'Last Year', display: 'Last Year', range: lastYear },
                  ]"/>
            </div>
          </div>
        </template>

        <template v-if="showAlert" slot="table-header">
          <div class="discount-table-header">
            <div  class ="counter" v-if="searchedTickets.length"> {{ searchedTickets.length }} Clubs Found</div>
          </div>
        </template>
       <template slot-scope="props">
        <b-table-column label="Club Name"
          field="name"
          header-class="is-unselectable"
          sortable>
          {{ props.row.name | capitalize }}
        </b-table-column>
        <b-table-column label="Club Size"
          field="size"
          header-class="is-unselectable"
          sortable>
          {{ props.row.size | capitalize}}
        </b-table-column>
        <b-table-column label="Sport"
          field="sport"
          header-class="is-unselectable"
          sortable>
          {{ props.row.sport | capitalize}}
        </b-table-column>
        <b-table-column  width="60"
          numeric
          label="New Tickets"
          header-class="is-unselectable"
          field="total_new_tickets"
          sortable>
          {{ props.row.total_new_tickets.toLocaleString()}}
        </b-table-column>
        <b-table-column  width="60"
          numeric
          label="Open Tickets"
          header-class="is-unselectable"
          field="total_open_tickets"
          sortable>
          {{ props.row.total_open_tickets.toLocaleString() }}
        </b-table-column>
        <b-table-column  width="60"
          numeric
          label="Solved Tickets"
          header-class="is-unselectable"
          field="total_solved_tickets"
          sortable>
          {{ props.row.total_solved_tickets.toLocaleString() }}
        </b-table-column>
        <b-table-column  width="60"
          label="Escalated Tickets"
          header-class="is-unselectable"
          numeric
          field="total_escalated_tickets"
          sortable>
          {{ props.row.total_escalated_tickets.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Time Spent"
        header-class="is-unselectable"
        numeric
        field="total_time_spent"
        sortable >
          {{ props.row.total_time_spent |  formatDuration }}
        </b-table-column>
        <b-table-column  width="60"
          numeric
          label="New Ticket Ratio"
          header-class="is-unselectable"
          field="ticket_ratio"
          sortable
          :custom-sort="sortByTicketRatio">
          {{ props.row.ticket_ratio.toLocaleString()  }}
        </b-table-column>
       </template>
      <template slot="empty">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
      </template>
    </TablePaginated>
    <TicketsFilter/>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';
import TicketsFilter from './TicketsFilter';

const defaultDateRange = {
  startDate: moment().subtract(30, 'days').toDate(),
  endDate: moment().toDate(),
};
export default {
  components: { Spinner,
    TablePaginated,
    DateRangeDropdown,
    TicketsFilter,
  },
  data() {
    return {
      counter: 0,
      loading: false,
      searchQuery: '',
      showAlert: true,
      activeTab: 0,
      tickets: [],
      usage: {},
      dateRange: _.cloneDeep(defaultDateRange),
    };
  },

  created() {
    this.fetchTickets();
  },

  watch: {
    dateRange() {
      this.fetchTickets();
      this.$emit('datesChanged', this.ticketDate);
    },
  },
  computed: {
    maxDate() {
      return new Date();
    },
    clubsWithTickets() {
      return this.$store.getters.clubs.map(club => ({ ...club,
        club_tickets: this.tickets.filter(tickets => this.idFilter(club.id, tickets.club_id)),
      })).filter(club => !club.is_deleted && !club.is_disabled && !club.is_test_club)
      ;
    },
    clubMap() {
      return this.clubsWithTickets.map(club => ({
        id: club.id,
        name: club.name,
        size: club.size,
        sport: club.sports.length ? this.clubSports(club.sports) : '-',
        total_new_tickets: club.club_tickets.length,
        ticket_ratio: this.ticketRatio(club),
        ...this.ticketCounts(club.club_tickets),
      })).filter(club => this.hasAggregateData(club));
    },
    searchedTickets() {
      return this.clubMap.filter(clubMap => (this.matchesName(clubMap, this.searchQuery)
      || this.matchesSports(clubMap, this.searchQuery))
      && this.matchesTicketFilter(clubMap)).sort((ticket1, ticket2) =>
        ((ticket1.total_new_tickets > ticket2.total_new_tickets) ? -1 : 1));
    },
    ticketDate() {
      return {
        start_date: moment(this.dateRange.startDate).format('YYYY-MM-DD'),
        end_date: moment(this.dateRange.endDate).format('YYYY-MM-DD'),
      };
    },
    // More actions button -------------------------------------------->
    ctas() {
      const ret = [];
      ret.push({ classes: 'is-outlined', text: 'Export Tickets', icon: 'sign-in', icon_transform: 'rotate-90' });
      return ret;
    },
    // Time stuff ----------------------------------------------------->
    last30Days() {
      return {
        startDate: moment().subtract(30, 'days').toDate(),
        endDate: moment().toDate(),
      };
    },
    last3Months() {
      return {
        startDate: moment().subtract(3, 'months').toDate(),
        endDate: moment().toDate(),
      };
    },
    thisYear() {
      return {
        startDate: moment().dayOfYear(1).toDate(),
        endDate: moment().toDate(),
      };
    },
    lastYear() {
      return {
        startDate: moment().subtract(1, 'years').toDate(),
        endDate: moment().toDate(),
      };
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('ticket'));
    },
    timeSpentMap() {
      return {
        under_5_minutes: 5,
        under_15_minutes: 15,
        under_30_minutes: 30,
        under_1_hour: 60,
        under_2_hours: 120,
        under_4_hours: 240,
        over_4_hours: 240,
      };
    },
  },
  methods: {
    sortByTicketRatio(a, b, isAsc) {
      if (a.ticket_ratio === '-') return isAsc ? 1 : -1;
      if (b.ticket_ratio === '-') return isAsc ? -1 : 1;
      if (a.ticket_ratio > b.ticket_ratio) return isAsc ? 1 : -1;
      if (a.ticket_ratio < b.ticket_ratio) return isAsc ? -1 : 1;
      return 0;
    },
    ticketRatio(club) {
      let ret = '';
      let ratio = 0;
      if (!(club.contract?.competitive_player_cap) && !(club.contract?.rec_player_cap)) {
        ret = '-';
      } else {
        const compPlayers = club.contract.competitive_player_cap ? club.contract.competitive_player_cap : 0;
        const recPlayers = club.contract.rec_player_cap ? club.contract.rec_player_cap : 0;
        ratio = (club.club_tickets.length / (compPlayers + recPlayers));
        ret = Math.round((ratio) * 10000) / 10000;
      }
      return ret;
    },
    fetchTickets() {
      this.loading = true;
      api().post('/cst/zendesk_tickets/search', this.ticketDate).then((res) => {
        this.tickets = res.data;
        this.loading = false;
      });
    },
    hasAggregateData(club) {
      return (club.total_new_tickets > 0 ||
        club.total_open_tickets > 0 ||
        club.total_solved_tickets > 0 ||
        club.total_escalated_tickets > 0 ||
        club.total_time_spent > 0);
    },
    ticketCounts(tickets) {
      const counts = {
        total_open_tickets: 0,
        total_solved_tickets: 0,
        total_escalated_tickets: 0,
        total_time_spent: 0,
      };
      tickets.forEach((ticket) => {
        counts.total_open_tickets += (ticket.status === 'open' || ticket.status === 'pending');
        counts.total_solved_tickets += (ticket.status === 'closed' || ticket.status === 'solved');
        counts.total_escalated_tickets += ticket.escalated;
        counts.total_time_spent += this.timeSpentMap[ticket.level_of_effort] || 0;
      });
      return counts;
    },
    idFilter(clubId, ticketId) {
      return (clubId === ticketId);
    },
    filterDate(date, start, end) {
      return (date >= start && date <= end);
    },
    matchesName(ticket, searchQuery) {
      return ticket.name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    matchesSports(ticket, searchQuery) {
      return ticket.sport.toLowerCase().includes(searchQuery.toLowerCase());
    },
    clubSports(sports) {
      // juts show 'main' sport
      if (sports) {
        return sports[0].sport;
      }
      return '-';
    },
    matchesTicketFilter(ticket) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('ticket');
      return Object.keys(filters).every(key => filters[key].includes(ticket[key]));
    },
    clubWithTicketsAndCounts(club) {
      const clubWithTickets = this.clubsWithTickets.find(cl => cl.id === club.id);
      const counts = this.ticketCounts(clubWithTickets.club_tickets);
      return {
        ...clubWithTickets,
        counts: {
          ...counts,
        },
      };
    },
    viewClubTickets(club) {
      this.$router.push({ name: 'ClubViewTickets', params: { club: club.id, fromTab: 'tickets' } });
    },
  },
};

</script>

<style lang="sass" scoped>
@import "~bulma/sass/utilities/mixins"
.table-top
  margin-top: 1rem
  ::v-deep th
    vertical-align: bottom
.counter
  padding-top: 1rem
  font-weight: bold
.is-vertical-center
  display: inline-flex
  align-items: center
.discount-table-header
  display: block
  width: 100%
  margin-top: -.75rem
  .fade-enter-active
    transition: opacity .125s
  .fade-leave-active
    transition: opacity .125s
  .fade-enter, .slide-fade-leave-to
    opacity: 0
  .discount-tool-bar
    background-color: $warning
    border-radius: 5px
    margin: 0
    padding: .75rem 1rem
    .divider
      height: 100%
      border-right-style: solid
      border-right-width: 1px
      border-color: $primary
      margin: 0 1rem
</style>
