<template>
  <div>
    <Modal
      ref="reauthenticationModal"
      title="Authentication Required"
      max-width="500px"
      use-mobile-modal
      is-all-white
      :has-cancel-button="false"
      @dismissModal="handleDismissModal"
    >
      <p>
        Your authentication is about to expire.  Please re-authenticate.
      </p>
      <p v-if="errorMessage" style="color:#f00;">
        {{ errorMessage }}
      </p>
      <template #footer>
        <b-button
          class="is-primary"
          :loading="requestInFlight"
          :disabled="requestInFlight"
          @click="handleSubmit"
        >Log In with Google</b-button>
      </template>
    </Modal>
    <Modal
      ref="expiredModal"
      title="Authentication Expired"
      max-width="400px"
      use-mobile-modal
      is-all-white
      hide-close-button
      :has-cancel-button="false"
      :dismiss-outside-click="false"
    >
      <p>
        Your authentication has expired.
      </p>
      <template #footer>
        <b-button
          class="is-primary"
          @click="handleLogOut"
        >Log Out</b-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import {
  getAuth,
  getIdTokenResult,
  signInWithPopup,
  GoogleAuthProvider,
} from 'firebase/auth';
import Modal from '@/components/layout/modals/Modal';

const SECOND_IN_MILLISECONDS = 1000;
const MINUTE_IN_MILLISECONDS = 60 * SECOND_IN_MILLISECONDS;
const HOUR_IN_MILLISECONDS = 60 * MINUTE_IN_MILLISECONDS;

const WARNING_SUPER_ADMIN_AUTH_DURATION_MILLISECONDS = 12 * 24 * HOUR_IN_MILLISECONDS; // 12 Days
const EXPIRED_SUPER_ADMIN_AUTH_DURATION_MILLISECONDS = 15 * 24 * HOUR_IN_MILLISECONDS; // 15 Days

export default {
  components: {
    Modal,
  },
  data() {
    return {
      // timeoutId: new Error("DO NOT UNCOMMENT THIS LINE, IT IS INTENTIONALLY NOT REACTIVE"),
      errorMessage: '',
      requestInFlight: false,
    };
  },
  computed: {
    userEmail() {
      return this.$store.getters.user?.email;
    },
  },
  watch: {
    userEmail: {
      handler(value) {
        this.stopTimer();
        if (value) {
          this.checkAuthTime();
        }
      },
      immediate: true,
    },
  },
  beforeDestroy() {
    this.stopTimer();
  },
  methods: {
    checkAuthTime() {
      const currentUser = getAuth(this.$firebaseApp).currentUser;
      getIdTokenResult(currentUser).then((result) => {
        const authTime = new Date(result.authTime).getTime();
        const nowTime = new Date().getTime();
        const authDuration = nowTime - authTime;
        if (authDuration > EXPIRED_SUPER_ADMIN_AUTH_DURATION_MILLISECONDS) {
          this.$refs.expiredModal.showModal();
        } else if (authDuration > WARNING_SUPER_ADMIN_AUTH_DURATION_MILLISECONDS) {
          this.$refs.reauthenticationModal.showModal();
        } else {
          this.startTimer(); // Check again later...
        }
      }).catch(() => {
        this.startTimer(); // We encountered an error... Ignore and try again later.
      });
    },
    startTimer() {
      this.stopTimer(); // Stop any existing timeouts...
      this.timeoutId = setTimeout(() => {
        this.checkAuthTime();
      }, 3 * HOUR_IN_MILLISECONDS);
    },
    stopTimer() {
      clearTimeout(this.timeoutId);
    },
    handleDismissModal() {
      this.errorMessage = '';
      this.startTimer();
    },
    handleSubmit() {
      if (this.requestInFlight) {
        return;
      }
      this.requestInFlight = true;
      const auth = getAuth(this.$firebaseApp);
      signInWithPopup(auth, new GoogleAuthProvider()).then(() => {
        this.$refs.reauthenticationModal.dismissModal();
      }).catch((error) => {
        this.errorMessage = 'Unable to authenticate. Please try again.';
        console.error(error);
      }).finally(() => {
        this.requestInFlight = false;
      });
    },
    handleLogOut() {
      this.$refs.expiredModal.dismissModal();
      this.$store.dispatch('logOut');
    },
  },
};
</script>
