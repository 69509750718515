<template>
  <div class="player-modal">
    <Modal
      ref="modal"
      title="Daily User Activity Report"
      is-all-white >
      <div class="column">
        <div class="bis-box full-height">
          <div class="has-text-weight-semibold is-font-mont"> Daily Activity for </div>
          <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
            {{ name }}
          </div>
          <div class="has-text-weight-semibold is-font-mont"> Day of </div>
          <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
            {{ date }}
          </div>
          <div class="has-text-weight-semibold is-font-mont">Total Hours</div>
          <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
            {{ toHours(timeSpent) }}
          </div>
        </div>
      </div>
      <div class="table-header"> {{`${name}'s Activities`}} </div>
      <b-table
        :data="tableRows"
        name="activities-table"
        class="day-user-table"
        >
        <template slot-scope="props">
          <b-table-column
            label="Club"
            field="club">
            {{ props.row.club | capitalize }}
          </b-table-column>
          <b-table-column
            label="Category"
            field="category">
            {{ props.row.category | capitalize }}
          </b-table-column>
          <b-table-column
            label="Milestone"
            field="milestone">
            {{ props.row.milestone | capitalize }}
          </b-table-column>
          <b-table-column
            label="Role"
            field="role">
            {{ props.row.role | capitalize }}
          </b-table-column>
          <b-table-column
            label="Hours"
            field="hours">
            {{ props.row.hours }}
          </b-table-column>
        </template>
      </b-table>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import moment from 'moment';

export default {

  components: {
    Modal,
  },
  data() {
    return {
      timeSpent: 0,
      id: 0,
      type: '',
      name: '',
      activities: [],
      date: null,
    };
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    tableRows() {
      return this.activities.map(act => ({
        club: this.clubsByID(act.club_id).name,
        category: act.category,
        milestone: act.milestone,
        role: act.lead_user_id === this.id ? 'primary' : 'secondary',
        hours: this.toHours(act.duration),
      }));
    },
  },
  methods: {
    clubsByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    toHours(m) {
      if (typeof m === 'string') return m;
      if (m) {
        const hrs = Math.round((m / 60) * 100) / 100;
        return Math.floor(hrs) === hrs ? `${hrs}.00 hrs` : `${hrs} hrs`;
      } else return '-';
    },
    showModal(id, time, name, activities, date) {
      this.id = id;
      this.timeSpent = time;
      this.name = name;
      this.activities = activities;
      this.date = moment(date).format('MMM D, YYYY');
      this.$refs.modal.showModal();
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissed', options);
      this.$refs.modal.dismissModal();
    },
  },
};
</script>

<style lang="sass" scoped>
.table-header
  font-family: $family-headers
  font-weight: $weight-semibold
  font-size: $size-5
  margin: 1rem 0
</style>
