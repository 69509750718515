<template>
  <Spinner v-if="loading"/>
  <div v-else>
    <div class="view-selector">
      <strong> View hours as: </strong>
      <b-select
        style="margin-left: 1rem"
        placeholder="Primary"
        v-model="currentView">
        <option
          v-for="view in views"
          :value="view"
          :key="view"
        >
          {{ view }}
        </option>
      </b-select>
    </div>
    <TablePaginated
      name="Staff Club Workload"
      v-if="tableRows.length"
      :searchable="false"
      :filterable="false"
      bordered
      :data="tableRows"
      :showPagination="false"
      class="onboarding-summary-table">
      <template slot-scope="props">
        <b-table-column label="Staff"
          field="user_name"
          class="user-name-column"
          header-class="is-unselectable header-background">
          <a @click="viewUser(props.row.id)"> {{ props.row.user_name | capitalize }} </a>
        </b-table-column>
        <b-table-column label="Setup Workload" field="setup_workload" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ tableformat(props.row.club_setup, props.row.num_setup) }} </div>
        </b-table-column>
        <b-table-column label="Registration Workload"
          field="registration_workload"
         header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ tableformat(props.row.club_registration, props.row.num_registration) }} </div>
        </b-table-column>
        <b-table-column label="Operations Workload" field="clubs_active" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ tableformat(props.row.club_operations, props.row.num_operations) }} </div>
        </b-table-column>
        <b-table-column label="Website Workload" field="clubs_onboarding" header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ tableformat(props.row.club_website, props.row.num_website) }} </div>
        </b-table-column>
      </template>
    </TablePaginated>
    <template v-else>
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>No Activities this Week</p>
        </div>
      </section>
    </template>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';

export default {
  components: { TablePaginated, Spinner },
  data() {
    return {
      currentView: 'Overall',
      views: ['Overall', 'Primary', 'Secondary'],
      onboardingActs: ['setup', 'registration', 'operations', 'website'],
      loading: false,
      plans: [],
    };
  },
  created() {
    this.fetchPlans();
  },
  computed: {
    users() {
      const depUsers = this.$store.getters.usersByDepartments(['product', 'success']);
      depUsers.push(...this.$store.getters.webBuilderUsers);
      return depUsers.filter(staff => staff.first_name || staff.last_name);
    },
    filteredPlans() {
      return this.plans.filter(plan => !(plan.activities === null || plan.archived_at || plan.status === 'completed'));
    },
    activities() {
      const arr = [];
      this.filteredPlans.forEach(plan => arr.push(plan.activities));
      return arr.flat();
    },
    mappedUsers() {
      return this.users.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        user_activities: this.activitiesByUser(user.id),
      })).filter(user => user.user_activities?.length).sort((user1, user2) => ((user1.name < user2.name) ? -1 : 1));
    },
    tableRows() {
      return this.mappedUsers.map(user => ({
        id: user.id,
        user_name: user.name,
        num_setup: this.countActivities(user.user_activities, 'setup'),
        club_setup: this.clubCounts(user.user_activities, 'setup'),
        num_registration: this.countActivities(user.user_activities, 'registration'),
        club_registration: this.clubCounts(user.user_activities, 'registration'),
        num_operations: this.countActivities(user.user_activities, 'operations'),
        club_operations: this.clubCounts(user.user_activities, 'operations'),
        num_website: this.countActivities(user.user_activities, 'website'),
        club_website: this.clubCounts(user.user_activities, 'website'),
      }));
    },
  },
  methods: {
    viewUser(id) {
      this.$router.push({ name: 'UserScheduleView', params: { userId: id, dateRange: 'default' } });
    },
    tableformat(club, activities) {
      let ret = '';
      if (activities) {
        if (club === 1) {
          ret = ret.concat(`${club} club `);
        } else if (club > 1) {
          ret = ret.concat(`${club} clubs `);
        }
        if (activities === 1) {
          ret = ret.concat(`(${activities} activity left)`);
        } else if (activities > 1) {
          ret = ret.concat(`(${activities} activities left)`);
        } return ret;
      } else return '-';
    },
    clubCounts(activities, cat) {
      return _.uniqBy(activities.filter(activity => activity.category === cat), activity => activity.club_id).length;
    },
    countActivities(activities, cat) {
      let count = 0;
      activities.forEach((activity) => {
        if (activity.category === cat) {
          count += 1;
        }
      },
      ); return count;
    },
    dateCheck(date) {
      return moment(date).isSameOrAfter(moment(), 'day') || !date;
    },
    activitiesByUser(id) {
      if (this.currentView === 'Primary') {
        return this.activities.filter(activity => (activity.lead_user_id === id) && this.dateCheck(activity.meeting_date));
      } else if (this.currentView === 'Secondary') {
        return this.activities.filter(activity => (activity.secondary_user_ids?.includes(id))
        && this.dateCheck(activity.meeting_date));
      } else {
        return this.activities.filter(activity => (activity.lead_user_id === id || activity.secondary_user_ids?.includes(id))
         && this.dateCheck(activity.meeting_date));
      }
    },
    fetchPlans() {
      this.loading = true;
      api().post('/cst/club_onboarding_plans/search?populate=users,activities').then((res) => {
        this.plans = res.data;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.user-name-column:hover
  background-color: $white-ter
.view-selector
  margin-top: 1rem
  display: flex
  align-items: center
  justify-content: flex-end
::v-deep .onboarding-summary-table
  font-family: $family-fira
  font-weight: $weight-normal
  .header-background
    vertical-align: bottom
    width: 175px
    font-family: $family-headers
    font-size: 1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
</style>
