<template>
  <div>
    <FilterTray
      title="Filter Schedule"
      :filters-active="isActive"
      @clear-filters="clearFilters">
      <FilterSection label="Club Size" collapsible>
        <b-field v-for="size in club_sizes" :key="size.value">
          <b-checkbox v-model="filters.club_sizes" :native-value="size.value"> {{ size.name }} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Onboarding Type" collapsible>
        <b-field v-for="t in onboarding_type" :key="t.value">
          <b-checkbox v-model="filters.onboarding_type" :native-value="t.value"> {{ t.name }} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Onboarding Category" collapsible>
        <b-field v-for="category in onboarding_category" :key="category.value">
          <b-checkbox v-model="filters.onboarding_category" :native-value="category.value"> {{ category.name }} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="View By" collapsible>
        <b-field>
          <b-select v-model="filters.view_by">
            <option v-for="view in view_by" :key="view.value" :value="view.value">{{ view.name }}</option>
          </b-select>
        </b-field>
      </FilterSection>
      <FilterSection label="Include TBD Activities" collapsible>
        <b-field>
          <b-switch v-model="filters.includeTBD" :native-value="false">Include TBD Activities</b-switch>
        </b-field>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';
import _ from 'lodash';

export default {
  components: {
    FilterTray,
    FilterSection,
  },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('userSchedule')),
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  computed: {
    club_sizes() {
      return this.$store.getters.clubSizes;
    },
    onboarding_type() {
      return this.$store.getters.onboardingType;
    },
    onboarding_category() {
      return this.$store.getters.onboardingCategory;
    },
    view_by() {
      return this.$store.getters.viewByUserSchedule;
    },
    isActive() {
      return this.isFiltersActive(this.$store.getters.activeFiltersFor('userSchedule'));
    },
  },
  methods: {
    isFiltersActive(filters) {
      return Object.keys(filters).some((key) => {
        if (key === 'view_by') {
          if (filters[key] === 'overall') {
            return false;
          }
          return true;
        }
        if (filters[key]) {
          return true;
        }
        return false;
      });
    },
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'userSchedule' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('userSchedule'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'userSchedule', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>
