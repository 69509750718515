<template>
  <div>
    <TablePaginated
      name="Summary"
      :data="hasCategoryStatusActive ? clubByCategoryStatus : searchedClubs"
      :filters-active="hasFiltersActive"
      searchable
      hoverable
      :row-class="() => 'clickable'"
      @queryUpdated="searchQuery = $event"
      @select="viewClub($event)"
      class="summary-table"
    >
      <template slot="table-header">
        <div class="block" v-if="hasCategoryStatusActive"> <strong>
          <span v-if="clubByCategoryStatus.length === 1"> {{ clubByCategoryStatus.length }} Club Found</span>
          <span v-else> {{ clubByCategoryStatus.length }} Clubs Found</span>
        </strong> </div>
        <div class="block" v-else> <strong>
          <span v-if="searchedClubs.length === 1"> {{ searchedClubs.length }} Club Found</span>
          <span v-else> {{ searchedClubs.length }} Clubs Found</span>
        </strong> </div>
      </template>
      <template slot-scope="props">
        <b-table-column label="Club Name"
          header-class="is-unselectable"
          class="align-bottom"
          field="name"
          sortable>
          {{ props.row.name ? props.row.name : '-' }}
        </b-table-column>
        <b-table-column label="Club Size"
          header-class="is-unselectable"
          field="size"
          sortable>
          {{ props.row.size ? props.row.size : '-' | capitalize }}
        </b-table-column>
        <b-table-column label="Customer Since"
          header-class="is-unselectable"
          width="10%"
          field="customer_since"
          sortable
          :custom-sort="(club1, club2, isAsc) => sortDates(club1, club2, isAsc)">
          {{ props.row.customer_since ? props.row.customer_since : '-' | formatDate('ll z') }}
        </b-table-column>
        <b-table-column centered
          label="Accounts"
          header-class="is-unselectable"
          field="accounts">
          <Stoplight class="summary__stoplight-align" :status="props.row.accounts_status"/>
        </b-table-column>
        <b-table-column centered
          label="Teams"
          header-class="is-unselectable"
          field="teams">
          <Stoplight class="summary__stoplight-align" :status="props.row.teams_status"/>
        </b-table-column>
        <b-table-column centered
          label="Players"
          header-class="is-unselectable"
          field="players">
          <Stoplight class="summary__stoplight-align" :status="props.row.players_status"/>
        </b-table-column>
        <b-table-column centered
          label="Programs"
          header-class="is-unselectable"
          field="programs">
          <Stoplight class="summary__stoplight-align" :status="props.row.programs_status"/>
        </b-table-column>
        <b-table-column centered
          label="Transactions"
          header-class="is-unselectable"
          field="transactions">
          <Stoplight class="summary__stoplight-align" :status="props.row.transactions_status"/>
        </b-table-column>
        <b-table-column centered
          label="Revenue"
          header-class="is-unselectable"
          field="revenue">
          <Stoplight class="summary__stoplight-align" :status="props.row.revenue_status"/>
        </b-table-column>
        <b-table-column centered
          label="Events"
          header-class="is-unselectable"
          field="events">
          <Stoplight class="summary__stoplight-align" :status="props.row.events_status"/>
        </b-table-column>
        <b-table-column centered
          label="Messages"
          header-class="is-unselectable"
          field="messages">
          <Stoplight class="summary__stoplight-align" :status="props.row.messages_status"/>
        </b-table-column>
        <b-table-column centered
          label="Forms"
          header-class="is-unselectable"
          field="forms">
          <Stoplight class="summary__stoplight-align" :status="props.row.forms_status"/>
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <UsageFilter :filters-active="hasFiltersActive"
      :isSummary="true"
      @date="customerSince=$event"
      @category="hasCat=$event"
      @status="hasStatus=$event"/>
  </div>
</template>

<script>
/* eslint-disable no-nested-ternary */
import _ from 'lodash';
import TablePaginated from '@/components/layout/TablePaginated';
import Stoplight from '@/components/usage/Stoplight';
// import ClubUsageModal from './ClubUsageModal';
import UsageFilter from './UsageFilter';

export default {
  props: {
    data: { type: Array, required: true },
    summary: { type: Object, required: true },
  },
  components: { TablePaginated, Stoplight, UsageFilter },
  data() {
    return {
      searchQuery: '',
      hasCat: null,
      hasStatus: null,
      customerSince: null,
    };
  },
  computed: {
    filteredData() {
      return this.data.filter(club => Object.keys(this.summary).includes(`${club.id}`)).map(club => ({ ...club,
        total_player_cap: club.rec_player_cap + club.comp_player_cap,
        revenue_last_30_days: this.summary[club.id].revenue.last_month,
        revenue_last_180_days: this.summary[club.id].revenue.last_six_months,
        transactions_last_30_days: this.summary[club.id].transactions.last_month,
        transactions_last_180_days: this.summary[club.id].transactions.last_6_months,
        events_next_month: this.summary[club.id].events,
        forms_last_month: this.summary[club.id].forms,
        messages_last_month: this.summary[club.id].messages,
        current_accounts: club.accounts?.current ? club.accounts.current.value : '-',
        current_teams: club.teams?.current ? club.teams.current.value : '-',
        current_players: club.players?.current ? club.players.current.value : '-',
        current_transactions: club.transactions?.current ? club.transactions.current.value : '-',
        current_programs: club.programs?.current ? club.programs.current.value : '-',
        previous_programs: club.programs?.previous ? club.programs.previous.value : '-',
        current_events: club.events?.current ? club.events.current.value : '-',
        current_forms: club.forms?.current ? club.forms.current.value : '-',
        current_messages: club.messages?.current ? club.messages.current.value : '-',
        current_chats: club.chats?.current ? club.chats.current.value : '-',
      }));
    },
    mappedData() {
      return this.filteredData.map(club => ({ ...club,
        accounts_percentage: this.calcPercentage(club.current_accounts, club.total_player_cap),
        teams_percentage: this.calcPercentage(club.total_player_cap / 15, club.current_teams),
        players_percentage: this.calcPercentage(club.current_players, club.total_player_cap),
        transactions_per_comp_player: this.calcPercentage(club.transactions_last_30_days, club.comp_player_cap),
        transactions_per_rec_player: this.calcPercentage(club.transactions_last_180_days, club.rec_player_cap),
        events_usage_rate: this.calcPercentage(club.events_next_month, club.total_player_cap),
        forms_usage_rate: this.calcPercentage(club.forms_last_month, club.total_player_cap),
        messages_usage_rate: this.calcPercentage(club.messages_last_month, club.total_player_cap),
      }));
    },
    tableRows() {
      return this.mappedData.map(club => ({ ...club,
        accounts_status: this.getStatus(club.accounts_percentage),
        teams_status: this.getStatus(club.teams_percentage),
        players_status: this.getStatus(club.players_percentage),
        programs_status: this.programsStatus(club),
        transactions_status: this.transactionsStatus(club),
        revenue_status: club.revenue_last_30_days || club.revenue_last_180_days ? 'success' : 'danger',
        events_status: this.getOpsStatus(club.events_usage_rate),
        forms_status: this.getOpsStatus(club.forms_usage_rate),
        messages_status: this.getOpsStatus(club.messages_usage_rate),
      }));
    },
    searchedClubs() {
      return this.customerSince ? this.tableRows.filter(club =>
        this.matchesName(club.name, this.searchQuery) && this.matchesUsageFilter(club) && this.matchesDate(club))
        : this.tableRows.filter(club => this.matchesName(club.name, this.searchQuery) && this.matchesUsageFilter(club));
    },
    clubByCategoryStatus() {
      return this.searchedClubs.filter(club => this.matchesCategoryStatus(club));
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('usage')) || this.customerSince !== null;
    },
    hasCategoryStatusActive() {
      return this.hasFiltersActive && this.hasCat && this.hasStatus;
    },
    summaryCategories() {
      return ['accounts', 'teams', 'players', 'programs', 'transactions', 'revenue', 'events', 'messages', 'forms'];
    },
    summaryStatus() {
      return ['success', 'warning', 'danger'];
    },
  },
  methods: {
    calcPercentage(num, den) {
      if (num === '-' || !num) return 0;
      return den !== 0 ? Math.floor((num / den) * 100) : '-';
    },
    getStatus(perc) {
      if (perc < 50 || perc === '-') return 'danger';
      else if (perc < 75) return 'warning';
      else return 'success';
    },
    transactionsStatus(club) {
      if (club.comp_player_cap && club.transactions_per_comp_player !== '-') {
        if (club.transactions_per_comp_player > 50) return 'success';
        else if (club.transactions_per_comp_player > 0) return 'warning';
        else return 'danger';
      } else if (club.transactions_per_rec_player && club.transactions_per_rec_player !== '-') {
        if (club.transactions_per_rec_player > 50) return 'success';
        else if (club.transactions_per_rec_player > 0) return 'warning';
        else return 'danger';
      } else return 'danger';
    },
    programsStatus(club) {
      if (club.current_programs && club.current_programs !== '-') return 'success';
      else if (club.previous_programs && club.previous_programs !== '-') return 'warning';
      else return 'danger';
    },
    // status color for operations and communications fields
    getOpsStatus(perc) {
      if (!perc || perc === '-') return 'danger';
      else if (perc < 50) return 'warning';
      else return 'success';
    },
    viewClub(club) {
      this.$router.push({ name: 'ClubViewUsage', params: { club: club.id, fromTab: 'usage' } });
    },
    clubsByID(id) {
      return this.data.filter(club => club.id === id);
    },
    matchesCategoryStatus(club) {
      let status;
      const cat = this.hasCat.toLowerCase();
      if (this.hasStatus === 'Healthy') status = 'success';
      else if (this.hasStatus === 'Concern') status = 'warning';
      else status = 'danger';
      return club[`${cat}_status`].includes(status);
    },
    matchesDate(club) {
      return new Date(club.customer_since) > this.customerSince;
    },
    matchesName(name, searchQuery) {
      return name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    sortDates(club1, club2, isAsc) {
      const date1 = new Date(club1.customer_since);
      const date2 = new Date(club2.customer_since);
      return (date1 < date2) === isAsc ? -1 : 1;
    },
    matchesUsageFilter(club) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('usage');
      if (Object.keys(filters).includes('cs_staff')) {
        return Object.keys(filters).filter(key => !key.includes('summary') && !key.includes('cs_staff')).every(key => filters[key].includes(club[key]))
          && Object.keys(filters).filter(key => key.includes('cs_staff')).every(key => filters[key].includes(club.cs_lead_user) || filters[key].includes(club.cs_secondary_user));
      } else return Object.keys(filters).filter(key => !key.includes('summary')).every(key => filters[key].includes(club[key]));
    },
  },
};
</script>

<style lang="sass" scoped>
.summary
  &__stoplight-align
    position: relative
    top: 3px
</style>
<style lang="sass">
.summary-table .table th
  vertical-align: bottom
</style>
