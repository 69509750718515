<template>
  <app-modal
    ref="modal"
    :title="options.title"
    :has-cancel-button="false"
    :has-datepicker="hasDatepicker"
    :max-width="maxWidth"
    :wide-modal="options.wideModal"
    modal-class="dialog modal-long-header"
    @closeModal="doClose">
    <template>
      <div class="media">
        <div v-if="options.icon" class="media-left" style="margin-right: 1.5rem">
          <span class="modalConfirmIcon has-text-danger is-large">
            <b-icon
              :pack="options.pack"
              :icon="options.icon"
              :type="options.iconType || options.type"
              :both="!options.icon"
              size="is-large"/>
          </span>
        </div>
        <div class="media-content">
          <slot name="top-content"/>
          <p v-if="options.message !== ''" v-html="options.message"/>
          <slot/>
        </div>
      </div>
      <slot name="bottom-content" />
    </template>
    <template slot="footer">
      <button
        v-if="options.showCancel"
        class="button is-text has-text-weight-normal cancel-button"
        @click="doCancel()">
        {{ options.cancelText }}
      </button>
      <button
        :class="options.type"
        :disabled="disabled"
        class="button has-text-weight-normal"
        @click="doConfirm()">
        {{ options.confirmText }}
      </button>
    </template>
  </app-modal>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';

const defaultOptions = {
  title: '',
  message: '',
  icon: null,
  iconType: null,
  pack: '',
  type: 'is-primary',
  confirmText: 'OK',
  showCancel: true,
  cancelText: 'Cancel',
  wideModal: false,
  onConfirm: () => {},
  onCancel: () => {},
  onClose: () => {},
};

export default {
  components: {
    'app-modal': Modal,
  },
  props: {
    disabled: { type: Boolean, default: false },
    maxWidth: { type: String, default: '' },
    hasDatepicker: { type: Boolean, default: false },
  },
  data() {
    return {
      options: defaultOptions,
    };
  },
  methods: {
    confirm(options = defaultOptions) {
      this.showModal(options);
    },
    alert(options = defaultOptions) {
      options.showCancel = false;
      this.showModal(options);
    },
    showModal(options = defaultOptions) {
      this.options = Object.assign({}, defaultOptions, options);
      this.$refs.modal.showModal();
    },
    dismissModal() {
      this.$refs.modal.dismissModal();
    },
    doConfirm() {
      this.$refs.modal.dismissModal();
      this.options.onConfirm();
    },
    doCancel() {
      this.$refs.modal.dismissModal();
      this.options.onCancel();
    },
    doClose() {
      this.options.onClose();
    },
  },
};
</script>

<style lang="sass">
.modalConfirmIcon .svg-inline--fa
  width: 1em !important
  height: 1em !important
</style>
