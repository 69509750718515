<template>
  <Spinner v-if="loading"/>
  <div v-else>
    <div class="view-selector">
      <strong> View role as: </strong>
      <b-select
        style="margin-left: 1rem"
        placeholder="Primary"
        v-model="currentView">
        <option
          v-for="view in views"
          :value="view"
          :key="view"
        >
          {{ view }}
        </option>
      </b-select>
    </div>
    <B-Table
      name="Schedule By Staff"
      :searchable="false"
      :filterable="false"
      :bordered="true"
      :data="tableRows"
      class="staff-schedule-table">
      <template slot-scope="props">
        <b-table-column label="Club"
          field="club_name"
          width="25%"
          :cell-class="isUser(props.row.club_name)"
          header-class="is-unselectable header-background">
          <a v-if="!isUser(props.row.club_name)" @click="viewActivities(props.row.club_name)">
            {{ props.row.club_name | capitalize }} </a>
          <div v-else> {{ props.row.club_name | capitalize }} </div>
        </b-table-column>
        <b-table-column label="Customer Since"
          field="customer_since"
          :cell-class="isUser(props.row.club_name)"
          header-class="is-unselectable header-background">
          {{ props.row.customer_since }}
        </b-table-column>
        <b-table-column label="Status"
          field="club_status"
          :cell-class="isUser(props.row.club_name)"
          header-class="is-unselectable header-background">
          {{ props.row.club_status | capitalize }}
        </b-table-column>
        <b-table-column label="Role"
          field="role"
          :cell-class="isUser(props.row.club_name)"
          header-class="is-unselectable header-background">
          {{ props.row.role | capitalize }}
        </b-table-column>
        <b-table-column label="Total Performed"
          field="total_performed"
          :cell-class="isUser(props.row.club_name)"
          header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.total_performed }} </div>
        </b-table-column>
        <b-table-column label="Total Scheduled"
          field="total_scheduled"
          :cell-class="isUser(props.row.club_name)"
          header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ props.row.total_scheduled }} </div>
        </b-table-column>
      </template>
    </B-Table>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/http-playmetrics';
import Spinner from '@/components/common/Spinner';
import { formatDate } from '@/filters';

export default {
  components: { Spinner },
  data() {
    return {
      currentView: 'Overall',
      views: ['Overall', 'Primary', 'Secondary'],
      loading: false,
      plans: [],
    };
  },
  created() {
    this.fetchPlans();
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    users() {
      return this.$store.getters.users;
    },
    mappedPlans() {
      return this.plans.filter(plan => !(plan.archived_at || plan.status === 'completed')).map(plan => ({ ...plan,
        club_name: this.clubsByID(plan.club_id).name,
        customer_since: this.clubsByID(plan.club_id).config.customer_since,
        performed_activities: plan.activities?.filter(act => act.meeting_date && moment().isAfter(moment(act.meeting_date)))
          .reduce((acc, curr) => acc + curr.duration, 0),
        scheduled_activities: plan.activities?.filter(act => !act.meeting_date || moment().isSameOrBefore(moment(act.meeting_date)))
          .reduce((acc, curr) => acc + curr.duration, 0),
      }));
    },
    mappedUsers() {
      return this.users.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        last_name: user.last_name,
        club_plans: this.plansByUser(user.id).sort((plan1, plan2) => ((plan1.customer_since > plan2.customer_since) ? -1 : 1)),
      })).filter(user => user.club_plans.length !== 0).sort((user1, user2) => ((user1.last_name < user2.last_name) ? -1 : 1));
    },
    tableRows() {
      let arr = [];
      if (this.mappedUsers.length) {
        for (let i = 0; i < this.mappedUsers.length; i += 1) {
          arr = [...arr, ...this.getRows(this.mappedUsers[i])];
        }
      }
      return arr;
    },
  },
  methods: {
    viewActivities(name) {
      const acts = this.plansByClub(name).activities;
      this.$router.push({ name: 'ActivitiesIndividualSummary', params: { activities: acts, backButton: true } });
    },
    isUser(name) {
      return this.mappedUsers.filter(u => name.toLowerCase().includes(u.name.toLowerCase())).length ? 'is-user' : '';
    },
    plansByUser(id) {
      if (this.currentView === 'Primary') return this.mappedPlans.filter(plan => plan.cs_lead_user_id === id);
      else if (this.currentView === 'Secondary') return this.mappedPlans.filter(plan => plan.cs_secondary_user_id === id);
      else {
        return [...this.mappedPlans.filter(plan => plan.cs_lead_user_id === id),
          ...this.mappedPlans.filter(plan => plan.cs_secondary_user_id === id)];
      }
    },
    plansByClub(name) {
      return this.mappedPlans.find(plan => plan.club_name.toLowerCase() === name.toLowerCase());
    },
    clubsByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    formatDuration(time) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      return `${hours > 1 ? `${hours} hrs` : ''}${hours === 1 ? `${hours} hr` : ''} ${minutes} min${minutes === 1 ? '' : 's'}`;
    },
    getRows(u) {
      const rows = [];
      rows.push({ club_name: `${u.name} (${u.club_plans.length} club${u.club_plans.length === 1 ? '' : 's'})`, customer_since: '', club_status: '', role: '', total_performed: '', total_scheduled: '' });
      for (let i = 0; i < u.club_plans.length; i += 1) {
        rows.push({
          club_name: u.club_plans[i].club_name ? u.club_plans[i].club_name : '-',
          customer_since: u.club_plans[i].customer_since ? formatDate(u.club_plans[i].customer_since, 'll z') : '-',
          club_status: u.club_plans[i].status ? u.club_plans[i].status : '-',
          role: u.club_plans[i].cs_lead_user_id === u.id ? 'primary' : 'secondary',
          total_performed: u.club_plans[i].performed_activities ? this.formatDuration(u.club_plans[i].performed_activities) : '0 hrs 0 mins',
          total_scheduled: u.club_plans[i].scheduled_activities ? this.formatDuration(u.club_plans[i].scheduled_activities) : '0 hrs 0 mins',
        });
      }
      return rows;
    },
    fetchPlans() {
      this.loading = true;
      api().post('/cst/club_onboarding_plans/search?populate=users,activities').then((res) => {
        this.plans = res.data;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.view-selector
  margin: 1rem 0 1rem 0
  display: flex
  align-items: center
  justify-content: flex-end
::v-deep .staff-schedule-table
  font-family: $family-fira
  font-weight: $weight-normal
  .header-background
    width: 175px
    font-family: $family-headers
    font-size: 1.1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
  .is-user
    background-color: #ebedf9
    font-family: $family-headers
    font-weight: $weight-bold
    color: $black
    border-color: #ebedf9
    padding: .75rem 0 .75rem .75rem
</style>
