<template>
  <div>
    <div class="notes">
      <div v-if="!isSales">
        <b-field
          :type="noteInvalid ? 'is-danger' : ''"
          label="Notes">
          <b-input v-model="noteText" type="textarea" />
        </b-field>
        <div class="notes__flex">
          <div>
            <span :class="noteInvalid ? 'notes__note-invalid' : ''">
              {{ noteText.length }}
            </span> / 4000
          </div>
         <b-button :disabled="!canSave"
          @click="submitNote"
          class="notes__button"
          type="is-primary">Add Note</b-button>
        </div>
      </div>
      <template v-for="note in orderedNotes">
        <Note :note="note"
          :key="note.id"
          :activities="plan.activities"
          @delete="deleteNote($event)"
          :readonly="isSales" />
      </template>
    </div>
  </div>
</template>

<script>
import Note from '@/components/common/notes/Note';
import api from '@/http-playmetrics';

export default {
  components: { Note },
  props: {
    plan: { type: Object, required: true },
  },
  data() {
    return {
      noteText: '',
    };
  },

  computed: {
    orderedNotes() {
      let notesCopy = [];
      if (this.plan.notes) {
        notesCopy = [...this.plan.notes];
      }
      if (this.activityNotes) {
        notesCopy = [...notesCopy, ...this.activityNotes];
      }
      return notesCopy?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
    activityNotes() {
      return this.plan?.activities?.filter(act => act.notes).map(act => act.notes).flat();
    },
    canSave() {
      return !this.noteInvalid && this.noteText.trim() !== '';
    },
    noteInvalid() {
      return this.noteText.length > 4000;
    },
    isSales() {
      return !this.$store.getters.canAccess(['product', 'success', 'operations']);
    },
  },
  methods: {
    submitNote() {
      api()
        .post(`/cst/club_onboarding_plans/${this.plan.id}/notes`, { note: this.noteText })
        .then((res) => {
          if (res.status === 200) {
            this.$emit('noteCreated');
          }
        });
      this.noteText = '';
    },
    deleteNote($event) {
      this.$confirm.confirm({
        confirmText: 'Yes, Delete Note',
        message: `You are about delete this onboarding note.
                  Are you sure you want to continue?`,
        title: 'Delete Onboarding Note',
        type: 'is-danger',
        pack: 'far',
        icon: 'minus-hexagon',
        onConfirm: () => {
          api()
            .delete(`/cst/club_onboarding_plans/${this.plan.id}/notes/${$event.id}`)
            .then(((res) => {
              if (res.status === 200) {
                this.$emit('noteDeleted');
              }
            }));
        },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.notes
  &__button
    display: block
    margin-left: auto
    margin-right: 0
  &__flex
    display: flex
  &__note-invalid
    color: $red
</style>
