<template>
  <div>
    <TablePaginated
      name="Setup"
      :data="searchedClubs"
      :filters-active="hasFiltersActive"
      searchable
      @queryUpdated="searchQuery = $event"
      class="setup-table"
    >
      <template slot="table-header">
        <div class="block"> <strong>
          <span v-if="searchedClubs.length === 1"> {{ searchedClubs.length }} Club Found</span>
          <span v-else> {{ searchedClubs.length }} Clubs Found</span>
        </strong> </div>
      </template>
      <template slot-scope="props">
        <b-table-column label="Club Name"
          header-class="is-unselectable"
          field="name"
          sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Club Size"
          header-class="is-unselectable"
          field="size"
          sortable>
          {{ props.row.size | capitalize }}
        </b-table-column>
        <b-table-column label="Customer Since"
          header-class="is-unselectable"
          field="customer_since"
          sortable
          :custom-sort="(club1, club2, isAsc) => sortDates(club1, club2, isAsc)">
          {{ props.row.customer_since | formatDate('ll z') }}
        </b-table-column>
        <b-table-column label="Total Accounts"
          header-class="is-unselectable"
          numeric
          field="accounts"
          sortable>
          {{ props.row.accounts.toLocaleString() }}
        </b-table-column>
        <b-table-column label="New Accounts this Week"
          header-class="is-unselectable"
          numeric
          field="accounts_delta"
          :class="deltaColor(props.row.accounts_delta)"
          :custom-sort="sortByNewAccountsDelta"
          sortable>
          {{ props.row.accounts_delta > 0 ? `+${props.row.accounts_delta.toLocaleString()}`
            : props.row.accounts_delta.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Total Teams"
          header-class="is-unselectable"
          numeric
          field="teams"
          sortable>
          {{ props.row.teams.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Teams Delta WoW"
          header-class="is-unselectable"
          numeric
          field="teams_delta"
          :class="deltaColor(props.row.teams_delta)"
          :custom-sort="sortByTeamsDelta"
          sortable>
          {{ props.row.teams_delta > 0 ? `+${props.row.teams_delta.toLocaleString()}`
            : props.row.teams_delta.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Total Players"
          header-class="is-unselectable"
          numeric
          field="players"
          sortable>
          {{ props.row.players.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Players Delta WoW"
          header-class="is-unselectable"
          numeric
          field="players_delta"
          :class="deltaColor(props.row.players_delta)"
          :custom-sort="sortByPlayersDelta"
          sortable>
          {{ deltaToString(props.row.players_delta, props.row.players) }}
        </b-table-column>
        <b-table-column label="Total Leagues"
          header-class="is-unselectable"
          numeric
          field="leagues"
          sortable>
          {{ props.row.leagues.toLocaleString() }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <UsageFilter :filters-active="hasFiltersActive" :isSummary="false" @date="customerSince=$event"/>
  </div>
</template>

<script>
import _ from 'lodash';
import TablePaginated from '@/components/layout/TablePaginated';
import UsageFilter from './UsageFilter';

export default {
  props: {
    data: { type: Array, required: true },
  },
  components: { TablePaginated, UsageFilter },
  data() {
    return {
      searchQuery: '',
      customerSince: null,
    };
  },
  computed: {
    tableRows() {
      return this.data.filter(club => club.accounts && club.teams && club.players && club.leagues).map(club => ({
        id: club.id,
        name: club.name,
        size: club.size ? club.size : '-',
        customer_since: club.customer_since,
        onboarding_status: club.onboarding_status,
        onboarding_size: club.onboarding_size,
        cs_lead_user: club.cs_lead_user ? club.cs_lead_user : 0,
        cs_secondary_user: club.cs_secondary_user ? club.cs_secondary_user : 0,
        sales_contact_user_id: club.sales_contact_user_id ? club.sales_contact_user_id : 0,
        accounts: club.accounts.current ? club.accounts.current.value : '-',
        accounts_delta: (club.accounts.current && club.accounts.previous) ? (club.accounts.current.value - club.accounts.previous.value) : '-',
        teams: club.teams.current ? club.teams.current.value : '-',
        teams_delta: (club.teams.current && club.teams.previous) ? (club.teams.current.value - club.teams.previous.value) : '-',
        players: club.players.current ? club.players.current.value : '-',
        players_delta: (club.players.current && club.players.previous) ? (club.players.current.value - club.players.previous.value) : '-',
        leagues: club.leagues.current ? club.leagues.current.value : '-',
      })).sort((club1, club2) => (club1.players > club2.players ? -1 : 1));
    },
    searchedClubs() {
      return this.customerSince ? this.tableRows.filter(club =>
        this.matchesName(club.name, this.searchQuery) && this.matchesUsageFilter(club) && this.matchesDate(club))
        : this.tableRows.filter(club => this.matchesName(club.name, this.searchQuery) && this.matchesUsageFilter(club));
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('usage')) || this.customerSince !== null;
    },
  },
  methods: {
    matchesDate(club) {
      return new Date(club.customer_since) > this.customerSince;
    },
    matchesName(name, searchQuery) {
      return name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    deltaToString(delta, base) {
      if (delta === '-') return '-';
      if (base === 0) {
        if (delta > 0) return `+${delta.toLocaleString()}`;
        else if (delta < 0) return `${delta.toLocaleString()}`;
        else return '0 (0%)';
      }
      if (delta > 0) return `+${delta.toLocaleString()} (${Math.floor((delta / base) * 100)}%)`;
      else if (delta < 0) return `${delta.toLocaleString()} (${Math.floor((delta / base) * 100)}%)`;
      else return '0 (0%)';
    },
    deltaColor(delta) {
      if (delta > 0) return 'has-text-success';
      else if (delta < 0) return 'has-text-danger';
      else return '';
    },
    sortDates(club1, club2, isAsc) {
      const date1 = new Date(club1.customer_since);
      const date2 = new Date(club2.customer_since);
      return (date1 < date2) === isAsc ? -1 : 1;
    },
    sortByNewAccountsDelta(a, b, isAsc) {
      if (a.accounts_delta === '-') return isAsc ? -1 : 1;
      if (b.accounts_delta === '-') return isAsc ? 1 : -1;
      if (a.accounts_delta > b.accounts_delta) return isAsc ? 1 : -1;
      if (a.accounts_delta < b.accounts_delta) return isAsc ? -1 : 1;
      return 0;
    },
    sortByTeamsDelta(a, b, isAsc) {
      if (a.teams_delta === '-') return isAsc ? -1 : 1;
      if (b.teams_delta === '-') return isAsc ? 1 : -1;
      if (a.teams_delta > b.teams_delta) return isAsc ? 1 : -1;
      if (a.teams_delta < b.teams_delta) return isAsc ? -1 : 1;
      return 0;
    },
    sortByPlayersDelta(a, b, isAsc) {
      if (a.players_delta === '-') return isAsc ? -1 : 1;
      if (b.players_delta === '-') return isAsc ? 1 : -1;
      if (a.players_delta > b.players_delta) return isAsc ? 1 : -1;
      if (a.players_delta < b.players_delta) return isAsc ? -1 : 1;
      return 0;
    },
    matchesUsageFilter(club) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('usage');
      if (Object.keys(filters).includes('cs_staff')) {
        return Object.keys(filters).filter(key => !key.includes('cs_staff')).every(key => filters[key].includes(club[key]))
          && Object.keys(filters).filter(key => key.includes('cs_staff')).every(key => filters[key].includes(club.cs_lead_user) || filters[key].includes(club.cs_secondary_user));
      } else return Object.keys(filters).every(key => filters[key].includes(club[key]));
    },
  },
};
</script>

<style lang="sass">
.setup-table .table th
  vertical-align: bottom
</style>
