<template slot="filter">
  <div>
    <ModalPopover
    ref="modal"
    trigger="click"
    placement="bottom-end">
      <template slot="trigger">
        <div class="columns">
          <div class="column is-four-fifths">
            <div class="view-name">
              <a class="item-line">
                <a class="line-item is-underline">Legend</a>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template slot="content">
        <div>
         <div class="wrap-class">
          <h1 class="legend-title">
            Financial Tracking Legend
          </h1>

          <div class="exit-button">
            <div style="flex-grow: 1"></div>
            <b-button class="button-position no-button-hover cancel-button is-text exit-button" @click="quit()">
              <fa-icon :icon="['far', 'times']"/>
            </b-button>

          </div>
        </div>
          <p class="header-title">
            <fa-icon :icon="['fas','circle']" size="xs"/>  RR: Risk Rating</p>
          <p class="header-title">
            <fa-icon :icon="['fas','circle']" size="xs"/>  Exp APV: Expected Annual Processing Volume</p>
          <p class="header-title">
            <fa-icon :icon="['fas','circle']" size="xs"/>  EPC: Expected Prepayment Coefficient</p>
          <p class="header-title">
            <fa-icon :icon="['fas','circle']" size="xs"/>  APC: Actual Prepayment Coefficient</p>
          <p class="header-title">
            <fa-icon :icon="['fas','circle']" size="xs"/>  Ann PV%: Annual Payment Volume Percentage</p>
          <p class="header-title">
            <fa-icon :icon="['fas','circle']" size="xs"/>  Ann RV%: Annual Refund Volume Percentage</p>
          <p class="header-title">
            <fa-icon :icon="['fas','circle']" size="xs"/>  Ann CBV%: Annual Chargeback Volume Percentage</p>
        </div>
      </template>
    </ModalPopover>
  </div>
</template>
<script>

import ModalPopover from '@/components/common/ModalPopover';


export default {
  components: {
    ModalPopover,
  },

  methods: {
    quit() {
      this.$refs.modal.hide();
    },
  },
};

</script>
