import api from '@/http-playmetrics';

// state
const state = {
  clubs: [],
};

// getters
const getters = {
  clubs(state) {
    return state.clubs;
  },
  clubCustomerHealth() {
    return [
      { name: 'No Concerns', value: '' },
      { name: 'No Concerns With Comments', value: 'no concerns with comments' },
      { name: 'Concerns', value: 'concerns' },
      { name: 'At Risk', value: 'at risk' },
    ];
  },
  clubTypes() {
    return [{
      name: 'Prospect',
      value: 'prospect',
    }, {
      name: 'Customer',
      value: 'customer',
    }];
  },
  clubSizes() {
    return [
      { name: 'Regional', value: 'regional' },
      { name: 'Large Metro', value: 'large metro' },
      { name: 'Medium Metro', value: 'medium metro' },
      { name: 'Small Local', value: 'small local' },
      { name: 'Niche', value: 'niche' },
    ];
  },
  clubSports() {
    return [
      { name: 'Soccer', value: 'soccer' },
      { name: 'Baseball', value: 'baseball' },
      { name: 'Basketball', value: 'basketball' },
      { name: 'eSports', value: 'esports' },
      { name: 'Field Hockey', value: 'fieldhockey' },
      { name: 'Hockey', value: 'hockey' },
      { name: 'Lacrosse', value: 'lacrosse' },
      { name: 'Rugby', value: 'rugby' },
      { name: 'Ski/Snowboard', value: 'ski/snowboard' },
      { name: 'Softball', value: 'softball' },
      { name: 'Volleyball', value: 'volleyball' },
      { name: 'Other', value: 'other' },
    ];
  },
  clubRiskRating() {
    return [
      { name: 'N/A', value: 'NA' },
      { name: 'S1', value: 'S1' },
      { name: 'S2', value: 'S2' },
      { name: 'S3', value: 'S3' },
      { name: 'L1', value: 'L1' },
      { name: 'L2', value: 'L2' },
      { name: 'L3', value: 'L3' },
    ];
  },
  clubOnboardingTypes() {
    return [
      { name: 'Full Service', value: 'full', text: 'A club that requires full onboarding training' },
      { name: 'Mid Service', value: 'mid', text: 'A club that requires a subset of our onboarding training' },
      { name: 'Self Service', value: 'self', text: 'A club that will not go through our onboarding training' },
    ];
  },
  clubOnboardingStatuses() {
    return [
      { name: 'Onboarding', value: 'onboarding' },
      { name: 'Active', value: 'active' },
      { name: 'Inactive', value: 'inactive' },
    ];
  },
  clubAdditionalFeatures() {
    return [
      { name: 'Registrations', value: 'enable_program_administration' },
      { name: 'Digital Documents', value: 'enable_digital_documents' },
      { name: 'Website Builder', value: 'enable_website_builder' },
    ];
  },
};

// mutations
const mutations = {
  setClubs(state, payload) {
    state.clubs = payload;
  },
  setClub(state, payload) {
    const { id } = payload;
    state.clubs[state.clubs.findIndex(c => c.id === id)] = payload;
  },
};

// actions
const actions = {
  // This will be updated to a general getter once backend is in place
  searchClubs(context, params) {
    return api().post('/cst/clubs/search?populate=contracts', params).then((res) => {
      context.commit('setClubs', res.data);
    }).catch((error) => {
      context.commit('setNotification', {
        message: error.message,
        success: false,
      });
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
