<template>
  <div>
    <FilterTray
      title="Filter Clubs"
      :filters-active="filtersActive"
      class="clubs-filter"
      @clear-filters="clearFilters">
    <FilterSection label="Club State" collapsible>
      <b-field>
        <div>
          <div class="tags">
            <a
              type="is-text"
              v-for="st in states"
              :key="st"
              :class="selected.includes(st) ? 'is-primary' : ''"
              class="tag is-medium is-white is-rounded is-outlined"
              @click="tagSelected(st)">
              {{ st }}
          </a>
          </div>
        </div>
      </b-field>
    </FilterSection>
    <FilterSection label="Club Sport" collapsible>
      <b-field v-for="s in sports" :key="s">
        <b-checkbox v-model="filters.sport.allow" :native-value="s">{{s | capitalize}}</b-checkbox>
      </b-field>
    </FilterSection>
  </FilterTray>
    </div>
  </template>
<script>
import { without, concat, cloneDeep } from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterTray,
    FilterSection,
  },
  props: {
    filtersActive: { type: Boolean, required: true },
    states: { type: Array, required: true },
    sports: { type: Array, required: true },
  },
  data() {
    return {
      selected: [],
      filters: cloneDeep(this.$store.getters.filtersFor('clubContacts')),
    };
  },
  computed: {
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    tagSelected(tag) {
      if (this.selected.includes(tag)) {
        this.selected = without(this.selected, tag);
      } else {
        this.selected = concat(this.selected, tag);
      }
      this.$emit('input', tag);
    },
    clearFilters() {
      this.selected = [];
      this.$emit('input', 'clear');
      this.$store.dispatch('clearFiltersFor', { name: 'clubContacts' }).then(() => {
        this.filters = cloneDeep(this.$store.getters.filtersFor('clubContacts'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'clubContacts', filters: cloneDeep(this.filters) });
    },
  },
};
</script>

