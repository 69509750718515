<template>
  <b-datepicker
    ref="datePicker"
    v-bind="$attrs"
    :value="asDate"
    :min-date="minDate"
    :max-date="maxDate"
    @blur="verifyDate"
    @input="toString">
    <template v-if="$slots.default !== undefined && $slots.default.length">
      <slot/>
    </template>
  </b-datepicker>
</template>
<script>
import Utils from '@/models/Utils';

export default {
  model: {
    prop: 'dtString',
  },
  props: {
    dtString: { type: String, default: null },
    minDate: { type: Date, default: null },
    maxDate: { type: Date, default: null },
    limitDateRange: { type: Boolean, default: false },
  },
  computed: {
    asDate() {
      return Utils.dateFromString(this.dtString);
    },
  },
  methods: {
    toString(dt) {
      this.$emit('input', Utils.dateToStringOrNull(dt));
    },
    toggle() {
      this.$refs.datePicker.toggle();
    },
    focus() {
      this.$refs.datePicker.focus();
    },

    verifyDate() {
      if (this.limitDateRange) {
        const date = new Date();
        if (Utils.dateFromString(this.dtString) > Utils.dateFromString('01-01-2020') && (Utils.dateFromString(this.dtString) < date)) {
          this.$emit('input', Utils.dateToStringOrNull(this.dtString));
        } else {
          this.$emit('input', '');
        }
      }
    },

  },
};
</script>
