<template>
  <div>
    <PageNavigation
      page-title="Tickets"
      :tabs="tabs"
      :active-tab="activeTab"
      :ctas="ctas"
      @tabClicked="tabClicked($event)"
      @ctaClicked="cta($event)">
    </PageNavigation>
    <div v-if="activeTab === 'Summary'">
      <TicketsSummary @datesChanged="dateRange = $event" ref="ticketSummary" />
    </div>
    <div v-if="activeTab === 'Reports'">
      <TicketsReports/>
    </div>
    <TicketExportModal :initStartDate="dateRange.start_date" :initEndDate="dateRange.end_date" ref="ticketExportModal"/>
  </div>
</template>
<script>
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TicketsSummary from '@/components/tickets/TicketsSummary';
import TicketsReports from '@/components/tickets/TicketsReports';
import TicketExportModal from '@/components/tickets/TicketExportModal';
import moment from 'moment';

export default {
  components: { TicketsSummary, TicketsReports, PageNavigation, TicketExportModal },
  data() {
    return {
      dateRange: {
        start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
      },
    };
  },
  props: {
    defaultTab: { type: String, default: 'Summary' },
  },
  computed: {
    activeTab() {
      return this.defaultTab;
    },
    tabs() {
      return [{ name: 'Summary', ctas: this.summaryCtas }, { name: 'Reports' }];
    },
    EXPORT_TICKETS() {
      return { classes: 'is-outlined', text: 'Export Tickets', icon: 'sign-in', icon_transform: 'rotate-90' };
    },
    summaryCtas() {
      const ret = [];
      ret.push(this.EXPORT_TICKETS);
      return ret;
    },
    ctas() {
      if (this.activeTab === 0) {
        return this.summaryCtas;
      }
      return [];
    },
  },
  methods: {
    cta($event) {
      if ($event === this.EXPORT_TICKETS) {
        this.$refs.ticketExportModal.showModal();
      }
    },
    tabClicked($event) {
      if ($event === this.activeTab) return;
      const map = {
        Reports: 'TicketsReports',
      };
      const route = map[$event] || 'TicketsSummary';
      this.$router.push({ name: route });
    },
  },
};
</script>
