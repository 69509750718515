<template>
  <div>
    <FilterTray
      title="Reports Filter"
      :filters-active="filtersActive"
      @clear-filters="clearFilters"
    >
    <FilterSection label="Customer Health"
      class="health_status"
      collapsible
      default-open>
        <b-field v-for="h in onboardingHeatlhOverallStatuses" :key="h.value">
          <b-checkbox v-model="filters.health_status.allow" :native-value="h.value">
            {{h.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Club State" collapsible>
        <b-field>
        <div>
          <div class="tags">
            <a
              v-for="st in states"
              :key="st"
              :class="selected.includes(st) ? 'is-primary' : ''"
              class="tag is-medium is-white is-rounded is-outlined"
              @click="tagSelected(st)">
              {{ st }}
            </a>
          </div>
        </div>
      </b-field>
      </FilterSection>
      <FilterSection label="Customer Since" collapsible>
        <DatePickerString
          append-to-body
          :min-date="minDate"
          :max-date="maxDate"
          v-model="customerSince"
          icon-right="calendar"
          placeholder="MM/DD/YYYY"
          @input="emitDate()"
          editable/>
          <a class="clear-date" @click="clearDate">Clear Date</a>
      </FilterSection>
      <FilterSection label="Status" class="status" collapsible>
        <b-field v-for="s in planStatuses" :key="s.value">
          <b-checkbox v-model="filters.onboarding_report_status.allow" :native-value="s.value">
            {{s.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="% Complete" class="percent-complete" collapsible>
      <b-field>
        <b-select
          class="comparison"
          v-model="filters.percent_complete.allow.comparison">
          <option value=""></option>
          <option value="more">More Than</option>
          <option value="equal">Equal To</option>
          <option value="less">Less Than</option>
        </b-select>
        <b-input
          class="count"
          v-model.number="filters.percent_complete.allow.number"
          type="number"/>
      </b-field>
      </FilterSection>
      <FilterSection label="CS Primary Lead" collapsible>
        <b-select
          v-model="filters.primary_cs_staff_member_id.allow"
          placeholder="Select Primary Lead User"
          expanded>
          <option
            v-for="staff in csStaff"
            :key="staff.id"
            :value="staff.id">
            {{ staff.first_name }} {{ staff.last_name }}
          </option>
        </b-select>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';
import DatePickerString from '@/components/common/form-elements/DatePickerString';

export default {
  components: {
    FilterSection,
    FilterTray,
    DatePickerString,
  },
  props: {
    filtersActive: { type: Boolean, required: true },
    states: { type: Array, required: true },
  },
  data() {
    return {
      selected: [],
      filters: _.cloneDeep(this.$store.getters.filtersFor('onboardingReports')),
      customerSince: null,
    };
  },
  computed: {
    csStaff() {
      return this.$store.getters.usersByDepartments(['product', 'success'])
        .filter(staff => staff.first_name || staff.last_name);
    },
    minDate() {
      return new Date('01-10-2018');
    },
    maxDate() {
      const date = new Date();
      return date;
    },
    onboardingHeatlhOverallStatuses() {
      return this.$store.getters.onboardingHeatlhOverallStatuses;
    },
    planStatuses() {
      return this.$store.getters.onboardingReportStatus;
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    tagSelected(tag) {
      if (this.selected.includes(tag)) {
        this.selected = _.without(this.selected, tag);
      } else {
        this.selected = _.concat(this.selected, tag);
      }
      this.$emit('input', tag);
    },
    hasStatus(status) {
      this.$emit('status', status);
    },
    setHealthStatus(status) {
      this.$emit('health_status', status);
    },
    hasDateActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('usage')) || this.customerSince !== null;
    },
    emitDate() {
      if (this.customerSince) this.$emit('date', new Date(this.customerSince));
      else this.$emit('date', null);
    },
    clearFilters() {
      this.customerSince = null;
      this.selected = [];
      this.$emit('input', 'clear');
      this.emitDate();
      this.hasStatus(null);
      this.setHealthStatus(null);
      this.$store.dispatch('clearFiltersFor', { name: 'onboardingReports' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('onboardingReports'));
      });
    },
    clearDate() {
      this.customerSince = null;
      this.$emit('date', null);
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'onboardingReports', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>

<style lang="sass" scoped>
.percent-complete
  .comparison
    margin-right: 0.5rem !important
  .count
    width: 100px
    text-align: right
  .tag:hover
    text-decoration: none
.clear-date
  margin-top: 0.6rem
  display: inline-block
  color: $black-ter
  font-size: $size-6
  font-weight: $weight-normal
  text-align: left
  text-decoration: underline
.percent-complete
  .comparison
    margin-right: 0.5rem !important
  .count
    width: 100px
    text-align: right
  .tag:hover
    text-decoration: none
</style>
