<template>
  <div>
    <DeviceDetector />
    <div
      v-if="$store.getters.isMobile"
      class="mobile-disabled-container">
      <div class="media">
        <div class="media-left">
          <fa-icon
            :icon="['far', 'exclamation-circle']"
            class="is-size-1 has-text-danger"/>
        </div>
        <div class="media-content is-size-5">
          <p>PlayMetrics Customer Success tracker is currently not optimized for mobile</p>
        </div>
      </div>
    </div>
    <div v-else id="default_layout">
      <template v-if="!loading">
        <div class="main-wrapper">
          <SideNav />
          <div class="main has-sidenav">
            <section
              class="layout__page">
              <main>
                <router-view
                  class="full-height"/>
              </main>
            </section>
          </div>
        </div>
      </template>
      <Spinner v-if="loading"/>
    </div>
  </div>
</template>

<script>
import SideNav from '@/layouts/default/SideNav';
import Spinner from '@/components/common/Spinner';
import DeviceDetector from '@/components/app/DeviceDetector';

export default {
  components: {
    DeviceDetector,
    SideNav,
    Spinner,
  },
  computed: {
    loading() {
      return !this.$store.getters.user;
    },
  },
};
</script>
