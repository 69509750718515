<template>
  <div>
    <TablePaginated
      name="Communications"
      :data="searchedClubs"
      :filters-active="hasFiltersActive"
      searchable
      @queryUpdated="searchQuery = $event"
      class="communications-table"
    >
      <template slot="table-header">
        <div class="block"> <strong>
          <span v-if="searchedClubs.length === 1"> {{ searchedClubs.length }} Club Found</span>
          <span v-else> {{ searchedClubs.length }} Clubs Found</span>
        </strong> </div>
      </template>
      <template slot-scope="props">
        <b-table-column label="Club Name"
          header-class="is-unselectable"
          class="align-bottom"
          field="name"
          sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Club Size"
          header-class="is-unselectable"
          field="size"
          sortable>
          {{ props.row.size | capitalize }}
        </b-table-column>
        <b-table-column label="Customer Since"
          header-class="is-unselectable"
          field="customer_since"
          sortable
          :custom-sort="(club1, club2, isAsc) => sortDates(club1, club2, isAsc)">
          {{ props.row.customer_since | formatDate('ll z') }}
        </b-table-column>
        <b-table-column label="Messages this Week"
          header-class="is-unselectable"
          numeric
          field="messages"
          sortable>
          {{ props.row.messages.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Messages Delta WoW"
          header-class="is-unselectable"
          numeric
          field="messages_delta"
          :class="deltaColor(props.row.messages_delta)"
          :custom-sort="sortByMessagesDelta"
          sortable>
          {{ deltaToString(props.row.messages_delta, props.row.messages) }}
        </b-table-column>
        <b-table-column label="Chats this Week"
          header-class="is-unselectable"
          numeric
          field="chats"
          sortable>
          {{ props.row.chats.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Chats Delta WoW"
          header-class="is-unselectable"
          numeric
          field="chats_delta"
          :class="deltaColor(props.row.chats_delta)"
          :custom-sort="sortByChatsDelta"
          sortable>
          {{ deltaToString(props.row.chats_delta, props.row.chats) }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <UsageFilter :filters-active="hasFiltersActive" :isSummary="false" @date="customerSince=$event"/>
  </div>
</template>

<script>
import _ from 'lodash';
import TablePaginated from '@/components/layout/TablePaginated';
import UsageFilter from './UsageFilter';

export default {
  props: {
    data: { type: Array, required: true },
  },
  components: { TablePaginated, UsageFilter },
  data() {
    return {
      searchQuery: '',
      customerSince: null,
    };
  },
  computed: {
    tableRows() {
      return this.data.filter(club => club.messages && club.chats).map(club => ({
        id: club.id,
        name: club.name,
        size: club.size ? club.size : '-',
        customer_since: club.customer_since,
        onboarding_status: club.onboarding_status,
        onboarding_size: club.onboarding_size,
        cs_lead_user: club.cs_lead_user ? club.cs_lead_user : 0,
        cs_secondary_user: club.cs_secondary_user ? club.cs_secondary_user : 0,
        sales_contact_user_id: club.sales_contact_user_id ? club.sales_contact_user_id : 0,
        messages: club.messages.current ? club.messages.current.value : '-',
        messages_delta: (club.messages.previous && club.messages.current) ? (club.messages.current.value - club.messages.previous.value) : '-',
        chats: club.chats.current ? club.chats.current.value : '-',
        chats_delta: (club.chats.current && club.chats.previous) ? (club.chats.current.value - club.chats.previous.value) : '-',
      })).sort((club1, club2) => (club1.messages > club2.messages ? -1 : 1));
    },
    searchedClubs() {
      return this.customerSince ? this.tableRows.filter(club =>
        this.matchesName(club.name, this.searchQuery) && this.matchesUsageFilter(club) && this.matchesDate(club))
        : this.tableRows.filter(club => this.matchesName(club.name, this.searchQuery) && this.matchesUsageFilter(club));
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('usage')) || this.customerSince !== null;
    },
  },
  methods: {
    matchesDate(club) {
      return new Date(club.customer_since) > this.customerSince;
    },
    matchesName(name, searchQuery) {
      return name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    deltaToString(delta, base) {
      if (delta === '-') return '-';
      if (base === 0) {
        if (delta > 0) return `+${delta.toLocaleString()}`;
        else if (delta < 0) return `${delta.toLocaleString()}`;
        else return '0 (0%)';
      }
      if (delta > 0) return `+${delta.toLocaleString()} (${Math.floor((delta / base) * 100)}%)`;
      else if (delta < 0) return `${delta.toLocaleString()} (${Math.floor((delta / base) * 100)}%)`;
      else return '0 (0%)';
    },
    deltaColor(delta) {
      if (delta > 0) return 'has-text-success';
      else if (delta < 0) return 'has-text-danger';
      else return '';
    },
    sortDates(club1, club2, isAsc) {
      const date1 = new Date(club1.customer_since);
      const date2 = new Date(club2.customer_since);
      return (date1 < date2) === isAsc ? -1 : 1;
    },
    sortByMessagesDelta(a, b, isAsc) {
      if (this.deltaToString(a.messages_delta, a.messages) === '-') return isAsc ? -1 : 1;
      if (this.deltaToString(b.messages_delta, b.messages) === '-') return isAsc ? 1 : -1;
      if (this.deltaToString(a.messages_delta, a.messages) > this.deltaToString(b.messages_delta, b.messages)) {
        return isAsc ? 1 : -1;
      }
      if (this.deltaToString(a.messages_delta, a.messages) < this.deltaToString(b.messages_delta, b.messages)) {
        return isAsc ? -1 : 1;
      }
      return 0;
    },
    sortByChatsDelta(a, b, isAsc) {
      if (this.deltaToString(a.chats_delta, a.chats) === '-') return isAsc ? -1 : 1;
      if (this.deltaToString(b.chats_delta, b.chats) === '-') return isAsc ? 1 : -1;
      if (this.deltaToString(a.chats_delta, a.chats) > this.deltaToString(b.chats_delta, b.chats)) {
        return isAsc ? 1 : -1;
      }
      if (this.deltaToString(a.chats_delta, a.chats) < this.deltaToString(b.chats_delta, b.chats)) {
        return isAsc ? -1 : 1;
      }
      return 0;
    },
    matchesUsageFilter(club) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('usage');
      if (Object.keys(filters).includes('cs_staff')) {
        return Object.keys(filters).filter(key => !key.includes('cs_staff')).every(key => filters[key].includes(club[key]))
          && Object.keys(filters).filter(key => key.includes('cs_staff')).every(key => filters[key].includes(club.cs_lead_user) || filters[key].includes(club.cs_secondary_user));
      } else return Object.keys(filters).every(key => filters[key].includes(club[key]));
    },
  },
};
</script>

<style lang="sass">
.communications-table .table th
  vertical-align: bottom
</style>
