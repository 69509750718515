import { getAuth, signInWithEmailAndPassword, signOut, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import Vue from 'vue';
import router from '@/router/index';
import api from '@/http-playmetrics';
import _ from 'lodash';

// state
const state = {
  user: null,
  loginInfo: null,
  loginRedirect: null,
  justLoggedIn: true,
  justLoggedOut: false,
  adminPermissions: null,
  isCstAdmin: false,
  hasAdminToolAccess: false,
};

// getters
const getters = {
  user(state) {
    return state.user;
  },
  loginInfo(state) {
    return state.loginInfo;
  },
  loginRedirect(state) {
    return state.loginRedirect;
  },
  justLoggedIn(state) {
    return state.justLoggedIn;
  },
  justLoggedOut(state) {
    return state.justLoggedOut;
  },
  from(state) {
    return state.from;
  },
  adminPermissions(state) {
    return state.adminPermissions;
  },
  isCstAdmin(state) {
    return state.isCstAdmin;
  },
  hasAdminToolAccess(state) {
    return state.hasAdminToolAccess;
  },
  // takes in an array of depts and returns boolean based on whether user's permissions include that dept ex ['product', 'success']
  canAccess: state => (depts) => {
    if (state.isCstAdmin) return true;
    const userDepts = state.user.admin_permissions.filter(permission => permission.includes('cst_tool.dept_'));
    const userDeptsArr = userDepts.map(department => department?.split('cst_tool.dept_')[1]);
    if (depts.some(dept => userDeptsArr.includes(dept))) return true;
    return false;
  },
};

// mutations
const mutations = {
  setUser(state, payload) {
    state.user = payload;
  },
  setLoginInfo(state, payload) {
    state.loginInfo = payload;
  },
  logUserOut(state) {
    state.user = null;
    state.loginInfo = null;
  },
  setLoginRedirect(state, payload) {
    state.loginRedirect = payload;
  },
  setJustLoggedIn(state, payload) {
    state.justLoggedIn = payload;
  },
  setJustLoggedOut(state, payload) {
    state.justLoggedOut = payload;
  },
  setAdminPermissions(state, payload) {
    state.adminPermissions = payload;
  },
  setIsCstAdmin(state, payload) {
    state.isCstAdmin = payload;
  },
  setHasAdminToolAccess(state, payload) {
    state.hasAdminToolAccess = payload;
  },
};

// actions
const actions = {
  login(context, payload) {
    context.commit('setJustLoggedIn', true);
    // Sign out in case we were signed in with unverified user.
    const auth = getAuth(Vue.prototype.$firebaseApp);
    signOut(auth).then(() => {
      signInWithEmailAndPassword(auth, payload.email, payload.password)
        .catch((error) => {
          context.commit('setNotification', {
            message: error.message,
            success: false,
            toast: false,
          });
        });
    });
  },
  logOut(context) {
    context.commit('setJustLoggedOut', true);
    const auth = getAuth(Vue.prototype.$firebaseApp);
    return signOut(auth).catch((error) => {
      context.commit('setNotification', {
        message: error.message,
        success: false,
      });
    });
  },
  gobackTo(context, payload) {
    let dest = payload.fallback;
    const currName = context.getters.from ? context.getters.from.name : null;
    if (payload.names.includes(currName)) {
      dest = context.getters.from;
    }
    dest = _.assign({}, dest, { params: _.merge({}, dest.params, payload.params) });
    router.push(dest);
  },
  async updateFirebaseUser(context, payload) {
    if (payload.user) {
      if (!payload.user.emailVerified) {
        context.commit('setNotification', {
          message: 'User not verified.',
          success: false,
          toast: false,
        });
      } else {
        // User is signed in and verified.
        await context.dispatch('performLoginWithToken', payload.user);
      }
    } else {
      // Log out
      context.commit('logUserOut');
      if (context.getters.justLoggedOut) {
        router.push('/login');
        context.commit('setJustLoggedOut', false);
      }
    }
  },
  performLoginWithToken(context) {
    const auth = getAuth(Vue.prototype.$firebaseApp);
    return new Promise((resolve) => {
      api({ showError: true, toast: false }).post('/cst/firebase/user/login', {}).then((response) => {
        const newUser = response.data;
        const permissions = newUser.admin_permissions?.filter(perm => perm.includes('cst_tool'));
        if (permissions?.length > 0) { // TODO update for CS Tracker permissions
          context.dispatch('rebuildState', { response, overrideEmailVerified: false });
        } else {
          context.commit('setNotification', {
            message: 'CS Tracker access is required',
            success: false,
            toast: false,
          });
          signOut(auth);
        }
        resolve();
      }).catch(() => {
        // Unable to authenticate so logout
        signOut(auth);
        resolve();
      });
    });
  },
  rebuildState(context, payload) {
    const user = payload.response.data;
    context.commit('setUser', user);
    if (context.getters.loginRedirect) {
      router.push(context.getters.loginRedirect);
      context.commit('setLoginRedirect', null);
    } else if (context.getters.justLoggedIn) {
      router.push('/');
    }
    if (user.admin_permissions.includes('cst_tool')) {
      context.commit('setIsCstAdmin', true);
    } else {
      context.commit('setIsCstAdmin', false);
    }
    if (user.admin_permissions.includes('admin_tool')) {
      context.commit('setHasAdminToolAccess', true);
    } else {
      context.commit('setHasAdminToolAccess', false);
    }
    context.commit('setJustLoggedIn', false);
  },
  loginGoogle(context) {
    context.dispatch('clearNotification');
    context.commit('setJustLoggedIn', true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth(Vue.prototype.$firebaseApp);
    return signInWithPopup(auth, provider).catch((error) => {
      console.log('signInWithPopup - error', error);
      context.commit('setNotification', {
        message: error.message,
        success: false,
        toast: false,
      });
    });
  },
  fetchAdminPermissions(context) {
    api().get('/cst/admin_permissions').then((response) => {
      context.commit('setAdminPermissions', response.data);
    });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
