<template>
  <div>
    <div class="notes">
      <b-field
        :type="noteInvalid ? 'is-danger' : ''"
        label="Notes">
        <b-input v-model="noteText" type="textarea" />
      </b-field>
      <div class="notes__flex">
        <div v-if="noteText.length > 3900">
          <span :class="noteInvalid ? 'notes__note-invalid' : ''">
            {{ noteText.length }}
          </span> / 4000
        </div>
        <b-button :disabled="!canSave"
          @click="addNote"
          class="notes__button"
          type="is-primary">Add Note</b-button>
      </div>
      <div v-for="note in orderedNotes" :key="note.id">
        <Note :activities="[activity]" :note="note" @delete="confirmDeleteNote($event)" />
      </div>
    </div>
  </div>
</template>

<script>
import Note from '@/components/common/notes/Note';
import api from '@/http-playmetrics';

export default {
  components: { Note },
  props: {
    activity: { type: Object, required: true },
  },
  data() {
    return {
      editing: false,
      notes: [],
      noteText: '',
    };
  },
  mounted() {
    if (this.activity) {
      this.editing = true;
      this.fetchNotes();
    } else {
      this.editing = false;
    }
  },
  computed: {
    noteInvalid() {
      return this.noteText.length > 4000;
    },

    canSave() {
      return !this.noteInvalid && this.noteText.trim() !== '';
    },

    orderedNotes() {
      if (this.notes) {
        const notesCopy = [...this.notes];
        return notesCopy.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
      return [];
    },
  },
  methods: {
    fetchNotes() {
      api().post('/cst/activities/search?populate=notes').then((res) => {
        this.notes = res.data.find(club => club.id === this.activity)?.notes || [];
      });
    },
    addNote() {
      this.notes.push({ note: this.noteText, created_at: (new Date()).toISOString(), newNote: true });
      this.noteText = '';
    },
    confirmDeleteNote($event) {
      this.$confirm.showModal({
        confirmText: 'Yes, Delete Note',
        message: 'Are you sure you want to delete this note?',
        title: 'Delete Underwriting Note',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => this.deleteNote($event),
      });
    },
    deleteNote($event) {
      if (this.editing && !$event?.newNote) {
        api().delete(`/cst/activities/${this.activity}/notes/${$event.id}/`).then(() => {
          this.fetchNotes();
        });
      } else {
        this.notes = this.notes.filter(note => note.created_at !== $event.created_at);
      }
    },
    submitNote(id) {
      const notesCopy = this.notes.filter(n => !n.id).map(n => ({
        note: n.note,
      }));
      for (let i = 0; i < notesCopy.length; i += 1) {
        api().post(`/cst/activities/${id}/notes`, notesCopy[i]).then((res) => {
          if (res.status === 200) {
            this.$emit('noteCreated');
          }
        });
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.notes
  &__button
    display: block
    margin-left: auto
    margin-right: 0
  &__flex
    display: flex
  &__note-:invalid
    color: $red
</style>
