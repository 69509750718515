<template>
  <div>
    <TablePaginated
      name="activitiesSummaryTable"
      hoverable
      :row-class="() => 'clickable'"
      :data="tableRows"
      :filters-active="hasFiltersActive"
      searchable
      @select="viewClubActivities($event)"
      @queryUpdated="searchQuery= $event">
      <template style="whitespace: nowrap" slot="header-left">
        <DateRangeDropdown
          v-model="dateRange"
          @input="dateRangeChanged"
          :presets="[
            { name: 'Last 30 Days', display: 'Last 30 Days', range: last30Days },
            { name: 'Last 3 Months', display: 'Last 3 Months', range: last3Months },
            { name: 'This Year', display: 'This Year', range: thisYear },
            { name: 'Last Year', display: 'Last Year', range: lastYear },
            { name: 'Lifetime', display: 'Lifetime', range: lifeTime },
          ]"
        />
      </template>
      <template slot-scope="props">
        <b-table-column
          label="Club Name"
          field="club_name"
          header-class="is-unselectable"
          sortable>
          {{ props.row.club_name | capitalize }}
        </b-table-column>
        <b-table-column
          label="Onboarding Type"
          field="onboarding_type"
          header-class="is-unselectable"
          sortable>
          {{ props.row.onboarding_type | capitalize }}
        </b-table-column>
        <b-table-column
          label="Total Time Spent: Setup"
          field="setup_time"
          header-class="is-unselectable"
          :custom-sort="sortBySetup"
          sortable>
          {{ formatDuration(props.row.setup_time) }}
        </b-table-column>
        <b-table-column
          label="Total Time Spent: Registrations"
          field="registrations_time"
          header-class="is-unselectable"
          :custom-sort="sortByRegistrations"
          sortable>
          {{ formatDuration(props.row.registrations_time) }}
        </b-table-column>
        <b-table-column
          label="Total Time Spent: Operations"
          field="operations_time"
          header-class="is-unselectable"
          :custom-sort="sortByOperations"
          sortable>
          {{ formatDuration(props.row.operations_time) }}
        </b-table-column>
        <b-table-column
          label="Total Time Spent: Website"
          field="website_time"
          header-class="is-unselectable"
          :custom-sort="sortByWebsite"
          sortable>
          {{ formatDuration(props.row.website_time) }}
        </b-table-column>
        <b-table-column
          label="Total Time Spent: Meetings"
          field="meetings_time"
          header-class="is-unselectable"
          :custom-sort="sortByMeetings"
          sortable>
          {{ formatDuration(props.row.meetings_time) }}
        </b-table-column>
        <b-table-column
          label="Total Time Spent: Team Accounts"
          field="team_accounts_time"
          header-class="is-unselectable"
          :custom-sort="sortByTeamAccounts"
          sortable>
          {{ formatDuration(props.row.team_accounts_time) }}
        </b-table-column>
        <b-table-column
          label="Total Time Spent"
          field="total_time"
          header-class="is-unselectable"
          :custom-sort="sortByTotal"
          sortable>
          {{ formatDuration(props.row.total_time) }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <ActivitiesFilter :isSummary="true"/>
  </div>
</template>

<script>
import TablePaginated from '@/components/layout/TablePaginated';
import moment from 'moment';
import ActivitiesFilter from '@/components/activities/ActivitiesFilter';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';
import _ from 'lodash';

export default {
  components: { TablePaginated, ActivitiesFilter, DateRangeDropdown },

  data() {
    return {
      searchQuery: '',
      dateRange: {},
    };
  },

  props: {
    activities: { type: Array, required: true },
    onboardingPlans: { type: Array, required: true },
    defaultDateRange: { type: Object, required: true },
  },
  created() {
    const dateRange = this.$store.getters.reportsDateRange;
    if (dateRange.startDate && dateRange.endDate) {
      this.dateRange = dateRange;
    } else {
      this.dateRange = _.cloneDeep(this.defaultDateRange);
    }
  },
  methods: {
    dateRangeChanged() {
      this.$emit('date', this.dateRange);
    },
    viewClubActivities(activity) {
      this.$store.commit('setReportDateRange', this.dateRange);
      this.$router.push({ name: 'ClubViewActivities', params: { club: activity.club_id, fromTab: 'activities reports' } });
    },
    isFiltersActive(filters) {
      return Object.keys(filters).some((key) => {
        if (key === 'time_spent') {
          if (filters[key].status) {
            return true;
          }
          return false;
        }
        if (key === 'club_type') {
          return !(filters[key] === 'customer');
        }
        if (filters[key]) {
          return true;
        }
        return false;
      });
    },
    sortBySetup(a, b, isAsc) {
      if (a.setup_time > b.setup_time) return isAsc ? 1 : -1;
      if (a.setup_time < b.setup_time) return isAsc ? -1 : 1;
      return 0;
    },
    sortByRegistrations(a, b, isAsc) {
      if (a.registrations_time > b.registrations_time) return isAsc ? 1 : -1;
      if (a.seturegistrations_timep_time < b.registrations_time) return isAsc ? -1 : 1;
      return 0;
    },
    sortByOperations(a, b, isAsc) {
      if (a.operations_time > b.operations_time) return isAsc ? 1 : -1;
      if (a.operations_time < b.operations_time) return isAsc ? -1 : 1;
      return 0;
    },
    sortByWebsite(a, b, isAsc) {
      if (a.website_time > b.website_time) return isAsc ? 1 : -1;
      if (a.website_time < b.website_time) return isAsc ? -1 : 1;
      return 0;
    },
    sortByMeetings(a, b, isAsc) {
      if (a.meetings_time > b.meetings_time) return isAsc ? 1 : -1;
      if (a.meetings_time < b.meetings_time) return isAsc ? -1 : 1;
      return 0;
    },
    sortByTotal(a, b, isAsc) {
      if (a.total_time > b.total_time) return isAsc ? 1 : -1;
      if (a.total_time < b.total_time) return isAsc ? -1 : 1;
      return 0;
    },
    sortByTeamAccounts(a, b, isAsc) {
      if (a.team_accounts_time > b.team_accounts_time) return isAsc ? 1 : -1;
      if (a.team_accounts_time < b.team_accounts_time) return isAsc ? -1 : 1;
      return 0;
    },
    matchesSearch(name) {
      return name && name.toLowerCase().includes(this.searchQuery.toLowerCase());
    },
    matchesFilter(club) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('activities');
      return Object.keys(filters).filter(key =>
        key !== 'club_type',
      ).every((key) => {
        if (key === 'onboarding_type' && filters[key].includes(club.onboarding_type)) {
          return true;
        } else if (key === 'time_spent') {
          if (this.durationCheck(filters[key].status, filters[key].duration, club.total_time)) {
            return true;
          }
          return false;
        } else {
          return false;
        }
      });
    },
    durationCheck(status, filterDuration, duration) {
      if (status === 'Longer Than') {
        return duration >= filterDuration;
      } else if (status === 'Shorter Than') {
        return duration <= filterDuration;
      } else if (!status) {
        return true;
      }
      return false;
    },
    formatDuration(time) {
      if (!time) return '-';
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      return `${hours > 1 ? `${hours} hrs` : ''}${hours === 1 ? `${hours} hr` : ''} ${minutes} min${minutes === 1 ? '' : 's'}`;
    },
    findOnboardingStatus(id) {
      return this.onboardingPlans.find(plan => plan.club_id === id);
    },
    matchesDate(activity) {
      return moment(activity.meeting_date).isBetween(moment(this.dateRange.startDate), moment(this.dateRange.endDate), 'day', '[]');
    },
    getDuration(id, cat) {
      if (cat === 'total') {
        return this.filteredActivities.reduce((acc, curr) => {
          if (curr.club_id === id) return acc + curr.duration;
          else return acc;
        }, 0);
      } else {
        return this.filteredActivities.reduce((acc, curr) => {
          if (curr.club_id === id && curr.category === cat) return acc + curr.duration;
          else return acc;
        }, 0);
      }
    },
  },

  computed: {
    activeFilters() {
      return this.$store.getters.activeFiltersFor('activities');
    },
    activeFiltersKeys() {
      return Object.keys(this.$store.getters.activeFiltersFor('activities'));
    },
    last30Days() {
      return {
        startDate: moment().subtract(30, 'days').toDate(),
        endDate: moment().toDate(),
      };
    },
    last3Months() {
      return {
        startDate: moment().subtract(3, 'months').toDate(),
        endDate: moment().toDate(),
      };
    },
    thisYear() {
      return {
        startDate: moment().startOf('year').toDate(),
        endDate: moment().toDate(),
      };
    },
    lastYear() {
      return {
        startDate: moment().startOf('year').subtract(1, 'year').toDate(),
        endDate: moment().startOf('year').toDate(),
      };
    },
    lifeTime() {
      return {
        startDate: moment().subtract(100, 'years').toDate(),
        endDate: moment().toDate(),
      };
    },
    filteredActivities() {
      return this.activities.map(activity => ({
        id: activity.id,
        club_id: activity.club_id,
        category: activity.category,
        milestone: activity.milestone,
        meeting_date: activity.meeting_date,
        duration: activity.duration,
        onboarding_status: this.findOnboardingStatus(activity.club_id)?.status || '-',
        club_type: this.storedClubs.find(club => club.id === activity.club_id)?.type || 'customer',
      })).filter(activity => this.matchesDate(activity) && activity.meeting_date);
    },
    clubsWithActivities() {
      const clubs = [];
      this.filteredActivities.forEach((act) => {
        if (!clubs.includes(act.club_id)) clubs.push(act.club_id);
      });
      return clubs;
    },
    tableRows() {
      return this.clubsWithActivities.map(id => ({
        club_id: id,
        club_name: this.storedClubs.find(club => club.id === id)?.name,
        onboarding_type: this.storedClubs.find(club => club.id === id) ? this.storedClubs.find(club => club.id === id).config.onboarding_type : '-',
        setup_time: this.getDuration(id, 'setup'),
        registrations_time: this.getDuration(id, 'registration'),
        operations_time: this.getDuration(id, 'operations'),
        website_time: this.getDuration(id, 'website'),
        meetings_time: this.getDuration(id, 'meeting'),
        team_accounts_time: this.getDuration(id, 'team_accounts'),
      })).map(club => ({ ...club,
        total_time: club.setup_time + club.registrations_time + club.operations_time + club.website_time + club.meetings_time }))
        .filter(club => this.matchesSearch(club.club_name) && this.matchesFilter(club));
    },
    hasFiltersActive() {
      return this.isFiltersActive(this.$store.getters.activeFiltersFor('activities'));
    },
    storedClubs() {
      return this.$store.getters.clubs;
    },
  },
};
</script>
