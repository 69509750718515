<template>
  <div>
    <Modal
      ref="modal"
      title="Export Club Values">
      <template>
        <p style="margin-bottom: 2rem">{{ exportText }}</p>
        <div style="margin-bottom: 2rem">
          <b-field label="Select Clubs">
            <b-radio v-model="exportSelect" name="club" native-value="all">All Clubs</b-radio>
          </b-field>
          <b-field>
            <b-radio v-model="exportSelect" name="club" native-value="select">Select Clubs</b-radio>
          </b-field>
          <div v-if="exportSelect === 'select'">
            <ChooseMultipleClubs :clubs="clubs" @save="$event => handleSave($event)"/>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div>
          <a v-if="disabled"
          :disabled="disabled"
          :loading="exportURL === ''"
          class="button is-primary">
          <span style="margin-right:.5rem">
            <fa-icon
              :icon="['far', 'sign-in']"
              :transform="{ rotate: 90 }"/>
          </span>
          Download as .CSV
        </a>
        <a v-else
          :loading="exportURL === ''"
          :href="exportURL"
          target="_blank"
          class="button is-primary"
          @click="dismissModal">
          <span style="margin-right:.5rem">
            <fa-icon
              :icon="['far', 'sign-in']"
              :transform=" {rotate: 90 }"/>
          </span>
          Download as .CSV
        </a>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import api from '@/http-playmetrics';
import _ from 'lodash';
import ChooseMultipleClubs from './ChooseMultipleClubs';

export default {
  components: {
    Modal,
    ChooseMultipleClubs,
  },
  props: {
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      serverURL: '',
      exportSelect: 'all',
      allURL: '',
      club_ids: [],
      clubInternals: [],
    };
  },
  computed: {
    clubIDS: {
      get() {
        if (this.exportSelect === 'all') {
          return this.allClubIDS;
        } else if (this.exportSelect === 'select') {
          return this.club_ids;
        } else {
          return [];
        }
      },
      set(value) {
        this.club_ids = value;
      },
    },
    clubs() {
      return this.$store.getters.clubs.filter(club => !(club.is_disabled || club.is_deleted || club.is_test_club))
        .map(club => ({
          id: club.id,
          sport: club.sports.map(sport => sport.sport),
          state: club.config.state,
          name: club.name,
          risk_rating: this.clubInternals[club.id]?.risk_rating,
          expected_apv: this.clubInternals[club.id]?.expected_apv,
          expected_prepayment_coefficient: this.clubInternals[club.id]?.expected_prepayment_coefficient,
        }));
    },
    allClubIDS() {
      return this.clubs.map(club => club.id);
    },
    exportText() {
      return 'Generate a .csv file of club specific financial tracking values';
    },
    exportURL() {
      if (this.disabled) {
        return '';
      }
      if (this.clubIDS && this.allURL) {
        return `${this.allURL}&data=${JSON.stringify(this.clubIDS)}`;
      }
      return this.allURL;
    },
  },
  methods: {
    handleSave(event) {
      this.clubIDS = event;
    },
    async fetchClubInternals() {
      return api().post('cst/club_internals/search').then((res) => {
        if (res.status === 200) {
          const clubInternals = res.data;
          this.clubInternals = _.keyBy(clubInternals, 'id');
        }
      });
    },
    async showModal() {
      await this.fetchClubInternals();
      this.exportSelect = 'all';
      this.$refs.modal.showModal();
      api().serverURL('cst/club_internals/export.csv').then((url) => {
        this.allURL = url;
      });
    },
    dismissModal() {
      this.$refs.modal.dismissModal();
    },
  },
};
</script>
