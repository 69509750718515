// state
const state = {
  contracts: null,
};

// getters
const getters = {
  contracts(state) {
    return state.contracts;
  },
  contractHealthStatus() {
    return [
      { name: 'No Concerns', value: '' },
      { name: 'No Concerns With Comments', value: 'no concerns with comments' },
      { name: 'Concerns', value: 'concerns' },
      { name: 'At Risk', value: 'at risk' },
    ];
  },
  contractSizes() {
    return [
      { name: 'Regional', value: 'regional' },
      { name: 'Large Metro', value: 'large metro' },
      { name: 'Medium Metro', value: 'medium metro' },
      { name: 'Small Local', value: 'small local' },
      { name: 'Niche', value: 'niche' },
    ];
  },
  contractSports() {
    return [
      { name: 'Soccer', value: 'soccer' },
      { name: 'Baseball', value: 'baseball' },
      { name: 'Basketball', value: 'basketball' },
      { name: 'eSports', value: 'esports' },
      { name: 'Field Hockey', value: 'fieldhockey' },
      { name: 'Hockey', value: 'hockey' },
      { name: 'Lacrosse', value: 'lacrosse' },
      { name: 'Rugby', value: 'rugby' },
      { name: 'Ski/Snowboard', value: 'ski/snowboard' },
      { name: 'Softball', value: 'softball' },
      { name: 'Volleyball', value: 'volleyball' },
      { name: 'Other', value: 'other' },
    ];
  },
  contractRecCaps() {
    return [
      { name: 'Way Over Player Cap (>20%)', value: 'has-text-danger' },
      { name: 'Slightly Over Player Cap (10%-20%)', value: 'has-text-warning-dark' },
      { name: 'Within Player Cap (0%-10%)', value: '' },
      { name: 'Under Player Cap', value: 'has-text-success' },
    ];
  },
  contractCompCaps() {
    return [
      { name: 'Way Over Player Cap (>20%)', value: 'has-text-danger' },
      { name: 'Slightly Over Player Cap (10%-20%)', value: 'has-text-warning-dark' },
      { name: 'Within Player Cap (0%-10%)', value: '' },
      { name: 'Under Player Cap', value: 'has-text-success' },
    ];
  },
};

export default {
  state,
  getters,
};
