import { render, staticRenderFns } from "./SidenavHeader.vue?vue&type=template&id=6e8a8bc8&scoped=true"
var script = {}
import style0 from "./SidenavHeader.vue?vue&type=style&index=0&id=6e8a8bc8&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e8a8bc8",
  null
  
)

export default component.exports