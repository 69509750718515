<template>
  <ModalPopover :popoverClass="'medium'">
    <template slot="trigger">
      <div v-if="customerHealth.health_status === 'no concerns with comments'"><fa-icon :icon="['far', 'book']"/></div>
      <div v-else class="stoplight" :class="customerHealth.health_status === 'concerns' ? 'concerns' : 'at-risk'"/>
    </template> <template slot="content">
      <div class="has-text-weight-semibold is-font-mont is-size-5"> {{ customerHealth.club_name || customerHealth.name }} </div>
      <div class="modal-subheader">
        <div v-if="customerHealth.health_status === 'no concerns with comments'"><fa-icon :icon="['far', 'book']"/></div>
        <div  v-else class="stoplight__modal" :class="customerHealth.health_status === 'concerns' ? 'concerns' : 'at-risk'"/>
        <div class="has-text-grey" style="float: left; padding-left: 0.5rem">
          {{ healthStatus | capitalize }} </div>
      </div><br/>
      <div v-if="customerHealth.health_notes">
        <div v-for="note_line in customerHealth.health_notes.split('\n')" :key="note_line.value">
          {{ note_line }}
        </div><br/><div class="has-text-grey is-size-7" style="font-style: italic"> Last Updated by
          {{ createdByUser }} on
          {{ customerHealth.health_updated_at | formatDate('ll z') }}</div>
      </div> <div v-else> No Comments </div>
    </template>
  </ModalPopover>
</template>
<script>
import ModalPopover from './ModalPopover';

export default {
  components: { ModalPopover },
  props: {
    customerHealth: { type: Object, required: true },
  },
  computed: {
    createdByUser() {
      const user = this.$store.getters.users.find(usr => usr.id === this.customerHealth.health_updated_by_user_id);
      return user ? `${user?.first_name} ${user?.last_name}` : '';
    },
    healthStatus() {
      return this.customerHealth.health_status === 'no concerns with comments' ? 'no concerns' : this.customerHealth.health_status;
    },
  },
};
</script>
<style lang="sass" scoped>
.modal-subheader
  display: flex
  flex-direction: row
.stoplight
  height: 1rem
  width: 1rem
  border-radius: 50%
  white-space: nowrap
  display: inline-block
  &__modal
    float: left
    margin-top: .4rem
    height: 0.75rem
    width: 0.75rem
    border-radius: 50%
    white-space: nowrap
    display: inline-block
.concerns
  background-color: $warning-dark
.at-risk
  background-color: $danger
</style>
