<template>
  <div class="centered">
    <div class="has-text-centered content">
      <img class="pm-ball" src="static/images/logos/pm-ball.png">
      <h1 class="title has-text-weight-normal">Welcome to the PlayMetrics<br/>Customer Success Tracker</h1>
      <p class="has-text-weight-light">To get started, click on a link in side navigation bar.</p>
    </div>
  </div>
</template>

<script>
// import api from '@/http-playmetrics';

export default {
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    user() {
      return this.$store.getters.user;
    },
  },
};
</script>

<style lang="sass" scoped>
.centered
  height: 80vh
  display: grid
  place-items: center
  .pm-ball
    width: 6rem
</style>
