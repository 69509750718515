<template>
  <spinner v-if="loading"/>
   <div id="onboarding-summary" v-else>
     <TablePaginated
       name="Clubs Onboarding Summary"
       :data="hasStateSelected ? clubsByState : searchedReportsWithClubFields"
       :bordered="isBordered"
       :sticky-header="stickyHeaders"
       height="600"
       class="reports-club-onboarding-table"
       searchable
       :default-sort="['date', 'desc']"
       scrollable
       hoverable
       :filters-active="hasFiltersActive"
       @queryUpdated="searchQuery = $event">
       <template slot="header-left">
           <div style="display: flex;flex-direction: column">
             <b-switch v-model="showStaff" >Show staff members</b-switch>
           </div>
         </template>
        <template slot-scope="props">
         <b-table-column label="Club Name"
          field="name"
          header-class="is-unselectable header-background counter"
          :custom-sort="sortByName"
          sortable>
          <a @click="viewPlan(props.row.id)"><div class="club-name" style="display: flex; flex-direction: row;">
            <div class="columns">
              <div class="column" style="width: 1.75rem">
                <CustomerHealthModalPopover v-if="props.row.health_status" :customerHealth="props.row"/>
              </div>
              <div class="column">
                {{ props.row.club_name | capitalize }}
              </div>
            </div></div>
          <div v-if="showStaff"> <i>{{ props.row.primary_CS_Staff_member | capitalize }}</i> </div>
          <div v-if="showStaff"> <i>{{ props.row.secondary_CS_Staff_member | capitalize }}</i> </div>
          </a>
         </b-table-column>
         <b-table-column label="State"
          field="state"
          header-class="is-unselectable header-background center"
          :custom-sort="sortByState"
          sortable>
           {{ props.row.club_state }}
         </b-table-column>
         <b-table-column label="Date Signed"
          field="date"
          header-class="is-unselectable header-background counter"
          :custom-sort="sortByDate"
          sortable>
           {{ props.row.club_date | formatDate }}
         </b-table-column>
         <b-table-column label="Status"
          field="status"
          header-class="is-unselectable header-background counter"
          sortable>
           {{ props.row.status | capitalize }}
         </b-table-column>
         <b-table-column label="% Complete"
          field="percent_complete"
          header-class="is-unselectable header-background counter"
          :custom-sort="sortByPercent"
          sortable>
           <div class="club-percent"> {{ percentFormat(props.row.percent_complete)}} </div>
         </b-table-column>
         <b-table-column label="Setup"
          field="setup"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.setup , props.row.total_setup, props.row.tbd_setup)"
          :custom-sort="sortBySetup"
          sortable>
           <div class="club-format">{{ ofFormat(props.row.setup ,props.row.total_setup)}}</div>
         </b-table-column>
         <b-table-column label="Registrations"
          field="registrations"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.registrations, props.row.total_registrations, props.row.tbd_registrations)"
          :custom-sort="sortByRegistrations"
          sortable>
           <div class="club-format">{{ ofFormat(props.row.registrations ,props.row.total_registrations) }}</div>
         </b-table-column>
         <b-table-column label="Operations"
          field="operations"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.operations, props.row.total_operations, props.row.tbd_operations)"
          :custom-sort="sortByOperations"
          sortable>
           <div class="club-format">{{ ofFormat(props.row.operations, props.row.total_operations) }}</div>
         </b-table-column>
         <b-table-column label="Website"
          field="website"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.website, props.row.total_website, props.row.tbd_website)"
          :custom-sort="sortByWebsite"
          sortable>
           <div class="club-format">{{ ofFormat(props.row.website, props.row.total_website) }}</div>
         </b-table-column>
         <b-table-column label="Team Accounts"
          field="team_accounts"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.team_accounts, props.row.total_team_accounts, props.row.tbd_team_accounts)">
          <div class="club-format">{{ ofFormat(props.row.team_accounts, props.row.total_team_accounts) }}</div>
         </b-table-column>
        </template>
       <template slot="empty">
           <div class="content has-text-grey has-text-centered">
             <p>No Clubs Found</p>
           </div>
       </template>
     </TablePaginated>
     <ReportsFilter :filters-active="hasFiltersActive"
      :states="states"
      @date="customerSince=$event"
      @input="selectState($event)"
      @status="hasStatus=$event"
      @health_status="setHealthStatus=$event"/>
   </div>
 </template>


<script>
import _ from 'lodash';
import moment from 'moment';
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';
import api from '@/http-playmetrics';
import CustomerHealthModalPopover from '../../common/CustomerHealthModalPopover';
import ReportsFilter from './ReportsFilter';


export default {
  components: { Spinner, TablePaginated, ReportsFilter, CustomerHealthModalPopover },
  props: {
    clubInternals: { typeArray: Array, required: true },
  },
  data() {
    return {
      counter: 0,
      loading: false,
      selectedStates: [],
      searchQuery: '',
      isBordered: true,
      showAlert: true,
      stickyHeaders: true,
      activeTab: 0,
      reports: [],
      showStaff: false,
      customerSince: null,
    };
  },
  created() {
    this.fetchReports();
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    clubsWithActivites() {
      return this.reports.filter(report => !(report.activities === null || report.archived_at || report.status === 'completed'));
    },
    states() {
      const st = [];
      this.reportsWithClubFields.forEach((c) => {
        if (c.club_state !== '-' && !st.includes(c.club_state)) st.push(c.club_state);
      });
      return st.sort((state1, state2) => (state1 < state2 ? -1 : 1));
    },
    reportsWithClubFields() {
      return this.clubsWithActivites.map((report) => {
        const count = this.tableCounter(report.activities);
        return {
          id: report.id,
          status: report.status,
          health_status: this.clubInternalsByID(report.club_id)?.health_status || '',
          health_notes: this.clubInternalsByID(report.club_id)?.health_notes || '',
          health_updated_at: this.clubInternalsByID(report.club_id)?.health_updated_at || '',
          health_updated_by_user_id: this.clubInternalsByID(report.club_id)?.health_updated_by_user_id || 0,
          club_name: this.clubName(report.club_id),
          club_state: this.clubState(report.club_id),
          club_date: this.clubDate(report.club_id),
          primary_CS_Staff_member: report.cs_lead_user ? report.cs_lead_user.name : '',
          primary_cs_staff_member_id: report.cs_lead_user_id,
          secondary_CS_Staff_member: report.cs_secondary_user ? report.cs_secondary_user.name : '',
          setup: count.setup.count,
          total_setup: count.setup.total,
          tbd_setup: count.setup.tbd,
          registrations: count.registration.count,
          total_registrations: count.registration.total,
          tbd_registrations: count.registration.tbd,
          operations: count.operations.count,
          total_operations: count.operations.total,
          tbd_operations: count.operations.tbd,
          website: count.website.count,
          total_website: count.website.total,
          tbd_website: count.website.tbd,
          team_accounts: count.team_accounts.count,
          total_team_accounts: count.team_accounts.total,
          tbd_team_accounts: count.team_accounts.tbd,
          percent_complete: this.completionPercent(report.activities),
        };
      }).sort((report1, report2) => ((report1.date > report2.date) ? -1 : 1));
    },
    filteredReports() {
      return this.reportsWithClubFields.filter(report => this.matchesReportFilter(report));
    },
    searchedReportsWithClubFields() {
      return this.customerSince ? this.reportsWithClubFields.filter(report =>
        this.matchesName(report, this.searchQuery) && !(report.status.toLowerCase() === 'completed') && this.matchesDate(report) && this.matchesReportFilter(report) && this.matchesReportPercent(report))
        : this.reportsWithClubFields.filter(report =>
          this.matchesName(report, this.searchQuery) && !(report.status.toLowerCase() === 'completed') && this.matchesReportFilter(report) && this.matchesReportPercent(report));
    },
    clubsByState() {
      return this.searchedReportsWithClubFields.filter(report => this.selectedStates.includes(report.club_state));
    },
    hasStateSelected() {
      return this.states.some(st => this.selectedStates.includes(st));
    },
    hasFiltersActive() {
      return !_.isEmpty(this.activeFilters) || this.customerSince !== null || this.hasStateSelected;
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('onboardingReports');
    },
  },

  methods: {
    createdByUser(id) {
      const user = this.$store.getters.users.find(usr => usr.id === id);
      return user ? `${user?.first_name} ${user?.last_name}` : '';
    },
    selectState(st) {
      if (st === 'clear') {
        this.selectedStates = [];
      } else if (this.selectedStates.includes(st)) {
        this.selectedStates = _.without(this.selectedStates, st);
      } else {
        this.selectedStates = _.concat(this.selectedStates, st);
      }
    },
    sortByOperations(a, b, isAsc) {
      if (this.ofFormat(a.operations, a.total_operations) === '-') return isAsc ? 1 : -1;
      if (this.ofFormat(b.operations, b.total_operations) === '-') return isAsc ? -1 : 1;
      if (this.ofFormat(a.operations, a.total_operations)
       > (this.ofFormat(b.operations, b.total_operations))) return isAsc ? 1 : -1;
      if (this.ofFormat(a.operations, a.total_operations) < this.ofFormat(b.operations, b.total_operations)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByWebsite(a, b, isAsc) {
      if (this.ofFormat(a.website, a.total_website) === '-') return isAsc ? 1 : -1;
      if (this.ofFormat(b.website, b.total_website) === '-') return isAsc ? -1 : 1;
      if ((this.ofFormat(a.website, a.total_website)) > this.ofFormat(b.website, b.total_website)) return isAsc ? 1 : -1;
      if (this.ofFormat(a.website, a.total_website) < this.ofFormat(b.website, b.total_website)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByRegistrations(a, b, isAsc) {
      if (this.ofFormat(a.registrations, a.total_registrations) === '-') return isAsc ? 1 : -1;
      if (this.ofFormat(b.registrations, b.total_registrations) === '-') return isAsc ? -1 : 1;
      if (this.ofFormat(a.registrations, a.total_registrations) >
       this.ofFormat(b.registrations, b.total_registrations)) return isAsc ? 1 : -1;
      if (this.ofFormat(a.registrations, a.total_registrations) <
       this.ofFormat(b.registrations, b.total_registrations)) return isAsc ? -1 : 1;
      return 0;
    },
    sortBySetup(a, b, isAsc) {
      if (this.ofFormat(a.setup, a.total_setup) === '-') return isAsc ? 1 : -1;
      if (this.ofFormat(b.setup, b.total_setup) === '-') return isAsc ? -1 : 1;
      if (this.ofFormat(a.setup, a.total_setup) >
       this.ofFormat(b.setup, b.total_setup)) return isAsc ? 1 : -1;
      if (this.ofFormat(a.setup, a.total_setup) <
       this.ofFormat(b.setup, b.total_setup)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByPercent(a, b, isAsc) {
      if ((a.percent_complete) > (b.percent_complete)) return isAsc ? 1 : -1;
      if ((a.percent_complete) < (b.percent_complete)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByName(a, b, isAsc) {
      if (a.club_name > b.club_name) return isAsc ? 1 : -1;
      if (a.club_name < b.club_name) return isAsc ? -1 : 1;
      return 0;
    },
    sortByState(a, b, isAsc) {
      if (a.club_state > b.club_state) return isAsc ? 1 : -1;
      if (a.club_state < b.club_state) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDate(a, b, isAsc) {
      if (!a.club_date) return 1;
      if (!b.club_date) return -1;
      if (moment(a.club_date, 'YYYY-MM-DD').isAfter(moment(b.club_date, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.club_date, 'YYYY-MM-DD').isBefore(moment(b.club_date, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    matchesName(reportsWithClubFields, searchQuery) {
      return reportsWithClubFields.club_name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    viewPlan(id) {
      this.$router.push({ name: 'PlanViewActivities', params: { plan: id } });
    },
    percentFormat(percent) {
      return `${percent} %`;
    },
    ofFormat(activity, total) {
      if (total) {
        return `${activity} of ${total}`;
      } else return '-';
    },
    colorClasses(activity, total, tbdCheck) {
      const colorStatus = (total - activity);
      const numTBD = (total - tbdCheck);
      if (total) {
        if (colorStatus === 0) {
          return 'disabled';
        }
        if ((numTBD) > 0) {
          return 'warning';
        }
        return '';
      } else {
        return 'disabled';
      }
    },
    tableCounter(activities) {
      const ret = {
        setup: {
          count: 0,
          total: 0,
          tbd: 0,
        },
        registration: {
          count: 0,
          total: 0,
          tbd: 0,
        },
        operations: {
          count: 0,
          total: 0,
          tbd: 0,
        },
        website: {
          count: 0,
          total: 0,
          tbd: 0,
        },
        team_accounts: {
          count: 0,
          total: 0,
          tbd: 0,
        },
        meeting: {
          count: 0,
          total: 0,
          tbd: 0,
        },
      };
      activities.forEach((activity) => {
        if (this.dateHasPassed(activity.meeting_date)) {
          ret[activity.category].count += 1;
        }
        if (activity.meeting_date) {
          ret[activity.category].tbd += 1;
        }
        ret[activity.category].total += 1;
      });
      return ret;
    },
    completionPercent(activities) {
      const oba = activities.filter(a => (a.category === 'setup' ||
         a.category === 'registration' ||
         a.category === 'operations' ||
         a.category === 'website'));
      if (oba.length === 0) return 100;
      const completed = oba.reduce(
        (compCount, activity) => compCount + (this.dateHasPassed(activity.meeting_date) ? 1 : 0),
        0);
      return Math.round((completed / oba.length) * 10000) / 100;
    },
    dateHasPassed(date) {
      if (!date) return false;
      return moment(date).isBefore(moment());
    },
    clubInternalsByID(id) {
      return this.clubInternals.find(club => club.id === id);
    },
    clubByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    clubName(id) {
      return this.clubByID(id)?.name || '';
    },
    clubState(id) {
      return this.clubByID(id)?.config.state || '-';
    },
    clubDate(id) {
      return this.clubByID(id)?.contract?.sign_date || '';
    },
    matchesDate(report) {
      return new Date(report?.club_date) > this.customerSince;
    },
    matchesClubName(report, searchQuery) {
      const name = this.clubName(report.club_id);
      return name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    matchesReportID(report, searchQuery) {
      return report.id.toString().includes(searchQuery);
    },
    matchesQuery(club) {
      return (
        (club.name &&
           club.name.toLowerCase().includes(this.searchQuery.toLowerCase())) ||
         (club.id && club.id.toString().includes(this.searchQuery.toLowerCase()))
      );
    },
    matchesReportPercent(report) {
      const filters = this.$store.getters.activeFiltersFor('onboardingReports');
      const reportPercent = filters.percent_complete;
      if (reportPercent.matches(report)) {
        return true;
      }

      return false;
    },
    matchesReportFilter(report) {
      if (!this.hasFiltersActive) return true;
      return Object.keys(this.activeFilters).every(key => this.activeFilters[key].matches(report));
    },
    fetchReports() {
      this.loading = true;
      api().post('/cst/club_onboarding_plans/search?populate=users,activities').then((res) => {
        this.reports = res.data.filter(plan => !(this.clubs.find(club => club.id === plan.club_id)?.type === 'prospect'));
        this.loading = false;
      });
    },
  },
};
</script>

 <style lang="sass" scoped>
 @import "~bulma/sass/utilities/mixins"
 #onboarding-summary
   ::v-deep .counter
     font-weight: bold
     padding-top: 1rem
     color: $white !important
     white-space: nowrap
   ::v-deep .club-name
     font-weight: bold
     white-space: nowrap
   ::v-deep .club-percent
     padding-right: 1.25rem
     text-align: right
   ::v-deep .club-format
     white-space: nowrap
     text-align: center
   .is-vertical-center
     display: inline-flex
     align-items: center
   .reports-club-onboarding-table
     margin-top: 1rem
   ::v-deep .header-background
     vertical-align: bottom
     width: 175px
     font-family: $family-headers
     font-size: 1rem
     background-color: $blue-slate
     color: $white !important
     padding: .75rem .75rem .75rem 1.25rem
   ::v-deep .success
     background-color: $success
   ::v-deep .warning
     background-color: rgba(#FFC000, 0.25)
   ::v-deep .danger
     background-color: rgba($danger, 0.1)
   ::v-deep .disabled
     background-color: #ebedf9
 </style>
