<template>
  <div>
    <Spinner v-if="loading"/>
    <div v-else>
      <div>
        <PageNavigation
          page-title="Activities"
          :ctas="ctas"
          :tabs="tabs"
          :active-tab="activeTab"
          @tabClicked="tabClicked($event)"
          @ctaClicked="cta($event)"/>
      </div>
      <div v-if="activeTab === 'Activities'">
        <Activities :activities="activities"
          :onboardingPlans="onboardingPlans"
          :defaultDateRange="dateRange"
          @date="dateRangeChanged"
          @tbdActive="tbdIsActive"
          @dataDisplayed="hasData"/>
      </div>
      <div v-if="activeTab === 'Reports'">
        <ActivitiesReports/>
      </div>
      <AddEditActivitiesModal :activityNotes="activityNotes"
        @noteCreated="refresh"
        @noteDeleted="refresh"
        @dismissed="addEditDismissed"
        ref="addEditPlan"/>
      <ActivitiesExportModal :tbdActive="tbdActive" :defaultDateRange="dateRange" ref="activitiesExportModal"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import Spinner from '@/components/common/Spinner';
import Activities from '@/components/activities/Activities';
import ActivitiesReports from '@/components/activities/ActivitiesReports';
import AddEditActivitiesModal from '@/components/activities/AddEditActivitiesModal';
import ActivitiesExportModal from '@/components/activities/ActivitiesExportModal';
import api from '@/http-playmetrics';

const defaultDateRange = () => ({
  startDate: moment().startOf('week').toDate(),
  endDate: moment().endOf('week').toDate(),
});

export default {

  components: { Activities, ActivitiesReports, PageNavigation, AddEditActivitiesModal, Spinner, ActivitiesExportModal },

  created() {
    this.fetchData();
  },

  data() {
    return {
      dateRange: defaultDateRange(),
      loading: false,
      activities: [],
      onboardingPlans: [],
      dataDisplayed: false,
      tbdActive: false,
    };
  },

  props: {
    defaultTab: { type: String, default: 'Activities' },

  },

  computed: {
    activeTab() {
      return this.defaultTab;
    },
    tabs() {
      return [{ name: 'Activities', ctas: this.ctas }, { name: 'Reports', ctas: this.ctas }];
    },
    ADD_ACTIVITY() {
      return 'Add Activity';
    },
    EXPORT_ACTIVITIES() {
      return { text: 'Export Activities', icon: 'sign-in', icon_transform: 'rotate-90', classes: 'is-outlined' };
    },
    activityNotes() {
      return this.activities.map(activity => ({
        id: activity.club_id,
        notes: activity?.notes ? [...activity?.notes] : [],
      }));
    },
    ctas() {
      const ret = [];
      if (this.dataDisplayed) {
        ret.push(this.EXPORT_ACTIVITIES);
      }
      ret.push(this.ADD_ACTIVITY);
      return ret;
    },
  },

  methods: {
    hasData(dt) {
      this.dataDisplayed = dt;
    },
    tbdIsActive(tbd) {
      this.tbdActive = _.cloneDeep(tbd);
    },
    dateRangeChanged(dr) {
      this.dateRange = _.cloneDeep(dr);
    },
    fetchData() {
      this.fetchActivities();
      this.fetchOnboardingPlans();
    },
    fetchActivities() {
      this.loading = true;
      api().post('/cst/activities/search?populate=notes').then((res) => {
        if (res.status === 200) {
          this.activities = res.data;
        }
        this.loading = false;
      });
    },
    fetchOnboardingPlans() {
      api().post('cst/club_onboarding_plans/search').then((res) => {
        if (res.status === 200) {
          this.onboardingPlans = res.data;
        }
      });
    },
    cta($event) {
      if ($event === this.ADD_ACTIVITY) {
        this.$refs.addEditPlan.show();
      }
      if ($event === this.EXPORT_ACTIVITIES) {
        this.$refs.activitiesExportModal.showModal();
      }
    },
    tabClicked($event) {
      if ($event === this.activeTab) return;
      const map = {
        Reports: 'ActivitiesReports',
      };
      const route = map[$event] || 'Activities';
      this.$router.push({ name: route });
    },
    refresh() {
      this.fetchActivities();
    },
    editActivity(activity) {
      this.$refs.addEditPlan.show(activity);
    },
    dismissedModal() {
      this.fetchActivities();
    },
    addEditDismissed(options) {
      if (options.refreshData) {
        this.fetchActivities();
      }
    },
  },
};
</script>
