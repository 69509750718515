<template>
  <a
    :class="[{ 'filter-selected-button': filtersActive, 'icon-only': iconOnly, 'filter-button-disabled': disabled }, extraClasses]"
    class="filter-button button is-white"
    @click.stop="toggleFilterTray()">
    <fa-icon
      :icon="['fas', 'circle']"
      transform="shrink-6"
      class="active-indicator" />
    <span :class="{'icon': true, 'is-small': !iconOnly}">
      <fa-icon :icon="['far', 'sliders-h']" class="fa-w-16" />
    </span>
    <span class="filter-text">{{ filter }}</span>
  </a>
</template>
<script>
export default {
  props: {
    filtersActive: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    extraClasses: {
      type: String,
      required: false,
      default: '',
    },
    filter: {
      type: String,
      required: false,
      default: 'Filter',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useSecondaryFilterMenu: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleFilterTray() {
      if (this.useSecondaryFilterMenu) {
        this.$store.dispatch('toggleSecondFilterTrayOpen');
      } else {
        this.$emit('toggleFilterTrayOpen');
        this.$store.dispatch('toggleFilterTrayOpen');
      }
    },
  },
};
</script>
