<template>
  <b-dropdown
    ref="datesDropdown"
    :disabled="disabled"
    :class="{'is-wide-dropdown': useMonthpicker}"
    :position="position"
    aria-role="menu"
    trap-focus
    class="date-range-dropdown">
    <a
      slot="trigger"
      role="button"
      :class="titleClass"
      class="title__section is-capitalized"
      style="margin: 0; display: flex; align-items: center">
      <slot name="trigger">
        <fa-icon
          v-if="!isMobile && !hideIcon"
          :icon="['fas', 'calendar']"
          style="margin-right: 0.75rem" />
        <span>{{ dateHeaderText }}</span>
        <fa-icon
          v-if="!disabled"
          :icon="['fas', 'caret-down']"
          class="is-size-6"
          style="margin-left: 0.75rem; margin-right: .75rem" />
      </slot>
    </a>

    <b-dropdown-item
      :focusable="false"
      aria-role="menu-item"
      custom
      paddingless>
      <div class="modal-card">
        <div class="modal-card-body is-flex">
          <div class="dropdown-close">
            <span class="clickable" style="padding: 0.5rem 0.75rem; margin: -.5rem -.75rem" @click="toggleDatesDropdown">
              <fa-icon :icon="['far', 'times']" />
            </span>
          </div>
          <div>
            <p
              v-if="useMonthpicker"
              class="is-font-mont has-text-weight-bold"
              style="margin-bottom: .75rem">
              Select Month
            </p>
            <div v-if="useMonthpicker" class="daterange-monthpicker">
              <b-datepicker
                v-model="selectedMonth"
                :month-names="monthNames"
                :max-date="maxMonth"
                type="month"
                inline
                @input="setDateRangeFromMonth" />
            </div>
            <template v-else>
              <div class="title__aside is-size-7">Date Range</div>
              <div v-for="preset in presets" :key="preset.name" style="margin-bottom: 1rem">
                <span
                  :class="{active: isActivePreset(preset.name)}"
                  class="range-preset"
                  @click="setDateFilter(preset.name)">
                  {{ preset.display }}
                </span>
              </div>
            </template>
          </div>
          <div class="divider-wrapper">
            <div class="divider" />
            <span>OR</span>
            <div class="divider" />
          </div>
          <div style="flex: 1; margin-bottom: 0.5rem">
            <p
              v-if="useMonthpicker"
              class="is-font-mont has-text-weight-bold"
              style="margin-bottom: -.5rem">
              Select Date Range
            </p>
            <div class="title__aside is-size-7" style="margin-bottom: 0.5rem">From</div>
            <b-field
              :type="fromInvalid ? 'is-danger' : ''"
              :message="fromInvalid ? 'Start date cannot be after end date' : ''">
              <b-datepicker
                v-model="dateRange.startDate"
                :min-date="minDate"
                :max-date="maxDate"
                :date-formatter="formatDate"
                :mobile-native="false"
                placeholder="Click to set start date..."
                icon="calendar"
                position="is-bottom-left"
                expanded
                @input="lastSelected = 'from'" />
            </b-field>
            <div class="title__aside is-size-7" style="margin-bottom: 0.5rem; margin-top: 1rem">To</div>
            <b-field
              :type="toInvalid ? 'is-danger' : ''"
              :message="toInvalid ? 'End date cannot be before start date' : ''">
              <b-datepicker
                v-model="dateRange.endDate"
                :min-date="minDate"
                :max-date="maxDate"
                :date-formatter="formatDate"
                :mobile-native="false"
                placeholder="Click to set end date..."
                icon="calendar"
                position="is-bottom-left"
                expanded
                @input="lastSelected = 'to'" />
            </b-field>
          </div>
        </div>
        <div class="modal-card-footer has-text-right">
          <button
            class="button is-text is-small is-wide"
            style="margin-right: 1rem"
            @click="toggleDatesDropdown">
            Cancel
          </button>
          <button
            :disabled="invalid"
            class="button is-primary is-small is-wide"
            @click="updateDateRange">
            Apply
          </button>
        </div>
      </div>
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    value: { type: Object, required: true },
    presets: { type: Array, default: () => [] },
    disabled: { type: Boolean, default: false },
    isTbd: { type: Boolean, default: false },
    maxRange: { type: Number, default: null },
    maxMonth: { type: Date, default: null },
    useMonthpicker: { type: Boolean, default: false },
    titleClass: { type: String, default: '' },
    titleDateFormatter: { type: Function, default: null },
    useMaxDate: { type: Boolean, default: true },
    hideIcon: { type: Boolean, default: false },
    position: { type: String, default: null },
    isActivities: { type: Boolean, default: false },
  },
  data() {
    return {
      dateRange: _.cloneDeep(this.value),
      selectedMonth: null,
      lastSelected: null,
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    dateHeaderText() {
      if (this.titleDateFormatter) return (this.titleDateFormatter(this.value));

      if (this.isTbd && this.isActivities) return 'TBD Activities';
      if (this.isTbd) return 'TBD';
      if (!this.value.startDate && !this.value.endDate) return '';
      const format = this.isMobile ? 'MMM D, YYYY' : 'ddd, MMM D, YYYY';
      const start = this.value.startDate ? moment(this.value.startDate).format(format) : '\u2014';
      const end = this.value.endDate ? moment(this.value.endDate).format(format) : '\u2014';
      if (start === end) return start;
      return `${start} - ${end}`;
    },
    maxDate() {
      if (this.useMaxDate) {
        if (!this.maxRange) return null;
        const maxDate = new Date(this.dateRange.startDate);
        maxDate.setDate(maxDate.getDate() + this.maxRange);
        return maxDate;
      } else return new Date();
    },
    minDate() {
      if (!this.maxRange) return null;
      const minDate = new Date(this.dateRange.endDate);
      minDate.setDate(minDate.getDate() - this.maxRange);
      return minDate;
    },
    startAfterEnd() {
      return moment(this.dateRange.startDate).isAfter(this.dateRange.endDate);
    },
    invalid() {
      return !this.dateRange.startDate || !this.dateRange.endDate || this.startAfterEnd;
    },
    fromInvalid() {
      return this.startAfterEnd && this.lastSelected === 'to';
    },
    toInvalid() {
      return this.startAfterEnd && this.lastSelected === 'from';
    },
    monthNames() {
      return ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    },
    isMonthSelected() {
      if (!this.useMonthpicker) return false;
      return moment(this.dateRange.startDate).isSame(this.dateRange.endDate, 'month') &&
        moment(this.dateRange.startDate).startOf('month').isSame(this.dateRange.startDate, 'day') &&
        moment(this.dateRange.endDate).endOf('month').isSame(this.dateRange.endDate, 'day');
    },
  },
  watch: {
    value() {
      this.dateRange = _.cloneDeep(this.value);
    },
    isMonthSelected() {
      this.setMonthSelected();
    },
  },
  created() {
    this.setMonthSelected();
  },
  methods: {
    toggleDatesDropdown() {
      if (this.$refs.datesDropdown) {
        this.$refs.datesDropdown.toggle();
      }
    },
    datesDropdownIsOpen() {
      return this.$refs.datesDropdown && this.$refs.datesDropdown.isActive;
    },
    updateDateRange() {
      this.toggleDatesDropdown();
      const oldDateRange = _.cloneDeep(this.value);
      const newDateRange = _.cloneDeep(this.dateRange);
      if (((oldDateRange.startDate || newDateRange.startDate) &&
           !moment(oldDateRange.startDate).isSame(newDateRange.startDate)) ||
          ((oldDateRange.endDate || newDateRange.endDate) &&
           !moment(oldDateRange.endDate).isSame(newDateRange.endDate))) {
        this.$emit('input', _.cloneDeep(this.dateRange));
      }
    },
    formatDate(date) {
      return moment(date).format('ddd, MMMM D, YYYY');
    },
    isActivePreset(presetName) {
      const preset = _.find(this.presets, p => p.name === presetName);
      if (preset) {
        return moment(this.dateRange.startDate).isSame(preset.range.startDate, 'day') &&
          moment(this.dateRange.endDate).isSame(preset.range.endDate, 'day');
      }
      return false;
    },
    setDateFilter(presetName) {
      if (this.isTbd) return;
      this.lastSelected = null;
      const preset = _.find(this.presets, p => p.name === presetName);
      if (preset) {
        this.dateRange = _.cloneDeep(preset.range);
      }
    },
    setMonthSelected() {
      if (this.isMonthSelected) {
        this.selectedMonth = this.dateRange.startDate;
      } else {
        this.selectedMonth = null;
      }
    },
    setDateRangeFromMonth() {
      if (this.selectedMonth) {
        this.dateRange = {
          startDate: moment(this.selectedMonth).startOf('month').toDate(),
          endDate: moment(this.selectedMonth).endOf('month').toDate(),
        };
      }
    },
  },
};
</script>

<style lang="sass">
.date-range-dropdown
  @import "~bulma/sass/utilities/mixins"
  .dropdown-menu
    overflow: visible !important
  .modal-card
    width: 450px
    max-width: 100%
    margin: 0
    padding: 0 20px .5rem 20px
    overflow: visible
    .modal-card-body
      padding: 1rem 0
      overflow: visible
      position: relative
      +mobile
        flex-direction: column-reverse
      .dropdown-close
        position: absolute
        top: 0.25rem
        color: $grey-light
        +mobile
          right: -0.5rem
        +tablet
          right: 0.5rem
        +desktop
          right: -1.5rem
      .range-preset
        cursor: pointer
        font-size: $size-6
        line-height: 1.25
        white-space: nowrap
        &.active
          color: $info
          border-bottom: 2px solid $info
      .divider-wrapper
        display: flex
        flex-direction: column
        align-self: stretch
        align-items: center
        margin: 0 3rem
        span
          font-family: $family-headers
          font-weight: $weight-bold
          padding: .5rem 0
        .divider
          border-left: 1px solid $grey-lighter
          flex: 1
          +mobile
            border-left: none
            border-bottom: 1px solid $grey-lighter
            margin: 1.5rem 0
      .datepicker
        .dropdown-content
          overflow-y: auto
        .datepicker-content
          height: auto
  &.dropdown.is-disabled
    opacity: 1
  &.is-wide-dropdown
    .dropdown-menu
      max-width: 490px
  .daterange-monthpicker
    margin-left: -1rem
    margin-right: -2rem
    margin-bottom: -1rem
    .datepicker
      .dropdown-content
        box-shadow: none
        padding: 0
        .datepicker-header
          margin-bottom: .5rem
          padding-bottom: 0
          border-bottom: none
        .datepicker-table .datepicker-body .datepicker-months
          width: 150px
</style>
