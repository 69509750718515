const VENDORS = {
  Stripe: 'Stripe',
  WePay: 'WePay',
};

function isVendor(clubMerchant, vendor) {
  return clubMerchant && clubMerchant.vendor === vendor;
}

function isStripe(clubMerchant) {
  return isVendor(clubMerchant, VENDORS.Stripe);
}

export default {
  VENDORS,
  isStripe,
};
