<template>
  <div v-if="club && club.config" class="plan-details">
    <div class="columns">
      <div class="column">
        <div class="columns bis-box full-height" style="margin-top: 0">
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Club Type</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.type | capitalize }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Contract Competitive Players</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.contract && club.contract.competitive_player_cap ?
                club.contract.competitive_player_cap.toLocaleString() : '-' }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Total Activity Time</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ totalActivityTime | formatDuration }}
            </div>
          </div>
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Onboarding Type</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.config.onboarding_type ? club.config.onboarding_type : '-' | capitalize }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Contract Recreational Players</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.contract && club.contract.rec_player_cap ?
                club.contract.rec_player_cap.toLocaleString() : '-' }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Customer Health </div>
            <div class="has-text-weight-bold is-font-mont is-size-4 row" style="margin-bottom:1rem;">
              <CustomerHealthModalPopover :customerHealth="customerHealth"/>
              <div class="has-text-info plan-details__health-status">
                {{ healthStatus | capitalize }}
              </div>
              <b-button class="button is-text"  @click=" updateOnboardingHealth(customerHealth)">
                <fa-icon :icon="['fas', 'pencil']" />
              </b-button>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="bis-box full-height">
          <div class="has-text-weight-semibold is-font-mont">Overall Lead</div>
          <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
            {{ plan.cs_lead_user ? plan.cs_lead_user.name : '-' }}
          </div>
          <div class="has-text-weight-semibold is-font-mont">Overall Secondary</div>
          <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
            {{ plan.cs_secondary_user ? plan.cs_secondary_user.name : '-' }}
          </div>
          <div class="has-text-weight-semibold is-font-mont">Sales Rep</div>
          <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
            {{ clubSalesContactName(club.config.sales_contact_user_id) || '-' }}
          </div>
        </div>
      </div>
    </div>
    <h3 class="plan-details__table-header">Customer Success Assignments</h3>
    <b-table
      :data="assignments"
      name="customerSuccessAssignments"
    >
      <template  slot-scope="props">
        <b-table-column
          label="Onboarding Category"
          field="onboardingCategory">
          {{ props.row.onboardingCategory }}
        </b-table-column>
        <b-table-column
          label="Primary Staff"
          field="primaryStaff">
          {{ props.row.primaryStaff }}
        </b-table-column>
        <b-table-column
          label="Secondary Staff"
          field="secondaryStaff">
          {{ props.row.secondaryStaff }}
        </b-table-column>
        <b-table-column
          label="Total Time"
          field="totalTime"
          numeric
          sortable
          :custom-sort="sortByTotalTime">
          {{ totalTimeForCategory(props.row.onboardingCategory) | formatDuration }}
        </b-table-column>
        <b-table-column
          label="Status"

          field="status">
          <StatusBadge :status="props.row.status"/>
        </b-table-column>
      </template>
    </b-table>
    <UpdateOnboardingHealth @dismissed="updateCustomerHealth" ref="onboardingHealth"/>
  </div>
</template>

<script>

import StatusBadge from '@/components/onboarding/plans/StatusBadge';
import CustomerHealthModalPopover from '@/components/common/CustomerHealthModalPopover';
import UpdateOnboardingHealth from './UpdateOnboardingHealth';

export default {
  components: { StatusBadge, UpdateOnboardingHealth, CustomerHealthModalPopover },
  props: {
    plan: { type: Object, required: true },
    club: { type: Object, default: () => {} },
    clubInternal: { type: Object, required: true },
  },
  computed: {
    customerHealth() {
      return {
        id: this.club.id,
        club_name: this.club.name,
        health_status: this.clubInternal.health_status,
        health_notes: this.clubInternal.health_notes,
        health_updated_at: this.clubInternal.health_updated_at,
        health_updated_by_user_id: this.clubInternal.health_updated_by_user_id,
      };
    },
    healthStatus() {
      if (this.clubInternal.health_status === 'no concerns with comments' || !this.clubInternal.health_status) return 'no concerns';
      return this.clubInternal.health_status;
    },
    salesUsers() {
      return this.$store.getters.salesUsers;
    },
    assignments() {
      const assignments = [
        {
          onboardingCategory: 'Setup',
          primaryStaff: this.plan.setup_lead_user?.name,
          secondaryStaff: this.plan.setup_secondary_user?.name,
          status: this.plan.setup_status,
        },
        {
          onboardingCategory: 'Operations',
          primaryStaff: this.plan.operations_lead_user?.name,
          secondaryStaff: this.plan.operations_secondary_user?.name,
          status: this.plan.operations_status,
        },
      ];
      if (this.plan.registration_status !== 'not applicable') {
        assignments.splice(1, 0, {
          onboardingCategory: 'Registration',
          primaryStaff: this.plan.registration_lead_user?.name,
          secondaryStaff: this.plan.registration_secondary_user?.name,
          status: this.plan.registration_status,
        });
      }
      if (this.plan.website_status !== 'not applicable') {
        assignments.push({
          onboardingCategory: 'Website',
          primaryStaff: this.plan.website_lead_user?.name,
          status: this.plan.website_status,
        });
      }
      return assignments;
    },
    totalActivityTime() {
      return this.plan.activities?.reduce((acc, activity) => acc + activity.duration, 0) || 0;
    },
  },
  methods: {
    clubSalesContactName(id) {
      if (!id) return '';
      return this.salesUsers[id] || '';
    },
    createdByUser(id) {
      const user = this.$store.getters.users.find(usr => usr.id === id);
      return user ? `${user?.first_name} ${user?.last_name}` : '';
    },
    updateCustomerHealth() {
      this.$emit('healthUpdated');
    },
    updateOnboardingHealth(plan) {
      this.$refs.onboardingHealth.showModal(plan);
    },
    totalTimeForCategory(cat) {
      return this.plan.activities?.filter(act => act.category.toLowerCase() === cat.toLowerCase())
        .reduce((acc, activity) => acc + activity.duration, 0) || 0;
    },
    sortByTotalTime(a, b, isAsc) {
      if (this.totalTimeForCategory(a.onboardingCategory) > this.totalTimeForCategory(b.onboardingCategory)) return isAsc ? 1 : -1;
      if (this.totalTimeForCategory(a.onboardingCategory) < this.totalTimeForCategory(b.onboardingCategory)) return isAsc ? -1 : 1;
      return 0;
    },
  },
};
</script>

<style lang="sass" scoped>
.row
  display: flex
  flex-direction: row
.plan-details
  &__table-header
    font-family: $family-headers
    font-weight: $weight-semibold
    font-size: $size-5
    margin-bottom: 1rem
  &__health-status
    margin-left: 0.5rem
</style>
