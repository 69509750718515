<template>
  <div class="total-card">
    <div class="total-card-label" v-text="`${this.label}${this.delimiter}`"/>
    <div class="total-card-value" v-text="value"/>
  </div>
</template>
<script>
export default {
  props: {
    label: { type: String, required: true },
    value: { type: String, required: true },
    delimiter: { type: String, default: ':' },
  },
};
</script>
<style lang="sass" scoped>
.total-card
  display: flex
  align-items: center
  justify-content: center
  gap: $space-2
  border-radius: $radius
  background: $grey-100
  color: $black
  padding: $space-1 $space-4

.total-card-value
  font-weight: $weight-medium
  color: $blue-300
</style>
