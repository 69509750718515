<template>
  <div>
    <h2 class="club-tickets__table-header">Tickets Details</h2>
      <TablePaginated
        name="ticketDetails"
        :data="filteredTickets"
        :searchable="false"
        :filterable="false">
        <template slot="header-left">
          <div style="margin-bottom: 1.5rem; flex: 1">
            <div class="level-left">
              <DateRangeDropdown
                v-model="dateRange"
                :maxRange="365"
                @input="dateRangeChanged"
                :useMaxDate="false"
                :presets="[
                  { name: 'Last 30 Days', display: 'Last 30 Days', range: last30Days },
                  { name: 'Last 3 Months', display: 'Last 3 Months', range: last3Months},
                  { name: 'This Year', display: 'This Year', range: thisYear },
                  { name: 'Last Year', display: 'Last Year', range: lastYear },
                ]"/>
            </div>
            <h2 class="club-tickets__sub-header"> {{ filteredTickets.length }} Tickets Found
              ( Time Spent: {{ totalTimeSpent | formatDuration }} )</h2>
          </div>
        </template>
        <template slot-scope="props">
          <b-table-column
            label="ID"
            field="id"
            sortable>
            <a
              :href="ticketUrl(props.row.id)"
              target="_blank"
              class="ticket_details__zendesk_link">
              {{ props.row.id }}
            </a>
          </b-table-column>
          <b-table-column
            label="Subject"
            field="subject"
            sortable>
            {{ props.row.subject | capitalize }}
          </b-table-column>
          <b-table-column
            label="Ticket Status"
            field="status"
            sortable>
            {{ status(props.row.status) | capitalize }}
          </b-table-column>
          <b-table-column
            label="Date Requested"
            field="date_requested"
            :custom-sort="sortByDateRequested"
            sortable>
            {{ props.row.created_at | formatDate() }}
          </b-table-column>
          <b-table-column
            label="Date Solved"
            field="date_solved"
            :centered="props.row.status === 'open'"
            :custom-sort="sortByDateSolved"
            sortable>
            {{ dateSolved(props.row) }}
          </b-table-column>
          <b-table-column
            label="Product Category"
            field="product_category"
            sortable>
            {{ productCategory(props.row.product_category) | capitalize }}
          </b-table-column>
          <b-table-column
            label="Level of Effort"
            field="level_of_effort"
            sortable>
            {{ levelOfEffort(props.row.level_of_effort) | capitalize }}
          </b-table-column>
          <b-table-column
            label="Ticket Assignee"
            field="assignee"
            sortable>
            {{ assignee(props.row.assignee) | capitalize }}
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div v-if="hasTickets" class="content has-text-grey has-text-centered">
              <p>No tickets for the selected time period</p>
            </div>
            <div v-else class="content has-text-grey has-text-centered">
              <p>No tickets associated with this club</p>
            </div>
          </section>
        </template>
      </TablePaginated>
  </div>
</template>

<script>
import TablePaginated from '@/components/layout/TablePaginated';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';
import _ from 'lodash';
import moment from 'moment';

const defaultDateRange = {
  startDate: moment().subtract(30, 'days').toDate(),
  endDate: moment().toDate(),
};

export default {
  components: { DateRangeDropdown, TablePaginated },
  props: {
    club: { type: Object, required: true },
  },
  data() {
    return {
      dateRange: _.cloneDeep(defaultDateRange),
    };
  },
  computed: {
    timeSpentMap() {
      return {
        under_5_minutes: 5,
        under_15_minutes: 15,
        under_30_minutes: 30,
        under_1_hour: 60,
        under_2_hours: 120,
        under_4_hours: 240,
        over_4_hours: 240,
      };
    },
    hasTickets() {
      return this.club.zendesk_tickets;
    },
    filteredTickets() {
      return (this.club.zendesk_tickets || []).filter(ticket => this.matchesDate(ticket));
    },
    totalTimeSpent() {
      return this.filteredTickets.reduce((acc, ticket) => acc + (this.timeSpentMap[ticket.level_of_effort] || 0), 0);
    },
    last30Days() {
      return {
        startDate: moment().subtract(30, 'days').toDate(),
        endDate: moment().toDate(),
      };
    },
    last3Months() {
      return {
        startDate: moment().subtract(3, 'months').toDate(),
        endDate: moment().toDate(),
      };
    },
    thisYear() {
      return {
        startDate: moment().dayOfYear(1).toDate(),
        endDate: moment().toDate(),
      };
    },
    lastYear() {
      return {
        startDate: moment().subtract(1, 'years').toDate(),
        endDate: moment().toDate(),
      };
    },
  },
  methods: {
    dateRangeChanged() {
      this.$emit('date', this.dateRange);
    },
    matchesDate(ticket) {
      return moment(ticket.created_at).isBetween(moment(this.dateRange.startDate), moment(this.dateRange.endDate), 'day', '[]') || !ticket.created_at;
    },
    assignee(name) {
      const user = this.$store.getters.users.find(usr => usr.email.includes(name));
      if (user) {
        return `${user.first_name} ${user.last_name}`;
      }
      return name;
    },
    status(str) {
      if (str === 'closed') {
        return 'solved';
      }
      return str;
    },
    levelOfEffort(str) {
      return str.replaceAll('_', ' ');
    },
    productCategory(str) {
      const updatedStr = str.replaceAll('/', ' / ');
      return this.levelOfEffort(updatedStr);
    },
    dateSolved(ticket) {
      if (ticket.status === 'open') {
        return '—';
      }
      return this.$options.filters.formatDate(ticket.first_resolved_date);
    },
    ticketUrl(id) {
      return `https://playmetrics.zendesk.com/agent/tickets/${id}`;
    },
    sortByDateRequested(a, b, isAsc) {
      if (new Date(a.created_at) > new Date(b.created_at)) return isAsc ? 1 : -1;
      if (new Date(a.created_at) < new Date(b.created_at)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDateSolved(a, b, isAsc) {
      if (new Date(a.first_resolved_date) > new Date(b.first_resolved_date)) return isAsc ? 1 : -1;
      if (new Date(a.first_resolved_date) < new Date(b.first_resolved_date)) return isAsc ? -1 : 1;
      return 0;
    },
  },
};
</script>

<style lang="sass">
.club-tickets
  margin-top: 1rem
  &__table-header
    font-family: $family-montserrat
    font-weight: $weight-semibold
    font-size: $size-4
  &__sub-header
    font-family: $family-montserrat
    font-weight: $weight-semibold
    font-size: $size-5
    margin-top: 1rem
</style>
