<template>
  <div>
    <div v-if="loading">
      <Spinner/>
    </div>
    <div v-else>
      <TeamAccountsFilter title="DEBITS" :filtersActive="hasActiveFilters" showOpen/>
      <TablePaginated
        name="Team Accounts Onboarding"
        :data="tableRows"
        searchable
        @queryUpdated="searchQuery = $event"
        :filterable="true"
        :filters-active="hasActiveFilters"
        :default-sort="['name']">
        <template #header-left>
          <TeamAccountsTableTotalsHeader>
            <TeamAccountsTableTotalCard
              label="Total Debit Amount"
              title="Total debit amount of open accounts"
              :value="aggregateTotalDebitAmountString"
            />
            <TeamAccountsTableTotalCard
              label="Total Reimbursement Amount"
              title="Total reimbursement amount of open accounts"
              :value="aggregateTotalReimbursementAmountString"
            />
          </TeamAccountsTableTotalsHeader>
        </template>
        <template slot-scope="props">
          <b-table-column label="Club Name"
            field="name"
            header-class="is-unselectable"
            sortable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="Club Size"
            field="size"
            header-class="is-unselectable"
            sortable>
            {{ props.row.size | capitalize }}
          </b-table-column>
          <b-table-column label="Sport"
            field="sport"
            header-class="is-unselectable"
            sortable>
            {{ props.row.sport | capitalize}}
          </b-table-column>
          <b-table-column label="Open Accounts"
            field="open_acc"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.open_acc }}
          </b-table-column>
          <b-table-column label="# of Debits"
            field="num_debits"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.num_debits }}
          </b-table-column>
          <b-table-column label="Debit Amount"
            field="debit_amount"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.debit_amount | currency}}
          </b-table-column>
          <b-table-column label="# of Reimbursements"
            field="num_reimbursements"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.num_reimbursements }}
          </b-table-column>
          <b-table-column label="Reimbursement Amount"
            field="reimbursement_amount"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ props.row.reimbursement_amount | currency }}
          </b-table-column>
        </template>
        <template slot="empty">
          No Team Accounts
        </template>
      </TablePaginated>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import TeamAccountsFilter from '@/components/team_accounts/TeamAccountsFilter';
import TablePaginated from '@/components/layout/TablePaginated';
import TeamAccountsTableTotalCard from '@/components/team_accounts/TeamAccountsTableTotalCard';
import TeamAccountsTableTotalsHeader from '@/components/team_accounts/TeamAccountsTableTotalsHeader';
import Spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import { currency } from '@/filters';

export default {
  components: {
    TeamAccountsTableTotalsHeader,
    TeamAccountsTableTotalCard,
    TeamAccountsFilter,
    TablePaginated,
    Spinner,
  },
  props: {
    loading: { type: Boolean, required: true },
    clubsById: { type: Object, required: true },
  },
  data() {
    return {
      searchQuery: '',
      tableData: [],
    };
  },
  computed: {
    hasActiveFilters() {
      return !_.isEmpty(this.filtersActive);
    },
    filtersActive() {
      return this.$store.getters.activeFiltersForContext('teamAccounts');
    },
    tableRows() {
      return this.tableData.flatMap((report) => {
        const club = this.clubsById[report.club_id];
        if (!club || club.is_test_club) return {};
        return {
          id: report.club_id,
          name: club.name,
          size: club.size || '-',
          sport: club.sports?.length ? club.sports[0].sport : '-',
          open_acc: report.num_open_accounts || 0,
          active_acc: report.num_active_accounts || 0,
          num_debits: report.debits || 0,
          debit_amount: report.debit_amount || 0,
          num_reimbursements: report.reimbursements || 0,
          reimbursement_amount: report.reimbursement_amount || 0,
        };
      }).filter(club => this.matchesName(club, this.searchQuery) && this.matchesFilter(club));
    },
    aggregateTotalDebitAmountString() {
      const total = this.tableRows
        .filter(row => row.open_acc > 0)
        .reduce((sum, row) => sum + row.debit_amount, 0);
      return currency(total);
    },
    aggregateTotalReimbursementAmountString() {
      const total = this.tableRows
        .filter(row => row.open_acc > 0)
        .reduce((sum, row) => sum + row.reimbursement_amount, 0);
      return currency(total);
    },
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData() {
      this.loading = true;
      return api().get('cst/team_accounts/summaries/fa_debits_reports').then((res) => {
        if (res.status === 200) {
          this.tableData = res.data;
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    matchesName(club, searchQuery) {
      return club?.name?.toLowerCase()?.includes(searchQuery?.toLowerCase() ?? '');
    },
    matchesFilter(club) {
      if (!this.hasActiveFilters) return true;
      return Object.keys(this.filtersActive).every(key => this.filtersActive[key].matches(club));
    },
  },
};
</script>
