<template>
  <div>
    <FilterTray
      title="Filter Contracts"
      :filters-active="hasFiltersActive"
      @clear-filters="clearFilters"
    >
      <FilterSection label="Customer Health" collapsible>
        <b-field v-for="h in contractHealthStatus" :key="h.value">
          <b-checkbox v-model="filters.health_status" :native-value="h.value">
            {{h.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Size" collapsible>
        <b-field v-for="s in sizes" :key="s.value">
          <b-checkbox  v-model="filters.size" :native-value="s.value"> {{s.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Sport" collapsible>
        <b-field v-for="s in sports" :key="s.value">
          <b-checkbox  v-model="filters.sport" :native-value="s.value"> {{s.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Rec Player Delta" collapsible>
        <b-field v-for="rc in recCaps" :key="rc.value">
          <b-checkbox  v-model="filters.recCap" :native-value="rc.value"> {{rc.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Comp Player Delta" collapsible>
        <b-field v-for="cc in compCaps" :key="cc.value">
          <b-checkbox  v-model="filters.compCap" :native-value="cc.value"> {{cc.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Advanced" collapsible>
            <b-switch v-model="filters.isArchived" :native-value="false">View Archived Contracts</b-switch>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterSection,
    FilterTray,
  },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('contract')),
    };
  },

  computed: {
    contractHealthStatus() {
      return this.$store.getters.contractHealthStatus;
    },
    sports() {
      return this.$store.getters.contractSports;
    },
    sizes() {
      return this.$store.getters.contractSizes;
    },
    recCaps() {
      return this.$store.getters.contractRecCaps;
    },
    compCaps() {
      return this.$store.getters.contractCompCaps;
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('contract'));
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'contract' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('contract'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'contract', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>
