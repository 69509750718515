<template>
  <DateNavigation
    :has-prev="hasPrev"
    :has-next="hasNext"
    @prev="$emit('prev')"
    @next="$emit('next')">
    <div class="date-navigation-with-picker">
      <div class="display-value-container">
        <span class="display-value">
          {{ displayValue }}
        </span>
      </div>
      <div>
        <button
          class="button is-text"
          @click="selectingDate = !selectingDate">
          <fa-icon
            :icon="['far', 'calendar-alt']"
            transform="grow-4 up-2"
          />
        </button>
        <b-datepicker
          v-if="selectingDate"
          v-model="currentValue"
          :min-date="minimumDate"
          :max-date="maximumDate"
          :first-day-of-week="firstDayOfWeek"
          inline
          @input="selectingDate = false"
        />
      </div>
    </div>
  </DateNavigation>
</template>

<script>
import DateNavigation from './DateNavigation';

export default {
  components: {
    DateNavigation,
  },
  props: {
    value: { type: Date, required: true },
    displayValue: { type: String, required: true },
    firstDayOfWeek: { type: Number, default: 1 },
    hasPrev: { type: Boolean, required: true },
    hasNext: { type: Boolean, required: true },
    minimumDate: { type: Date, default: null },
    maximumDate: { type: Date, default: null },
  },
  data() {
    return {
      selectingDate: false,
    };
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="sass" scoped>
  @import "~bulma/sass/utilities/mixins"

  .date-navigation-with-picker
    display: flex
    align-items: center
    .display-value-container
      flex: 1
      .display-value
        font-size: $size-5
        padding: 0 .5rem
        +mobile
          font-size: $size-6
          padding: 0
    ::v-deep
      .datepicker
        position: fixed
        z-index: 3
        +mobile
          right: 15px
</style>
