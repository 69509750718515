<template>
  <div>
    <PageNavigation
      page-title="Onboarding Summary"
      :active-tab="activeTab"
      :tabs="tabs"
      @tabClicked="tabClicked($event)"
      @ctaClicked="cta($event)"/>
    <div
      v-if="activeTab === 'Plans'">
        <PlansTab :plans="plans"
          :clubInternals="clubInternals"
          :loading="loading"
          @archived="planArchived"
          @restored="planRestored"
          @edited="planEdited"
          @healthUpdated="fetchPlans"/>
    </div>
    <div v-if="activeTab === 'Reports'">
      <ReportsTab :clubInternals="clubInternals"/>
    </div>
    <AddEditPlanModal :plans="plans" @dismissed="addPlanDismissed" ref="addPlan"/>
  </div>
</template>

<script>
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
// eslint-disable-next-line import/extensions
import PlansTab from '@/components/onboarding/plans/PlansTab.vue';
import ReportsTab from '@/components/onboarding/reports/ReportsTab';
// eslint-disable-next-line import/extensions
import AddEditPlanModal from '@/components/onboarding/plans/AddEditPlanModal.vue';
import api from '@/http-playmetrics';

export default {
  components: { PlansTab, PageNavigation, AddEditPlanModal, ReportsTab },
  props: {
    defaultTab: { type: String, default: 'Plans' },
  },

  data() {
    return {
      loading: false,
      plans: [],
      clubInternals: [],
    };
  },
  computed: {
    activeTab() {
      return this.defaultTab;
    },
    ADD_PLAN() { return 'Add Plan'; },
    EXPORT_PLANS() {
      return { text: 'Export Plans', icon: 'sign-in', icon_transform: 'rotate-90', classes: 'is-outlined' };
    },
    plansCtas() {
      const ret = [];
      if (this.$store.getters.canAccess(['product', 'success', 'operations'])) {
        ret.push(this.EXPORT_PLANS);
        ret.push(this.ADD_PLAN);
      }
      return ret;
    },
    tabs() {
      return [{ name: 'Plans', ctas: this.plansCtas }, { name: 'Reports' }];
    },
  },
  mounted() {
    this.fetchPlans();
  },
  methods: {
    fetchPlans() {
      this.loading = true;
      api().post('/cst/club_onboarding_plans/search?populate=users').then((res) => {
        this.plans = res.data;
        this.fetchInternalClubs();
      });
    },
    fetchInternalClubs() {
      api().get('/cst/club_internals/').then((res) => {
        this.clubInternals = res.data;
        this.loading = false;
      });
    },
    cta($event) {
      if ($event === this.ADD_PLAN) {
        this.$refs.addPlan.show();
      }
      if ($event === this.EXPORT_PLANS) {
        api().serverURL('/cst/club_onboarding_plans/club_onboarding_plans.csv').then((url) => {
          window.open(url, '_blank');
        });
      }
    },
    tabChange(tab) {
      this.activeTab = tab;
    },
    tabClicked($event) {
      if ($event === this.activeTab) return;
      const map = {
        Reports: 'OnboardingSummaryReports',
      };
      const route = map[$event] || 'OnboardingSummaryPlans';
      this.$router.push({ name: route });
    },
    addPlanDismissed(options) {
      if (options.refreshData) {
        this.fetchPlans();
      }
    },
    planArchived(options) {
      if (options.refreshData) {
        this.fetchPlans();
      }
    },
    planRestored(options) {
      if (options.refreshData) {
        this.fetchPlans();
      }
    },
    planEdited(options) {
      if (options.refreshData) {
        this.fetchPlans();
      }
    },
  },
};
</script>
