<template>
  <div class="player-modal">
    <Modal
      ref="modal"
      title="Export Tickets"
      is-all-white
      @dismiss="dismissModal">
    <template>
      <div class="centered-tool plan-form">
        <div class="plan-form__section">
          <p >Generate a .csv file of your tickets</p>
          <div style="margin-top: 1rem" class="columns">
            <div class="column is-half">
              <b-field :type=" submitFlag && (dateInvalid || !startDate) ? 'is-danger' : ''"
                label="Start Date">
                 <DatePickerString
                  append-to-body
                  :min-date="minDate"
                  v-model="startDate"
                  @input="submitFlag = false"
                  icon-right="calendar"
                  placeholder="MM/DD/YYYY"
                  editable/>
              </b-field>
              <div v-if="submitFlag && !startDate">
                <span class="help is-danger">
                  <fa-icon :icon="['fas', 'exclamation']" class="has-text-danger"/>
                  This is a required field
                </span>
              </div>
              <div v-if="submitFlag && dateInvalid">
                <span class="help is-danger">
                End date must be after start date
                </span>
              </div>
            </div>
            <div class="column is-half">
              <b-field :type="submitFlag && (dateInvalid || !startDate) ? 'is-danger' : ''"
              label="End Date">
                <DatePickerString
                  append-to-body
                  :min-date="minDate"
                  v-model="endDate"
                  @input="submitFlag = false"
                  icon-right="calendar"
                  placeholder="MM/DD/YYYY"
                  editable/>
              </b-field>
              <div v-if="submitFlag && !endDate">
                <span class="help is-danger">
                  <fa-icon :icon="['fas', 'exclamation']" class="has-text-danger"/>
                  This is a required field
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
     </template>
     <template slot="footer">
        <a
          class="button is-primary"
          :href="exportURLWithParams"
          :class="{'is-loading': saving}"
          @click="save">
          Download as .CSV
        </a>
      </template>
    </Modal>
  </div>

</template>

<script>
import Modal from '@/components/layout/modals/Modal';
import moment from 'moment';
import api from '@/http-playmetrics';
import DatePickerString from '@/components/common/form-elements/DatePickerString';


export default {
  props: {
    initStartDate: { type: String },
    initEndDate: { type: String },
  },
  components: { Modal, DatePickerString },
  data() {
    return {
      loading: false,
      editing: false,
      submitFlag: false,
      exportURL: '',
      startDate: null,
      endDate: null,
    };
  },
  mounted() {
    this.fetchExportURL();
  },
  computed: {
    minDate() {
      return new Date('01-10-2018');
    },
    dateInvalid() {
      return moment(this.startDate).isAfter(moment(this.endDate));
    },
    saving() {
      return null;
    },
    dateRangeAsJSON() {
      return JSON.stringify(this.ticketDate);
    },
    exportURLWithParams() {
      if (!this.invalidFields) {
        return `${this.exportURL}&data=${this.dateRangeAsJSON}`;
      } else {
        return null;
      }
    },
    invalidFields() {
      return (this.dateInvalid || !(this.endDate && this.startDate));
    },
    modalActions() {
      return ['save'];
    },
    ticketDate() {
      return {
        start_date: moment(this.startDate).format('YYYY-MM-DD'),
        end_date: moment(this.endDate).format('YYYY-MM-DD'),
      };
    },
  },

  methods: {
    save() {
      if (this.invalidFields === true) {
        this.submitFlag = true;
        console.log('Marking Bad Fields');
      } else { this.dismissModal(); }
    },
    showModal() {
      this.startDate = this.initStartDate;
      this.endDate = this.initEndDate;
      this.$refs.modal.showModal();
    },
    dismissModal(options = { refreshData: false }) {
      this.$refs.modal.dismissModal();
      this.$emit('dismissModal', options);
      this.startDate = this.initStartDate;
      this.endDate = this.initEndDate;
    },
    // this is a place holder for the csv
    fetchExportURL() {
      api().serverURL('/cst/zendesk_tickets/zendesk_tickets.csv').then((url) => {
        this.exportURL = url;
      });
    },
  },
};

</script>

<style></style>
