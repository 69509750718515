<template>
  <FullScreenModal ref="modal">
    <template slot="header">
      <slot name="header">
        <SelectMultipleObjectsDefaultHeader
          title="Club Contacts"
          :num-selected="numSelected"
          type="Contacts"
          @dismissModal="dismissModal"
          @handleSave="handleSave"
        />
      </slot>
    </template>
    <TablePaginated
      class="mt-6 mr-4 ml-4"
      :sortable="false"
      :filterable="false"
      :data="tableRows"
      :checked-rows.sync="selectedClubAdmins"
      :custom-is-checked="(a,b) => a && b && a.id === b.id"
      @queryUpdated="searchQuery = $event"
      checkable
      name="selectMultipleClubs"
    >
      <template slot="header-left">
        <SelectMultipleObjectsTabGroup
          :num-selected="numSelected"
          type="Contacts"
          :active-tab.sync="activeTab"
        />
      </template>
      <template slot-scope="props">
        <b-table-column label="Name">
          {{ props.row.name | capitalize }}
        </b-table-column>
        <b-table-column label="Email">
          {{ props.row.email }}
        </b-table-column>
      </template>
    </TablePaginated>
  </FullScreenModal>
</template>

<script>
import TablePaginated from '@/components/layout/TablePaginated';
import { keyBy } from 'lodash';
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import SelectMultipleObjectsDefaultHeader from '@/components/common/utils/SelectMultipleObjectsDefaultHeader';
import SelectMultipleObjectsTabGroup from '@/components/common/utils/SelectMultipleObjectsTabGroup';

export default {
  components: {
    SelectMultipleObjectsTabGroup,
    SelectMultipleObjectsDefaultHeader,
    FullScreenModal,
    TablePaginated,
  },
  props: {
    clubAdmins: { type: Array, required: true },
  },
  data() {
    return {
      clubAdminsByID: {},
      selectedClubAdmins: [],
      searchQuery: '',
      activeTab: 0,
    };
  },
  computed: {
    numSelected() {
      return this.selectedClubAdmins.length;
    },
    tableRows() {
      const adminsToDisplay = !this.activeTab ? this.clubAdmins : this.selectedClubAdmins;
      return adminsToDisplay.map(contact => ({ ...contact,
        name: `${contact.first_name} ${contact.last_name}`,
      })).filter(contact => this.matchesQuery(contact));
    },
  },
  methods: {
    showModal() {
      this.clubAdminsByID = keyBy(this.clubAdmins, 'id');
      this.selectedClubAdmins = this.clubAdmins.filter(admin => admin.current_role_permissions?.includes('club_contact'));
      this.$refs.modal.show();
    },
    matchesQuery(contact) {
      return contact.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
        contact.email.toLowerCase().includes(this.searchQuery.toLowerCase());
    },
    handleSave() {
      this.$emit('save', this.selectedClubAdmins.map(admin => admin.id));
      this.dismissModal();
    },
    dismissModal() {
      this.$refs.modal.dismiss();
    },
    isContact(user) {
      if (user.current_role_permissions) {
        return user.current_role_permissions.includes('club_contact');
      }
      return false;
    },
  },
};
</script>
