import { render, staticRenderFns } from "./RenewContractModal.vue?vue&type=template&id=524a5955&scoped=true"
import script from "./RenewContractModal.vue?vue&type=script&lang=js"
export * from "./RenewContractModal.vue?vue&type=script&lang=js"
import style0 from "./RenewContractModal.vue?vue&type=style&index=0&id=524a5955&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "524a5955",
  null
  
)

export default component.exports