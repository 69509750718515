export function parseInputAsCents(input) {
  if (typeof input !== 'string') {
    return null;
  }
  const normalizedInput = input
    .trim() // Trim whitespace...
    .replace(/-\s*/g, '-') // Remove whitespace after negative.
    .replace(/^\$\s*/, '') // Remove leading currency symbol.
    .replace(/^-\$\s*/, '-') // Remove currency symbol if negative.
    .replace(/\s*\$$/, '') // Remove trailing currency symbol.
    .replaceAll(',', ''); // Remove commas.
  if (!/^-?\d*\.?\d*$/.test(normalizedInput)) {
    return null;
  }
  const parts = normalizedInput.split('.');
  const fractional = parts.length === 1 ? 0 : parseInt(`${parts[1]}0`.slice(0, 2), 10);
  return (parseInt(parts[0] || '0', 10) * 100) + fractional;
}

// TODO: This should probably be in a different file.
export function parseInputAsPercent(input, allowFractional = false) {
  if (typeof input !== 'string') {
    return null;
  }
  const normalizedInput = input
    .trim() // Trim whitespace...
    .replace(/-\s*/g, '-') // Remove whitespace after negative.
    .replaceAll(',', ''); // Remove commas.
  if (!/^-?\d*\.?\d*$/.test(normalizedInput)) {
    return null;
  }
  if (allowFractional) {
    return parseFloat(normalizedInput || '0');
  }
  const parts = normalizedInput.split('.');
  return parseFloat(parts[0] || '0');
}

export default {
  parseInputAsCents,
  parseInputAsPercent,
};
