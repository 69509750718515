import moment from 'moment';

export function cmToFt(cm) {
  const realFeet = ((cm * 0.393700) / 12);
  let feet = Math.floor(realFeet);
  let inches = Math.round((realFeet - feet) * 12);
  if (inches === 12) {
    feet += 1;
    inches = 0;
  }
  return `${feet}ft ${inches}in`;
}

export function titleize(s) {
  return s.replace(/_/g, ' ').replace('-', ' ').replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function kgToLbs(kg) {
  return Math.round(kg * 2.20462);
}
export function capitalize(str, lower = false) {
  if (!str) return str;
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

export function currency(value, options) {
  if (typeof value !== 'number') {
    return value;
  }
  if (value === 0 && (options || {}).empty) return options.empty;
  const formatterOptions = Object.assign({
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  }, options || {});
  const formatter = new Intl.NumberFormat('en-US', formatterOptions);
  return options && options.fraction ? formatter.format(value) : formatter.format(value / 100.0);
}

export function formatDate(date, format = 'll z') {
  if (!date) {
    return 'N/A';
  }
  return moment(date).format(format);
}

export function formatDuration(time) {
  if (time === '-') {
    return time;
  }
  const hours = Math.floor(time / 60);
  const minutes = time % 60;
  let ret = '';
  if (hours === 1) {
    ret = ret.concat(`${hours} hr `);
  } else if (hours > 1) {
    ret = ret.concat(`${hours} hrs `);
  }
  ret = ret.concat(`${minutes} mins`);
  return ret;
}
