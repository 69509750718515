<template>
  <FullScreenModal
  ref="modal">
  <template slot="header">
    <slot name="header">
      <SelectMultipleObjectsDefaultHeader
        title="Export Club Values"
        :num-selected="numSelected"
        type="Selected"
        @dismissModal="dismissModal"
        @handleSave="handleSave"/>
    </slot>
  </template>
  <div v-if="loading" class="modal-body">
    <Spinner/>
  </div>
  <div v-else class="modal-body columns is-flex">
    <div v-if="loadingObjects" class="column left-column">
      <Spinner/>
    </div>
    <div v-else class="column left-column">
      <div class="level">
        <div class="level-left">
          <SelectMultipleObjectsTabGroup
            :num-selected="numSelected"
            type="Clubs"
            :label-all="labelAll"
            :tab-label="tabLabel"
            :active-tab.sync="activeTab"/>
        </div>
        <div v-if="activeTab === 0" class="level-right">
          <b-field grouped>
            <b-input
              v-if="!searchFirst"
              v-model="searchQuery"
              :placeholder="`Search ${type}...`"
              type="search"
              icon="search"
              expanded
              @input="$emit('search-query', $event)"/>
          </b-field>
        </div>
      </div>
      <template>
        <slot v-if="activeTab === 0" name="table-header"/>
        <TablePaginated
        :class="{ 'hide-select-all': activeTab === 1 }"
        :custom-is-checked="(a,b) => a && b && a.id === b.id"
        :searchable="false"
        :sortable="false"
        :header-checkable="false"
        :filterable="false"
        :checked-rows="checkedRows"
        class="select-table"
        checkable
        @check="check"
        :data="visibleData"
        name="selectMultipleClubs">
        <template v-if="activeTab === 0 && visibleData.length > 0" slot="table-header">
          <div style="margin: 1rem 0rem -3.8rem .8rem">
            <b-checkbox
              style="z-index: 2"
              :value="allSelected"
              @change.native="toggleSelectAll"/>
          </div>
        </template>
        <template slot-scope="props">
          <b-table-column label="ID" field="id">
            {{ props.row.id}}
          </b-table-column>
          <b-table-column label="Name" field="name">
            {{ props.row.name }}
          </b-table-column>
          <b-table-column label="State" field="state">
            {{ props.row.state }}
          </b-table-column>
          <b-table-column label="Sport" field="sport">
            {{ titleize(props.row.sport) }}
          </b-table-column>
          <b-table-column label="Risk Rating" field="risk_rating">
            {{ props.row.risk_rating }}
          </b-table-column>
          <b-table-column label="Expected APV" field="expected_apv">
            {{ props.row.expected_apv }}
          </b-table-column>
          <b-table-column label="Expected Prepayment Coefficient" field="expected_prepayment_coefficient">
            {{ props.row.expected_prepayment_coefficient }}
          </b-table-column>
        </template>
      </TablePaginated>
      </template>
    </div>
    <div class="column is-narrow filter-column" style="width: 300px">
      <StaticFilter>
        <template slot="content">
          <FinancialClubsFilter :states="states"/>
        </template>
      </StaticFilter>
    </div>
  </div>
  </FullScreenModal>
</template>

<script>
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import SelectMultipleObjectsDefaultHeader from '@/components/common/utils/SelectMultipleObjectsDefaultHeader';
import Spinner from '@/components/common/Spinner';
import SelectMultipleObjectsTabGroup from '@/components/common/utils/SelectMultipleObjectsTabGroup';
import TablePaginated from '@/components/layout/TablePaginated';
import _ from 'lodash';
import FinancialClubsFilter from '@/components/financial_tracking/FinancialClubsFilter';
import StaticFilter from './StaticFilter';

export default {
  components: {
    FullScreenModal,
    SelectMultipleObjectsDefaultHeader,
    Spinner,
    SelectMultipleObjectsTabGroup,
    TablePaginated,
    StaticFilter,
    FinancialClubsFilter,
  },
  props: {
    data: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    loadingObjects: { type: Boolean, default: false },
    labelAll: { type: String, default: 'All' },
    tabLabel: { type: String, default: '' },
    searchFirst: { type: Boolean, default: false },
    type: { type: String, required: true },
  },
  data() {
    return {
      clubByID: {},
      selectedClubsByID: {}, // Selected Clubs By Checkboxes
      searchQuery: '',
      activeTab: 0,
      perPage: 20,
    };
  },
  watch: {
    selectedClubsIDs() {
      this.$emit('selected', this.selectedClubsIDs);
    },
  },
  methods: {
    titleize(arr) {
      return arr.map(s => _.startCase(s)).toString();
    },
    check(checkedList, row) {
      if (!row) {
        this.toggleSelectAll();
      } else {
        this.selectedClubsByID = _.keyBy(checkedList.map(i => i.id));
      }
    },
    toggleSelectAll() {
      const allSelected = !this.allSelected;
      this.visibleData.forEach(row => this.checkRow(row.id, allSelected));
    },
    checkRow(rowID, check) {
      if (check) {
        this.$set(this.selectedClubsByID, rowID, rowID);
      } else {
        this.$delete(this.selectedClubsByID, rowID);
      }
    },
    getClubByID(id) {
      return this.clubByID[id];
    },
    handleSave() {
      this.$emit('save', this.selectedClubsIDs);
      this.dismissModal();
    },
    showModal() {
      this.clubByID = Object.freeze(_.keyBy(this.data, 'id'));
      this.$refs.modal.show();
    },
    dismissModal() {
      this.$refs.modal.dismiss();
    },
    matchesFilter(club) {
      if (!this.hasActiveFilters) return true;
      return Object.keys(this.activeFilters).every((key) => {
        if (key === 'advanced') {
          for (let i = 0; i < this.activeFilters[key].allow.length; i += 1) {
            if (!club[this.activeFilters[key].allow[i]]) {
              return true;
            }
          }
          return false;
        } else {
          return this.activeFilters[key].matches(club);
        }
      });
    },
    matchesQuery(club) {
      return club.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    },
  },
  computed: {
    states() {
      return _.uniqBy(this.data, club => club.state).reduce((ret, club) => {
        if (club.state) {
          ret.push(club.state);
        }
        return ret;
      }, []);
    },
    hasActiveFilters() {
      return !_.isEmpty(this.activeFilters);
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('clubFinancialTracking');
    },
    numSelected() {
      return this.selectedClubsIDs.length;
    },
    selectedClubsIDs() {
      return Object.keys(this.selectedClubsByID).map(id => parseInt(id, 10));
    },
    checkedRows() {
      return this.selectedClubs;
    },
    selectedClubs() {
      return _.compact(Object.keys(this.selectedClubsByID).map(id => this.getClubByID(id)));
    },
    allSelected() {
      if (this.visibleData.length === 0) {
        return false;
      }
      return !this.visibleData.some(row => !this.selectedClubsByID[row.id]);
    },
    visibleData() {
      return this.data.filter(club => this.matchesFilter(club) && this.matchesQuery(club));
    },
  },
};
</script>

<style lang="sass" scoped>
.filter-column
  width: $tray-width-wide
  background-color: $white-ter
  height: $planner-height
  min-height: $planner-height
  padding: 2rem 0 3rem 0!important
  overflow: auto
  display: flex
  flex-direction: column
.modal-body
  background: $white
  margin: 0
  position: relative
  height: 100%
  .left-column
    padding: 2rem
    margin: 0 auto
    overflow: auto
    .select-table
      -webkit-overflow-scrolling: touch
      > *
        -webkit-transform: translate3d(0,0,0)
</style>

<style lang="sass">
.hide-select-all
  th .b-checkbox.checkbox
    display: none
</style>
