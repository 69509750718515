<template>
  <b-tabs
    v-model="computedActiveTab"
    :expanded="isMobile"
    type="is-toggle"
    class="b-tabs-no-content">
    <b-tab-item :label="`${labelAll} ${tabLabel ? tabLabel : type}`"/>
    <b-tab-item :label="`Selected (${numSelected})`"/>
  </b-tabs>
</template>

<script>
export default {
  props: {
    labelAll: {
      type: String,
      default: 'All',
    },
    tabLabel: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    numSelected: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    computedActiveTab: {
      get() {
        return this.activeTab;
      },
      set(value) {
        this.$emit('update:activeTab', value);
      },
    },
  },
};
</script>
