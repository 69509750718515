<template>
  <div :class="{ 'wide-header': wideForm , 'no-title': noTitle, 'is-fixed-header': isFixedHeader}" class="form-modal-header">
    <nav :class="{ 'wide-form': wideForm }" class="level">
      <div class="level-left overflow-hidden">
        <div class="level-item">
          <div class="overflow-hidden">
            <slot name="title">
              <p
                v-if="subtitle"
                class="is-size-7 has-text-weight-bold is-light is-font-mont has-text-grey-light">{{ subtitle }}
              </p>
              <p class="is-size-4 has-text-weight-bold is-font-mont">{{ title }}</p>
            </slot>
          </div>
          <div v-if="customTitle" style="margin-left: 1rem">
            <slot name="customTitle"/>
          </div>
        </div>
      </div>
      <div
        v-if="hasActions"
        class="level-right"
        style="margin-left: 1rem">
        <div
          v-for="(action, index) in actions"
          :key="index"
          class="level-item action">
          <slot :name="action" />
        </div>
      </div>
      <button
        v-if="hasBackArrow"
        class="button is-text back-button"
        @click="$emit('back')">
        <fa-icon :icon="['far', 'arrow-left']"/>
      </button>
      <button
        v-if="!hideButton"
        class="button is-text close-button"
        @click="$emit('dismiss')">
        <fa-icon :icon="['far', 'times']"/>
      </button>
    </nav>
  </div>
</template>

<script>
export default {

  props: {
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    actions: { type: Array, default: () => ([]) },
    hideButton: { type: Boolean, default: false },
    wideForm: { type: Boolean, default: false },
    customTitle: { type: Boolean, default: false },
    hasBackArrow: { type: Boolean, default: false },
    isFixedHeader: { type: Boolean, default: false },
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    hasActions() {
      return this.actions.length > 0;
    },
    noTitle() {
      return this.title === '' && !this.$slots.title;
    },
  },

};
</script>

<style lang="sass" scoped>
@import "~bulma/sass/utilities/mixins"
.form-modal-header
  position: relative
  padding: 1rem
  border-bottom: 1px solid $grey-lighter
  background: #fff
  &.is-fixed-header
    position: fixed
    top: 0
    left: 0
    width: 100%
    z-index: 100
  @media screen and (min-width:768px) and (max-width:830px)
    padding-right: 100px
  +mobile
    padding-right: 0
  +mobile
    padding: 1rem
    display: flex
    flex: 1 1 auto
    flex-direction: column
  &.has-background-success
    color: white
    .close-button
      color: white
      &:hover
        background-color: lightgrey
  &.wide-header
    +desktop
      padding: 1rem 0
  &.no-title
    border-bottom: none
    padding: 0
  .level
    max-width: $form-width
    margin: 0 auto
    +mobile
      margin: 0
    .level-left
      flex: 1 1 auto
      max-width: 80%
      +mobile
        flex: 1 1 auto
      .level-item
        max-width: 100%
        p
          white-space: nowrap
          overflow: hidden
          text-overflow: ellipsis
        +mobile
          flex: 1 1 auto
          justify-content: space-between
    .level-right
      +mobile
        display: flex
        align-items: center
        justify-content: flex-start
        flex-wrap: wrap
        margin-top: 1rem
        margin-left: 0 !important
      .level-item
        &.action:nth-last-child(2)
          // margin-right: 0
        +mobile
          margin: 0 1rem 0.5rem 0
  .close-button
    position: absolute
    top: .5rem
    right: 2rem
    font-size: $size-4
    +mobile
      right: .5rem
  .back-button
    position: absolute
    top: .5rem
    left: 2rem
    font-size: $size-4
    +mobile
      left: .5rem
  .overflow-hidden
    text-overflow: ellipsis
    overflow: hidden
    white-space: nowrap
</style>
<style lang="sass">
.has-fixed-header
  margin-top: 60px
</style>
