<template>
  <ModalConfirm
    ref="modal"
    class="set-default-modal"
  >
    <p class="set-default-modal__instructions">
      <template v-if="currentDefaultAccount">
        The current default merchant account is <span class="set-default-modal__account-name">{{ currentName }}</span>.
      </template>
      You are about to set <span class="set-default-modal__account-name">{{ setName }}</span> as default.
      All revenue will flow to this account unless otherwise specified on a registration program.
    </p>
    <p class="set-default-modal__instructions">
      Are you sure you want to proceed?
    </p>
  </ModalConfirm>
</template>

<script setup>
import { computed, defineEmits, defineExpose, ref } from 'vue';
import api from '@/http-playmetrics';
import ModalConfirm from '@/components/layout/modals/ModalConfirm';
import useToast from 'jseminor/composables/toast';

const emit = defineEmits(['set-default']);

const modal = ref(null);
const currentDefaultAccount = ref(null);
const setDefaultAccount = ref(null);

function getAccountDisplayName(account) {
  return account?.name || `merchant account ID ${account?.id}`;
}
const currentName = computed(() => getAccountDisplayName(currentDefaultAccount.value));
const setName = computed(() => getAccountDisplayName(setDefaultAccount.value));

const { success: successToast, error: errorToast } = useToast();
async function confirmSetAsDefault() {
  if (setDefaultAccount.value.id) {
    try {
      await api({ showError: false }).post(`cst/merchant_accounts/${setDefaultAccount.value.id}/is_default`);
      successToast('Set default merchant account');
      emit('set-default');
    } catch {
      errorToast('Could not set default merchant account');
    }
  }
}

function showModal(inSet, inCurrent) {
  setDefaultAccount.value = inSet;
  currentDefaultAccount.value = inCurrent;
  modal.value.showModal({
    title: 'Set Default Merchant Account',
    confirmText: 'Set as Default',
    type: 'is-danger',
    pack: 'fas',
    icon: 'exclamation-circle',
    onConfirm: confirmSetAsDefault,
  });
}

defineExpose({ showModal });
</script>

<style lang="sass" scoped>
.set-default-modal
  &__account-name
    font-weight: $weight-bold
  &__instructions + &__instructions
    margin-top: $space-4
</style>
