<template>
  <div class="wrapper">
    <button
      :disabled="!hasPrev"
      class="button is-text"
      @click="$emit('prev')">
      <fa-icon
        :icon="['fas', 'chevron-left']"
        :transform="squeezed ? 'shrink-1' : 'grow-4'"/>
    </button>
    <div>
      <slot>
        <p :style="`font-size:${squeezed ? '14px' : '18px'};`">
          {{ context }}
        </p>
      </slot>
    </div>
    <button
      :disabled="!hasNext"
      class="button is-text"
      @click="$emit('next')">
      <fa-icon
        :icon="['fas', 'chevron-right']"
        :transform="squeezed ? 'shrink-1' : 'grow-4'"/>
    </button>
  </div>
</template>

<script>
export default {
  name: 'DateNavigation',
  props: {
    context: { type: String, required: false, default: '' },
    hasPrev: { type: Boolean, required: false, default: true },
    hasNext: { type: Boolean, required: false, default: true },
    squeezed: { type: Boolean, required: false, default: false },
  },
};
</script>

<style lang="sass" scoped>
  .wrapper
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    > :first-child,
    > :last-child
      flex: 1
    > :nth-child(2)
      flex: 1.8
      font-family: $family-headers
      text-transform: uppercase
      font-weight: $weight-bold
      color: $black
      white-space: nowrap
      text-align: center
</style>
