<template>
  <div>
    <Spinner v-if="loading"/>
    <div v-else>
      <div>
        <PageNavigation
          page-title="Clubs"
          :ctas="ctas"
          :tabs="tabs"
          :active-tab="activeTab"
          @tabClicked="tabClicked"
          @ctaClicked="cta"/>
      </div>
      <div v-if="activeTab === 'Clubs'">
        <ClubsView @load-clubs="loadClubs" :clubs=clubs ref="clubsView"/>
      </div>
      <div v-if="activeTab === 'Club Contacts'">
        <ClubContacts :clubs=clubs ref="clubContacts" />
      </div>
      <AddEditClub @dismissed="addEditClubDismissed" ref="addEditClub"/>
      <AddClubContact @dismissed="addClubContactDismissed"
      @refresh="addClubContactDismissed"
      ref="addClubContact"/>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import Spinner from '@/components/common/Spinner';
import ClubsView from '@/components/clubs/ClubsView';
import api from '@/http-playmetrics';
import AddEditClub from '@/components/clubs/AddEditClub';
import AddClubContact from '@/components/clubs/AddClubContact';
import ClubContacts from './ClubContacts';


export default {

  components: { ClubsView, ClubContacts, PageNavigation, AddEditClub, Spinner, AddClubContact },

  props: {
    defaultTab: { type: String, default: 'Clubs' },
  },

  data() {
    return {
      loading: false,
      tbdActive: false,
      clubs: [],
    };
  },

  computed: {
    activeTab() {
      return this.defaultTab;
    },
    tabs() {
      return [{ name: 'Clubs', ctas: this.ctas }, { name: 'Club Contacts', ctas: this.ctas }];
    },
    EXPORT_CLUBS() {
      return { text: 'Export Clubs', icon: 'sign-in', icon_transform: 'rotate-90', classes: 'is-outlined' };
    },
    EXPORT_CLUB_CONTACTS() {
      return { text: 'Export Club Contacts', icon: 'sign-in', icon_transform: 'rotate-90', classes: 'is-outlined' };
    },
    ADD_CLUB() {
      return 'Add Club';
    },
    ADD_CLUB_CONTACT() {
      return 'Add Club Contact';
    },
    ctas() {
      const ret = [];
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        if (this.activeTab === 'Clubs') {
          ret.push(this.EXPORT_CLUBS);
          ret.push(this.ADD_CLUB);
        } else if (this.activeTab === 'Club Contacts') {
          ret.push(this.EXPORT_CLUB_CONTACTS);
          ret.push(this.ADD_CLUB_CONTACT);
        }
      }
      return ret;
    },
  },
  mounted() {
    this.loadClubs();
  },
  methods: {
    tbdIsActive(tbd) {
      this.tbdActive = cloneDeep(tbd);
    },
    cta($event) {
      if ($event === this.ADD_CLUB) {
        this.$refs.addEditClub.show();
      }
      if ($event === this.EXPORT_CLUBS) {
        api().serverURL('/cst/clubs/clubs.csv').then((url) => {
          window.open(url, '_blank');
        });
      }
      if ($event === this.EXPORT_CLUB_CONTACTS) {
        api().serverURL('/cst/club_contacts.csv').then((url) => {
          window.open(url, '_blank');
        });
      }
      if ($event === this.ADD_CLUB_CONTACT) {
        this.$refs.addClubContact.showModal();
      }
    },
    tabClicked($event) {
      if ($event === this.activeTab) return;
      const map = {
        'Club Contacts': 'ClubContacts',
      };
      const route = map[$event] || 'clubs';
      this.$router.push({ name: route });
    },
    canAccess(depts) {
      return this.$store.getters.canAccess(depts);
    },
    loadClubs() {
      this.clubs = this.$store.getters.clubs;
    },
    addEditClubDismissed(options) {
      if (options.refreshData) {
        // reload in store
        this.$refs.clubsView.hasPopulatedLegalEntityDetails = false;
        this.$refs.clubsView.activeTab = 0;
        this.$store.dispatch('searchClubs').then(() => this.loadClubs());
      }
    },
    addClubContactDismissed() {
      this.$refs.clubContacts.fetchContacts();
    },
  },
};
</script>
