<template>
  <div>
    <spinner v-if="loading" />
    <FullScreenModal v-else ref="modal">
      <template slot="header">
        <FormModalHeader
          :title="editing ? 'Edit Underwriting Plan' : 'New Underwriting Plan'"
          :wide-form="true"
          :actions="modalActions"
          @dismiss="dismiss"
        >
          <template slot="save">
            <b-button type="is-primary" @click="save">
              <span v-if="editing">Save Changes</span>
              <span v-else>Create Plan</span>
            </b-button>
          </template>
          <template slot="archive">
            <b-button icon-left="minus-hexagon"
              outlined
              type="is-danger"
              @click="archive(clubData)">
              Archive Plan
            </b-button>
          </template>
          <template slot="restore">
            <b-button class="is-ghost"
              icon-left="redo"
              outlined
              @click="restore(clubData)">
              Restore Plan
            </b-button>
          </template>
        </FormModalHeader>
      </template>
      <div class="centered-tool plan-form">
        <div class="plan-form__section">
          <p class="plan-form__section__label">Underwriting Details</p>
          <div class="columns">
            <div class="column is-one-third">
              <b-field :type="(!submitFlag || clubData) ? '' : 'is-danger'"
                label="Club">
                <b-select v-if="editing"
                  v-model="clubData.name"
                  disabled
                  expanded>
                  <option>
                    {{ clubData.name }}
                  </option>
                </b-select>
                <b-autocomplete expanded
                  v-else
                  v-model="clubDataName"
                  placeholder="Search for Club"
                  :data="clubSearchFilter"
                  field="name"
                  @select="assignPlan($event)">
                </b-autocomplete>
              </b-field>
              <div v-if="submitFlag && !clubData.club_id">
                <span class="help is-danger">
                  This is a required field
                </span>
              </div>
            </div>
            <div class="column is-one-third">
              <b-field :type="(!submitFlag || selectedVendor) ? '' : 'is-danger'" label="Processing Vendor">
                <b-select v-model="selectedVendor" disabled expanded>
                  <option value="Stripe">Stripe</option>
                </b-select>
              </b-field>
              <div v-if="submitFlag && !selectedVendor">
                <span class="help is-danger">
                  This is a required field
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <b-field label="Sales Rep">
              <b-select value="0" disabled expanded>
                <option value="0">
                  {{ userName(clubData.sales_user_id) }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Lead Staff CS Member">
              <b-select value="0" disabled expanded>
                <option value="0">
                  {{ userName(clubData.lead_cs_user_id) }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Secondary Staff CS Member">
              <b-select value="0" disabled expanded>
                <option value="0">
                  {{ userName(clubData.secondary_cs_user_id) }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <div class="centered-tool plan-form">
        <div class="plan-form__section">
          <p class="plan-form__section__label">Key Dates</p>
          <div class="columns">
            <div class="column is-one-third">
              <b-field :type="!dateInvalid || !minDateWarning ? ((!submitFlag || clubData.due_date)
              ? (!showWarning ? '' : 'is-warning-dark') : 'is-danger') : 'is-warning-dark'"
                label="Approval Due Date">
                  <DatePickerString
                    v-model="clubData.due_date"
                    :icon-right="dateIcon"
                    placeholder ="mm/dd/yyyy"
                    @input="targetFocusDate()"
                    editable/>
              </b-field>
              <div v-if="minDateWarning">
                <span class="help is-danger">
                  Invalid Date
                </span>
              </div>
              <div v-else-if="dateInvalid">
                <span class="help is-warning-dark">
                  Approval Due Date is after the the Paid Registration Target Date
                </span>
              </div>
              <div v-else-if="showWarning">
                <span class="help is-warning-dark">Approval Date is within 2 weeks of Target Due Date</span>
              </div>
              <div v-else-if="(submitFlag && !clubData.due_date)">
                <span class="help is-danger">
                  This is a required field
                </span>
              </div>
            </div>
            <div class="column is-one-third">
              <b-field :type="!dateInvalid ? ((!submitFlag || clubData.target_date)
              ? '' : 'is-danger') : 'is-warning-dark'"
                label="Paid Registration Target Due Date">
                <DatePickerString
                  v-model="clubData.target_date"
                  icon-right="calendar"
                  placeholder="mm/dd/yyyy"
                  editable/>
              </b-field>
              <div v-if="submitFlag && !clubData.target_date">
                <span class="help is-danger">
                  This is a required field
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="centered-tool plan-form">
        <div class="plan-form__section">
          <p class="plan-form__section__label">Underwriting Review Details</p>
          <div class="columns">
            <div class="column is-half">
              <b-field :type="(!submitFlag || clubData.underwriting_status) ? '' : 'is-danger'"
                label="Underwriting Status">
                <b-select label="Underwriting Status" v-model="clubData.underwriting_status">
                  <option value="Not Submitted">Not Submitted</option>
                  <option value="In Review">In Review</option>
                  <option value="Approved">Approved</option>
                  <option value="Canceled">Canceled</option>
                </b-select>
              </b-field>
              <div v-if="submitFlag && !clubData.underwriting_status">
                <span class="help is-danger">
                  This is a required field
                </span>
              </div>
            </div>
          </div>
          <div>
            <UnderwritingNotes ref="underwritingNotes"
              @noteCreated="noteCreated"
              @noteDeleted="noteDeleted"
              :plan="clubData" />
          </div>
        </div>
      </div>
    </FullScreenModal>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import spinner from '@/components/common/Spinner';
import FormModalHeader from '@/components/layout/modals/FormModalHeader';
import DatePickerString from '@/components/common/form-elements/DatePickerString';
import UnderwritingNotes from '@/components/underwriting/UnderwritingNotes';
import api from '@/http-playmetrics';

export default {
  components: { FullScreenModal, spinner, FormModalHeader, DatePickerString, UnderwritingNotes },
  props: {
    clubPull: { type: Array, default: () => [] },
    totalData: { type: Array, default: () => [] },
  },
  data() {
    return {
      clubDataName: '',
      onboardingPlans: [],
      merchAccounts: [],
      loading: false,
      editing: false,
      selectedVendor: 'Stripe',
      submitFlag: false,
      clubData: {
        id: '',
        club_id: '',
        archived_at: '',
        created_by_user_id: '',
        due_date: '',
        live_target_date: '',
        status: '',
        kyc_status: '',
        vendor: 'Stripe',
        sales_user_id: '',
        lead_cs_user_id: '',
        secondary_cs_user_id: '',
        notes: '',
      },
    };
  },
  computed: {
    minDateWarning() {
      return moment(this.clubData.due_date).isBefore(this.minDate) || moment(this.clubData.target_date).isBefore(this.minDate);
    },
    minDate() {
      return moment('1/10/2018', 'MM-DD-YYYY');
    },
    dateIcon() {
      return this.clubData.due_date || !this.submitFlag ? 'calendar' : 'exclamation';
    },
    clubSearchFilter() {
      return this.clubPullFilter.filter(option =>
        option.name.toString().toLowerCase().indexOf(this.clubDataName.toLowerCase()) >= 0);
    },
    onboardingPlan() {
      return this.onboardingPlans.find(plan => plan.club_id === this.clubData.club_id);
    },
    storedUsers() {
      return this.$store.getters.users;
    },
    storedClubs() {
      return this.$store.getters.clubs;
    },
    dateInvalid() {
      return moment(this.clubData.due_date).isAfter(moment(this.clubData.target_date));
    },
    clubPullFilter() {
      return _.sortBy(this.storedClubs.filter(club => club.registration_url && !(club.is_test_club || club.is_deleted) && this.merchAccounts.find(mClub => mClub.club_id === club.id && mClub.vendor === 'Stripe') &&
        !this.totalData.find(tClub => tClub.club_id === club.id))).map(club => ({
        club_id: club.id,
        name: club.name,
        due_date: null,
        target_date: null,
        underwriting_status: null,
        kyc_status: 'Approved',
        sales_user_id: club.config.sales_contact_user_id,
        lead_cs_user_id: this.onboardingPlans.find(plan => plan.club_id === club.id)?.cs_lead_user_id || '',
        secondary_cs_user_id: this.onboardingPlans.find(plan => plan.club_id === club.id)?.cs_secondary_user_id || '',
        vendor: 'Stripe',
      }));
    },
    invalidFields() {
      return (!this.clubData.due_date || !this.clubData.target_date ||
        !this.clubData.underwriting_status || !this.selectedVendor || this.minDateWarning);
    },
    modalActions() {
      if (this.editing && !this.clubData.archived_at) {
        return ['archive', 'save'];
      } else if (this.editing && this.clubData.archived_at) {
        return ['restore', 'save'];
      }
      return ['save'];
    },
    showWarning() {
      const a = moment(this.clubData.due_date);
      const b = moment(this.clubData.target_date);
      if (a === null || b === null) {
        return false;
      }
      if (Math.abs(a.diff(b, 'days')) <= 13) {
        return true;
      } else {
        return false;
      }
    },
    toSave() {
      return {
        club_id: this.clubData.club_id,
        due_date: this.clubData.due_date,
        live_target_date: this.clubData.target_date,
        status: this.clubData.underwriting_status,
        kyc_status: this.clubData.kyc_status ? this.clubData.kyc_status : null,
        vendor: this.clubData.vendor,
      };
    },
  },
  methods: {
    assignPlan(plan) {
      if (plan) {
        this.clubData = plan;
      } else {
        this.clubData = {
          id: '',
          club_id: '',
          archived_at: '',
          created_by_user_id: '',
          due_date: '',
          live_target_date: '',
          status: '',
          kyc_status: '',
          vendor: 'Stripe',
          sales_user_id: '',
          lead_cs_user_id: '',
          secondary_cs_user_id: '',
          notes: '',
        };
      }
    },
    targetFocusDate() {
      if (!this.clubData.target_date) {
        this.clubData.target_date = moment(this.clubData.due_date, 'YYYY-MM-DD').add(14, 'days').format('YYYY-MM-DD');
      }
    },
    restore(club) {
      this.$confirm.showModal({
        confirmText: 'Yes, Restore Plan',
        message: `You are about to restore the underwriting plan for <strong>${club.name}</strong>.
                  <br><br>
                  Are you sure you want to continue?`,
        title: 'Restore Underwriting Plan',
        type: 'is-primary',
        icon: 'redo',
        onConfirm: () => {
          api().post(`/cst/underwritings/${club.id}/unarchive`).then(() => {
            this.$store.commit('setNotification', { toast: true, success: true, message: 'Plan unarchived' });
            this.dismiss({ refreshData: true });
          });
        },
      });
    },
    postUnderwritingNotes(id) {
      this.$refs.underwritingNotes.submitNote(id);
    },
    fetchOnboardingPlans() {
      api().post('cst/club_onboarding_plans/search').then((res) => {
        if (res.status === 200) {
          this.onboardingPlans = res.data;
        }
      });
    },
    userName(id) {
      const userData = this.storedUsers.find(user => user.id === id);
      return `${userData?.first_name || ''} ${userData?.last_name || ''}`;
    },
    noteCreated() {
      this.$emit('noteCreated');
    },
    noteDeleted() {
      this.$emit('noteDeleted');
    },
    clubName(id) {
      return this.$store.getters.clubs.find(club => club.id === id)?.name || '';
    },
    approve(clubUrl) {
      const showMembers = this.clubData.secondary_cs_user_id || this.clubData.lead_cs_user_id || this.clubData.sales_user_id;
      const salesMember = !!this.clubData.sales_user_id;
      const csMembers = this.clubData.secondary_cs_user_id || this.clubData.lead_cs_user_id;
      this.$confirm.showModal({
        confirmText: 'Yes, Approve',
        message: `Are you sure you want to approve the underwriting for <strong>${this.clubData.name}</strong>?
                  ${showMembers ? `This will notify the club's ${salesMember && csMembers ? 'sales rep and CS staff members.' : ''}${salesMember && !(salesMember && csMembers) ? 'sales rep.' : ''}${csMembers && !(salesMember && csMembers) ? 'CS staff members.' : ''}` : ''}`,
        title: 'Approve Underwriting',
        icon: 'check',
        type: 'is-success',
        onConfirm: () => {
          this.postPlan(clubUrl);
        },
      });
    },
    fetchMerchantAccounts() {
      api().post('cst/merchant_accounts/search').then((res) => {
        if (res.status === 200) {
          this.merchAccounts = res.data;
        }
      });
    },
    archive(club) {
      this.$confirm.showModal({
        confirmText: 'Yes, Archive Plan',
        message: `You are about to archive <strong>${this.clubName(club.club_id)}'s</strong> underwriting plan.
                  <br><br>
                  Are you sure you want to archive <strong>${this.clubName(club.club_id)}'s</strong> underwriting plan?`,
        title: 'Archive Underwriting Plan',
        type: 'is-danger',
        icon: 'minus-hexagon',
        onConfirm: () => {
          api().post(`/cst/underwritings/${club.id}/archive`).then(() => {
            this.$store.commit('setNotification', { toast: true, success: true, message: 'Plan archived' });
            this.dismiss({ refreshData: true });
          });
        },
      });
    },
    postPlan(clubUrl) {
      api().post(clubUrl, this.toSave).then((res) => {
        this.postUnderwritingNotes(res.data.id);
        this.$store.commit('setNotification', { toast: true, success: true, message: 'Plan updated' });
        this.dismiss({ refreshData: true });
      });
    },
    save() {
      if (this.invalidFields) {
        this.submitFlag = true;
      } else if (this.editing) {
        this.saveExisting(`/cst/underwritings/${this.clubData.id}`);
      } else {
        this.saveNew('/cst/underwritings/');
      }
    },
    saveNew(clubUrl) {
      if (this.toSave.status === 'Approved') {
        this.approve(clubUrl);
      } else {
        this.postPlan(clubUrl);
      }
    },
    saveExisting(clubUrl) {
      if (this.toSave.status === 'Approved') {
        this.approve(clubUrl);
      } else {
        this.postPlan(clubUrl);
      }
    },
    async dismiss(options = { refreshData: false }) {
      this.$refs.modal.dismiss();
      await this.$emit('dismissed', options);
    },
    show(plan) {
      this.loading = true;
      if (plan) {
        this.editing = true;
        this.clubData = this.clubPull.find(club => club.club_id === plan.club_id);
      } else {
        this.fetchOnboardingPlans();
        this.fetchMerchantAccounts();
        this.editing = false;
        this.clubData = {
          club_id: null,
          id: null,
          name: null,
          due_date: null,
          target_date: null,
          underwriting_status: null,
          kyc_status: null,
        };
        this.clubDataName = '';
        this.submitFlag = false;
      }
      this.$refs.modal.show();
      this.loading = false;
    },
  },
};
</script>

<style lang="sass">
.plan-form
  &__section
    margin-bottom: 3rem
    &__label
      font-family: $family-headers
      font-weight: $weight-semibold
      font-size: $size-4
      margin-bottom: 1rem
    &__sub-label
      font-family: $family-headers
      font-weight: $weight-semibold
      font-size: $size-5
      margin-bottom: .75rem
    &__sub-selection
        margin-bottom: 1.5rem !important
</style>
