<template>
  <nav class="nav level is-mobile">
    <div class="level-left">
      <div>
        <div class="header-title">{{ title }}</div>
        <div>
          <span class="header-num-selected">{{ numSelected }}</span>
          <span class="header-type">{{ type }}</span>
        </div>
      </div>
    </div>
    <div class="level-right">
      <button
        class="level-item button is-inverted is-outlined is-primary"
        @click="handleDismissModal">
        Cancel
      </button>
      <button
        :disabled="disabledSave"
        class="level-item button is-inverted is-primary"
        @click="handleSave">
        {{ saveText }}
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    numSelected: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    disabledSave: {
      type: Boolean,
      default: false,
    },
    saveText: {
      type: String,
      default: 'Save Selection',
    },
  },
  methods: {
    handleDismissModal() {
      this.$emit('dismissModal');
    },
    handleSave() {
      this.$emit('handleSave');
    },
  },
};
</script>

<style lang="sass" scoped>
.nav
  background: $primary
  padding: 1rem 2rem
  .mobile
    padding: 1rem
  .level-left
    flex: 1
  .header-title
    color: $white
    font-weight: $weight-bold
    font-family: $family-headers
    font-size: $size-5
  .header-num-selected
    color: $white
    font-weight: $weight-bold
    font-family: $family-headers
    background-color: rgba($black, 0.4)
    border-radius: 20px
    padding: 0.1rem .7rem
    margin-right: .5rem
  .header-type
    color: $white
    opacity: 0.6
</style>
