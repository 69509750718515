const getters = {
  onboardingCategory() {
    return [
      { name: 'Setup', value: 'setup' },
      { name: 'Registration', value: 'registration' },
      { name: 'Operations', value: 'operations' },
      { name: 'Website', value: 'website' },
      { name: 'Meeting', value: 'meeting' },
    ];
  },
  viewByUserSchedule() {
    return [
      { name: 'Primary', value: 'primary' },
      { name: 'Secondary', value: 'secondary' },
      { name: 'Overall', value: 'overall' },
    ];
  },
};

const mutations = {
  setUserSchedule(state, payload) {
    state.userSchedule = payload;
  },
};

export default {
  getters,
  mutations,
};
