<template>
  <div>
    <PageNavigation page-title="Users" :ctas="ctas"  @ctaClicked="cta($event)" />
    <TablePaginated name="users"
      :data="filteredUsers"
      :filterable=false
      @queryUpdated="searchQuery=$event"
    >
      <template slot-scope="props">
        <b-table-column
          header-class="is-unselectable"
          label="ID"
          field="id"
          sortable>
          {{ props.row.id }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Email"
          field="email"
          sortable>
          {{ props.row.email }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="First Name"
          field="first_name"
          sortable>
          {{ props.row.first_name }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Last Name"
          field="last_name"
          sortable>
          {{ props.row.last_name }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Department"
          field="department"
          :custom-sort="sortByDepartment"
          sortable>
          {{ displayDepartment(props.row.admin_permissions) | capitalize }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Is Admin?"
          field="is_admin"
          :custom-sort="sortByIsAdmin"
          sortable>
          {{ displayIsAdmin(props.row.admin_permissions) }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          v-if="$store.getters.hasAdminToolAccess"
          centered
          label="Actions"
          field="actions">
          <a style="color: inherit" @click="editUser(props.row)">
          <fa-icon :icon="['fas', 'pencil']" />
          </a>
        </b-table-column>
      </template>
      <template slot="empty">
        <spinner v-if="loading"/>
        <section v-else class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Users Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <AdminPermissionsModal @dismiss="dismissPermissionsModal($event)" ref="editAdminPermissions"/>
  </div>
</template>

<script>
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TablePaginated from '@/components/layout/TablePaginated';
import Spinner from '@/components/common/Spinner';
import CONFIG from '@/env-config';
import AdminPermissionsModal from '@/components/users/AdminPermissionsModal';

export default {
  components: { TablePaginated, PageNavigation, Spinner, AdminPermissionsModal },
  data() {
    return {
      searchQuery: '',
      users: [],
      loading: false,
    };
  },
  computed: {
    adminPermissions() {
      return this.$store.getters.adminPermissions;
    },
    cstDeptPermissions() {
      return this.adminPermissions.filter(permission => permission.includes('cst_tool.dept_'));
    },
    departments() {
      return this.cstDeptPermissions.map(permission => permission.split('cst_tool.dept_')[1])
        .map(dept => dept.charAt(0).toUpperCase() + dept.slice(1));
    },
    filteredUsers() {
      return this.users.filter(user => this.matchesUserName(user, this.searchQuery)
        || this.matchesEmail(user, this.searchQuery) || this.matchesUserId(user, this.searchQuery));
    },
    ADD_USER() { return 'Add User'; },
    ctas() {
      const ret = [];
      if (this.$store.getters.hasAdminToolAccess) {
        ret.push(this.ADD_USER);
      }
      return ret;
    },
  },
  mounted() {
    this.users = this.$store.getters.users;
  },
  methods: {
    dismissPermissionsModal(options) {
      if (options.refreshData) {
        this.refresh();
      }
    },
    editUser(rowData) {
      const userData = {
        id: rowData.id,
        email: rowData.email,
        admin_permissions: rowData.admin_permissions,
      };
      this.$refs.editAdminPermissions.show(userData);
    },
    refresh() {
      this.loading = true;
      this.$store.dispatch('fetchUsers').then(() => {
        this.users = this.$store.getters.users;
        this.loading = false;
      });
    },
    displayIsAdmin(permissions) {
      return permissions.includes('cst_tool') ? 'Yes' : 'No';
    },
    displayDepartment(permissions) {
      const departments = permissions.filter(permission => permission.includes('cst_tool.dept_'));
      return departments.map(department => department?.split('cst_tool.dept_')[1]).join(', ');
    },
    cta($event) {
      if ($event === this.ADD_USER) {
        const url = `${CONFIG.adminURL}admin-users`;
        window.open(url, '_blank');
      }
    },
    matchesUserName(user, searchQuery) {
      const userName = `${user.first_name} ${user.last_name}`;
      return userName.toLowerCase().includes(searchQuery.toLowerCase());
    },
    matchesEmail(user, searchQuery) {
      return user.email.toLowerCase().includes(searchQuery.toLowerCase());
    },
    matchesUserId(user, searchQuery) {
      return user.id.toString().includes(searchQuery);
    },
    editUserUrl(id) {
      return `${CONFIG.adminURL}users/${id}`;
    },
    sortByDepartment(a, b, isAsc) {
      if (this.displayDepartment(a.admin_permissions) > this.displayDepartment(b.admin_permissions)) return isAsc ? 1 : -1;
      if (this.displayDepartment(a.admin_permissions) < this.displayDepartment(b.admin_permissions)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByIsAdmin(a, b, isAsc) {
      if (this.displayIsAdmin(a.admin_permissions) > this.displayIsAdmin(b.admin_permissions)) return isAsc ? 1 : -1;
      if (this.displayIsAdmin(a.admin_permissions) < this.displayIsAdmin(b.admin_permissions)) return isAsc ? -1 : 1;
      return 0;
    },
  },
};



</script>
