<template>
  <div>
    <div class="notes">
      <b-field
        :type="noteInvalid ? 'is-danger' : ''"
        label="Notes">
        <b-input v-model="noteText" type="textarea" />
      </b-field>
      <div class="notes__flex">
        <div v-if="noteText.length > 3900">
          <span :class="noteInvalid ? 'notes__note-invalid' : ''">
            {{ noteText.length }}
          </span> / 4000
        </div>
        <b-button :disabled="!canSave"
          @click="addNote"
          class="notes__button"
          type="is-primary">Add Note</b-button>
      </div>
      <div v-if="editing">
        <template  v-for="note in orderedNotes">
          <Note :note="note"
            :key="note.id"
            :activities="[activity]"
            @delete="confirmDeleteNote($event)" />
        </template>
      </div>
      <div v-else>
        <template  v-for="note in newNotes">
          <Note :note="note"
            :key="note.created_at"
            :activities="[activity]"
            @delete="confirmDeleteNote($event)" />
        </template>
      </div>

    </div>
  </div>
</template>

<script>
import Note from '@/components/common/notes/Note';
import api from '@/http-playmetrics';

export default {
  components: { Note },
  props: {
    activity: { type: Object, default: (() => {}) },
  },
  data() {
    return {
      newNotes: [],
      noteText: '',
    };
  },
  computed: {
    noteInvalid() {
      return this.noteText.length > 4000;
    },
    canSave() {
      return !this.noteInvalid && this.noteText.trim() !== '';
    },
    orderedNotes() {
      if (this.notes) {
        const notesCopy = [...this.notes];
        return notesCopy.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      }
      return [];
    },
    notes() {
      return this.activity.notes;
    },
    activityID() {
      return this.activity.id;
    },
    editing() {
      return !!this.activityID;
    },
  },
  methods: {
    deleteNewNotes() {
      this.newNotes.forEach(note => this.deleteNote(note));
    },
    async addNote() {
      this.newNotes.push({ note: this.noteText, created_at: (new Date()).toISOString(), newNote: true });
      if (this.editing) {
        await this.submitNote(this.activityID);
      }
      this.noteText = '';
    },
    confirmDeleteNote($event) {
      this.$confirm.showModal({
        confirmText: 'Yes, Delete Note',
        message: 'Are you sure you want to delete this note?',
        title: 'Delete Underwriting Note',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => {
          if (this.editing) {
            this.deleteNote($event);
          } else {
            this.newNotes.pop();
          }
        },
      });
    },
    deleteNote($event) {
      if (this.editing && !$event?.newNote) {
        api().delete(`/cst/activities/${this.activity?.id}/notes/${$event.id}/`).then((res) => {
          if (res.status === 200) {
            this.$emit('noteDeleted', this.activityID);
          }
        });
      } else {
        this.notes = this.notes.filter(note => note.created_at !== $event.created_at);
      }
    },
    submitNote(id) {
      const notesCopy = this.newNotes.filter(note => !note.id).map(n => ({
        note: n.note,
      }));
      for (let i = 0; i < notesCopy.length; i += 1) {
        api().post(`/cst/activities/${id}/notes`, notesCopy[i]).then((res) => {
          if (res.status === 200) {
            this.newNotes.push(res.data);
            this.$emit('noteCreated', id);
          }
        });
      }
      this.newNotes = this.newNotes.filter(note => note.id);
    },
  },
};
</script>

<style lang="sass" scoped>
.notes
  &__button
    display: block
    margin-left: auto
    margin-right: 0
  &__flex
    display: flex
  &__note-:invalid
    color: $red
</style>
