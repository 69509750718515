<template>
  <div class="columns is-centered has-text-centered">
    <div class="column is-half-tablet is-one-third-desktop">
      <div
        style="margin-top:4rem"
        class="box content">
        <h2 class="has-text-weight-light">Login</h2>
        <p v-if="error" class="has-text-danger">{{ error.message }}</p>
        <div class="field">
          <p class="control">
            <input
              class="button is-primary is-fullwidth is-medium"
              @click="loginWithGoogle"
              value="Login with Google">
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
    };
  },

  computed: {
    error() {
      return this.$store.getters.notification;
    },
  },

  created() {
  },

  methods: {
    loginWithGoogle() {
      this.$store.dispatch('loginGoogle');
    },
  },
};
</script>
