<template>
  <div>
    <p class="view-label" style="margin-bottom: 0.5rem">{{ label }}</p>
    <div
      v-if="options.length === 1"
      class="view-name"
    >
      {{ dropdownValue }}
    </div>
    <b-dropdown
      v-else
      v-model="dropdownValue"
      :mobile-modal="false"
    >
      <a slot="trigger" class="view-name">
        {{ dropdownValue }}
        <fa-icon
          :icon="['fas', 'caret-down']"
          class="is-size-16px"
          transform="right-5"
        />
      </a>
      <b-dropdown-item
        v-for="option in options"
        :key="option"
        :value="option"
      >
        {{ option }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, required: true },
    options: { type: Array, required: true },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    label() {
      if (this.options.length === 1) {
        return 'Viewing';
      }
      return 'View By';
    },
    dropdownValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.currentValue = value;
        this.$emit('input', this.currentValue);
      },
    },
  },
};
</script>

<style lang="sass" scoped>
.view-label
  font-family: $family-headers
  font-weight: $weight-medium
  font-size: $size-7
  color: $blue-slate
.view-name
  font-family: $family-headers
  font-weight: $weight-semibold
  color: $black
  font-size: 1.15rem
</style>
