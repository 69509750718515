<template>
  <div>
    <FilterTray
      title="Filter Plans"
      :filters-active="filtersActive"
      @clear-filters="clearFilters"
    >
      <FilterSection label="Overall Status" collapsible default-open>
        <b-field v-for="s in planStatuses" :key="s.value">
          <b-checkbox v-model="filters.status" :native-value="s.value">
            {{s.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Customer Health" collapsible default-open>
        <b-field v-for="h in onboardingHealth" :key="h.value">
          <b-checkbox v-model="filters.health_status" :native-value="h.value">
            {{h.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Customer Success Staff" collapsible default-open>
        <b-field v-for="s in csStaff" :key="s.id">
          <b-checkbox v-model="filters.cs_staff" :native-value="s.id">
            {{s.first_name}} {{s.last_name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Club Size" collapsible>
        <b-field v-for="s in sizes" :key="s.value">
          <b-checkbox  v-model="filters.club_size" :native-value="s.value"> {{s.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Club Sports" collapsible>
        <b-field v-for="s in sports" :key="s.value">
          <b-checkbox v-model="filters.club_sport"  :native-value="s.value">{{s.name}}</b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Sales Rep" collapsible>
        <b-field v-for="sc in salesContacts" :key="sc.id">
          <b-checkbox
            v-model="filters.sales_contact_user_id"
            :native-value="sc.id">{{ `${sc.first_name} ${sc.last_name}` }}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Onboarding Type" collapsible>
        <b-field v-for="ot in onboardingTypes" :key="ot.value">
          <b-checkbox v-model="filters.onboarding_type" :native-value="ot.value">
            {{ot.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Setup Status" collapsible>
        <b-field v-for="s in planStatuses" :key="s.value">
          <b-checkbox v-model="filters.setup_status" :native-value="s.value">
            {{s.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Registration Status" collapsible>
        <b-field v-for="s in registrationStatuses" :key="s.value">
          <b-checkbox v-model="filters.registration_status" :native-value="s.value">
            {{s.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Operations Status" collapsible>
        <b-field v-for="s in planStatuses" :key="s.value">
          <b-checkbox v-model="filters.operations_status" :native-value="s.value">
            {{s.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Website Status" collapsible>
        <b-field v-for="s in websiteStatuses" :key="s.value">
          <b-checkbox v-model="filters.website_status" :native-value="s.value">
            {{s.name}}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Advanced" collapsible>
        <b-switch v-model="filters.show_archived_players">
          Show Archived Plans
        </b-switch>
      </FilterSection>
    </FilterTray>
  </div>
</template>
<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterSection,
    FilterTray,
  },
  props: {
    filtersActive: { type: Boolean, default: false },
  },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('onboardingPlans')),
    };
  },

  computed: {
    types() {
      return this.$store.getters.clubTypes;
    },
    sports() {
      return this.$store.getters.clubSports;
    },
    sizes() {
      return this.$store.getters.clubSizes;
    },
    salesContacts() {
      // can change to pass type param once thats in
      return this.$store.getters.users.filter(u => u.admin_permissions.includes('cst_tool.dept_sales'))
        .filter(staff => staff.first_name || staff.last_name);
    },
    onboardingHealth() {
      return this.$store.getters.onboardingHealthStatus;
    },
    planStatuses() {
      return this.$store.getters.onboardingPlanStatuses;
    },
    registrationStatuses() {
      return this.$store.getters.onboardingPlanRegistrationStatuses;
    },
    websiteStatuses() {
      return this.$store.getters.onboardingPlanWebsiteStatuses;
    },
    onboardingTypes() {
      return this.$store.getters.clubOnboardingTypes;
    },
    csStaff() {
      return this.$store.getters.usersByDepartments(['product', 'success'])
        .filter(staff => staff.first_name || staff.last_name);
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'onboardingPlans' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('onboardingPlans'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'onboardingPlans', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>
