<template>
  <b-field
    :type="type"
    :message="message"
    :class="fieldClass">
    <b-input
      ref="inputMoney"
      v-model="strValue"
      :class="{'input-money-has-value': newValue != null, 'left-align': leftAlign, 'show-negative': showNegative }"
      type="text"
      :icon="icon"
      :icon-right="iconRight"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      :has-counter="false"
      class="input-money"
      inputmode="decimal"
      @keydown.native="ignoreNonNumeric($event)"
      @focus="handleFocus"
      @blur="valueChanged"/>
  </b-field>
</template>
<script>
import { parseInputAsCents, parseInputAsPercent } from '@/models/Money';

export default {
  model: {
    prop: 'value',
  },
  props: {
    value: { type: Number, default: null },
    maxlength: { type: Number, default: null },
    hasIcon: { type: Boolean, default: true },
    asPercent: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    customPlaceholder: { type: String, default: null },
    leftAlign: { type: Boolean, default: false },
    message: { type: String, default: '' },
    type: { type: String, default: '' },
    showNegative: { type: Boolean, default: false },
    startNull: { type: Boolean, default: false },
    digits: { type: Number, default: 0 },
  },
  data() {
    return {
      newValue: this.value,
      strValue: this.value === 0 && this.startNull ? '' : this.intToDisplay(this.value),
      isFocused: false,
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    icon() {
      return (this.hasIcon && !this.asPercent) ? 'dollar-sign' : null;
    },
    iconRight() {
      return (this.hasIcon && this.asPercent) ? 'percent' : null;
    },
    placeholder() {
      if (this.customPlaceholder) return this.customPlaceholder;
      return this.asPercent ? '0' : '0.00';
    },
    fieldClass() {
      return !this.asPercent ? 'field-hide-right-icon' : '';
    },
  },
  watch: {
    value() {
      if (this.newValue !== this.value) {
        this.newValue = this.value;
        this.strValue = this.intToDisplay(this.value);
      }
    },
    newValue() {
      this.$emit('input', this.newValue);
    },
    strValue() {
      this.newValue = this.displayToInt(this.strValue);
    },
    asPercent() {
      this.strValue = null;
    },
  },
  methods: {
    intToDisplay(val) {
      if (val == null) return null;
      if (this.asPercent) {
        if (this.digits === 4) return (val / 10000.0).toString();
        return (val).toFixed(0);
      }

      // Currency (no symbol)
      const formatter = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2 });
      return formatter.format(val / 100.0);
    },
    displayToInt(val) {
      if (!val || !val.trim()) {
        return null;
      }
      if (this.asPercent) {
        const parsedVal = parseInputAsPercent(val, true);
        if (typeof parsedVal !== 'number') return parsedVal;
        if (this.digits === 4) return Math.round(10000 * parsedVal);
        return Math.round(parsedVal);
      }
      return parseInputAsCents(val);
    },
    valueChanged() {
      this.isFocused = false;
      if (!(this.value === 0 && this.startNull)) {
        this.strValue = this.intToDisplay(this.newValue);
      }

      this.$emit('blur');
    },
    ignoreNonNumeric($event) {
      const re = /[0-9.]/;
      if ($event.key && $event.key.length === 1 && !($event.metaKey || $event.ctrlKey) && !re.test($event.key)) {
        $event.preventDefault();
      }
    },
    focus() {
      // iOS does not open the keyboard on focus, only on user input. Mimic a click to get iOS to open the keyboard
      this.$refs.inputMoney.$el.addEventListener('click', this.doFocus);
      this.$refs.inputMoney.$el.click();
      this.$refs.inputMoney.$el.removeEventListener('click', this.doFocus);
    },
    doFocus() {
      this.$refs.inputMoney.focus();
    },
    handleFocus() {
      this.isFocused = true;
      this.$emit('focus');
    },
  },
};
</script>
<style lang="sass">
.input-money
  &.left-align
    input
      text-align: left
  input
    text-align: right
  .icon.is-left .fa-dollar-sign
    font-size: $size-5
  &.input-money-has-value
    .icon.is-left .fa-dollar-sign
      color: $success
  &.show-negative
    .icon.is-left .fa-dollar-sign
      &::before
        content: '-\f155'
  &.input-money-has-value
    .icon.is-right .fa-percent
      color: $primary
</style>
