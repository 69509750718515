<template>
  <FullScreenModal modal-class="club-form-modal" ref="modal">
    <template slot="header">
      <FormModalHeader
        :title="editing ? 'Edit Club' : 'New Club'"
        wide-form
        :actions="['save']"
        @dismiss="dismiss"
      >
        <template slot="save">
          <b-button type="is-primary" @click="save">
            <span v-if="editing">Save Club</span>
            <span v-else>Create Club</span>
          </b-button>
        </template>
      </FormModalHeader>
    </template>
    <div class="centered-tool club-form">
      <div class="club-form__section">
        <p class="club-form__section__label">Club Name</p>
        <div class="columns">
          <div class="column is-three-quarters">
            <b-field ref="name" :type="{'is-danger' : errors.name}" :message="errors.name">
              <b-input v-model="formData.name" @input="clearErrorFor('name')"/>
            </b-field>
            <b-field>
              <b-switch v-model="formData.is_test_club" :disabled="editing">This is a test club</b-switch>
            </b-field>
          </div>
        </div>
      </div>
      <div class="club-form__section">
        <p class="club-form__section__label">Club Details</p>
        <div class="columns">
          <div class="column is-narrow">
            <b-field ref="sport"
              label="Sport"
              :type="{'is-danger' : errors.sport}"
              :message="errors.sport">
              <b-select v-model="formData.sport" @input="clearErrorFor('sport')" :disabled="editing">
                <option
                  v-for="sport in clubSportOptions"
                  :value="sport.value"
                  :key="sport.value"
                >
                  {{ sport.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-narrow">
            <b-field ref="size"
              label="Size"
              :type="{'is-danger' : errors.size}"
              :message="errors.size">
              <b-select v-model="formData.size" @input="clearErrorFor('size')">
                <option
                  v-for="size in clubSizeOptions"
                  :value="size.value"
                  :key="size.value"
                  >
                  {{ size.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <b-field ref="status"
              label="Status"
              :type="{'is-danger' : errors.onboarding_status}"
              :message="errors.onboarding_status">
              <b-select v-model="formData.onboarding_status" @input="clearErrorFor('onboarding_status')">
                <option
                  v-for="status in clubOnboardingStatusOptions"
                  :key="status.value"
                  :value="status.value"
                >
                  {{ status.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <b-field ref="website"
              label="Website"
              :type="{'is-danger' : this.errors.website}"
              :message="this.errors.website">
              <b-input v-model="formData.website" @input="clearErrorFor('website')"/>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <b-field ref="email"
              label="Contract Name"
              :type="{'is-danger' : contractNameErrors}"
              :message="contractNameErrors">
              <b-input v-model="formData.contract_name" @input="clearErrorFor('contract_name')"/>
            </b-field>
          </div>
        </div>
      </div>
      <div class="club-form__section">
        <p ref="type" class="club-form__section__label" :class="{'has-text-danger': errors.type }">
          Select Club Type <span class="error" v-if="errors.type">Please make a club type selection</span>
        </p>
        <div
          class="box club-type-box"
          :class="{'box-selected-outlined': formData.type === 'prospect',
          'clickable': canSetTypeProspect,
          'disabled' : !canSetTypeProspect}"
          @click="setTypeProspect">
          <div class="columns">
            <div class="column is-narrow" style="margin-top:2px;padding-right:0">
              <b-radio
                :disabled="!canSetTypeProspect"
                v-model="formData.type"
                native-value="prospect" />
            </div>
            <div class="column" style="padding-left: 0">
              <p class="label-text">Prospect</p>
              <p class="description-text">A club that has not yet signed a contract (still in the sales cycle)</p>
            </div>
          </div>
          <div v-if="formData.type === 'prospect'">
            <div class="columns">
              <div class="column is-narrow"></div>
              <div class="column"><span class="label">Expected Contract Details:</span></div>
            </div>
            <div class='columns'>
              <div class="column is-narrow"></div>
              <div class="column is-one-fifth">
                <b-field label="Signature Date"
                  ref="sign_date"
                  :type="{'is-danger' : errors.sign_date}"
                  :message="errors.sign_date">
                  <DatePickerString
                    @blur="validateDate('sign_date')"
                    v-model="formData.sign_date"
                    icon-right="calendar"
                    placeholder="MM/DD/YYYY"
                    editable/>
                </b-field>
              </div>
              <div class="column is-one-quarter">
                <b-field label="Recreational Players"
                  ref="rec_player_cap"
                  :type="{'is-danger' : errors.rec_player_cap}"
                  :message="errors.rec_player_cap">
                  <b-input type='text'
                           :value="formData.rec_player_cap !== null ? formData.rec_player_cap.toLocaleString() : ''"
                           @input="setFromLocaleString('rec_player_cap', $event)"/>
                </b-field>
              </div>
              <div class="column is-one-quarter">
                <b-field label="Competitive Players"
                  ref="competitive_player_cap"
                  :type="{'is-danger' : errors.competitive_player_cap}"
                  :message="errors.competitive_player_cap">
                  <b-input type='text'
                           :value="formData.competitive_player_cap !== null ? formData.competitive_player_cap.toLocaleString() : ''"
                           @input="setFromLocaleString('competitive_player_cap', $event)"/>
                </b-field>
              </div>
            </div>
          </div>
        </div>
        <div
          class="box clickable club-type-box"
          :class="{'box-selected-outlined': formData.type === 'customer'}"
          @click="setTypeCustomer">
          <div class="columns">
            <div class="column is-narrow" style="margin-top:2px;padding-right:0">
              <b-radio
                v-model="formData.type"
                native-value="customer" />
            </div>

            <div class="column" style="padding-left: 0">
              <p class="label-text">Customer</p>
              <p class="description-text">A club that has already signed a contract</p>
            </div>
          </div>
          <div v-if="formData.type === 'customer'">
            <div class="columns">
              <div class="column is-narrow"></div>
              <div class="column">
                <span class="label">Current Contract Details:</span>
                <span v-if="!hasContract" class="has-text-danger">
                  No current contract found. This will create a new contract.
                </span>
              </div>
            </div>
            <div class='columns'>
              <div class="column is-narrow"></div>
              <div class="column is-one-fifth">
                <b-field ref="sign_date"
                  label="Date Signed"
                  :type="{'is-danger' : errors.sign_date}"
                  :message="errors.sign_date">
                  <DatePickerString
                    v-model="formData.sign_date"
                    icon-right="calendar"
                    @blur="validateDate('sign_date')"
                    placeholder="MM/DD/YYYY"
                    editable/>
                </b-field>
              </div>
              <div class="column is-one-fifth">
                <b-field ref="renew_date"
                  label="Renews On"
                  :type="{'is-danger' : errors.renew_date}"
                  :message="errors.renew_date">
                  <DatePickerString
                    v-model="formData.renew_date"
                    icon-right="calendar"
                    @blur="validateDate('renew_date')"
                    placeholder="MM/DD/YYYY"
                    editable/>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Recreational Players"
                  ref="rec_player_cap"
                  :type="{'is-danger' : errors.rec_player_cap}"
                  :message="errors.rec_player_cap">
                  <b-input type='text'
                           :value="formData.rec_player_cap !== null ? formData.rec_player_cap.toLocaleString() : ''"
                           @input="setFromLocaleString('rec_player_cap', $event)"/>
                </b-field>
              </div>
              <div class="column">
                <b-field label="Competitive Players"
                  ref="competitive_player_cap"
                  :type="{'is-danger' : errors.competitive_player_cap}"
                  :message="errors.competitive_player_cap">
                  <b-input type="text"
                           :value="formData.competitive_player_cap !== null ? formData.competitive_player_cap.toLocaleString() : ''"
                           @input="setFromLocaleString('competitive_player_cap', $event)"/>
                </b-field>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="club-form__section">
        <p ref="onboarding_type" class="club-form__section__label" :class="{'has-text-danger': errors.onboarding_type }">
          Select Onboarding Type
          <span class="error" v-if="errors.onboarding_type"> Please make an onboarding type selection</span>
        </p>
        <div
          v-for="onboardingType in clubOnBoardingTypeOptions"
          :key="onboardingType.value"
          class="box clickable club-onboarding-type-box"
          :class="{'box-selected-outlined': formData.onboarding_type === onboardingType.value}"
          @click="setClubOnboardingType(onboardingType.value)">
          <div class="columns">
            <div class="column is-narrow" style="margin-top:2px;padding-right:0">
              <b-radio
                v-model="formData.onboarding_type"
                @input="clearErrorFor(onboardingType.value)"
                :native-value="onboardingType.value" />
            </div>
            <div class="column" style="padding-left: 0">
              <p class="label-text">{{ onboardingType.name }}</p>
              <p class="description-text">{{ onboardingType.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="club-form__section">
        <p class="club-form__section__label">Primary Club Contact</p>
        <div class="columns">
          <div class="column">
            <b-field ref="first_name"
              label="First Name"
              :type="{'is-danger' : errors.first_name}"
              :message="errors.first_name">
              <b-input v-model="formData.first_name" @input="clearErrorFor('first_name')"/>
            </b-field>
          </div>
          <div class="column">
            <b-field ref="last_name"
              label="Last Name"
              :type="{'is-danger' : errors.last_name}"
              :message="errors.last_name">
              <b-input v-model="formData.last_name" @input="clearErrorFor('last_name')"/>
            </b-field>
          </div>
          <div class="column">
            <b-field
              ref="club_contact_title"
              label="Club Role"
              :type="{'is-danger' : errors.club_contact_title}"
              :message="errors.club_contact_title">
              <b-input v-model="formData.club_contact_title" @input="clearErrorFor('club_contact_title')"/>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-third">
            <b-field ref="email"
              label="Email Address"
              :type="{'is-danger' : errors.email}"
              :message="errors.email">
              <b-input v-model="formData.email" @input="clearErrorFor('email')"/>
            </b-field>
          </div>
        </div>
        <template v-if="formData.type === 'customer'">
          <b-button
            v-if="editing"
            :disabled="emailSent"
            class="is-text"
            @click="showWelcomeEmailModal">{{ emailSent ? 'Welcome Email Sent' : 'Send Welcome Email' }}</b-button>
          <b-checkbox v-else v-model="formData.send_register_email">Send Welcome Email</b-checkbox>
        </template>
      </div>
      <div class="club-form__section">
        <p class="club-form__section__label">Club Location</p>
        <div class="columns">
          <div class="column is-narrow">
            <b-field ref="country"
              label="Country"
              :type="{'is-danger' : errors.country}"
              :message="errors.country">
              <b-select v-model="formData.country" @input="formData.timezone = ''; clearErrorFor('country')">
                <option v-for="country in clubCountryOptions" :key="country" :value="country">
                  {{country}}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field ref="city"
              label="City"
              :type="{'is-danger' : errors.city}"
              :message="errors.city">
              <b-input v-model="formData.city" @input="clearErrorFor('city')"/>
            </b-field>
          </div>
          <div class="column">
            <b-field ref="state"
              label="State"
              :type="{'is-danger' : errors.state}"
              :message="errors.state">
              <b-select v-model="formData.state" @input="clearErrorFor('state')">
                <option v-for="state in clubStateOptions" :key="state.abbr" :value="state.abbr">
                  {{state.name}}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field ref="timezone"
              label="Timezone"
              :type="{'is-danger' : errors.timezone}"
              :message="errors.timezone">
              <b-select v-model="formData.timezone" @input="clearErrorFor('timezone')">
                <option v-for="tz in clubTimezoneOptions" :key="tz" :value="tz">
                  {{tz}}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>
      </div>
      <div class="club-form__section">
        <p class="club-form__section__label">Additional PlayMetrics Features</p>
        <div class="columns">
          <div class="column">
            <b-checkbox @input="formData.enable_financial_aid = $event"
                        v-model="formData.enable_program_administration">Registrations</b-checkbox>
          </div>
          <div class="column">
            <b-checkbox v-model="formData.enable_website_builder">Website Builder</b-checkbox>
          </div>
          <div class="column">
            <b-checkbox v-model="formData.enable_digital_documents">Digital Documents</b-checkbox>
            <div v-if="formData.enable_digital_documents" class="club-form__digital_docs">
              <b-field
                :type="{'is-danger': errors.pandadoc_contract_date }"
                :message="errors.pandadoc_contract_date"
                label="Pandadoc Contract Date">
                <DatePickerString
                  v-model="formData.pandadoc_contract_date"
                  placeholder="Click to select..."
                  @blur="clearErrorFor('pandadoc_contract_date')"
                />
              </b-field>
              <div class="club-form__digital_docs__flex">
                <b-field :type="{'is-danger': errors.pandadoc_contract_amount }"
                         :message="errors.pandadoc_contract_amount"
                         label="Contract Amount">
                  <b-input v-model="formData.pandadoc_contract_amount" @input="clearErrorFor('pandadoc_contract_amount')"/>
                </b-field>
              </div>
            </div>
          </div>
          <div v-if="hasSecondaryAccount" class="column">
            <b-checkbox :value="true" disabled>Team Accounts</b-checkbox>
            <div class="club-form__digital_docs">
              <b-field
                :type="{'is-danger': errors.team_accounts_contract_date }"
                :message="errors.team_accounts_contract_date"
                label="Contract Date">
                <DatePickerString
                  v-model="formData.team_accounts_contract_date"
                  placeholder="Click to select..."
                  @blur="clearErrorFor('team_accounts_contract_date')"
                />
              </b-field>
              <b-field :type="{'is-danger': errors.team_accounts_cards_contract_amount }"
                       :message="errors.team_accounts_cards_contract_amount"
                       label="Card Contract Amount">
                <b-input
                  v-model="formData.team_accounts_cards_contract_amount"
                  @input="clearErrorFor('team_accounts_cards_contract_amount')"/>
              </b-field>
              <b-field :type="{'is-danger': errors.team_accounts_contract_amount }"
                       :message="errors.team_accounts_contract_amount"
                       label="Account Contract Amount">
                <b-input
                  v-model="formData.team_accounts_contract_amount"
                  @input="clearErrorFor('team_accounts_contract_amount')"/>
              </b-field>
              <b-field :type="{'is-danger': errors.team_accounts_max_financial_accounts }"
                       :message="errors.team_accounts_max_financial_accounts"
                       label="Max Financial Accounts">
                <b-input
                  v-model="formData.team_accounts_max_financial_accounts"
                  @input="clearErrorFor('team_accounts_max_financial_accounts')"/>
              </b-field>
            </div>
          </div>
          <div class="column">
            <b-checkbox
              @input="formData.enable_third_party_insurance = $event"
              v-model="formData.enable_third_party_insurance"
            >
              Third Party Insurance
            </b-checkbox>
          </div>
        </div>
      </div>
      <div class="club-form__section">
        <p class="club-form__section__label">PlayMetrics Contacts</p>
        <b-field label="Sales Rep">
          <b-select v-model.number="formData.sales_contact_user_id">
            <option v-for="id in Object.keys(salesUsers)" :key="id" :value="id"> {{ salesUsers[id] }} </option>
          </b-select>
        </b-field>

      </div>
      <div class="club-form__section">
        <p class="club-form__section__label">Club Notes</p>
        <ClubNotes ref="clubNotes" :club="originalClub"/>
      </div>
    </div>
  </FullScreenModal>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/http-playmetrics';
import Utils from '@/models/Utils';
import FormModalHeader from '@/components/layout/modals/FormModalHeader';
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import DatePickerString from '@/components/common/form-elements/DatePickerString';
import ClubNotes from './ClubNotes';

const optionalFields = ['contract_name', 'pandadoc_contract_date','pandadoc_contract_amount','club_contact_title', 'website', 'sales_contact_user_id', 'send_register_email', 'enable_program_administration', 'enable_digital_documents', 'enable_website_builder', 'enable_financial_aid', 'enable_third_party_insurance','pandadoc_contract_amount', 'pandadoc_id', 'team_accounts_contract_date', 'team_accounts_cards_contract_amount', 'team_accounts_contract_amount', 'team_accounts_max_financial_accounts', 'merchant_account_id']; // eslint-disable-line
const optionalProspectFields = ['size', 'first_name', 'last_name', 'email', 'competitive_player_cap', 'renew_date', 'rec_player_cap', 'onboarding_status', 'onboarding_type'];
// use for scrolling field into view
const formOrder = ['name', 'sport', 'size', 'onboarding_status', 'website', 'type', 'renew_date', 'sign_date', 'rec_player_cap', 'competitive_player_cap', 'onboarding_type', 'first_name', 'last_name', 'email', 'country', 'city', 'state', 'timezone']; // eslint-disable-line
const initialState = () => (
  {
    noLegalEntityDetails: true,
    merchantAccountLegalEntityDetails: {},
    primaryMerchantAccountID: undefined,
    editing: false,
    originalClub: {},
    formData: {
      name: '',
      contract_name: '',
      sport: '',
      size: '',
      type: '',
      website: '',
      is_test_club: false,
      onboarding_type: '',
      onboarding_status: '',
      sales_contact_user_id: 0,
      club_contact_title: '',
      sign_date: '',
      renew_date: '',
      first_name: '',
      last_name: '',
      email: '',
      send_register_email: false,
      timezone: 'America/New_York',
      country: 'US',
      state: '',
      city: '',
      rec_player_cap: null,
      competitive_player_cap: null,
      enable_program_administration: false,
      enable_digital_documents: false,
      enable_website_builder: false,
      enable_financial_aid: false,
      enable_third_party_insurance: false,
      pandadoc_contract_date: new Date().toLocaleDateString(),
      pandadoc_contract_amount: '',
      pandadoc_id: null,
      team_accounts_contract_date: new Date().toLocaleDateString(),
      team_accounts_cards_contract_amount: '',
      team_accounts_contract_amount: '',
      team_accounts_max_financial_accounts: '',
      merchant_account_id: null,
    },
    errors: {},
    emailSent: false,
    hasContract: true,
    hasSecondaryAccount: false,
  });

export default {
  components: {
    FormModalHeader,
    FullScreenModal,
    DatePickerString,
    ClubNotes,
  },
  data() {
    return initialState();
  },
  computed: {
    contractNameErrors() {
      if (this.noLegalEntityDetails && this.editing) {
        return 'No Legal Entity Data Found';
      } else if (this.formData.contract_name !== this.merchantAccountLegalEntityDetails.name && this.editing) {
        return 'Contract Name Does Not Match Legal Entity Name';
      }
      return '';
    },
    salesUsers() {
      return this.$store.getters.salesUsers;
    },
    clubSportOptions() {
      return this.$store.getters.clubSports;
    },
    clubSportChanged() {
      const originalSport = this.originalClub.sports?.length && this.originalClub.sports[0].sport;
      return this.editing && (this.formData.sport !== originalSport);
    },
    digitalDocsChanged() {
      const originalDigitalDocs = this.originalClub.enable_digital_documents;
      return this.editing && (this.formData.enable_digital_documents !== originalDigitalDocs);
    },
    clubRenewDate() {
      return moment(this.formData.renew_date);
    },
    clubSignDate() {
      return moment(this.formData.sign_date);
    },
    setCustomerSince() {
      return (!this.editing && this.formData.type === 'customer') || (this.editing && this.originalClub.type === 'prospect' && this.formData.type === 'customer');
    },
    clubSizeOptions() {
      return this.$store.getters.clubSizes;
    },
    canSetTypeProspect() {
      return !(this.editing && this.originalClub.type === 'customer');
    },
    clubOnBoardingTypeOptions() {
      return this.$store.getters.clubOnboardingTypes;
    },
    clubOnboardingStatusOptions() {
      return this.$store.getters.clubOnboardingStatuses;
    },
    clubCountryOptions() {
      return ['US', 'CA'];
    },
    clubStateOptions() {
      return api().statesForCountry(this.formData.country || 'US');
    },
    clubTimezoneOptions() {
      return api().timezonesForCountry(this.formData.country || 'US');
    },
    toPostContract() {
      const ret = {
        sign_date: this.formData.sign_date,
        renew_date: this.formData.renew_date,
        rec_player_cap: this.formData.rec_player_cap,
        competitive_player_cap: this.formData.competitive_player_cap,
      };
      if (this.editing) {
        return Object.assign({ ...this.originalClub.contract }, ret);
      }
      return ret;
    },
    toPostSignup() {
      const ret = _.cloneDeep(this.formData);
      if (this.setCustomerSince) {
        ret.customer_since = ret.sign_date;
      }
      return ret;
    },
    toPostClub() {
      const ret = {
        name: this.formData.name,
        size: this.formData.size,
        type: this.formData.type,
        website: this.formData.website,
        contact_name: `${this.formData.first_name.trim()} ${this.formData.last_name.trim()}`,
        contact_email: this.formData.email,
        config: {
          country: this.formData.country,
          state: this.formData.state,
          city: this.formData.city,
          onboarding_type: this.formData.onboarding_type,
          onboarding_status: this.formData.onboarding_status,
          sales_contact_user_id: this.formData.sales_contact_user_id,
          club_contact_title: this.formData.club_contact_title,
          enable_program_administration: this.formData.enable_program_administration,
          enable_digital_documents: this.formData.enable_digital_documents,
          enable_website_builder: this.formData.enable_website_builder,
          enable_financial_aid: this.formData.enable_financial_aid,
          enable_third_party_insurance: this.formData.enable_third_party_insurance,
        },
      };
      if (this.setCustomerSince) {
        ret.config.customer_since = this.formData.sign_date;
      }
      return ret;
    },
    toPostPandaDoc() {
      return {
        enabled: true,
        fee_cents: 25,
        contract_date: this.formData.pandadoc_contract_date,
        annual_document_limit: parseInt(this.formData.pandadoc_contract_amount, 10),
      };
    },
    toPostAccount() {
      return {
        contract_date: this.formData.team_accounts_contract_date,
        num_cards_limit: parseInt(this.formData.team_accounts_cards_contract_amount, 10),
        num_accounts_limit: parseInt(this.formData.team_accounts_contract_amount, 10),
        max_financial_accounts: parseInt(this.formData.team_accounts_max_financial_accounts, 10),
      };
    },
    valid() {
      return _.isEmpty(this.errors);
    },
  },
  methods: {
    isRequiredField(key) {
      return !(optionalFields.includes(key) || (this.formData.type === 'prospect' && optionalProspectFields.includes(key)));
    },
    isEmptyField(key) {
      return (this.formData[key] === null ||
        this.formData[key] === undefined ||
        (typeof this.formData[key] === 'string' && Utils.normalizeString(this.formData[key]) === ''));
    },
    errorForField(key) {
      const numericalValueError = 'Please provide a numerical value';
      const value = this.formData[key];
      if (this.formData.type === 'prospect' && this.formData.email === '') return '';
      if (key === 'email' && !Utils.isValidEmailFormat(value)) {
        return 'Please provide a valid email address';
      }
      if (key === 'website' && Utils.normalizeString(value) !== '' && !Utils.isValidWebsiteUrl(value)) {
        return 'Please provide a valid url';
      }
      if (key !== 'sales_contact_user_id' && typeof value === 'number' && value < 0) {
        return numericalValueError;
      }
      if (this.formData.enable_digital_documents && key === 'pandadoc_contract_amount' && value === '') {
        return numericalValueError;
      }
      if (this.hasSecondaryAccount) {
        if (key === 'team_accounts_cards_contract_amount' && value === '') {
          return numericalValueError;
        }
        if (key === 'team_accounts_max_financial_accounts' && typeof value === 'number' && value < 0) {
          return numericalValueError;
        }
        if (key === 'team_accounts_contract_amount' && value === '') {
          return numericalValueError;
        }
      }
      return '';
    },
    validateDate(key) {
      if (!this.formData[key]) {
        this.$set(this.errors, key, 'Invalid date format');
      } else if (key === 'sign_date' && this.formData.type === 'customer' && this.clubSignDate.isAfter(moment())) {
        this.$set(this.errors, key, 'Date cannot be in the future');
      } else if (key === 'renew_date' && this.formData.type === 'customer' && this.clubRenewDate.isBefore(this.clubSignDate)) {
        this.$set(this.errors, key, 'Renewal data cannot happen before signature date');
      } else {
        this.$delete(this.errors, key);
      }
    },
    validate() {
      Object.keys(this.formData).forEach((key) => {
        if (this.isRequiredField(key)) {
          if (this.isEmptyField(key)) {
            this.$set(this.errors, key, 'This is a required field');
            return;
          } else {
            this.$delete(this.errors, key);
          }
        }
        const error = this.errorForField(key);
        if (error) {
          this.$set(this.errors, key, error);
        } else {
          this.$delete(this.errors, key);
        }
      });
    },
    async show(club) {
      Object.assign(this.$data, initialState());
      if (club) {
        this.editing = true;
        await this.initClub(club);
        await this.fetchClubInternals(club.id);
        await this.findLegalEntityDetails();
      }
      this.$refs.modal.show();
    },
    async fetchClubInternals(id) {
      return api().get(`/cst/club_internals/${id}/`).then((res) => {
        this.formData.contract_name = res.data.contract_name;
      });
    },
    async findLegalEntityDetails() {
      if (this.primaryMerchantAccountID) {
        api({ showError: false }).get(`/cst/merchant_accounts/${this.primaryMerchantAccountID}/legal_entity_details`).then((r) => {
          this.merchantAccountLegalEntityDetails = r.data;
          this.noLegalEntityDetails = false;
        }).catch(() => {
          this.noLegalEntityDetails = true;
        });
      }
    },
    dismiss(options = { refreshData: false, saving: false }) {
      if (this.editing && !options.saving) this.$refs.clubNotes.deleteNewNotes();
      this.$refs.modal.dismiss();
      this.$emit('dismissed', options);
    },
    save() {
      this.validate();
      if (!this.valid) {
        this.scrollToFirstError();
        return;
      }
      if (this.editing) {
        this.saveExisting();
      } else {
        this.saveNew();
      }
    },
    saveExisting() {
      const clubUrl = `/cst/clubs/${this.originalClub.id}`;
      api().post(clubUrl, this.toPostClub)
        .then(res => Promise.all([
          this.updateClubInternal(res.data),
          this.updateClubContract(res.data),
          this.createClubPandadoc(res.data),
          this.updateTeamAccounts()]))
        .then(() => {
          if (this.clubSportChanged) {
            this.updateClubSport();
          } else {
            this.finishUpdate();
          }
        });
    },
    updateClubSport() {
      return api().post(`/cst/clubs/${this.originalClub.id}/sports`, [this.formData.sport]).then(() => {
        this.finishUpdate();
      });
    },
    finishUpdate() {
      this.$store.commit('setNotification', { toast: true, success: true, message: 'Club updated' });
      this.dismiss({ refreshData: true, saving: true });
    },
    saveNew() {
      api().post('/cst/clubs/signup', this.toPostSignup)
        .then(res => Promise.all([
          this.createClubInternal(res.data),
          this.createClubContract(res.data),
          this.postClubNotes(res.data),
          this.createClubPandadoc(res.data),
          this.updateTeamAccounts(),
        ]))
        .then(() => {
          this.$store.commit('setNotification', { toast: true, success: true, message: 'Club created' });
          this.dismiss({ refreshData: true });
        });
    },
    setTypeCustomer() {
      const prev = this.formData.type;
      this.formData.type = 'customer';
      this.$delete(this.errors, 'type');
      if (prev !== '') {
        this.validateDate('sign_date');
        this.validateDate('renew_date');
      }
    },
    setTypeProspect() {
      if (this.canSetTypeProspect) {
        const prev = this.formData.type;
        this.formData.type = 'prospect';
        this.formData.renew_date = '';
        this.formData.send_register_email = false;
        this.$delete(this.errors, 'competitive_player_cap');
        this.$delete(this.errors, 'rec_player_cap');
        this.$delete(this.errors, 'type');
        if (prev !== '') {
          this.validateDate('sign_date');
        }
      }
    },
    setClubOnboardingType(obt) {
      this.formData.onboarding_type = obt;
      this.clearErrorFor('onboarding_type');
    },
    showWelcomeEmailModal() {
      this.$confirm.confirm({
        title: 'Send Welcome Email',
        message: 'This will send a welcome email to the club\'s primary contact.<br/><br/>Are you sure you want to continue?',
        confirmText: 'Yes, Send Email',
        type: 'is-primary',
        icon: 'check-circle',
        onConfirm: () => this.sendWelcomeEmail(),
      });
    },
    sendWelcomeEmail() {
      api().post(`/cst/clubs/${this.originalClub.id}/register_email`).then(() => {
        this.emailSent = true;
        this.$store.commit('setNotification', { toast: true, success: true, message: 'Register Email Sent' });
      }).catch((err) => { this.$store.commit('setNotification', { toast: true, success: false, message: err }); });
    },
    async initClub(club) {
      this.originalClub = club;

      this.formData.name = club.name;
      this.formData.is_test_club = club.is_test_club;
      this.formData.sport = (club.sports && club.sports.length > 0 && club.sports[0].sport) || '';
      this.formData.size = club.size;
      this.formData.website = club.website;
      this.formData.type = club.type;
      this.formData.first_name = club.contact_name.split(' ')[0];
      this.formData.last_name = club.contact_name.split(' ')[1];
      this.formData.email = club.contact_email;
      this.formData.timezone = club.timezone;

      // Config fields
      this.formData.country = club.config.country;
      this.formData.state = club.config.state;
      this.formData.city = club.config.city;

      this.formData.onboarding_status = club.config.onboarding_status;
      this.formData.onboarding_type = club.config.onboarding_type;
      this.formData.sales_contact_user_id = club.config.sales_contact_user_id;
      this.formData.club_contact_title = club.config.club_contact_title;

      this.formData.enable_program_administration = club.config.enable_program_administration;
      this.formData.enable_digital_documents = club.config.enable_digital_documents;
      this.formData.enable_website_builder = club.config.enable_website_builder;
      this.formData.enable_financial_aid = club.config.enable_financial_aid;
      this.formData.enable_third_party_insurance = club.config.enable_third_party_insurance;

      // contract fields
      if (!club.contract) {
        // contract was closed
        this.hasContract = false;
        this.formData.rec_player_cap = null;
        this.formData.competitive_player_cap = null;
        this.formData.sign_date = '';
        this.formData.renew_date = '';
      } else {
        this.formData.rec_player_cap = club.contract.rec_player_cap;
        this.formData.competitive_player_cap = club.contract.competitive_player_cap;
        this.formData.sign_date = club.contract.sign_date;
        this.formData.renew_date = club.contract.renew_date;
      }
      await this.getClubDetails(this.originalClub.id, this.formData.enable_digital_documents);
    },
    createClubInternal(res) {
      const clubInternalData = {
        id: res.id,
        type: res.type,
        size: res.size,
        contract_name: this.formData.contract_name,
      };
      return api().post('/cst/club_internals/', clubInternalData);
    },
    updateClubInternal(club) {
      const clubInternalData = {
        type: club.type,
        size: club.size,
        contract_name: this.formData.contract_name,
      };
      return api().post(`/cst/club_internals/${club.id}/`, clubInternalData);
    },
    createClubContract(club) {
      return api().post('/cst/club_contracts/', { ...this.toPostContract, club_id: club.id });
    },
    updateClubContract(club) {
      if (!this.hasContract) return this.createClubContract(club);
      return api().post(`/cst/club_contracts/${this.originalClub.contract.id}`, { ...this.toPostContract });
    },
    postClubNotes(club) {
      return this.$refs.clubNotes.postNotes(club.id);
    },
    createClubPandadoc(club) {
      if (this.formData.enable_digital_documents) {
        if (this.formData.pandadoc_id) {
          this.updateClubPandadoc();
        } else {
          return api().post('/cst/pandadoc_accounts', { club_id: club.id, ...this.toPostPandaDoc });
        }
      }
      return null;
    },
    updateClubPandadoc() {
      return api().post(`/cst/pandadoc_accounts/${this.formData.pandadoc_id}`, this.toPostPandaDoc);
    },
    updateTeamAccounts() {
      if (this.hasSecondaryAccount) {
        return api().post(`/cst/merchant_accounts/${this.formData.merchant_account_id}/contract_data`, this.toPostAccount);
      }
      return Promise.resolve();
    },
    clearErrorFor(field) {
      this.$delete(this.errors, field);
    },
    setFromLocaleString(field, value) {
      this.formData[field] = parseInt(value.replaceAll(',', ''), 10) || 0;
      this.clearErrorFor(field);
    },
    scrollToFirstError() {
      // find key of error that is first in the formOrder, and scroll its ref into view
      const firstErr = Object.keys(this.errors).sort((a, b) => formOrder.indexOf(a) - formOrder.indexOf(b))[0];
      if (this.$refs[firstErr]) this.$refs[firstErr].$el.scrollIntoView({ behavior: 'smooth' });
    },
    async getClubDetails(id, hasDigitalDocsEnabled) {
      let populate = 'payment_details';
      if (hasDigitalDocsEnabled) populate += ',pandadoc';
      return api().get(`/cst/clubs/${id}/?populate=${populate}`).then((res) => {
        const primaryMerchant = this.bestFitMerchant(res.data.club_merchants, false);
        const secondaryMerchant = this.bestFitMerchant(res.data.club_merchants, true);
        this.primaryMerchantAccountID = primaryMerchant?.id;
        if (primaryMerchant) {
          if (primaryMerchant.contract_date) {
            this.formData.team_accounts_contract_date = primaryMerchant.contract_date;
          }
          if (primaryMerchant.num_cards_limit) {
            this.formData.team_accounts_cards_contract_amount = primaryMerchant.num_cards_limit;
          }
          if (primaryMerchant.num_accounts_limit) {
            this.formData.team_accounts_contract_amount = primaryMerchant.num_accounts_limit;
          }
          if (primaryMerchant.max_financial_accounts) {
            this.formData.team_accounts_max_financial_accounts = primaryMerchant.max_financial_accounts;
          }
          this.formData.merchant_account_id = primaryMerchant.id;
          this.hasSecondaryAccount = secondaryMerchant && secondaryMerchant.vendor === 'Stripe';
        }
        if (hasDigitalDocsEnabled && res.data.club_pandadoc) {
          this.formData.pandadoc_contract_date = res.data.club_pandadoc.contract_date;
          this.formData.pandadoc_contract_amount = res.data.club_pandadoc.annual_document_limit;
          this.formData.pandadoc_id = res.data.club_pandadoc.id;
        }
      });
    },
    bestFitMerchant(merchants, isSecondary) {
      if (!merchants) return null;
      let bestFit = null;
      merchants.forEach((clubMerchant) => {
        const includesSecondary = clubMerchant.flags.includes('Secondary');
        if (isSecondary === includesSecondary && !clubMerchant.flags.includes('Archived')) {
          if (!bestFit) {
            bestFit = clubMerchant;
          } else if (clubMerchant.flags.includes('Enabled') && !bestFit.flags.includes('Enabled')) {
            bestFit = clubMerchant;
          } else if (bestFit.flags.includes('Disabled') && !clubMerchant.flags.includes('Disabled')) {
            bestFit = clubMerchant;
          }
        }
      });
      return bestFit;
    },
  },
};
</script>

<style lang="sass" scoped>
.club-form-modal
  .club-form
    max-width: 800px !important
    &__section
        margin-bottom: 3rem
        &__label
            font-family: $family-headers
            font-weight: $weight-bold
            font-size: $size-5
            margin-bottom: 0.75rem
            .error
              font-style: italic
              font-size: $size-7
              font-family: $family-primary
              font-weight: $weight-normal
    &__digital_docs
      margin-top: 1rem
      &__flex
        display: flex
        align-items: center
    .box
      .label-text
        font-family: $family-headers
        font-weight: $weight-semibold
        margin-bottom: 0.5rem
      .description-text
        font-style: italic
        color: $blue-slate
      &.disabled
        opacity: 0.5
        cursor: not-allowed
        color: #747B82
</style>
<style lang="sass">
.club-form .club-type-box label
  font-size: $size-7
</style>
