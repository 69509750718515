<template>
  <span class="stoplight" :class="status"></span>
</template>

<script>

export default {
  props: {
    status: { type: String, required: true },
  },
};
</script>

<style lang="sass" scoped>
.stoplight
  height: 1rem
  width: 1rem
  border-radius: 50%
  white-space: nowrap
  display: inline-block
.success
  background-color: $success
.warning
  background-color: $warning-dark
.danger
  background-color: $danger
.disabled
  background-color: $grey-light
</style>
