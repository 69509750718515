<template>
    <div
      class="tray-wrapper">
      <div
        :class="{ 'filter-tray-active': filterTrayOpen }"
        class="filter-tray"
        @click.stop="$emit('click', $event)">
        <div class="filter-container filter-header" :class="{empty: !title}">
          <nav class="columns is-vcentered is-marginless">
            <div class="column is-paddingless">
              <h3 v-if="title" class="filter-title">{{ title }}</h3>
            </div>
            <div class="column is-narrow is-paddingless">
              <a class="delete is-medium" @click="toggleFilterTrayOpen()"/>
            </div>
          </nav>
          <div v-if="hasClearFiltersButton" class="filter-link-container">
            <div class="columns is-mobile is-vcentered">
              <div class="column">
                <div v-if="hasClearFiltersButton">
                  <a v-if="filtersActive" class="filter-link" @click="$emit('clear-filters')">Clear Filters</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="tray" v-if="$slots.default && true" class="content-no-overflow">
          <slot/>
        </div>
        <template v-else>
          <div class="content">
            <slot name="content" />
          </div>
        </template>
      </div>
    </div>
</template>

<script>

export default {

  props: {
    title: { type: String, default: 'Filter Items' },
    filtersActive: { type: Boolean, default: false },
    hasClearFiltersButton: { type: Boolean, default: true },
  },
  computed: {
    filterTrayOpen() {
      return this.$store.getters.filterTrayOpen;
    },
  },
  methods: {
    toggleFilterTrayOpen() {
      this.$refs.tray.scrollTo({ top: 0 });
      this.$store.dispatch('toggleFilterTrayOpen');
    },
  },
};
</script>

<style lang="sass" scoped>
.tray-wrapper
  position: fixed
  top: 0
  right: 0
  min-height: 100%
  z-index: 50
  &.tray-wrapper-active
    width: 310px
  &.tray-wrapper-active-full
    left: 0
.filter-tray
  min-height: 100%
  height: 100%
  width: $tray-width-wide
  position: absolute
  top: 0
  right: -$tray-width-wide
  background-color: $white-ter
  overflow-x: visible
  overflow-y: hidden
  padding-bottom: 1rem
  padding-top: 2rem
  display: flex
  flex-direction: column
.filter-tray-active
  right: 0
  z-index: 5
  box-shadow: -2px 0 10px 2px rgba(0,0,0,0.2)
.filter-header
  box-shadow: 0 1px 0 0 $grey-lighter
  padding-bottom: 1.5rem
  &.empty
    box-shadow: none
    padding-bottom: 0
    margin-top: -1.25rem
.filter-title
  font-family: $family-headers
  font-weight: $weight-medium
  font-size: $size-4
  padding-right: 0.75rem
.filter-link-container
  margin-top: 1rem
  margin-bottom: -1.5rem
  min-height: 3rem
.filter-link
  color: $black-ter
  font-size: $size-6
  font-weight: $weight-normal
  text-align: left
  text-decoration: underline
.content-no-overflow
  flex-grow: 1
  overflow-x: hidden
  overflow-y: auto
  // for mobile Safari only
  @media not all and (min-resolution: .001dpcm)
    @supports (-webkit-appearance: none)
      overflow: scroll
.content
  flex-grow: 1
  overflow-y: auto

.mobile-filter-tray ::v-deep
  .mobile-menu
    background-color: $white-ter
    font-family: $family-headers
    header
      margin-bottom: 0
      .filter-link-mobile
        font-weight: $weight-medium
        &.is-inactive
          pointer-events: none
          color: $grey-light
    .content,
    .content-no-overflow
      margin: 0 -24px
  .switch
    display: flex
    flex-direction: row-reverse
    align-items: center
    padding-top: .5rem
    padding-bottom: 1rem
    .control-label
      flex: 1
    .check
      font-size: 1.15rem
.filter-column
  width: $tray-width-wide
  background-color: $white-ter
  height: $planner-height
  min-height: $planner-height
  padding: 2rem 0 3rem 0!important
  overflow: auto
  display: flex
  flex-direction: column
</style>
