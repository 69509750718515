import { render, staticRenderFns } from "./StaffClubWorkload.vue?vue&type=template&id=1ab4b3b9&scoped=true"
import script from "./StaffClubWorkload.vue?vue&type=script&lang=js"
export * from "./StaffClubWorkload.vue?vue&type=script&lang=js"
import style0 from "./StaffClubWorkload.vue?vue&type=style&index=0&id=1ab4b3b9&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ab4b3b9",
  null
  
)

export default component.exports