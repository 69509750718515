<template>
  <div>
    <div v-if="isLoading">
      <spinner />
    </div>
    <div v-else>
      <PageNavigation
        :page-title="club? club.name : ''"
        :tabs="tabs"
        :active-tab="activeTab"
        :back-button="backButton"
        @tabClicked="tabClicked($event)"
        @ctaClicked="cta($event)"
      />
      <div v-if="activeTab === 'Summary'">
        <ClubSummary :club="club"/>
      </div>
      <div v-if="activeTab === 'Activities'">
        <ClubActivities :club="club"
          :activityNotes="activityNotes"
          :activities="clubActivities"
          @refresh="loadClub"/>
      </div>
      <div v-if="activeTab === 'Usage'">
        <ClubUsage :club="club"/>
      </div>
      <div v-if="activeTab === 'Tickets'">
        <ClubTickets :club="club" @date="dateRangeChanged"/>
      </div>
      <div v-if="activeTab === 'Contacts'">
        <ClubViewContacts ref="clubViewContacts" :club="club"/>
      </div>
      <AddEditActivitiesModal
        :activityNotes="activityNotes"
        @noteCreated="loadClub"
        @noteDeleted="loadClub"
        @dismissed="addEditDismissed"
        ref="addActivity"/>
      <TicketExportModal
        :initStartDate="dateRange.start_date"
        :initEndDate="dateRange.end_date"
        ref="ticketExportModal"/>
      <AddClubContact
        :club="club"
        @dismissed="addClubContactDismissed"
        @refresh="addClubContactDismissed"
        ref="addClubContact"/>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import ClubSummary from '@/components/clubs/ClubSummary';
import ClubActivities from '@/components/clubs/ClubActivities';
import ClubUsage from '@/components/clubs/ClubUsage';
import ClubTickets from '@/components/clubs/ClubTickets';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TicketExportModal from '@/components/tickets/TicketExportModal';
import AddEditActivitiesModal from '@/components/activities/AddEditActivitiesModal';
import spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import ClubViewContacts from '@/components/clubs/ClubViewContacts';
import AddClubContact from './AddClubContact';

const defaultDateRange = {
  startDate: moment().subtract(30, 'days').toDate(),
  endDate: moment().toDate(),
};

export default {
  components: {
    PageNavigation,
    ClubSummary,
    ClubActivities,
    ClubUsage,
    ClubTickets,
    AddEditActivitiesModal,
    TicketExportModal,
    spinner,
    ClubViewContacts,
    AddClubContact,
  },
  props: {
    defaultTab: { type: String, default: 'Summary' },
  },
  data() {
    return {
      dateRange: _.cloneDeep(defaultDateRange),
      club: null,
      goBackTo: '',
    };
  },
  computed: {
    isLoading() {
      return this.club === null;
    },
    activeTab() {
      return this.defaultTab;
    },
    clubActivities() {
      if (this.club) {
        return this.club.club_onboarding_plans ? this.club.club_onboarding_plans[0].activities : [];
      } return [];
    },
    backButton() {
      const ret = { name: 'Back' };
      switch (this.goBackTo) {
        case 'activities':
          ret.routeNames = ['Activities'];
          ret.routeFallback = 'Activities';
          break;
        case 'activities reports':
          ret.routeNames = ['ActivitiesReports'];
          ret.routeFallback = 'ActivitiesReports';
          break;
        case 'usage':
          ret.routeNames = ['Usage'];
          ret.routeFallback = 'Usage';
          break;
        case 'tickets':
          ret.routeNames = ['TicketsSummary'];
          ret.routeFallback = 'TicketsSummary';
          break;
        default:
          ret.routeNames = ['clubs'];
          ret.routeFallback = 'clubs';
      }
      return ret;
    },
    EXPORT_TICKETS() {
      return { classes: 'is-outlined', text: 'Export Tickets', icon: 'sign-in', icon_transform: 'rotate-90' };
    },
    ADD_CLUB_CONTACTS() {
      return 'Add Club Contact';
    },
    ticketsCtas() {
      const ret = [];
      ret.push(this.EXPORT_TICKETS);
      return ret;
    },
    activityNotes() {
      if (this.club.club_onboarding_plans) {
        return this.club.club_onboarding_plans[0].activities?.map(activity => ({
          id: activity.club_id,
          notes: activity?.notes ? [...activity?.notes] : [],
        }));
      } else return [];
    },
    ADD_ACTIVITY() { return 'Add Activity'; },
    activitiesCtas() {
      const ret = [];
      if (this.$store.getters.canAccess(['product', 'success', 'operations'])) {
        ret.push(this.ADD_ACTIVITY);
      }
      return ret;
    },
    contactsCtas() {
      const ret = [];
      if (this.$store.getters.canAccess(['product', 'success', 'operations'])) {
        ret.push(this.ADD_CLUB_CONTACTS);
      }
      return ret;
    },
    plan() {
      return this.club.club_onboarding_plans ? this.club.club_onboarding_plans[0] : null;
    },
    tabs() {
      const ret = [];
      ret.push({ name: 'Summary', route: 'ClubViewSummary' });
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        ret.push({ name: 'Activities', route: 'ClubViewActivities', ctas: this.activitiesCtas });
      }
      ret.push({ name: 'Usage', route: 'ClubViewUsage' });
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        ret.push({ name: 'Tickets', route: 'ClubViewTickets', ctas: this.ticketsCtas });
      }
      if (this.canAccess(['product', 'success', 'operations', 'sales'])) {
        ret.push({ name: 'Contacts', route: 'ClubViewContacts', ctas: this.contactsCtas });
      }
      return ret;
    },
  },
  created() {
    this.getBackTab();
    this.loadClub();
    if (this.$route.params.activeTab) {
      this.activeTab = this.$route.params.activeTab;
    }
  },
  methods: {
    canAccess(depts) {
      return this.$store.getters.canAccess(depts);
    },
    getBackTab() {
      this.goBackTo = this.$route.params.fromTab;
    },
    dateRangeChanged(dr) {
      this.dateRange = _.cloneDeep(dr);
    },
    cta($event) {
      if ($event === this.EXPORT_TICKETS) {
        this.$refs.ticketExportModal.showModal();
      }
      if ($event === this.ADD_ACTIVITY) {
        this.$refs.addActivity.show();
      }
      if ($event === this.ADD_CLUB_CONTACTS) {
        this.$refs.addClubContact.showModal();
      }
    },
    addEditDismissed(options) {
      if (options.refreshData) {
        this.loadClub();
      }
    },
    addClubContactDismissed() {
      this.$refs.clubViewContacts.fetchUsers();
    },
    loadClub() {
      this.club = null;
      api().get(`cst/clubs/${this.$route.params.club}?populate=contracts,sports,pandadoc,zendesk_tickets,onboarding_plans,onboarding_plans:activities,onboarding_plans:users,kpis,kpis:active_teams,kpis:active_team_players,kpis:active_rec_players,kpis:active_competitive_players,kpis:active_leagues,kpis:open_programs,kpis:new_transactions,kpis:new_revenue,kpis:new_events,kpis:new_messages,kpis:new_chat_messages,kpis:total_accounts,kpis:form_requests,kpis:new_digital_docs,kpis:upcoming_events`)
        .then((ret) => {
          this.club = ret.data;
        });
    },
    tabClicked($event) {
      if ($event === this.activeTab) return;
      const route = this.tabs.find(tab => tab.name === $event).route || 'ClubViewSummary';
      this.$router.push({ name: route });
    },
  },
};
</script>
