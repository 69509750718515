<template>
  <div v-if="loading">
    <spinner />
  </div>
  <div v-else class="club-summary">
    <h3 class="club-summary__table-header">Club Overview</h3>
    <div class="columns">
      <div class="column">
        <div class="columns bis-box full-height">
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Club Type</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.type | capitalize }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Customer Since</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.config ? club.config.customer_since : '-' | formatDate() }}
            </div>
          </div>
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Club Sport</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ clubSports(club.sports) | capitalize }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Club Size</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.size || '-' | capitalize }}
            </div>
          </div>
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Onboarding Type</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.config ? club.config.onboarding_type : '-' | capitalize }}
            </div>
          </div>
        </div>
      </div>
      <div class="column" style="margin-left: 0.75rem;">
        <div class="columns bis-box full-height">
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Overall Lead</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.club_onboarding_plans && club.club_onboarding_plans[0].cs_lead_user ?
                club.club_onboarding_plans[0].cs_lead_user.name : '-' }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Overall Secondary</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.club_onboarding_plans && club.club_onboarding_plans[0].cs_secondary_user ?
                club.club_onboarding_plans[0].cs_secondary_user.name : '-' }}
            </div>
          </div>
          <div class="column">
            <div class="has-text-weight-semibold is-font-mont">Total Activity Time</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ totalActivityTime | formatDuration }}
            </div>
            <div class="has-text-weight-semibold is-font-mont">Sales Rep</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
              {{ club.config ? clubSalesContactName(club.config.sales_contact_user_id) : '-' }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 class="club-summary__table-header">Total Document Usage</h3>
    <div class="columns bis-box" style="padding-bottom: 0;">
      <div class="column is-one-third">
        <div class="has-text-weight-semibold is-font-mont">Actual Count</div>
        <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
          {{ clubDocumentsUsed.toLocaleString() }}
        </div>
      </div>
      <div class="column is-one-third">
        <div class="has-text-weight-semibold is-font-mont">Contract Count</div>
        <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
          {{ club.club_pandadoc ? club.club_pandadoc.annual_document_limit.toLocaleString() : 0 }}
        </div>
      </div>
      <div class="column is-one-third">
        <div class="has-text-weight-semibold is-font-mont">Remaining</div>
        <div class="has-text-weight-bold is-font-mont has-text-info is-size-4" style="margin-bottom:1rem">
          {{ remainingDocuments.toLocaleString() }}
        </div>
      </div>
    </div>
    <h3 class="club-summary__table-header">Contract Summary</h3>
    <TablePaginated
      :data="clubContract"
      name="clubContract"
      :searchable="false"
      :filterable="false"
      :show-pagination="false"
    >
      <template slot-scope="props">
        <b-table-column
          header-class="is-unselectable"
          label="Renewal Date"
          field="renew_date">
          {{ props.row.renew_date | formatDate() }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Rec Player Count"
          field="rec_player_median"
          numeric>
          {{ props.row.rec_player_median }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Rec Player Cap"
          field="rec_player_cap"
          numeric>
          {{ props.row.rec_player_cap.toLocaleString() }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Rec Player Diff"
          field="rec_player_delta"
          numeric>
          {{ props.row.rec_player_delta }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Comp Player Count"
          field="competitive_player_median"
          numeric>
          {{ props.row.competitive_player_median }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Comp Player Cap"
          field="comp_player_cap"
          numeric>
          {{ props.row.competitive_player_cap.toLocaleString() }}
        </b-table-column>
        <b-table-column
          header-class="is-unselectable"
          label="Comp Player Diff"
          field="comp_player_delta"
          numeric>
          {{ props.row.comp_player_delta }}
        </b-table-column>
      </template>
    </TablePaginated>
    <div class="footer-button">
      <b-button type="is-primary" @click="showRosterCounts(clubContract[0])">
        <fa-icon :icon="['fas', 'chart-bar']" style="margin-right: .5rem"/>View Roster Counts
      </b-button>
    </div>
    <RosterCountsModal ref="rosterCounts"/>
  </div>
</template>

<script>
import spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import RosterCountsModal from '@/components/contracts/RosterCountsModal';
import TablePaginated from '@/components/layout/TablePaginated';

export default {
  components: { RosterCountsModal, spinner, TablePaginated },
  props: {
    club: { type: Object, required: true },
  },
  data() {
    return {
      loading: false,
      recMedian: {},
      compMedian: {},
    };
  },
  computed: {
    clubRecMedian() {
      return this.recMedian[this.club.id] || 0;
    },
    clubCompMedian() {
      return this.compMedian[this.club.id] || 0;
    },
    clubContract() {
      return [{ ...this.club.contract,
        name: this.club.name,
        rec_player_cap: this.club.contract?.rec_player_cap ? this.club.contract.rec_player_cap : 0,
        competitive_player_cap: this.club.contract?.competitive_player_cap ? this.club.contract.competitive_player_cap : 0,
        rec_player_median: this.clubRecMedian.toLocaleString(),
        competitive_player_median: this.clubCompMedian.toLocaleString(),
        rec_player_delta: this.getDelta(this.club.contract?.rec_player_cap, this.clubRecMedian).toLocaleString(),
        comp_player_delta: this.getDelta(this.club.contract?.competitive_player_cap, this.clubCompMedian).toLocaleString(),
      }];
    },
    clubDocumentsUsed() {
      return this.club.kpis?.filter(stat => stat.kpi_id === 10013).reduce((acc, week) => acc + week.value, 0) || 0;
    },
    remainingDocuments() {
      if (this.club.club_pandadoc) {
        return this.club.club_pandadoc?.annual_document_limit - this.clubDocumentsUsed;
      } return 0;
    },
    users() {
      return this.$store.getters.users;
    },
    salesUsers() {
      return this.$store.getters.salesUsers;
    },
    totalActivityTime() {
      if (this.club.club_onboarding_plans) {
        return this.club.club_onboarding_plans[0].activities?.reduce((acc, activity) => acc + activity.duration, 0) || 0;
      } else return 0;
    },
  },
  created() {
    this.fetchMedianCounts();
  },
  methods: {
    clubSalesContactName(id) {
      if (!id) return '-';
      return this.salesUsers[id] || '-';
    },
    getDelta(cap, median) {
      if (cap) return cap - median;
      if (median) return -1 * median;
      else return 0;
    },
    clubSports(sports) {
      if (sports) {
        return sports[0].sport;
      } return '-';
    },
    showRosterCounts(contract) {
      this.$refs.rosterCounts.showModal(contract);
    },
    fetchMedianCounts() {
      this.loading = true;
      api().post('/cst/usage/median', 10002).then((res) => {
        this.recMedian = res.data;
      });
      api().post('/cst/usage/median', 10003).then((res) => {
        this.compMedian = res.data;
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="sass">
.club-summary
  margin-top: 1rem
  &__table-header
    font-family: $family-headers
    font-weight: $weight-semibold
    font-size: $size-4
    margin-bottom: 1.5rem
    margin-top: 2rem
.footer-button
  margin-top: 1rem
  float: right
</style>
