import { render, staticRenderFns } from "./CommunicationsView.vue?vue&type=template&id=50b34280"
import script from "./CommunicationsView.vue?vue&type=script&lang=js"
export * from "./CommunicationsView.vue?vue&type=script&lang=js"
import style0 from "./CommunicationsView.vue?vue&type=style&index=0&id=50b34280&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports