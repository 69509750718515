<template>
 <spinner v-if="loading"/>
  <div id="onboarding-meeting" v-else>
    <TablePaginated
      name="Meeting Times"
      :data="searchedReportsWithClubFields"
      :bordered="isBordered"
      :sticky-header="stickyHeaders"
      height="600"
      class="reports-club-onboarding-table"
      searchable
      scrollable
      :default-sort="['date', 'desc']"
      :filterable="false"
      hoverable
      @queryUpdated="searchQuery = $event">
    <template slot="header-left">
          <div style="display: flex;flex-direction: column">
            <b-switch v-model="showManHours" >Show total time spent by team members (man-hours)</b-switch>
          </div>
        </template>
       <template slot-scope="props">
        <b-table-column label="Club"
          field="name"
          class="club-name-column"
          header-class="is-unselectable header-background counter"
          :custom-sort="sortByGenerator('club_name')"
          sortable>
          <a @click="viewPlan(props.row.id)">
            <div class="club-name"> {{ props.row.club_name | capitalize }} </div>
          </a>
        </b-table-column>
         <b-table-column
           :visible="false"
           label="Date Signed"
           field="date"
           header-class="is-unselectable header-background counter"
           :custom-sort="sortByGenerator('byDate')"
           sortable>
           {{ props.row.club_date | formatDate }}
         </b-table-column>
        <b-table-column label="Type"
          field="type"
          header-class="is-unselectable header-background counter"
          :custom-sort="sortByGenerator('club_type')"
          sortable>
          {{ props.row.club_type | capitalize }}
        </b-table-column>
        <b-table-column label="Status"
          field="status"
          header-class="is-unselectable header-background counter"
          sortable>
          {{ props.row.status | capitalize }}
        </b-table-column>
        <b-table-column label="Setup"
          field="setup"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.setup)"
          :custom-sort="sortByGenerator('setup')"
          sortable>
          <div class="club-date">{{ props.row.setup |  formatDuration}}</div>
        </b-table-column>
        <b-table-column label="Registrations"
          field="registrations"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.registrations)"
          :custom-sort="sortByGenerator('registrations')"
          sortable>
          <div class="club-date">{{ props.row.registrations |  formatDuration}}</div>
        </b-table-column>
        <b-table-column label="Operations"
          field="operations"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.operations)"
          :custom-sort="sortByGenerator('operations')"
          sortable>
          <div class="club-date">{{ props.row.operations |  formatDuration}}</div>
        </b-table-column>
        <b-table-column label="Team Accounts"
          field="team_accounts"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.team_accounts)"
          :custom-sort="sortByGenerator('team_accounts')"
          sortable>
          <div class="club-date">{{ props.row.team_accounts | formatDuration }}</div>
      </b-table-column>
        <b-table-column label="Website"
          field="website"
          header-class="is-unselectable header-background counter"
          :cell-class="webColorClass(props.row.website)"
          :custom-sort="sortByGenerator('website')"
          sortable>
          <div class="club-date">{{ props.row.website |  formatDuration}}</div>
        </b-table-column>
        <b-table-column label="Meetings"
          field="Meetings"
          header-class="is-unselectable header-background counter"
          :cell-class="webColorClass(props.row.meeting)"
          :custom-sort="sortByGenerator('meetings')"
          sortable>
          <div class="club-date">{{ props.row.meeting |  formatDuration}}</div>
        </b-table-column>
        <b-table-column label="Total Hours"
          field="Total"
          header-class="is-unselectable header-background counter"
          :cell-class="webColorClass(props.row.total_hours)"
          :custom-sort="sortByGenerator('total_hours')"
          sortable>
          <div class="club-date">{{ props.row.total_hours |  formatDuration}}</div>
        </b-table-column>
       </template>
      <template slot="empty">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
      </template>
    </TablePaginated>
  </div>
</template>

<script>
// import _ from 'lodash';
import moment from 'moment';
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';
import api from '@/http-playmetrics';

export default {
  components: { Spinner, TablePaginated },
  data() {
    return {
      counter: 0,
      loading: false,
      searchQuery: '',
      isBordered: true,
      stickyHeaders: true,
      showAlert: true,
      activeTab: 0,
      reports: [],
      showManHours: false,
    };
  },

  created() {
    this.fetchReports();
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    clubsWithActivites() {
      return this.reports.filter(report => !(report.activities === null || report.archived_at || report.status === 'completed'));
    },
    reportsWithClubFields() {
      return this.clubsWithActivites.map((report) => {
        const activityDataByType = this.typeCounter(report.activities);
        return { ...report,
          club_name: this.clubName(report.club_id),
          club_date: this.clubDate(report.club_id),
          club_type: this.clubType(report.club_id),
          club_sign_date: this.clubDate(report.club_id),
          setup: activityDataByType.setup ?? '-',
          registrations: activityDataByType.registration ?? '-',
          operations: activityDataByType.operations ?? '-',
          team_accounts: activityDataByType.team_accounts ?? '-',
          website: activityDataByType.website ?? '-',
          meeting: activityDataByType.meeting ?? '-',
          total_hours: Object.keys(activityDataByType).reduce((acc, curr) => acc + activityDataByType[curr], 0),
        };
      }).sort((report1, report2) =>
        ((report1.date > report2.date) ? -1 : 1));
    },
    searchedReportsWithClubFields() {
      return this.reportsWithClubFields.filter(reportsWithClubFields =>
        (this.matchesName(reportsWithClubFields, this.searchQuery)));
    },
  },

  methods: {
    sortByGenerator(key) {
      if (key === 'byDate') {
        return (a, b, isAsc) => {
          if (!a.club_date) return 1;
          if (!b.club_date) return -1;
          if (moment(a.club_date, 'YYYY-MM-DD').isAfter(moment(b.club_date, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
          if (moment(a.club_date, 'YYYY-MM-DD').isBefore(moment(b.club_date, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
          return 0;
        };
      }
      return (a, b, isAsc) => {
        if (a[key] === '-') return isAsc ? 1 : -1;
        if (b[key] === '-') return isAsc ? -1 : 1;
        if (a[key] > b[key]) return isAsc ? 1 : -1;
        if (a[key] < b[key]) return isAsc ? -1 : 1;
        return 0;
      };
    },
    matchesName(reportsWithClubFields, searchQuery) {
      return reportsWithClubFields.club_name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    viewPlan(id) {
      this.$router.push({ name: 'PlanViewActivities', params: { plan: id } });
    },
    colorClasses(colorStatus) {
      if (colorStatus === '3 of 3') {
        return 'success';
      }
      if (colorStatus === '2 of 3' || this.colorStatus === '1 of 3') {
        return 'warning';
      }
      if (colorStatus === '-') {
        return 'disabled';
      }
      return '';
    },
    webColorClass(colorStatus) {
      if (this.colorStatus === '1 of 1') {
        return 'success';
      }
      if (colorStatus === '-') {
        return 'disabled';
      }
      return '';
    },
    typeCounter(activities) {
      const ret = {};
      activities.forEach((activity) => {
        let count = ret[activity.category] ?? 0;
        if (this.dateCheck(activity.meeting_date) && !this.showManHours) {
          count += activity.duration;
        }
        if (this.dateCheck(activity.meeting_duration) && this.showManHours) {
          let staff = 0;
          if (activity.lead_user_id) {
            staff += 1;
          }
          if (activity.secondary_user_ids.length) {
            staff += activity.secondary_user_ids.length;
          }
          count += activity.duration * staff;
        }
        ret[activity.category] = count;
      });
      return ret;
    },
    dateCheck(date) {
      return moment(date).isBefore(moment().toDate());
    },
    clubByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    clubName(id) {
      return this.clubByID(id)?.name || '';
    },
    clubType(id) {
      return this.clubByID(id)?.config.onboarding_type || '';
    },
    clubDate(id) {
      return this.clubByID(id)?.created_at || '';
    },
    fetchReports() {
      this.loading = true;
      api().post('/cst/club_onboarding_plans/search?populate=users,activities').then((res) => {
        this.reports = res.data.filter(plan => !(this.clubs.find(club => club.id === plan.club_id).type === 'prospect'));
        this.loading = false;
      });
    },
  },
};
</script>

<style lang="sass" scoped>
@import "~bulma/sass/utilities/mixins"
#onboarding-meeting
 .club-name-column
   background-color: $white-ter
   padding: 0
 .club-name
   padding: 7.5px 10px

 ::v-deep .counter
   font-weight: bold
   white-space: nowrap
   padding-top: 1rem
   color: $white !important
 ::v-deep .club-date
   white-space: nowrap
   text-align: right !important
 .is-vertical-center
   display: inline-flex
   align-items: center
 .reports-club-onboarding-table
   margin-top: 1rem
   ::v-deep .header-background
    vertical-align: bottom
    width: 175px
    font-family: $family-headers
    font-size: 1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
 ::v-deep .success
  background-color: $success
 ::v-deep .warning
  background-color: $warning-dark
 ::v-deep .danger
  background-color: $danger
 ::v-deep .disabled
  background-color: lightgrey
</style>
