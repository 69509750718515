<template>
  <header class="masthead">
    <div class="main-nav__content">
      <template>
        <app-sidenav-header/>
        <app-sidenav-menu/>
      </template>
      <app-sidenav-footer/>
    </div>
  </header>
</template>

<script>
import SidenavHeader from './SidenavHeader';
import SidenavMenu from './SidenavMenu';
import SidenavFooter from './SidenavFooter';

export default {
  components: {
    'app-sidenav-header': SidenavHeader,
    'app-sidenav-menu': SidenavMenu,
    'app-sidenav-footer': SidenavFooter,
  },
};
</script>

<style lang="sass" scoped>
.masthead
  background-color: $cst-purple
  color: white
  height: 100vh
  width: $sidenav-width
  position: fixed
  z-index: 1
  top: 0
  left: 0
  overflow-x: visible
.main-nav__content
  display: flex
  flex-direction: column
  height: 100vh
</style>
