<template>
  <div>
    <div v-if="loading">
      <spinner/>
    </div>
    <div v-else>
      <PageNavigation
        :page-title="user.name ? user.name : ''"
        :back-button="backButton" />
      <TablePaginated
        name="userScheduleTable"
        :data="filteredUserActivities"
        :filters-active="hasFiltersActive"
        searchable
        @queryUpdated="searchQuery= $event">
          <template slot="header-left">
            <div class="level" style="margin-bottom:1.5rem;flex:1;">
              <div class="level-left">
                <DateRangeDropdown
                  v-model="dateRange"
                  :presets="[
                  { name: 'Last 30 Days', display: 'Last 30 Days', range: last30Days },
                  { name: 'Last 3 Months', display: 'Last 3 Months', range: last3Months},
                  { name: 'This Year', display: 'This Year', range: thisYear },
                  { name: 'Last Year', display: 'Last Year', range: lastYear },
                  ]"
                />
              </div>
            </div>
          </template>
        <template slot-scope="props">
          <b-table-column
            label="Club Name"
            field="name"
            header-class="is-unselectable"
            sortable>
            {{ props.row.name }}
          </b-table-column>
          <b-table-column
            label="Onboarding Type"
            field="type"
            header-class="is-unselectable"
            :custom-sort="sortByType"
            sortable>
            {{ props.row.onboarding_type | capitalize }}
          </b-table-column>
          <b-table-column
            label="Onboarding Category"
            field="category"
            header-class="is-unselectable"
            :custom-sort="sortByCategory"
            sortable>
            {{ props.row.onboarding_category | capitalize }}
          </b-table-column>
          <b-table-column
            label="Milestone"
            field="milestone"
            header-class="is-unselectable"
            sortable>
            {{ props.row.milestone }}
          </b-table-column>
          <b-table-column
            label="Date"
            field="date"
            header-class="is-unselectable"
            :custom-sort="sortByDate"
            sortable>
            {{ props.row.meeting_date ? (formatDate(props.row.meeting_date, 'MMM D, YYYY')) : "TBD" }}
          </b-table-column>
          <b-table-column
            label="Time Spent"
            field="duration"
            header-class="is-unselectable"
            numeric
            sortable>
            {{ formatDuration(props.row.duration) }}
          </b-table-column>
          <b-table-column
            label="Lead Staff"
            field="leader"
            header-class="is-unselectable"
            :custom-sort="sortByUserLead"
            sortable>
            {{ userName(props.row.lead_user_id) }}
          </b-table-column>
          <b-table-column
            label="Secondary Staff"
            field="secondaryStaff"
            header-class="is-unselectable"
            :custom-sort="sortByUserSecondary"
            sortable>
            {{ props.row.secondary_user_ids.length > 0 ? userLists(props.row.secondary_user_ids) : '' }}
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>No Clubs found</p>
            </div>
          </section>
        </template>
      </TablePaginated>
      <UserScheduleViewFilter/>
    </div>
  </div>
</template>
<script>
import spinner from '@/components/common/Spinner';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TablePaginated from '@/components/layout/TablePaginated';
import api from '@/http-playmetrics';
import UserScheduleViewFilter from '@/components/onboarding/reports/UserScheduleViewFilter';
import moment from 'moment';
import _ from 'lodash';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';

const defaultDateRange = {
  default: {
    startDate: moment().toDate(),
    endDate: moment().add(7, 'days').toDate(),
  },
  daily: {
    startDate: moment().startOf('week').add(1, 'days').toDate(),
    endDate: moment().endOf('week').subtract(1, 'days').toDate(),
  },
  weekly: {
    startDate: moment().startOf('week').add(1, 'days').toDate(),
    endDate: moment().startOf('week').add(5, 'weeks').toDate(),
  },
};

const normalizeOnboardingType = (obt) => {
  if (obt === 'Full Service') return 'full';
  if (obt === 'Mid-Service') return 'mid';
  if (obt === 'Self Service') return 'self';
  return obt;
};

export default {
  components: {
    spinner,
    PageNavigation,
    TablePaginated,
    UserScheduleViewFilter,
    DateRangeDropdown,
  },
  created() {
    this.dateRange = _.cloneDeep(defaultDateRange[this.$route.params.dateRange]);
    this.fetchData();
  },
  data() {
    return {
      loading: true,
      userData: null,
      activityData: null,
      searchQuery: '',
      dateRange: {},
    };
  },
  computed: {
    last30Days() {
      return {
        startDate: moment().subtract(30, 'days').toDate(),
        endDate: moment().toDate(),
      };
    },
    last3Months() {
      return {
        startDate: moment().subtract(3, 'months').toDate(),
        endDate: moment().toDate(),
      };
    },
    thisYear() {
      return {
        startDate: moment().dayOfYear(1).toDate(),
        endDate: moment().endOf('year').toDate(),
      };
    },
    lastYear() {
      return {
        startDate: moment().subtract(1, 'year').startOf('year').toDate(),
        endDate: moment().subtract(1, 'year').endOf('year').toDate(),
      };
    },
    hasFiltersActive() {
      return this.isFiltersActive(this.$store.getters.activeFiltersFor('userSchedule'));
    },
    filteredUserActivities() {
      let activities = this.userActivities;
      if (!this.$store.getters.filtersFor('userSchedule').includeTBD) {
        activities = this.userActivities.filter(act => act.meeting_date);
      }
      return activities.filter(activity =>
        (this.checkDate(activity)) && this.matchesFilter(activity) &&
        this.searchMatch(activity)).sort((a, b) =>
        moment(a.meeting_date).diff(moment(b.meeting_date)));
    },
    storedUsers() {
      return this.$store.getters.users;
    },
    storedClubs() {
      return this.$store.getters.clubs;
    },
    userActivities() {
      return this.activityData.filter(activity => activity.lead_user_id === parseInt(this.user.id, 10)
            || activity.secondary_user_ids.includes(this.user.id)).map(activity => ({
        name: this.clubName(activity.club_id),
        id: activity.id,
        club_sizes: this.storedClubs.find(club => club.id === activity.club_id).size,
        onboarding_type: normalizeOnboardingType(this.findOnboardingType(activity.club_id)),
        onboarding_category: activity.category,
        milestone: activity.milestone,
        meeting_date: activity.meeting_date,
        duration: activity.duration,
        lead_user_id: activity.lead_user_id,
        secondary_user_ids: activity.secondary_user_ids,
      }));
    },
    user() {
      return {
        name: this.userName(this.userData?.id),
        id: this.userData?.id,
      };
    },
    backButton() {
      const ret = { name: 'Back to Staff Reports' };
      if (this.$route.params.fromTab) {
        ret.useRouter = false;
        ret.routeNames = ['OnboardingSummaryReports'];
        ret.params = { activeTab: this.$route.params.fromTab };
      } else {
        ret.useRouter = true;
      }
      return ret;
    },
  },
  methods: {
    isFiltersActive(filters) {
      return Object.keys(filters).some((key) => {
        if (key === 'view_by') {
          if (filters[key] === 'overall') {
            return false;
          }
          return true;
        }
        if (filters[key]) {
          return true;
        }
        return false;
      });
    },
    checkDate(activity) {
      if (!activity.meeting_date) return true;
      return moment(activity.meeting_date).isBetween(moment(this.dateRange.startDate), moment(this.dateRange.endDate), 'day', '[]');
    },
    sortByUserLead(a, b, isAsc) {
      if (this.userName(a.lead_user_id) > this.userName(b.lead_user_id)) return isAsc ? 1 : -1;
      if (this.userName(a.lead_user_id) < this.userName(b.lead_user_id)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByUserSecondary(a, b, isAsc) {
      if (this.userName(a.secondary_user_ids[0]) > this.userName(b.secondary_user_ids[0])) return isAsc ? 1 : -1;
      if (this.userName(a.secondary_user_ids[0]) < this.userName(b.secondary_user_ids[0])) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDate(a, b, isAsc) {
      if (!a.meeting_date) return 1;
      if (!b.meeting_date) return -1;
      if (moment(a.meeting_date, 'YYYY-MM-DD').isBefore(moment(b.meeting_date, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.meeting_date, 'YYYY-MM-DD').isAfter(moment(b.meeting_date, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByType(a, b, isAsc) {
      if (a.onboarding_type > b.onboarding_type) return isAsc ? 1 : -1;
      if (a.onboarding_type < b.onboarding_type) return isAsc ? -1 : 1;
      return 0;
    },
    sortByCategory(a, b, isAsc) {
      if (a.onboarding_category > b.onboarding_category) return isAsc ? 1 : -1;
      if (a.onboarding_category < b.onboarding_category) return isAsc ? -1 : 1;
      return 0;
    },
    searchMatch(activity) {
      return activity.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    },
    includeTBD(activity) {
      if (this.$store.getters.filtersFor('userSchedule').includeTBD) {
        return true;
      } else {
        return activity.meeting_date;
      }
    },
    matchesFilter(activities) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('userSchedule');
      return Object.keys(filters).filter(key => typeof filters[key] !== 'boolean').every((key) => {
        if (key === 'view_by') {
          if (filters[key] === 'secondary') {
            return activities.secondary_user_ids.includes(this.user.id);
          } else if (filters[key] === 'primary') {
            return activities.lead_user_id === this.user.id;
          }
          return true;
        } else {
          return filters[key].includes(activities[key]);
        }
      });
    },
    formatDuration(time) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      return `${hours} hr${hours === 1 ? '' : 's'} ${minutes} min${minutes === 1 ? '' : 's'}`;
    },
    formatDate(date, format) {
      return moment(date).format(format);
    },
    userLists(ids) {
      const users = ids.map(id => this.userName(id));
      return users.join(', ');
    },
    findOnboardingType(id) {
      return this.storedClubs.find(club => club.id === id)?.config.onboarding_type;
    },
    clubName(id) {
      return this.storedClubs.find(club => club.id === id)?.name || '';
    },
    userName(id) {
      const userData = this.storedUsers.find(user => user.id === id);
      return `${userData?.first_name} ${userData?.last_name}`;
    },
    fetchData() {
      api().post('/cst/activities/search').then((res) => {
        if (res.status === 200) {
          this.activityData = res.data;
          this.userData = this.$store.getters.users.find(user => user.id === parseInt(this.$route.params.userId, 10));
        }
        this.loading = false;
      });
    },
  },
};
</script>
