import { render, staticRenderFns } from "./MerchantAccountModal.vue?vue&type=template&id=a67d80d6&scoped=true"
import script from "./MerchantAccountModal.vue?vue&type=script&lang=js"
export * from "./MerchantAccountModal.vue?vue&type=script&lang=js"
import style0 from "./MerchantAccountModal.vue?vue&type=style&index=0&id=a67d80d6&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a67d80d6",
  null
  
)

export default component.exports