<template>
  <Modal
    ref="modal"
    title="Add Club Contact"
    is-all-white >
    <div class="columns">
      <div class="column is-full">
        <b-field
          :type="{'is-danger' : errors.club}"
          :label= "selectedClub ? 'Clubs' : 'Select Club'"
          custom-class="is-medium"
          class="has-buttons">
            <div v-if="!selectedClub" class="field-background">
              <b-autocomplete
                v-model="clubDataName"
                :data="clubSearchFilter"
                open-on-focus
                type="search"
                placeholder="Enter Club Name"
                field="name">
              </b-autocomplete>
              <div class="buttons is-right">
                <b-button type="is-text" @click="cancelSelection()">Cancel</b-button>
                <b-button type="is-primary" @click="selectClub()">Select Club</b-button>
              </div>
            </div>
            <div v-else class="field-background">
              <strong> {{ selectedClub.name }} </strong>
            </div>
        </b-field>
        <div v-if="selectedClub">
          <div class="spacer"/>
          <p class="is-text-size-4 has-text-weight-medium mb-2">
            Select Club Contract
          </p>
          <Spinner v-if="isLoadingUsers"/>
          <div v-else>
            <div v-for="contact in clubContacts" :key="`${contact.id}`">
              <div class="field-background mb-2">
                <strong>{{ `${contact.first_name} ${contact.last_name}` | capitalize }}</strong>
              </div>
            </div>
            <b-button
              type="is-text"
              class="text-button"
              @click="showSelectClubContacts">
              + Add Club Contacts
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <SelectMultipleClubContacts
      ref="selectClubContacts"
      v-if="clubUsers"
      :club-admins="clubUsers"
      @save="postClubContacts"/>
  </Modal>
</template>

<script>
import { isEmpty } from 'lodash';
import Modal from '@/components/layout/modals/Modal';
import Spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import SelectMultipleClubContacts from '@/components/clubs/SelectMultipleClubContacts';

export default {
  components: {
    SelectMultipleClubContacts,
    Spinner,
    Modal,
  },
  props: {
    club: { type: Object, default: () => {} },
  },
  data() {
    return {
      clubDataName: '',
      selectedClub: null,
      clubUsers: null,
      userDataName: '',
      errors: {},
    };
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    isLoadingUsers() {
      return this.selectedClub && this.clubUsers === null;
    },
    disableClubSearch() {
      return !isEmpty(this.club);
    },
    clubSearchFilter() {
      return this.clubs.filter(club =>
        this.clubName(club.id).toLowerCase().indexOf(this.clubDataName.toLowerCase()) >= 0);
    },
    clubContacts() {
      return this.clubUsers?.filter(user => this.isContact(user));
    },
  },
  methods: {
    showSelectClubContacts() {
      this.$refs.selectClubContacts.showModal();
    },
    validateClub() {
      if (this.clubSearchFilter[0].name !== this.clubDataName) {
        this.errors.club = 'Please Select a Valid Club';
      } else {
        delete this.errors.club;
      }
    },
    async fetchClubUsers() {
      this.clubUsers = null;
      try {
        const { data } = await api().get(`/cst/clubs/${this.selectedClub.id}/admins?populate=permissions`);
        this.clubUsers = data;
      } catch {
        await this.$store.dispatch('setErrorToast', 'Failed to get users');
      }
    },
    async postClubContacts(ids) {
      try {
        await api({ showError: false }).post(`/cst/clubs/${this.selectedClub.id}/admins/club_contacts`, ids);
        await this.$store.dispatch('setSuccessToast', 'Updated club contacts');
        await this.dismissModal(true);
      } catch {
        await this.$store.dispatch('setErrorToast', 'Failed to update club contacts');
      }
    },
    clubName(id) {
      return this.clubs.find(club => club.id === id)?.name || '';
    },
    userName(id) {
      const user = this.clubUsers.find(usr => usr.id === id);
      return user ? `${user.first_name} ${user.last_name}` : '';
    },
    showModal() {
      if (this.disableClubSearch) {
        this.selectedClub = this.club;
        this.fetchClubUsers();
      }
      this.$refs.modal.showModal();
    },
    dismissModal(refreshData) {
      if (refreshData) {
        this.$emit('refresh');
      }
      this.$refs.modal.dismissModal();
      this.cancelSelection();
    },
    cancelSelection() {
      this.clubDataName = '';
      this.userDataName = '';
      this.selectedClub = null;
    },
    selectClub() {
      this.validateClub();
      if (Object.keys(this.errors).length === 0) {
        this.selectedClub = this.clubSearchFilter[0];
        this.fetchClubUsers();
      }
    },
    isContact(user) {
      return user?.current_role_permissions?.includes('club_contact');
    },
  },
};
</script>

<style lang="sass" scoped>
.spacer
  height: 0.15rem
  background-color: $white-ter
  margin-bottom: $size-4
  margin-top: $size-4
.text-button
  text-decoration: none !important
  font-size: $size-6
  color: $primary !important
.field-background
  background-color: $white-ter
  padding: $size-6
  border-radius: $radius-large
  .buttons
    margin-top: 0.5rem
    display: flex
    justify-content: flex-end
    button
      margin-left: $size-6
.has-buttons
  margin-bottom: $size-6
  display: flex
  flex-direction: column
  justify-content: space-between
  .buttons
    align-self: flex-end
</style>
