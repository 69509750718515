<template>
  <DateNavigationWithPicker
    v-model="currentValue"
    :display-value="displayValue"
    :first-day-of-week="firstDayOfWeek"
    :has-prev="prevEnabled"
    :has-next="nextEnabled"
    :minimum-date="minimumDate"
    :maximum-date="maximumDate"
    @prev="handlePrev"
    @next="handleNext"
  />
</template>

<script>
import moment from 'moment';
import DateNavigationWithPicker from '@/components/onboarding/reports/DateNavigationWithPicker';

export default {
  components: {
    DateNavigationWithPicker,
  },
  props: {
    value: { type: Date, required: true },
    displayFormat: { type: String, default: 'MMM D' },
    firstDayOfWeek: { type: Number, default: 0 },
    hasPrev: { type: Boolean, default: false },
    hasNext: { type: Boolean, default: false },
    minimumDate: { type: Date, default: null },
    maximumDate: { type: Date, default: null },
    segments: { type: Number, required: true },
    dateIncrement: { type: String, default: 'month' },
  },
  data() {
    return {
      selectingDate: false,
    };
  },
  computed: {
    currentValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dateFormat() {
      if (this.dateIncrement === 'month') {
        return 'MMM';
      } else {
        return this.displayFormat;
      }
    },
    startOfSegment() {
      return moment(this.value).startOf(this.dateIncrement);
    },
    endOfSegment() {
      return moment(this.value).add(this.segments - 1, this.dateIncrement);
    },
    preamble() {
      if (this.dateIncrement === 'month') {
        return 'Months of ';
      } else {
        return 'Weeks of';
      }
    },
    displayValue() {
      return `${this.preamble} ${this.startOfSegment.format(this.dateFormat)} - ${this.endOfSegment.format(this.dateFormat)}`;
    },
    prevEnabled() {
      return this.hasPrev && (!this.minimumDate || moment(this.minimumDate).isBefore(this.startOfSegment, 'day'));
    },
    nextEnabled() {
      return this.hasNext && (!this.maximumDate || moment(this.endOfSegment).isBefore(this.maximumDate, 'day'));
    },
  },
  methods: {
    changeDateBy(amount) {
      this.currentValue = moment(this.currentValue).add(amount, this.dateIncrement).toDate();
    },
    handlePrev() {
      this.changeDateBy(-1);
    },
    handleNext() {
      this.changeDateBy(1);
    },
  },
};
</script>
