<template>
  <v-popover
    ref="popover"
    :disabled="disabled"
    :auto-hide="true"
    :popover-base-class="popoverBaseClass"
    :placement="placement"
    :trigger="trigger"
    @auto-hide="$emit('closed')"
    @close-directive="$emit('closed')"
  >
    <slot name="trigger" />
    <template slot="popover">
      <slot name="content" />
    </template>
  </v-popover>
</template>

<script>
export default {

  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    popoverClass: {
      type: String,
      required: false,
      default: '',
    },
    placement: {
      type: String,
      required: false,
      default: 'bottom',
    },
    trigger: {
      type: String,
      required: false,
      default: 'hover',
    },
  },
  computed: {
    popoverBaseClass() {
      return `modal-popover ${this.popoverClass}`;
    },
  },
  methods: {
    hide() {
      this.$refs.popover.hide();
    },
  },
};
</script>

<style lang="sass">
  .modal-popover
    z-index: 100

    .popover-inner
      background: $white
      padding: 1rem
      border-radius: 3px
      box-shadow: 0 3px 6px rgba(black, .4), 0 0 25px rgba(black, .3)
    .tooltip-arrow
      width: 0
      height: 0
      position: absolute
      margin: 5px
      border: solid white
      z-index: 1

    &[x-placement^="top"]
      margin-bottom: 10px
      .tooltip-arrow
        border-width: 10px 10px 0 10px
        border-left-color: transparent !important
        border-right-color: transparent !important
        border-bottom-color: transparent !important
        bottom: -10px
        left: calc(50% - 10px)
        margin-top: 0
        margin-bottom: 0

    &[x-placement^="bottom"]
      margin-top: 10px
      .tooltip-arrow
        border-width: 0 10px 10px 10px
        border-left-color: transparent !important
        border-right-color: transparent !important
        border-top-color: transparent !important
        top: -10px
        left: calc(50% - 10px)
        margin-top: 0
        margin-bottom: 0

    &[x-placement^="right"]
      margin-left: 10px
      .tooltip-arrow
        border-width: 10px 10px 10px 0
        border-left-color: transparent !important
        border-top-color: transparent !important
        border-bottom-color: transparent !important
        left: -10px
        top: calc(50% - 10px)
        margin-left: 0
        margin-right: 0

    &[x-placement^="left"]
      margin-right: 10px
      .tooltip-arrow
        border-width: 10px 0 10px 10px
        border-top-color: transparent !important
        border-right-color: transparent !important
        border-bottom-color: transparent !important
        right: -10px
        top: calc(50% - 10px)
        margin-left: 0
        margin-right: 0

    &[aria-hidden='true']
      visibility: hidden
      opacity: 0
      transition: opacity .15s, visibility .15s

    &[aria-hidden='false']
      visibility: visible
      opacity: 1
      transition: opacity .15s

    &.no-shadow
      .popover-inner
        box-shadow: none
        border: 1px solid #DBDBDB

    &.narrow
      .popover-inner
        width: 200px
        text-align: center

    &.medium
      .popover-inner
        width: 350px

    &.narrow-popover-info
      .popover-inner
        width: 250px
        font-size: $size-8
        background-color: $blue-slate-dark
        color: $white
      .popover-arrow
        border-color: $blue-slate-dark

    &.modal-popover--success
      .popover-inner
        background-color: $success
        color: $white
      .popover-arrow
        border-color: $success
</style>
