<template>
  <div class="background-checks">
    <PageNavigation page-title="Background Checks" :ctas="ctas" @ctaClicked="cta($event)" />
    <TablePaginated
      name="Background Checks"
      :data="filteredTableRows"
      :filters-active="hasActiveFilters"
      @queryUpdated="searchQuery = $event"
      searchable>
      <template #header-left>
        <span class="label" v-if="filteredTableRows">
          {{filteredTableRows.length}} {{filteredTableRows.length === 1 ? 'background check' : 'background checks'}} found
        </span>
      </template>
      <template slot-scope="props">
        <b-table-column :header-selectable="false"
          label="ID"
          field="id"
          sortable>
          {{ props.row.id }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Club Name"
          field="name"
          sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Size"
          field="size"
          sortable>
          {{ props.row.size | capitalize }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Sport"
          field="sport"
          sortable>
          {{ props.row.sport | capitalize }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Risk Managers"
          field="risk_managers"
          sortable>
          {{ props.row.risk_managers }}
        </b-table-column>
        <b-table-column :header-selectable="false"
          label="Status"
          field="status"
          sortable>
          {{ props.row.status }}
        </b-table-column>
        <b-table-column label="Actions" centered :header-selectable="false">
          <a @click="editBackgroundChecks(props.row)"><fa-icon :icon="['fas', 'pencil']" /></a>
          <b-dropdown position="is-bottom-left">
            <template #trigger>
              <fa-icon :icon="['fas', 'ellipsis-h']" style="margin-left: 1rem" />
            </template>
            <b-dropdown-item
              v-if="props.row.status !== 'Disabled'"
              @click="disableBackgroundChecks(props.row)">
              Disable Background Checks
            </b-dropdown-item>
            <b-dropdown-item
              v-else
              @click="enableBackgroundChecks(props.row)">
              Enable Background Checks
            </b-dropdown-item>
          </b-dropdown>
        </b-table-column>
      </template>
    </TablePaginated>
    <BackgroundChecksFilter :filters-active="hasActiveFilters" @showTestClubs="showTestClubs = $event" />
    <BackgroundCheckModal
      ref="backgroundCheckModal"
      :clubs="enableableClubs"
      @dismissed="createModalDismissed" />
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import TablePaginated from '@/components/layout/TablePaginated';
import BackgroundChecksFilter from '@/components/background_checks/BackgroundChecksFilter';
import BackgroundCheckModal from '@/components/background_checks/BackgroundCheckModal';

export default {
  components: {
    BackgroundCheckModal,
    BackgroundChecksFilter,
    TablePaginated,
    PageNavigation,
  },
  data() {
    return {
      loading: false,
      searchQuery: '',
      clubYardstiks: [],
      showTestClubs: false,
    };
  },
  computed: {
    ctas() {
      const ret = [];
      ret.push('Enable Background Checks');
      return ret;
    },
    visibleClubYardstiks() {
      return this.clubYardstiks.filter(cy => !cy.club.is_test_club || this.showTestClubs);
    },
    tableRows() {
      return this.visibleClubYardstiks.map(cy => ({
        club_yardstik: cy,
        id: cy.club_id,
        name: cy.club.name,
        size: cy.club.size,
        sport: cy.club.sports && cy.club.sports.length ? this.clubSports(cy.sports) : '-',
        risk_managers: cy.risk_managers.map(rm => rm.user.display_name).join(', '),
        status: cy.club.config.integrated_background_checks_enabled ? 'Enabled' : 'Disabled',
      }));
    },
    filteredTableRows() {
      return this.tableRows.filter(row => this.matchesFilter(row) && this.matchesQuery(row));
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('backgroundChecks');
    },
    hasActiveFilters() {
      return !_.isEmpty(this.activeFilters);
    },
    enableableClubs() {
      return this.$store.getters.clubs.filter(club => !club.is_deleted && !this.clubHasClubYardstik(club));
    },
  },
  mounted() {
    this.fetchClubYardstiks();
  },
  methods: {
    fetchClubYardstiks() {
      this.loading = true;
      api().get('/cst/yardstik/accounts?populate=club,risk_managers').then((res) => {
        this.clubYardstiks = res.data;
        this.loading = false;
      });
    },
    clubSports(sports) {
      // Just show the club's 'main' sport
      if (sports) {
        return sports[0].sport;
      }
      return '-';
    },
    cta(action) {
      if (action === 'Enable Background Checks') {
        this.$refs.backgroundCheckModal.showModal();
      }
    },
    matchesFilter(row) {
      if (!this.hasActiveFilters) return true;
      return Object.keys(this.activeFilters).every(key => this.activeFilters[key].matches(row));
    },
    matchesQuery(row) {
      return row.name && row.name.toLowerCase().includes(this.searchQuery.toLowerCase());
    },
    clubHasClubYardstik(club) {
      return _.some(this.clubYardstiks, cy => cy.club_id === club.id && cy.enabled);
    },
    createModalDismissed(options) {
      if (options.refreshData) {
        this.fetchClubYardstiks();
      }
    },
    editBackgroundChecks(row) {
      this.$refs.backgroundCheckModal.showModal(row);
    },
    disableBackgroundChecks(row) {
      this.$confirm.showModal({
        confirmText: 'Yes, Disable',
        message: `You are about to disable integrated background checks for <span class="has-text-weight-bold">${row.name}</span> which will remove this capability and its associated clearances from this club.<br><br>Are you sure you want to continue?`,
        title: 'Disable Background Checks',
        type: 'is-danger',
        icon: 'minus-hexagon',
        onConfirm: () => this.doDisableBackgroundChecks(row),
      });
    },
    doDisableBackgroundChecks(row) {
      api().post(`/cst/yardstik/${row.id}/disable`).then(() => {
        this.$store.commit('setNotification', { toast: true, success: true, message: `Disabled yardstik access for ${row.name}` });
        this.fetchClubYardstiks();
      });
    },
    enableBackgroundChecks(row) {
      this.$confirm.showModal({
        confirmText: 'Yes, Enable',
        message: `You are about to enable integrated background checks for <span class=has-text-weight-bold">${row.name}</span>.<br><br>Are you sure you want to continue?`,
        title: 'Enable Background Checks',
        type: 'is-primary',
        icon: 'check',
        onConfirm: () => this.doEnableBackgroundChecks(row),
      });
    },
    doEnableBackgroundChecks(row) {
      api().post(`/cst/yardstik/${row.id}/enable`).then(() => {
        this.$store.commit('setNotification', { toast: true, success: true, message: `Enabled yardstik access for ${row.name}` });
        this.fetchClubYardstiks();
      });
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
