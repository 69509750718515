<template>
  <Spinner v-if="loading"/>
  <div v-else>
    <b-tabs
      v-model="activeTab"
      type="is-toggle"
      class="b-tabs-no-content">
      <b-tab-item label="Tickets"/>
      <b-tab-item label="Staff"/>
      <b-tab-item label="Time"/>
    </b-tabs>
    <div class="charts-container">
      <div style="margin-bottom: 0.5rem">
        <ViewSelector :value="selectedView" @input="$event = currentView = $event" :options="viewOptions"/>
      </div>
      <DateRangeDropdown v-if="showDateRangeDropdown"
          v-model="chartDateRange"
          :presets="[
            { name: 'This Week', display: 'This Week', range: thisWeek },
            { name: 'Last Week', display: 'Last Week', range: lastWeek },
            { name: 'Next Week', display: 'Next Week', range: nextWeek },
            { name: 'Last 7 Days', display: 'Last 7 Days', range: last7Days },
            { name: 'Next 7 Days', display: 'Next 7 Days', range: next7Days },
          ]"
        />
      <div class="weeknav-parent" v-if="showTable">
        <div class="level-item">
          <DateNavigationPicker
            :firstDayOfWeek="0"
            :segments="7"
            :dateIncrement="dateNavigationIncrement"
            hasPrev
            hasNext
            v-model="selectedTableDate"/>
        </div>
        <div class="view-selector weeknav-child">
          <b-select
            style="margin-left: 1rem"
            placeholder="Select Time View"
            v-model="viewBy.tickets_by_staff">
            <option v-for="view in tableViews" :value="view.value" :key="view.name">
              {{ view.name }}
            </option>
          </b-select>
        </div>
      </div>
      <TablePaginated
        name="Tickets by Staff"
        v-if="showTable"
        :searchable="false"
        :filterable="false"
        :showPagination="false"
        bordered
        scrollable
        :defaultPerPage="uniqAssignees.length * 3"
        :data="tableData"
        class="time-by-staff-table">
        <template slot-scope="props">
          <b-table-column v-for="i in 8"
            :key="i"
            header-class="is-unselectable header-background"
            :label="tableLabels[i - 1]"
            :cell-class="isUserCSS(props.row)">
            {{ props.row[`col_${i - 1}`] }}
          </b-table-column>
        </template>
      </TablePaginated>
      <template v-else>
        <div class="table-options-container">
          <div class="table-options">
            <TimeframeSelector v-if="showTimeFrame"
              v-model="timeframeSelected"
              @yearOverYearToggle="$event => overlayToggle[currentView] = $event"
              :hasToggle="hasOverlayToggle"
              :options="timeframeViews"/>
          </div>
        </div>
        <Highcharts ref="highcharts" :options="chartOptions"/>
        <b-switch v-if="showSuccessOnlySwitch" v-model="isOnlySuccessStaff">Success Staff Only</b-switch>
      </template>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import { genComponent } from 'vue-highcharts';
import api from '@/http-playmetrics';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';
import TablePaginated from '@/components/layout/TablePaginated';
import DateNavigationPicker from '@/components/tickets/DateNavigationPicker';
import Spinner from '@/components/common/Spinner';
import ViewSelector from '@/components/common/ViewSelector';
import TimeframeSelector from '@/components/tickets/TimeframeSelector';



highchartsMore(Highcharts);

// REMOVE AFTER NORMALIZING IN DB
const normalizeOnboardingType = (obt) => {
  if (!obt) return 'self';
  if (obt === 'Full Service') return 'full';
  if (obt === 'Mid Service') return 'mid';
  if (obt === 'Self Service') return 'self';
  return obt;
};

// Time Granularities
const timeBuckets = ['month', 'week', 'day'];

// Charts With Overlay Toggle
const needOverlayToggle = ['total_time_spent', 'club_role', 'full_service_vs_self_service'];

// Tables
const tableViews = ['tickets_by_staff'];

const dateRangeDropdownViews = ['tickets_by_category', 'tickets_by_type'];

const categoryFormat = {
  month: 'MMM',
  week: 'MMM DD',
  day: 'MMM DD',
};

const timeSpentMap = {
  '': 0,
  under_5_minutes: 5,
  under_15_minutes: 15,
  under_30_minutes: 30,
  under_1_hour: 60,
  under_2_hours: 120,
  under_4_hours: 240,
  over_4_hours: 240,
};

export default {
  components: {
    DateRangeDropdown,
    TablePaginated,
    DateNavigationPicker,
    Spinner,
    ViewSelector,
    TimeframeSelector,
    Highcharts: genComponent('Highcharts', Highcharts),
  },
  data() {
    return {
      refreshChart: 0,
      dateRanges: {},
      loading: true,
      upperBound: moment(),
      lowerBound: moment().startOf('month').add(-1, 'year'),
      chartDateRange: { startDate: moment().startOf('week').toDate(), endDate: moment().endOf('week').toDate() },
      categoryLabelMap: {},
      ticketMap: null,
      isOnlySuccessStaff: true,
      viewBy: {},
      overlayToggle: {},
      tickets: [],
      selectedTab: 0,
      ticketViews: ['Full Service VS Self Service', 'Club Role', 'Tickets Over Time', 'Tickets by Category', 'Tickets by Type'],
      staffViews: ['Time Spent by Staff', 'Tickets by Staff'],
      timeViews: ['Total Time Spent', 'Response Time', 'Solve Time'],
      selectedView: 'Full Service VS Self Service',
      timeframeViews: [
        { name: 'Last 12 Months', value: 'month' },
        { name: 'Last 12 Weeks', value: 'week' },
      ],
    };
  },
  created() {
    this.generateTicketMap();
    this.generateDataRetainers();
    this.fetchTickets({ start_date: moment().startOf('month').add(-2, 'year').format('YYYY-MM-DD'), end_date: moment().add(1, 'month').startOf('month').format('YYYY-MM-DD') });
  },
  computed: {
    dateRangeDifference() {
      return this.dateRange.now.endDate.diff(this.dateRange.now.startDate, this.viewBy[this.currentView]);
    },
    dateRange: {
      get() {
        return this.dateRanges[this.currentView];
      },
      set(value) {
        const entry = {
          now: { startDate: value.startDate, endDate: value.endDate },
          prev: { startDate: moment(value.startDate).add(-1, 'year'), endDate: moment(value.endDate).add(-1, 'year') },
        };
        this.handleTicketsFetch(entry);
        this.dateRanges[this.currentView] = entry;
      },
    },
    fetchTicketsRange: {
      get() {
        return { start_date: this.lowerBound.format('YYYY-MM-DD'), end_date: this.upperBound.format('YYYY-MM-DD') };
      },
      set(value) {
        this.lowerBound = value.start_date;
        this.upperBound = value.end_date;
      },
    },
    ticketTypeMap() {
      return {
        user_question: 'User Question',
        user_error: 'User Error',
        spam: 'Spam',
        sales_inquiry: 'Sales Inquiry',
        'product_performance/stability': 'Product Performance/Stability',
        'product_feature/gap': 'Product Feature/Gap',
        'product_defect/error': 'Product Defect/Error',
        meeting_request: 'Meeting Request',
        forward_to_club: 'Forward to Club',
        feature_request: 'Feature Request',
        customer_outreach: 'Customer Outreach',
      };
    },
    thisWeek() {
      return {
        startDate: moment().startOf('week').toDate(),
        endDate: moment().endOf('week').toDate(),
      };
    },
    lastWeek() {
      return {
        startDate: moment().subtract(1, 'weeks').startOf('week').toDate(),
        endDate: moment().subtract(1, 'weeks').endOf('week').toDate(),
      };
    },
    nextWeek() {
      return {
        startDate: moment().add(1, 'weeks').startOf('week').toDate(),
        endDate: moment().add(1, 'weeks').endOf('week').toDate(),
      };
    },
    last7Days() {
      return {
        startDate: moment().subtract(7, 'days').toDate(),
        endDate: moment().toDate(),
      };
    },
    next7Days() {
      return {
        startDate: moment().toDate(),
        endDate: moment().add(7, 'days').toDate(),
      };
    },
    tableViews() {
      const ret = [];
      timeBuckets.forEach((granularity) => {
        if (granularity !== 'day') {
          ret.push({
            value: granularity,
            name: `View by ${granularity.charAt(0).toUpperCase() + granularity.slice(1)}`,
          });
        }
      });
      return ret;
    },
    dateNavigationIncrement() {
      return this.viewBy[this.currentView];
    },
    tableLabels() {
      const ret = [' '];
      for (let i = 0; i < 7; i += 1) {
        ret.push(this.graphCategories[i]);
      }
      return ret;
    },
    selectedTableDate: {
      get() {
        return this.dateRange.now.startDate.toDate();
      },
      set(value) {
        this.dateRange = { startDate: moment(value), endDate: moment(value).add(7, this.viewBy[this.currentView]) };
      },
    },
    successStaff() {
      return this.$store.getters.usersByDepartments(['success']).map(user => user.email.slice(0, user.email.indexOf('@')));
    },
    graphCategories() {
      const ret = [];
      const granularity = this.viewBy[this.currentView];
      for (let i = 0; i < this.dateRangeDifference; i += 1) {
        const toPush = moment(this.dateRange.now.startDate).add(i, granularity).format(categoryFormat[granularity]);
        ret.push(toPush);
      }
      return ret;
    },
    chartOptions() {
      // Used To Force Computed Property To Update Since Map is Nonreactive
      this.refreshChart; // eslint-disable-line
      let options;
      switch (this.currentView) {
        case 'full_service_vs_self_service': options = this.serviceOptions(); break;
        case 'tickets_over_time': options = this.yearOptions(); break;
        case 'club_role': options = this.roleOptions(); break;
        case 'time_spent_by_staff': options = this.staffOptions(); break;
        case 'total_time_spent': options = this.timeOptions(); break;
        case 'tickets_by_category': options = _.merge({}, this.baseBarChartOptions, this.categoryOptions()); break;
        case 'tickets_by_type': options = _.merge({}, this.baseBarChartOptions, this.typeOptions()); break;
        case 'response_time': options = this.timeResponseOptions(); break;
        case 'solve_time': options = this.timeSolveOptions(); break;
        default: return {};
      }
      return _.merge({}, this.baseChartOptions, options);
    },
    zonesData() {
      const flags = { now: {}, prev: {} };
      const ret = {
        now: {},
        prev: {},
      };
      flags.now = false;
      flags.prev = false;
      ret.now = this.getDefaultZoneObj(0);
      ret.prev = this.getDefaultZoneObj(0);
      for (let i = 0; i < this.dateRangeDifference; i += 1) {
        const granularity = this.viewBy[this.currentView];
        if (!flags.now && moment(this.dateRange.now.startDate).add(i, granularity).isSameOrAfter(moment().startOf(granularity))) {
          flags.now = true;
          ret.now[0].value = i - 1;
        }
        if (!flags.prev && moment(this.dateRange.prev.startDate).add(i, granularity).isSameOrAfter(moment().startOf(granularity))) {
          flags.prev = true;
          ret.prev[0].value = i - 1;
        }
      }
      if (!flags.now) {
        ret.now[0].value = this.dateRangeDifference - 1;
      }
      if (!flags.prev) {
        ret.prev[0].value = this.dateRangeDifference - 1;
      }
      return ret;
    },
    activeTab: {
      get() {
        return this.selectedTab;
      },
      set(value) {
        switch (value) {
          case 0: this.currentView = this.ticketViews[0]; break;
          case 1: this.currentView = this.staffViews[0]; break;
          case 2: this.currentView = this.timeViews[0]; break;
          default: break;
        }
        this.selectedTab = value;
      },
    },
    uniqAssignees() {
      const uniqAssignees = _.union(this.getTicketData('staffData', this.viewBy[this.currentView], this.dateRange.now.startDate).flatMap(Object.keys));
      if (this.isOnlySuccessStaff) { return _.intersection(this.successStaff, uniqAssignees); }
      return uniqAssignees;
    },
    roleMap() {
      return {
        admin: 'clubCount',
        program_admin: 'clubCount',
        marketer: 'clubCount',
        director: 'clubCount',
        coach: 'teamCount',
        team_staff: 'teamCount',
        player_contact: 'playerCount',
        not_in_system: 'not_in_system',
      };
    },
    serviceByClubId() {
      const ret = new Map();
      this.$store.getters.clubs.forEach((club) => {
        ret.set(club.id, club.config.onboarding_type);
      });
      return ret;
    },
    viewOverlay() {
      if (this.overlayToggle[this.currentView]) {
        return 'prev';
      } else {
        return 'now';
      }
    },
    xAxisTitle() {
      const word = this.viewBy[this.currentView];
      return { text: `${word.charAt(0).toUpperCase() + word.slice(1)} of ` };
    },
    baseBarChartOptions() {
      return {
        colors: [
          '#34568B',
          '#88B04B',
          '#FF6F61',
          '#92A8D1',
          '#C2FB10',
          '#E664FF',
          '#FFFF33',
        ],
        tooltip: {
          pointFormat: '{point.y}',
        },
        title: {
          margin: 0,
          style: { color: 'is-black' },
        },
        plotOptions: {
          series: {
            colorByPoint: true,
            dataLabels: {
              enabled: true,
              inside: true,
              color: 'black',
              style: {
                fontWeight: 'bold',
              },
            },
            pointWidth: 30,
            groupPadding: 0,
          },
        },
        xAxis: {
          type: 'category',
          visible: false,
        },
        chart: {
          spacingTop: 30,
          type: 'bar',
        },
        legend: {
          enabled: false,
        },
      };
    },
    baseChartOptions() {
      return {
        chart: {
          showAxes: true,
          spacingTop: 50,
          plotBorderWidth: 1,
          plotBorderColor: 'black',
        },
        legend: {
          alignColumns: true,
          align: 'center',
          verticalAlign: 'bottom',
          floating: false,
          layout: 'horizontal',
        },
        credits: {
          enabled: false,
        },
        title: {
          y: -15,
          margin: 30,
          style: { color: 'is-black' },
        },
        xAxis: {
          title: this.xAxisTitle,
          startOnTick: true,
          allowDecimals: false,
        },
        yAxis: {
          min: 0,
          allowDecimals: false,
        },
      };
    },
    allViews() {
      return this.ticketViews.concat(this.staffViews, this.timeViews);
    },
    showDateRangeDropdown() {
      return dateRangeDropdownViews.includes(this.currentView);
    },
    showTimeFrame() {
      return !this.showDateRangeDropdown;
    },
    showSuccessOnlySwitch() {
      return this.selectedView === 'Time Spent by Staff';
    },
    timeframeSelected: {
      get() {
        return { view: this.viewBy[this.currentView], date: this.dateRange.now.startDate.toDate() };
      },
      set(value) {
        this.viewBy[this.currentView] = value.view;
        this.dateRange = { startDate: moment(value.date), endDate: moment(value.date).add(13, this.viewBy[this.currentView]) };
      },
    },
    currentView: {
      get() {
        return this.viewName(this.selectedView);
      },
      set(value) {
        this.selectedView = value;
      },
    },
    viewOptions() {
      switch (this.activeTab) {
        case 0: return this.ticketViews;
        case 1: return this.staffViews;
        case 2: return this.timeViews;
        default: return [''];
      }
    },
    showTable() {
      return tableViews.includes(this.currentView);
    },
    hasOverlayToggle() {
      return needOverlayToggle.includes(this.currentView);
    },
    tableData() {
      const ret = [];
      this.uniqAssignees.forEach((user) => {
        const userData = this.getTicketData(`staffData.${user}`, this.viewBy[this.currentView], this.dateRange.now.startDate);
        const newRows = [{ col_0: this.getAssigneeFullName(user) }, { col_0: 'Number of Tickets' }, { col_0: 'Time Spent' }];
        for (let i = 1; i < 8; i += 1) {
          const col = `col_${i}`;
          const timeSpent = ((userData[i - 1].staffTime ?? 0) / 60).toFixed(2);
          newRows[0][col] = ' ';
          newRows[1][col] = `${userData[i - 1].staffCount ?? 0}`;
          newRows[2][col] = `${timeSpent} Hour${timeSpent === 1 ? '' : 's'}`;
        }
        ret.push(...newRows);
      });
      return ret;
    },
  },
  methods: {
    serviceOptions() {
      const serviceSeries = this.serviceSeries();
      return {
        title: {
          text: '<strong> Tickets by Full Service vs Self Service Clubs </strong>',
        },
        yAxis: {
          title: { text: 'Ticket Count' },
        },
        xAxis: {
          categories: this.graphCategories,
        },
        series: serviceSeries[this.viewOverlay].map(s => ({
          data: s.data,
          name: s.name,
          ...this.getZonesData(`${s.frame}`),
        })),
      };
    },
    yearOptions() {
      const yearSeries = this.yearSeries();
      return {
        title: {
          text: '<strong> Tickets Over Time </strong>',
        },
        yAxis: {
          title: { text: 'Ticket Count' },
        },
        xAxis: {
          categories: this.graphCategories,
        },
        series: [
          { ...this.getZonesData('now'), ...yearSeries.now },
          { ...this.getZonesData('prev'), ...yearSeries.prev },
        ],
      };
    },
    roleOptions() {
      return {
        title: {
          text: '<strong> Tickets by Club Role </strong>',
        },
        yAxis: {
          title: { text: 'Ticket Count' },
        },
        xAxis: {
          categories: this.graphCategories,
        },
        series: this.roleSeries()[this.viewOverlay].map(s => ({
          data: s.data,
          name: s.name,
          ...this.getZonesData(`${s.frame}`),
        })),
      };
    },
    timeOptions() {
      const timeChunks = this.timeframeSelected?.view || 'month';
      return {
        tooltip: {
          formatter() {
            const time = this.y;
            const hours = Math.floor(time);
            const minutes = Math.round((60 * time) % 60);
            const timeFormatted = `${hours > 1 ? `${hours} hrs` : ''}${hours === 1 ? `${hours} hr` : ''} ${minutes} min${minutes === 1 ? '' : 's'}`;
            return `${timeFormatted} spent on the ${timeChunks} of ${this.x}`;
          },
        },
        title: {
          text: '<strong> Tickets by Total Time Spent </strong>',
        },
        xAxis: {
          categories: this.graphCategories,
        },
        yAxis: {
          title: { text: 'Ticket Count' },
        },
        series: this.timeSeries()[this.viewOverlay].map(s => ({
          data: s.data.map(ticket => ticket / 60),
          name: s.name,
          ...this.getZonesData(`${s.frame}`),
        })),
      };
    },
    categoryOptions() {
      const categorySeries = this.categorySeries();
      return {
        title: {
          text: '<strong> Tickets by Category </strong>',
        },
        yAxis: {
          title: { text: 'Ticket Count ' },
        },
        xAxis: {
          title: { text: 'Ticket Product Categories' },
        },
        chart: {
          height: categorySeries.length * 50,
        },
        series: [{
          dataLabels: [{
            align: 'center',
            format: '({point.name})',
            style: {
              textOutline: '0px',
            },
          }],
          data: categorySeries,
        }],
      };
    },
    typeOptions() {
      const typeSeries = this.typeSeries();
      return {
        title: {
          text: '<strong> Tickets by Type </strong>',
        },
        yAxis: {
          title: { text: 'Ticket Count ' },
        },
        xAxis: {
          title: { text: 'Ticket Types ' },
        },
        chart: {
          height: typeSeries.length * 50,
        },
        series: [{
          dataLabels: [{
            align: 'center',
            format: '({point.name})',
            style: {
              textOutline: '0px',
            },
          }],
          data: typeSeries,
        }],
      };
    },
    staffOptions() {
      const timeChunks = this.timeframeSelected?.view || 'month';
      return {
        tooltip: {
          formatter() {
            const time = this.y;
            const hours = Math.floor(time);
            const minutes = Math.round((60 * time) % 60);
            const timeFormatted = `${hours > 1 ? `${hours} hrs` : ''}${hours === 1 ? `${hours} hr` : ''} ${minutes} min${minutes === 1 ? '' : 's'}`;
            return `${timeFormatted} spent on the ${timeChunks} of ${this.x}`;
          },
        },
        title: {
          text: '<strong> Time Spent By Staff </strong>',
        },
        yAxis: {
          title: { text: 'Time Spent (Hours)' },
        },
        xAxis: {
          categories: this.graphCategories,
        },
        series: this.staffSeries().map(s => ({
          data: s.data.map(ticket => ticket / 60),
          name: s.name,
          ...this.getZonesData('now'),
        })),
      };
    },
    timeResponseOptions() {
      return {
        tooltip: {
          formatter() {
            const time = this.y;
            const hours = Math.floor(time);
            const minutes = Math.round((60 * time) % 60);
            const timeFormatted = `${hours > 1 ? `${hours} hrs` : ''}${hours === 1 ? `${hours} hr` : ''} ${minutes} min${minutes === 1 ? '' : 's'}`;
            return `${timeFormatted} median time response ${this.x}`;
          },
        },
        title: {
          text: '<strong> Response Time </strong>',
        },
        yAxis: {
          title: { text: 'Response Time' },
        },
        xAxis: {
          categories: this.graphCategories,
        },
        series: this.timeResponseSeries().map(s => ({
          data: s.data.map(time => time / 3600),
          name: s.name,
          ...this.getZonesData('now'),
        })),
      };
    },
    timeSolveOptions() {
      return {
        tooltip: {
          formatter() {
            const time = this.y;
            const hours = Math.floor(time);
            const minutes = Math.round((60 * time) % 60);
            const timeFormatted = `${hours > 1 ? `${hours} hrs` : ''}${hours === 1 ? `${hours} hr` : ''} ${minutes} min${minutes === 1 ? '' : 's'}`;
            return `${timeFormatted} median time response ${this.x}`;
          },
        },
        title: {
          text: '<strong> Solve Time </strong>',
        },
        yAxis: {
          title: { text: 'Solve Time (hours)' },
        },
        xAxis: {
          categories: this.graphCategories,
        },
        series: this.timeSolveSeries().map(s => ({
          data: s.data.map(time => time / 3600),
          name: s.name,
          ...this.getZonesData('now'),
        })),
      };
    },
    serviceSeries() {
      const ret = { now: [], prev: [] };
      Object.keys(ret).forEach((key) => {
        const timeRange = this.getTimeRange(key);
        const granularity = this.viewBy[this.currentView];
        const startingDate = this.dateRange[key].startDate;
        ret[key].push({
          name: `Full Service ${timeRange}`,
          data: this.getTicketData('full', granularity, startingDate),
          frame: key,
        });
        ret[key].push({
          name: `Mid Service ${timeRange}`,
          data: this.getTicketData('mid', granularity, startingDate),
          frame: key,
        });
        ret[key].push({
          name: `Self Service ${timeRange}`,
          data: this.getTicketData('self', granularity, startingDate),
          frame: key,
        });
      });
      ret.prev = ret.prev.concat(ret.now);
      return ret;
    },
    roleSeries() {
      const ret = {
        now: [],
        prev: [],
      };
      Object.keys(ret).forEach((key) => {
        const timeRange = this.getTimeRange(key);
        const granularity = this.viewBy[this.currentView];
        ret[key].push({
          name: `Club ${timeRange}`,
          data: this.getTicketData('clubCount', granularity, this.dateRange[key].startDate),
          frame: key,
        });
        ret[key].push({
          name: `Team ${timeRange}`,
          data: this.getTicketData('teamCount', granularity, this.dateRange[key].startDate),
          frame: key,
        });
        ret[key].push({
          name: `Player ${timeRange}`,
          data: this.getTicketData('playerCount', granularity, this.dateRange[key].startDate),
          frame: key,
        });
      });
      ret.prev = ret.prev.concat(ret.now);
      return ret;
    },
    timeSeries() {
      const ret = {
        now: [],
        prev: [],
      };
      Object.keys(ret).forEach((key) => {
        const timeRange = this.getTimeRange(key);
        ret[key].push({
          name: `Total Time Spent ${timeRange}`,
          data: this.getTicketData('totalTimeSpent', this.viewBy[this.currentView], this.dateRange[key].startDate),
          frame: key,
        });
      });
      ret.prev = ret.prev.concat(ret.now);
      return ret;
    },
    yearSeries() {
      const ret = {
        now: {},
        prev: {},
      };
      const dateRange = this.dateRange;
      ret.now = {
        name: `${dateRange.now.startDate.format('YYYY')} -${dateRange.now.startDate.format('YYYY')}`,
        data: this.getTicketData('totalTicketCount', this.viewBy[this.currentView], dateRange.now.startDate),
      };
      ret.prev = {
        name: `${dateRange.prev.startDate.format('YYYY')} - ${dateRange.prev.endDate.format('YYYY')}`,
        data: this.getTicketData('totalTicketCount', this.viewBy[this.currentView], dateRange.prev.startDate),
      };
      return ret;
    },
    staffSeries() {
      const ret = [];
      const assigneeList = this.uniqAssignees;
      assigneeList.forEach((user) => {
        ret.push({
          name: user,
          data: this.getTicketData(`staffData.${user}.staffTime`, this.viewBy[this.currentView], this.dateRange.now.startDate),
        });
      });
      return ret;
    },
    categorySeries() {
      const data = this.getTicketSumData('category', this.viewBy[this.currentView], moment(this.chartDateRange.startDate), moment(this.chartDateRange.endDate));
      const ret = Object.keys(data).map(categoryName => ({
        name: this.categoryLabelMap[categoryName] ?? categoryName,
        y: data[categoryName],
      }));
      return ret;
    },
    typeSeries() {
      const data = this.getTicketSumData('type', this.viewBy[this.currentView], moment(this.chartDateRange.startDate), moment(this.chartDateRange.endDate));
      const ret = Object.keys(data).map(typeName => ({
        name: this.ticketTypeMap[typeName] ?? typeName,
        y: data[typeName],
      }));
      return ret;
    },
    timeResponseSeries() {
      const ret = [];
      const targets = ['total', 'officeHours', 'afterHours'];
      targets.forEach((target) => {
        ret.push({
          data: this.getTicketData(`responseTime.${target}`, this.viewBy[this.currentView], this.dateRange.now.startDate).map((data) => {
            if (!Array.isArray(data)) {
              // If no data found for targeted range (nothing will be printed at point)
              return NaN;
            }
            data.sort((a, b) => a - b);
            const half = Math.floor(data.length / 2);
            return data.length % 2 ? data[half] : (data[half - 1] + data[half]) / 2.0;
          }),
          name: _.startCase(target),
        });
      });
      return ret;
    },
    timeSolveSeries() {
      const ret = [];
      const targets = ['total', 'officeHours', 'afterHours'];
      targets.forEach((target) => {
        ret.push({
          data: this.getTicketData(`solveTime.${target}`, this.viewBy[this.currentView], this.dateRange.now.startDate).map((data) => {
            if (!Array.isArray(data)) {
              // If no data found for targeted range (nothing will be printed at point)
              return NaN;
            }
            data.sort((a, b) => a - b);
            const half = Math.floor(data.length / 2);
            return data.length % 2 ? data[half] : (data[half - 1] + data[half]) / 2.0;
          }),
          name: _.startCase(target),
        });
      });
      return ret;
    },
    handleTicketsFetch(dateRange) {
      if (dateRange.prev.startDate.isBefore(this.fetchTicketsRange.start_date)) {
        this.fetchTickets({ start_date: dateRange.prev.startDate.format('YYYY-MM-DD'), end_date: this.fetchTicketsRange.start_date });
        this.fetchTicketsRange.start_date = dateRange.prev.startDate.format('YYYY-MM-DD');
      }
      if (dateRange.now.endDate.isSameOrAfter(this.fetchTicketsRange.end_date)) {
        this.fetchTickets({ start_date: this.fetchTicketsRange.end_date, end_date: dateRange.now.endDate.format('YYYY-MM-DD') });
        this.fetchTicketsRange.end_date = dateRange.now.endDate.format('YYYY-MM-DD');
      }
    },
    getAssigneeFullName(username) {
      const id = this.getAssigneeID(username);
      if (id === -1) {
        return username;
      }
      const user = this.$store.getters.users.find(usr => usr.id === id);
      return user ? `${user.first_name} ${user.last_name}` : username;
    },
    getAssigneeID(name) {
      if (name) {
        const user = this.$store.getters.users.find(usr => usr.email.includes(name));
        return user ? user.id : -1;
      }
      return -1;
    },
    isUserCSS(name) {
      return name.col_0 !== 'Number of Tickets' && name.col_0 !== 'Time Spent' ? 'is-user' : '';
    },
    getTimeRange(key) {
      const dateRange = this.dateRange;
      return key === 'now'
        ? `${dateRange.now.startDate.format('YYYY')} - ${dateRange.now.endDate.format('YYYY')}`
        : `${dateRange.prev.startDate.format('YYYY')} - ${dateRange.prev.endDate.format('YYYY')}`;
    },
    getZonesData(path) {
      return { zones: _.get(this.zonesData, path, null), zoneAxis: 'x' };
    },
    getDefaultZoneObj(value) {
      return [{
        value,
        dashStyle: 'Solid',
      }, {
        dashStyle: 'ShortDash',
      }];
    },
    getTicketData(target, dateIncrement, startingDate) {
      const ret = [];
      const finalSegment = moment(startingDate).add(this.dateRangeDifference, dateIncrement);
      for (
        let segment = moment(startingDate).startOf(dateIncrement);
        segment.isBefore(finalSegment);
        segment.add(1, dateIncrement)
      ) {
        ret.push(target.split('.').reduce((acc, curr) => acc[curr] ?? 0,
          this.ticketMap[dateIncrement].get(this.getKey(segment, dateIncrement)) ?? {}));
      }
      return ret;
    },
    getTicketSumData(target, dateIncrement, startingDate, finalSegment) {
      const ret = {};
      for (
        let segment = moment(startingDate).startOf(dateIncrement);
        segment.isBefore(finalSegment);
        segment.add(1, dateIncrement)
      ) {
        const data = _.get(this.ticketMap[dateIncrement].get(this.getKey(segment, dateIncrement)) ?? {}, target, {});
        Object.keys(data).forEach((key) => {
          ret[key] = (ret[key] ?? 0) + data[key];
        });
      }
      return ret;
    },
    getKey(date, unit) {
      return moment(date).startOf(unit).format('YYYY-MM-DD');
    },
    viewName(viewName) {
      return Array.isArray(viewName) ? viewName.map(name => name.toLowerCase().replaceAll(' ', '_')) : viewName.toLowerCase().replaceAll(' ', '_');
    },
    fetchTickets(fetchRange) {
      return api().post('/cst/zendesk_tickets/search', fetchRange).then((res) => {
        if (res.status === 200) {
          const tickets = res.data;
          this.populateTicketMap(tickets);
        }
      });
    },
    generateDataRetainers() {
      this.viewBy = _.merge(
        this.viewNamesToStringKeyableObj(_.difference(this.viewName(this.allViews), dateRangeDropdownViews), 'month'),
        this.viewNamesToStringKeyableObj(dateRangeDropdownViews, 'day'),
      );
      this.dateRanges = _.merge(
        this.viewNamesToStringKeyableObj(_.difference(this.viewName(this.allViews), tableViews, dateRangeDropdownViews),
          {
            now: { startDate: moment().startOf('month').add(-1, 'year'), endDate: moment().add(1, 'month').startOf('month') },
            prev: { startDate: moment().startOf('month').add(-2, 'year'), endDate: moment().add(1, 'month').startOf('month').add(-1, 'year') },
          }),
        this.viewNamesToStringKeyableObj(tableViews, { now: { startDate: moment().startOf('month').add(-7, 'month'), endDate: moment() } }),
        this.viewNamesToStringKeyableObj(dateRangeDropdownViews, { startDate: moment().startOf('week').toDate(), endDate: moment().toDate() }),
      );
      this.generateOverlayToggle();
    },
    viewNamesToStringKeyableObj(list, defaultValue) {
      return list.reduce((acc, curr) => {
        acc[this.viewName(curr)] = defaultValue;
        return acc;
      }, {});
    },
    generateOverlayToggle() {
      this.overlayToggle = needOverlayToggle.reduce((acc, curr) => {
        acc[curr] = false;
        return acc;
      }, {});
    },
    generateTicketMap() {
      this.ticketMap = timeBuckets.reduce((acc, curr) => {
        acc[curr] = new Map();
        return acc;
      }, {});
    },
    populateTicketMap(tickets) {
      this.loading = true;
      tickets.forEach((ticket) => {
        const when = moment(ticket.created_at);
        timeBuckets.forEach((time) => {
          const key = this.getKey(when, time);
          const timeData = this.ticketMap[time].get(key) ?? {
            totalTimeSpent: 0,
            totalTicketCount: 0,
            full: 0,
            mid: 0,
            self: 0,
            clubCount: 0,
            teamCount: 0,
            playerCount: 0,
            not_in_system: 0,
            responseTime: { total: [], officeHours: [], afterHours: [] },
            solveTime: { total: [], officeHours: [], afterHours: [] },
            staffData: {},
            category: {},
            type: {},
          };
          const timeSpent = timeSpentMap[ticket.level_of_effort];
          if (ticket.assignee) {
            const userData = timeData.staffData[ticket.assignee] ?? { staffCount: 0, staffTime: 0 };
            userData.staffCount += 1;
            userData.staffTime += timeSpent;
            timeData.staffData[ticket.assignee] = userData;
          }
          if (ticket.product_category) {
            timeData.category[ticket.product_category] = (timeData.category[ticket.product_category] ?? 0) + 1;
          }
          if (ticket.type) {
            timeData.type[ticket.type] = (timeData.category[ticket.type] ?? 0) + 1;
          }
          const responseTime = moment(ticket.initially_assigned_at).diff(moment(ticket.created_at), 's');
          if (!Number.isNaN(responseTime)) {
            if (moment(ticket.created_at).isBetween(moment(ticket.created_at).hour(9), moment(ticket.created_at).hour(17), 'hour', '[)')) {
              timeData.responseTime.officeHours.push(responseTime);
            } else {
              timeData.responseTime.afterHours.push(responseTime);
            }
            timeData.responseTime.total.push(responseTime);
          }
          const solveTime = moment(ticket.solved_at).diff(moment(ticket.initially_assigned_at), 'seconds');
          if (!Number.isNaN(solveTime)) {
            if (moment(ticket.created_at).isBetween(moment(ticket.created_at).hour(9), moment(ticket.created_at).hour(17), 'hour', '[)')) {
              timeData.solveTime.officeHours.push(solveTime);
            } else {
              timeData.solveTime.afterHours.push(solveTime);
            }
            timeData.solveTime.total.push(solveTime);
          }
          timeData[(this.roleMap[ticket.user_role] ?? 'not_in_system')] += 1;
          timeData[normalizeOnboardingType(this.serviceByClubId.get(ticket.club_id))] += 1;
          timeData.totalTicketCount += 1;
          timeData.totalTimeSpent += timeSpent;
          this.ticketMap[time].set(key, timeData);
        });
      });
      this.loading = false;
      this.refreshChart += 1;
    },
  },
};

</script>

<style lang="sass" scoped>
.header-background
    width: 175px
    font-family: $family-headers
    font-size: 1.1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
.is-user
    background-color: #ebedf9
    font-family: $family-headers
    font-weight: $weight-bold
    color: $black
    border-color: #ebedf9
    padding: .75rem 0 .75rem .75rem
::v-deep .time-by-staff-table
  font-family: $family-fira
  font-weight: $weight-normal
  thead th:nth-child(n+9)
    display: none
.weeknav-parent
  display: flex
  justify-content: center
  position: relative
.weeknav-child
  position: absolute
  right: 0px
.charts-container
  padding: 2rem 0
  .table-options-container
    width: 100%
    display: flex
    justify-content: flex-end
    .table-options
      display: flex
      flex-direction: column
      align-items: flex-start
      padding-bottom: 1rem
      gap: 1rem
</style>
