<template>
  <Spinner v-if="loading"/>
  <div v-else>
    <div class="table-header">
      <div class="level-item week-picker">
        <div>
          <DateRangeDropdown
            v-model="dateRange"
            :presets="[
              { name: 'Last 30 Days', display: 'Last 30 Days', range: last30Days },
              { name: 'Last 3 Months', display: 'Last 3 Months', range: last3Months },
              { name: 'This Year', display: 'This Year', range: thisYear },
              { name: 'Last Year', display: 'Last Year', range: lastYear },
              { name: 'Lifetime', display: 'Lifetime', range: lifeTime },
            ]"
          />
        </div>
      </div>
      <b-field label="View Total As:">
          <b-select v-model="viewTotalAs">
            <option value="overall">Overall</option>
            <option value="primary">Primary</option>
            <option value="secondary">Secondary</option>
          </b-select>
        </b-field>
    </div>
    <b-table
      name="Staff by Time View"
      bordered
      :data="tableRows"
      class="day-schedule-table">
      <template slot-scope="props">
        <b-table-column label="Staff Member"
          field="name"
          header-class="is-unselectable header-background">
          {{ props.row.name | capitalize }}
        </b-table-column>
        <b-table-column label="Onboarding"
          field="onboarding"
          header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ toHours(props.row.onboarding) }} </div>
        </b-table-column>
        <b-table-column label="Meetings"
          field="meeting"
          header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ toHours(props.row.meeting) }} </div>
        </b-table-column>
        <b-table-column label="Total"
          field="total"
          header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ toHours(props.row.total) }} </div>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import moment from 'moment';
import api from '@/http-playmetrics';
import DateRangeDropdown from '@/components/common/DateRangeDropdown';
import Spinner from '@/components/common/Spinner';

export default {
  components: { DateRangeDropdown, Spinner },
  data() {
    return {
      loading: false,
      startDate: moment().add(-30, 'day'),
      endDate: moment(),
      activities: [],
      userIDs: [],
      viewTotalAs: 'overall',
    };
  },
  created() {
    this.fetchActivities();
  },
  computed: {
    dateRange: {
      get() {
        return { endDate: this.endDate.toDate(), startDate: this.startDate.toDate() };
      },
      set(val) {
        this.endDate = moment(val.endDate);
        this.startDate = moment(val.startDate);
      },
    },
    tableRows() {
      return Array.from(this.activitiesByUser.values(), (user) => {
        let onboarding = 0;
        let meeting = 0;
        if (this.viewTotalAs !== 'secondary') {
          onboarding += user.primary.onboarding;
          meeting += user.primary.meeting;
        }
        if (this.viewTotalAs !== 'primary') {
          onboarding += user.secondary.onboarding;
          meeting += user.secondary.meeting;
        }
        return {
          id: user.id,
          name: user.name,
          onboarding,
          meeting,
          total: onboarding + meeting,
        };
      });
    },
    activitiesByUser() {
      const map = new Map();
      for (let i = moment(this.startDate); i.isSameOrBefore(this.endDate, 'day'); i.add(1, 'day')) {
        const date = i.format('YYYY-MM-DD');
        const data = this.activitiesByDate.get(date);
        if (data) {
          Array.from(data.keys()).forEach((userID) => {
            const rowData = map.get(userID) ?? {
              id: userID,
              name: this.getName(userID),
              primary: { onboarding: 0, meeting: 0 },
              secondary: { onboarding: 0, meeting: 0 },
            };
            const userData = data.get(userID);
            rowData.primary.onboarding += userData.primary.onboarding;
            rowData.primary.meeting += userData.primary.meeting;
            rowData.secondary.onboarding += userData.secondary.onboarding;
            rowData.secondary.meeting += userData.secondary.meeting;
            map.set(userID, rowData);
          });
        }
      }
      return map;
    },
    activitiesByDate() {
      const map = new Map();
      this.activities.forEach((activity) => {
        const date = activity.meeting_date ? moment(activity.meeting_date).format('YYYY-MM-DD') : 'TBD';
        const data = map.get(date) ?? new Map();
        if (activity.lead_user_id) {
          const lead = data.get(activity.lead_user_id) ?? {
            secondary: { onboarding: 0, meeting: 0 },
            primary: { onboarding: 0, meeting: 0 },
          };
          lead.primary[this.getActivityType(activity.category)] += activity.duration ?? 0;
          map.set(date, data.set(activity.lead_user_id, lead));
        }
        activity.secondary_user_ids.forEach((id) => {
          const secondary = data.get(id) ?? {
            secondary: { onboarding: 0, meeting: 0 },
            primary: { onboarding: 0, meeting: 0 },
          };
          secondary.secondary[this.getActivityType(activity.category)] += activity.duration ?? 0;
          map.set(date, data.set(id, secondary));
        });
      });
      return map;
    },
    last30Days() {
      return {
        startDate: moment().subtract(30, 'days').toDate(),
        endDate: moment().toDate(),
      };
    },
    last3Months() {
      return {
        startDate: moment().subtract(3, 'months').toDate(),
        endDate: moment().toDate(),
      };
    },
    thisYear() {
      return {
        startDate: moment().startOf('year').toDate(),
        endDate: moment().toDate(),
      };
    },
    lastYear() {
      return {
        startDate: moment().startOf('year').subtract(1, 'year').toDate(),
        endDate: moment().startOf('year').toDate(),
      };
    },
    lifeTime() {
      return {
        startDate: moment().subtract(100, 'years').toDate(),
        endDate: moment().toDate(),
      };
    },
  },
  methods: {
    toHours(m) {
      if (typeof m === 'string') return m;
      if (m) {
        const hrs = Math.round((m / 60) * 100) / 100;
        return Math.floor(hrs) === hrs ? `${hrs}.00 hrs` : `${hrs} hrs`;
      } else return '-';
    },
    getActivityType(category) {
      if (category === 'meeting') {
        return 'meeting';
      }
      return 'onboarding';
    },
    fetchActivities() {
      this.loading = true;
      return api().post('/cst/activities/search').then((res) => {
        this.activities = res.data;
        this.loading = false;
      });
    },
    getName(id) {
      const u = this.$store.getters.users.find(user => user.id === id);
      return u ? `${u.first_name} ${u.last_name}` : 'Name Not Found';
    },
  },
};
</script>

<style lang="sass" scoped>
.table-header
  display: flex
  align-items: center
  flex-direction: row
  justify-content: space-between
.week-picker
  margin-top: 1rem
::v-deep .day-schedule-table
  font-family: $family-fira
  font-weight: $weight-normal
  padding-top: 1.5rem
  .header-background
    width: 175px
    font-family: $family-headers
    font-size: 1.1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
</style>
