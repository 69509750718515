<template>
  <div
    :class="[{'table-paginated-no-filter': !filterable, 'level-right-only': !levelLeftHasHeight}, customClass]"
    class="table-paginated">
    <nav
      v-if="(searchable || filterable || (sortable && isMobile) || $slots['header-left'])"
      :class="{ 'is-mobile': !wrapLevel }"
      class="level nav-table-header"
      style="align-items: flex-start">
      <div :style="levelLeftHasHeight ? 'height: 37px' : ''" class="level-left">
        <slot name="header-left"/>
      </div>
      <div
        class="level-right">
        <div class="level-item" style="justify-content:space-between;position:relative;">
          <slot name="header-right">
            <div  v-if="legend"
                class="legend-class">
                <slot name="filter"></slot>

              </div>
            <span
              v-if="searchable"
              :style="(isMobile && sortable) ? 'padding-right: 52px; width: 100%; min-width: 70%;':'padding-right: 5px;'"
              :class="{'search-bar__has-no-filter': !filterable}"
              class="control search-bar">
              <input
                v-model="searchQuery"
                :placeholder="searchPlaceholderText"
                class="input"
                type="text"
              >
            </span>
            <fa-icon
              v-if="sortable"
              :icon="['far', 'sort-amount-down']"
              class="table-mobile-sort-icon is-hidden-tablet"
            />
            <div>
            <app-filter-button v-if="filterable" :filters-active="filtersActive"/>



            </div>


          </slot>
        </div>

        <div v-if="!hasRightContent" class="level-item">&nbsp;</div>
      </div>
    </nav>
    <div v-if="customHeader">
      <slot name="custom-table-header"/>
    </div>
    <div v-else style="display: flex;margin:10px 0">
      <slot name="table-header"/>
    </div>
    <b-table
      ref="table"
      v-bind="$attrs"
      :current-page="currentPage"
      :data="data"
      :default-sort="currentSort"
      :per-page="perPage"
      :paginated="hasPages"
      pagination-simple
      pagination-position="none"
      v-on="$listeners"
      @page-change="pageChange"
      @sort="sortChange"
      @check-all="$emit('check-all')"
    >
      <template slot-scope="props">
        <slot
          :row="props.row"
          :index="props.index"
          :column="props.column" />
      </template>
      <template slot="empty">
        <slot name="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <p>{{ emptyMessage }}</p>
            </div>
          </section>
        </slot>
      </template>
      <template v-if="$slots.detail || $scopedSlots.detail" slot="detail" slot-scope="props">
        <slot
          name="detail"
          :row="props.row"
          :index="props.index"
        />
      </template>
    </b-table>
    <nav
      v-if="data.length > 0 && showPagination"
      class="level is-mobile"
      :style="`margin-top: 1.5rem; ${paginationStyle}`">
      <div class="level-left">
        <div v-if="hasPages" class="level-item">
          <b-select v-model="perPage">
            <option value="10">10 per page</option>
            <option value="15">15 per page</option>
            <option value="20">20 per page</option>
            <option value="50">50 per page</option>
            <option value="100">100 per page</option>
          </b-select>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <b-pagination
            :total="data.length"
            :current.sync="currentPage"
            :simple="true"
            :per-page="perPage"
            @change="pageChange"/>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import FilterButton from '../common/filter/FilterButton';

export default {
  components: {
    'app-filter-button': FilterButton,
  },
  props: {
    data: { type: Array, default: () => [] },
    defaultSort: { type: Array, default: null },
    emptyMessage: { type: String, default: 'No results' },
    filterable: { type: Boolean, default: true },
    filtersActive: { type: Boolean, default: false },
    name: { type: String, required: true },
    context: { type: String, default: null },
    searchable: { type: Boolean, default: true },
    sortable: { type: Boolean, default: true },
    customHeader: { type: Boolean, default: false },
    wrapLevel: { type: Boolean, default: false },
    fixedNavHeight: { type: Boolean, default: true },
    customClass: { type: String, default: null },
    searchPlaceholderText: { type: String, default: 'Search' },
    defaultPerPage: { type: Number, default: null },
    paginationStyle: { type: String, default: '' },
    showPagination: { type: Boolean, default: true },
    legend: { type: Boolean, default: false },
  },
  data() {
    return {
      searchQuery: this.$store.getters.tableSearchFor(this.name),
      currentPage: this.$store.getters.currentPageFor[this.context || this.name] || 1,
    };
  },
  computed: {
    currentSort() {
      const sort = this.$store.getters.tableSortFor(this.name);
      return sort ? [sort.sort, sort.direction] : this.defaultSort;
    },
    perPage: {
      get() { return this.$store.getters.perPage; },
      set(value) { this.$store.commit('setPerPage', value); },
    },
    isAllChecked() {
      return this.$refs.table.isAllChecked;
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    hasPages() {
      return this.data.length > this.perPage;
    },
    levelLeftHasHeight() {
      return this.wrapLevel && this.isMobile && this.fixedNavHeight;
    },
    hasRightContent() {
      return this.searchable || this.filterable || !!this.$slots['"header-right'];
    },
    legendCheck() {
      if (this.legend) {
        return 'legend-padding';
      } else {
        return '';
      }
    },
  },
  watch: {
    searchQuery() {
      this.$emit('queryUpdated', this.searchQuery);
      this.$store.commit('setTableDataFor', { name: this.name, search: this.searchQuery });
    },
    context() {
      this.currentPage = this.$store.getters.currentPageFor[this.context || this.name] || 1;
    },
  },
  created() {
    this.$emit('queryUpdated', this.searchQuery);
    if (this.defaultPerPage) {
      this.perPage = this.defaultPerPage;
    }
  },
  methods: {
    pageChange(page) {
      this.$store.commit('setCurrentPageFor', { name: this.context || this.name, value: page });
    },
    sortChange(sort, direction) {
      this.$store.commit('setTableDataFor', { name: this.name, sort: { sort, direction } });
    },
  },
};
</script>

<style lang="sass">
.table-paginated
  position: relative
  .b-table
    position: relative
  .table-mobile-sort
    top: -62.5px !important
    right: 82px !important
    .select select
      background-color: transparent
    .select:not(.is-multiple):not(.is-loading)::after
      border-color: white
  .table-mobile-sort-icon
    position: absolute
    top: 8px !important
    right: 54px !important
  &.table-paginated-no-filter
    .table-mobile-sort
      right: 46px !important
    .table-mobile-sort-icon
      right: 18px !important
.filter-slot-class
  right: 0rem
  position: absolute
.legend-class
  display: flex
  flex-direction: column
  margin-right: 1.5rem
.legend-padding
  padding-top: 1.5rem
</style>
