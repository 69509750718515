<template>
  <div>
    <div class="notes">
      <b-field
        :type="noteInvalid ? 'is-danger' : ''"
        label="Add Note">
        <b-input v-model="noteText" type="textarea" />
      </b-field>
      <div class="notes__flex">
        <div>
          <span :class="noteInvalid ? 'notes__note-invalid' : ''">
            {{ noteText.length }}
          </span> / 4000
        </div>
        <b-button :disabled="!canSave"
          @click="addNote"
          class="notes__button"
          type="is-primary">Add Note</b-button>
      </div>
      <template v-for="note in orderedNotes">
        <Note :note="note" :key="note.id" @delete="confirmDeleteNote($event)" />
      </template>
    </div>
  </div>
</template>

<script>

import api from '@/http-playmetrics';
import Note from '@/components/common/notes/Note';

export default {
  components: { Note },
  props: {
    club: { type: Object, default: null },
  },
  data() {
    return {
      newNotes: [],
      noteText: '',
      notes: [],
      editing: false,
    };
  },
  mounted() {
    if (this.club?.id) {
      this.editing = true;
      this.fetchClubNotes();
    } else {
      this.editing = false;
    }
  },
  computed: {
    orderedNotes() {
      return [...this.notes].sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
    canSave() {
      return !this.noteInvalid && this.noteText.trim() !== '';
    },
    noteInvalid() {
      return this.noteText.length > 4000;
    },
  },
  methods: {
    deleteNewNotes() {
      this.newNotes.forEach(note => this.deleteNote(note));
    },
    fetchClubNotes(noteAdded) {
      api({ showError: false }).get(`/cst/clubs/${this.club.id}/?populate=notes`).then((res) => {
        this.notes = res.data.notes || [];
        if (noteAdded) {
          this.newNotes.push(res.data.notes[res.data.notes.length - 1]);
        }
      });
    },
    async addNote() {
      this.notes.push({ note: this.noteText, created_at: (new Date()).toISOString() });
      if (this.editing) {
        await this.postNotes(this.club.id);
        await this.fetchClubNotes(true);
      }
      this.noteText = '';
    },
    // Save NEW notes after club gets created
    postNotes(id) {
      return api().post(`/cst/clubs/${id}/notes`, this.notes.filter(n => !n.id));
    },
    confirmDeleteNote($event) {
      this.$confirm.confirm({
        confirmText: 'Yes, Delete Note',
        message: `You are about delete this club note.
                  Are you sure you want to continue?`,
        title: 'Delete Club Note',
        type: 'is-danger',
        icon: 'minus-hexagon',
        onConfirm: () => this.deleteNote($event),
      });
    },
    deleteNote($event) {
      if (this.editing) {
        // actually delete the note if were editing
        api().delete(`/cst/clubs/${this.club.id}/notes/${$event.id}`).then(() => {
          this.fetchClubNotes();
        });
      } else {
        this.notes = this.notes.filter(n => n.created_at !== $event.created_at);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.notes
  &__button
    display: block
    margin-left: auto
    margin-right: 0
  &__flex
    display: flex
  &__note-invalid
    color: $red
</style>
