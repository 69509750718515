<template>
  <div>
    <div
     v-if="visible"
      :class="modalClasses"
      class="modal is-active">
      <div
        class="modal-background"
        @click.stop="dismissOutside()"
      />
      <div
        :style="style"
        class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">
            <slot name="header">
              {{ title }}
            </slot>
          </p>
          <div v-if="isAllWhiteButFooter"/>
          <b-button
            v-else-if="isAllWhite"
            type="is-text"
            aria-label="close"
            class="close-button"
            @click.stop="closeModal()">
            <fa-icon :icon="['fal', 'times']" />
          </b-button>
          <button
            v-else
            class="delete"
            aria-label="close"
            @click.stop="closeModal()" />
        </header>
        <section ref="modalBody" class="modal-card-body" :class="shiftedUpHeader">
          <slot/>
        </section>
        <footer
          class="modal-card-foot modal-button-spread"
          :class="shiftedUpFooter">
          <button
            v-if="hasCancelButton && $slots.footer"
            :class="isAllWhite ? 'is-primary is-outlined' : 'is-text cancel-button'"
            class="button has-text-weight-normal"
            @click="closeModal()">
            Cancel
          </button>
          <slot name="footer"/>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modalClass: { type: String, default: '' },
    hasDatepicker: { type: Boolean, default: false },
    hasCancelButton: { type: Boolean, default: true },
    isAllWhite: { type: Boolean, default: false },
    isAllWhiteButFooter: { type: Boolean, default: false },
    wideModal: { type: Boolean, default: false },
    maxWidth: { type: String, default: '' },
    title: { type: String, default: '' },
    useMobileModal: { type: Boolean, default: false },
    dismissOutsideClick: { type: Boolean, default: true },
    isFullHeightMobile: { type: Boolean, default: true },
    isAboveFullscreenModal: { type: Boolean, default: false },
  },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    modalClasses() {
      const ret = [];
      ret.push(this.modalClass);
      if (this.hasDatepicker) ret.push('modal-allow-overflow');
      if (this.isAllWhite) ret.push('modal-all-white');
      if (this.isAllWhiteButFooter) ret.push('modal-all-white-but-footer');
      if (this.isAboveFullscreenModal) ret.push('is-above-fullscreen-modal');
      return ret;
    },
    style() {
      if (this.maxWidth !== '') return `width: ${this.maxWidth}; max-width: 100%`;
      return this.wideModal ? 'width: 912px; max-width: 100%' : '';
    },
    isMobile() {
      return this.$store && this.$store.getters.isMobile;
    },
    shiftedUpHeader() {
      if (this.isAllWhite && !(this.title || this.$slots.header)) return 'is-shifted-up';
      return '';
    },
    shiftedUpFooter() {
      if (this.isAllWhite && !this.$slots.footer) return 'is-shifted-up';
      return '';
    },
  },

  methods: {
    showModal() {
      this.visible = true;
    },
    closeModal() {
      this.$emit('closeModal');
      this.dismissModal();
    },
    dismissOutside() {
      if (this.dismissOutsideClick) this.dismissModal();
      else this.$emit('outside-click');
    },
    dismissModal() {
      this.visible = false;
      this.$emit('dismissModal');
    },
    modalBody() {
      return this.$refs.modalBody;
    },
  },
};
</script>
<style lang="sass" scoped>
@import "../../../../node_modules/bulma/sass/utilities/mixins"
.modal.modal-top
  justify-content: normal
  .modal-card-head
    margin-top: 2rem
.modal .modal-card-foot
  > *:first-child
    margin-left: 0
    margin-right: auto
  > *:last-child
    margin-left: auto
    margin-right: 0
.modal .modal-card-head
  .modal-card-title
    font-family: $family-headers
    font-weight: $weight-medium
.modal.is-above-fullscreen-modal
  z-index: 101
.modal.modal-all-white-but-footer
  .modal-card
    box-shadow: 0px 2px 15px -5px rgba(0, 0, 0, 0.15), 0px 6px 32px -4px rgba(0, 0, 0, 0.2)
    border-radius: 8px
  .modal-card-head
    background-color: white
    align-items: flex-start
    padding-bottom: 0px
  .modal-card-body
    margin-top: -1rem
  .modal-card-foot
    padding: 0 2.5rem
  .modal-background
    background-color: transparent !important
.modal.modal-all-white
  .modal-card
    +mobile
      margin: 0
  .modal-card-head
    background-color: white
    align-items: flex-start
    padding-bottom: 0px
    .close-button
      color: $blue-slate
      font-size: $size-4
      padding: 0.5rem 0.75rem
      height: auto
      line-height: 1
      position: relative
      right: -10px
      top: -10px
      background-color: white !important
      +mobile
        font-size: $size-5
    .modal-card-title
      font-weight: $weight-semibold
      font-size: $size-4
      // margin-top: 1.25rem
      margin-bottom: 1.5rem
      flex-shrink: 1
      line-height: 1.25
      +mobile
        font-size: $size-5
        margin-top: 0.75rem
  .modal-card-foot
    background-color: white
    &.is-shifted-up
      margin-top: -1.5rem
  .modal-card-body
    padding-top: 0px
    z-index: 5
    overscroll-behavior: contain
    &.is-shifted-up
      margin-top: -1.5rem
.modal.modal-allow-overflow
  &:not(.modal-inner-scroll)
    overflow: auto
  &.modal-inner-scroll .modal-card .modal-card-body
    overflow: scroll
    position: relative
    overscroll-behavior: contain
  .modal-background
    position: fixed
  .modal-card
    overflow: visible
    .modal-card-body
      overflow: visible
      ::v-deep .datepicker-header .field
        margin-bottom: 0
        margin-top: 0
      ::v-deep .tab-content
        overflow: visible
    .modal-card-foot
      +mobile
        margin-bottom: 5rem
.modal.modal-long-header
  .modal-card-title
    width: 80%
.modal.modal-min-height
  .modal-card
    min-height: 25rem
.modal.modal-hidden-behind .modal-card .modal-card-body
  overflow: visible !important
.modal-card
  &.modal-narrow
    max-width: 400px
</style>
