<template>
  <spinner v-if="loading"/>
  <div v-else>
    <div class="level-item">
      <div class="level-item week-picker">
        <WeekNavigationWithPicker
          v-model="selectedDate"
          :byFiveWeeks="true"
          has-prev
          has-next/>
      </div>
    </div>
    <TablePaginated
      name="Staff Weekly Summary"
      v-if="tableRows.length"
      :searchable="false"
      :filterable="false"
      :showPagination="false"
      :defaultPerPage="tableRows.length"
      bordered
      scrollable
      :data="tableRows"
      class="weekly-summary-table">
      <template slot="header-left">
        <b-switch v-model="onboardingOnly">Show only onboarding activities</b-switch>
      </template>
      <template slot="header-right">
        <div class="view-selector">
          <strong> View hours as: </strong>
          <b-select
            style="margin-left: 1rem"
            placeholder="Primary"
            v-model="currentView">
            <option
              v-for="view in views"
              :value="view"
              :key="view">
              {{ view }}
            </option>
          </b-select>
        </div>
      </template>
      <template slot-scope="props">
        <b-table-column field="user_name"
          class="user-name-column"
          header-class="is-unselectable header-background"
          :cell-class="cellBackground(0, props.row.is_header)"
          width="16rem">
          <a v-if="!(props.row.user_name === 'Total' || props.row.user_name === '% of Capacity' ||
            props.row.user_name.includes('Former User'))"
            @click="viewUser(props.row.id)">
            {{ props.row.user_name | capitalize }} </a>
          <div v-else> {{ props.row.user_name }} </div>
        </b-table-column>

        <b-table-column field="first_week"
          centered
          :cell-class="cellBackground(props.row.first_week, props.row.is_header)"
          header-class="is-unselectable header-background"
          :label="`${formatWeek(0)}`"
          width="16rem"
          :class="isSelectable(props.row.id, props.row.first_week)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ formatWeek(0) }} </p> <div v-if="week === 'first_week'" class="indicator"/>
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.first_week)"
            @click="showModal(props.row.id, props.row.first_week,
            props.row.user_name, activitiesForWeek(props.row.id, weeks[0]), weeks[0])"> <div class="has-text-right">
            {{ toHours(props.row.first_week) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.first_week) }} </div>
        </b-table-column>

        <b-table-column field="second_week"
          centered
          :cell-class="cellBackground(props.row.second_week, props.row.is_header)"
          header-class="is-unselectable header-background"
          :label="`${formatWeek(1)}`"
          width="16rem"
          :class="isSelectable(props.row.id, props.row.second_week)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ formatWeek(1) }} </p> <div v-if="week === 'second_week'" class="indicator"/>
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.second_week)"
            @click="showModal(props.row.id, props.row.second_week,
            props.row.user_name, activitiesForWeek(props.row.id, weeks[1]), weeks[1])"> <div class="has-text-right">
            {{ toHours(props.row.second_week) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.second_week) }} </div>
        </b-table-column>

        <b-table-column field="third_week"
          centered
          :cell-class="cellBackground(props.row.third_week, props.row.is_header)"
          header-class="is-unselectable header-background"
          :label="`${formatWeek(2)}`"
          width="16rem"
          :class="isSelectable(props.row.id, props.row.third_week)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ formatWeek(2) }} </p> <div v-if="week === 'third_week'" class="indicator"/>
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.third_week)"
            @click="showModal(props.row.id, props.row.third_week,
            props.row.user_name, activitiesForWeek(props.row.id, weeks[2]), weeks[2])"> <div class="has-text-right">
            {{ toHours(props.row.third_week) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.third_week) }} </div>
        </b-table-column>

        <b-table-column field="fourth_week"
          centered
          :cell-class="cellBackground(props.row.fourth_week, props.row.is_header)"
          header-class="is-unselectable header-background"
          :label="`${formatWeek(3)}`"
          width="16rem"
          :class="isSelectable(props.row.id, props.row.fourth_week)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ formatWeek(3) }} </p> <div v-if="week === 'fourth_week'" class="indicator"/>
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.fourth_week)"
            @click="showModal(props.row.id, props.row.fourth_week,
            props.row.user_name, activitiesForWeek(props.row.id, weeks[3]), weeks[3])"> <div class="has-text-right">
            {{ toHours(props.row.fourth_week) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.fourth_week) }} </div>
        </b-table-column>

        <b-table-column field="fifth_week"
          centered
          :cell-class="cellBackground(props.row.fifth_week, props.row.is_header)"
          header-class="is-unselectable header-background"
          :label="`${formatWeek(4)}`"
          width="16rem"
          :class="isSelectable(props.row.id, props.row.fifth_week)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ formatWeek(4) }} </p> <div v-if="week === 'fifth_week'" class="indicator"/>
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.fifth_week)"
            @click="showModal(props.row.id, props.row.fifth_week,
            props.row.user_name, activitiesForWeek(props.row.id, weeks[4]), weeks[4])"> <div class="has-text-right">
            {{ toHours(props.row.fifth_week) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.fifth_week) }} </div>
        </b-table-column>

        <b-table-column field="sixth_week"
          centered
          :cell-class="cellBackground(props.row.sixth_week, props.row.is_header)"
          header-class="is-unselectable header-background"
          :label="`${formatWeek(5)}`"
          width="16rem"
          :class="isSelectable(props.row.id, props.row.sixth_week)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ formatWeek(5) }} </p> <div v-if="week === 'sixth_week'" class="indicator"/>
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.sixth_week)"
            @click="showModal(props.row.id, props.row.sixth_week,
            props.row.user_name, activitiesForWeek(props.row.id, weeks[5]), weeks[5])"> <div class="has-text-right">
            {{ toHours(props.row.sixth_week) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.sixth_week) }} </div>
        </b-table-column>

        <b-table-column field="seventh_week"
          centered
          :cell-class="cellBackground(props.row.seventh_week, props.row.is_header)"
          header-class="is-unselectable header-background"
          :label="`${formatWeek(6)}`"
          width="16rem"
          :class="isSelectable(props.row.id, props.row.seventh_week)">
          <template slot="header"> <div style="display: flex; align-items: center;" >
            <p class="header-font"> {{ formatWeek(6) }} </p> <div v-if="week === 'seventh_week'" class="indicator"/>
          </div> </template>
          <a v-if="isSelectable(props.row.id, props.row.seventh_week)"
            @click="showModal(props.row.id, props.row.seventh_week,
            props.row.user_name, activitiesForWeek(props.row.id, weeks[6]), weeks[6])"> <div class="has-text-right">
            {{ toHours(props.row.seventh_week) }} </div></a>
          <div v-else class="has-text-right"> {{ toHours(props.row.seventh_week) }} </div>
        </b-table-column>
      </template>
    </TablePaginated>
    <template v-else>
      <section class="section">
        <div class="content has-text-grey has-text-centered">
          <p>No Activities this Week</p>
        </div>
      </section>
    </template>
    <WeekUserActivitiesModal ref="userActivities"/>
  </div>
</template>

<script>
import moment from 'moment';
import Spinner from '@/components/common/Spinner';
import api from '@/http-playmetrics';
import TablePaginated from '@/components/layout/TablePaginated';
import WeekUserActivitiesModal from '@/components/onboarding/reports/WeekUserActivitiesModal';
import WeekNavigationWithPicker from '@/components/onboarding/reports/WeekNavigationWithPicker';

const iWeek = {
  0: 'first_week',
  1: 'second_week',
  2: 'third_week',
  3: 'fourth_week',
  4: 'fifth_week',
  5: 'sixth_week',
  6: 'seventh_week',
};

export default {
  components: { Spinner, TablePaginated, WeekUserActivitiesModal, WeekNavigationWithPicker },
  data() {
    return {
      unfilteredActivities: [],
      onboardingOnly: false,
      currentView: 'Overall',
      views: ['Overall', 'Primary', 'Secondary'],
      loading: false,
      selectedDate: new Date(),
    };
  },
  created() {
    this.fetchActivities();
  },
  computed: {
    activities() {
      return this.onboardingOnly
        ? this.unfilteredActivities.filter(activity => activity.category !== 'meeting')
        : this.unfilteredActivities;
    },
    week() {
      const curr = moment().startOf('isoWeek');
      for (let i = 0; i < 7; i += 1) {
        if (this.weeks[i].isSame(curr, 'day')) return iWeek[i];
      }
      return '';
    },
    weeks() {
      const ret = [];
      for (let i = 0; i < 7; i += 1) {
        ret.push(moment(this.selectedDate).add(i, 'week').startOf('isoWeek'));
      }
      return ret;
    },
    numCSMembers() {
      const ret = {};
      for (let i = 0; i < 7; i += 1) {
        const date = moment(this.startingDate).add(i, 'week').startOf('isoWeek').format('YYYY-MM-DD');
        ret[iWeek[i]] = this.getCSStaffForWeek(date).length;
      }
      return ret;
    },
    startingDate() {
      return moment(this.selectedDate).startOf('isoWeek').format('YYYY-MM-DD');
    },
    totalTimeSpent() {
      const ret = {};
      this.userIDs.forEach((id) => {
        const userTimes = this.timeSpent(id);
        for (let i = 0; i < 7; i += 1) {
          ret[iWeek[i]] = userTimes[iWeek[i]] + (ret[iWeek[i]] ?? 0);
        }
      });
      return ret;
    },
    totalRow() {
      return {
        ...this.totalTimeSpent,
        is_header: true,
        user_name: 'Total',
      };
    },
    totalPercentages() {
      const ret = {};
      for (let i = 0; i < 7; i += 1) {
        ret[iWeek[i]] = this.totalRow[iWeek[i]]
          ? `${Math.round((this.totalRow[iWeek[i]] / (60 * 16 * this.numCSMembers[iWeek[i]])) * 10000) / 100}%`
          : '-';
      }
      return ret;
    },
    percentageRow() {
      return {
        ...this.totalPercentages,
        is_header: true,
        user_name: '% of Capacity',
      };
    },
    tableRows() {
      const users = this.userIDs.map(id => ({
        ...this.timeSpent(id),
        id,
        user_name: this.getUserName(id),
      }));
      return [...users, this.totalRow, this.percentageRow];
    },
    userIDs() {
      return Array.from(this.activitiesByUser.keys());
    },
    activitiesByUser() {
      const userByID = new Map();
      this.activities.forEach((activity) => {
        const week = moment(activity.meeting_date, 'YYYY-MM-DD').startOf('isoWeek').format('YYYY-MM-DD');
        if (activity.lead_user_id) {
          const leadUser = userByID.get(activity.lead_user_id) ?? {
            lead_activities: new Map(),
            secondary_activities: new Map(),
            id: activity.lead_user_id,
            name: this.getUserName(activity.lead_user_id),
          };
          const data = leadUser.lead_activities.get(week) ?? [];
          data.push(activity);
          leadUser.lead_activities.set(week, data);
          userByID.set(activity.lead_user_id, leadUser);
        }
        activity.secondary_user_ids.forEach((id) => {
          const secondaryUser = userByID.get(id) ?? {
            lead_activities: new Map(),
            secondary_activities: new Map(),
            id,
            name: this.getUserName(id),
          };
          const data = secondaryUser.secondary_activities.get(week) ?? [];
          data.push(activity);
          secondaryUser.secondary_activities.set(week, data);
          userByID.set(id, secondaryUser);
        });
      });
      return userByID;
    },
  },
  methods: {
    viewUser(id) {
      if (id) {
        this.$router.push({ name: 'UserScheduleView', params: { userId: id, dateRange: 'weekly' } });
      }
    },
    showModal(id, time, name, activities, date) {
      this.$refs.userActivities.showModal(id, time, name, activities, date);
    },
    activitiesForWeek(id, week) {
      const formattedWeek = week.format('YYYY-MM-DD');
      const primaryActivities = this.activitiesByUser.get(id).lead_activities.get(formattedWeek) || [];
      const secondaryActivities = this.activitiesByUser.get(id).secondary_activities.get(formattedWeek) || [];
      if (this.currentView === 'Primary') return primaryActivities;
      if (this.currentView === 'Secondary') return secondaryActivities;
      return [...primaryActivities, ...secondaryActivities];
    },
    formatWeek(week) {
      return this.weeks[week].format('MMM DD');
    },
    toHours(m) {
      if (typeof m === 'string') return m;
      if (m) {
        const hrs = Math.round((m / 60) * 100) / 100;
        return Math.floor(hrs) === hrs ? `${hrs}.00 hrs` : `${hrs} hrs`;
      } else return '-';
    },
    isSelectable(id, week) {
      return this.userIDs.find(user => user === id) && this.toHours(week) !== '-' ? 'user-name-column' : '';
    },
    cellBackground(mins, header) {
      if (header) return 'is-first';
      if (mins > 720) return 'is-hours-danger';
      if (mins > 570) return 'is-hours-warning';
      return '';
    },
    getCSStaffForWeek(date) {
      const ret = [];
      this.userIDs.forEach((id) => {
        const userData = this.activitiesByUser.get(id);
        if (userData.lead_activities.get(date) || userData.secondary_activities.get(date)) {
          ret.push(id);
        }
      });
      return ret;
    },
    timeSpent(id) {
      const ret = {};
      for (let i = 0; i < 7; i += 1) {
        let time = 0;
        const date = moment(this.startingDate).add(i, 'week').startOf('isoWeek').format('YYYY-MM-DD');
        const data = this.activitiesByUser.get(id);
        if (this.currentView !== 'Primary') {
          time = data.secondary_activities.get(date)
            ? data.secondary_activities.get(date).reduce((acc, activity) => acc + activity.duration, time)
            : time;
        }
        if (this.currentView !== 'Secondary') {
          time = data.lead_activities.get(date)
            ? data.lead_activities.get(date).reduce((acc, activity) => acc + activity.duration, time)
            : time;
        }
        ret[iWeek[i]] = time;
      }
      return ret;
    },
    getUserName(id) {
      const u = this.$store.getters.users.find(user => user.id === id);
      if (u) {
        return `${u.first_name} ${u.last_name}`;
      }
      return `Former User (ID: ${id})`;
    },
    fetchActivities() {
      this.loading = true;
      return api().post('/cst/activities/search').then((res) => {
        this.unfilteredActivities = res.data.filter(activity => activity.meeting_date);
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.user-name-column:hover
  background-color: $white-ter
.week-picker
  margin-top: 1rem
.view-selector
  display: flex
  align-items: center
  justify-content: flex-end
::v-deep .weekly-summary-table
  font-family: $family-fira
  font-weight: $weight-normal
  thead th:nth-child(n+9)
    display: none
  .is-hours-danger
    background-color: rgba($danger, 0.1)
  .is-hours-warning
    background-color: rgba(#FFC000, 0.25)
  .header-background
    background-color: $blue-slate
    padding: .75rem 0 .75rem 1.25rem
    .header-font
      font-family: $family-headers
      font-size: 1rem
      color: $white !important
    .indicator
      margin-left: .5rem
      background-color: $orange
      border-radius: 100%
      width: .6rem
      height: .6rem
  .is-first
    background-color: #ebedf9
</style>
