<template>
  <div>
    <FilterTray
      title="Filter Activities"
      :filters-active="filtersActive"
      @clear-filters="clearFilters">
      <FilterSection
        collapsible
        label="Onboarding Type">
        <b-field v-for="o in onboarding_type" :key="o.value">
          <b-checkbox v-model="filters.onboarding_type" :native-value="o.value"> {{ o.name }} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection
        v-if="!isSummary"
        collapsible
        label="Category and Milestone">
        <div v-for="c in category" :key="c.value">
          <b-checkbox v-model="filters.category"
            @input="updateCategory()"
            style="font-size: 1.1rem;"
            :native-value="c.value"> {{ c.name }} </b-checkbox>
          <div style="padding-left: .5rem;" v-for="(milestone, cat) in milestonesSelect[c.value]" :key="cat">
            <b-checkbox v-model="filters.milestone"
              :native-value="milestone.value"> {{ milestone.name }} </b-checkbox>
          </div>
        </div>
      </FilterSection>
      <FilterSection
        v-if="!isSummary"
        collapsible
        label="Customer Success Staff">
        <b-field v-for="staff in csStaff" :key="staff.id">
          <b-checkbox v-model="filters.cs_staff" :native-value="staff.id">
            {{ staff.first_name }} {{ staff.last_name }}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection
        collapsible
        label="Total Time Spent">
        <b-field label="Time is">
          <b-select v-model="filters.time_spent.status">
            <option
              v-for="status in timeStatus"
              :value="status"
              :key="status">
              {{ status }}
            </option>
          </b-select>
        </b-field>
        <div>
          <span class="label">Hours</span>
        </div>
        <b-field>
          <b-input v-model="filters.time_spent.hours"
            type="number"
            @input="updateDuration()"
            style="margin-right: 1rem;"/>
          <b-select v-model="filters.time_spent.minutes" @input="updateDuration()">
            <option
              v-for="time in timeMinutes"
              :value="time.value"
              :key="time.value">
              {{ time.name }}
            </option>
          </b-select>
        </b-field>
        <button class="button is-danger is-small" style="margin-top: 0.25rem" @click="clearTimeSpent">Clear</button>
      </FilterSection>
      <FilterSection
        v-if="!isSummary"
        collapsible
        label="Club Type">
        <b-field label="Type">
          <b-select v-model="filters.club_type">
            <option  value="customer">Customer</option>
            <option value="prospect">Prospect</option>
          </b-select>
        </b-field>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterSection,
    FilterTray,
  },

  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('activities')),
    };
  },

  props: {
    isSummary: { type: Boolean, default: false },
  },

  computed: {
    timeStatus() {
      return ['Shorter Than', 'Longer Than'];
    },
    filtersActive() {
      return this.isFiltersActive(this.$store.getters.activeFiltersFor('activities'));
    },
    onboarding_type() {
      return this.$store.getters.activityOnboardingType;
    },
    category() {
      return this.$store.getters.activityOnboardingCategory;
    },
    milestone() {
      return this.$store.getters.activityMilestones;
    },
    milestonesSelect() {
      return _.pick(this.milestone, this.filters.category);
    },
    csStaff() {
      return this.$store.getters.usersByDepartments(['product', 'success'])
        .filter(staff => staff.first_name || staff.last_name);
    },
    timeMinutes() {
      return [
        {
          name: '0 mins',
          value: 0,
        },
        {
          name: '15 mins',
          value: 15,
        },
        {
          name: '30 mins',
          value: 30,
        },
        {
          name: '45 mins',
          value: 45,
        },
      ];
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    updateCategory() {
      let newMilestones = [];
      this.filters.category.forEach((category) => {
        newMilestones = newMilestones.concat(this.milestonesSelect[category].map(milestone => milestone.value));
      });
      this.filters.milestone = this.filters.milestone.filter(milestone => newMilestones.includes(milestone));
    },
    isFiltersActive(filters) {
      return Object.keys(filters).some((key) => {
        if (key === 'time_spent') {
          if (filters[key].status) {
            return true;
          }
          return false;
        }
        if (key === 'club_type') {
          return !(filters[key] === 'customer');
        }
        if (filters[key]) {
          return true;
        }
        return false;
      });
    },
    updateDuration() {
      this.filters.time_spent.duration = (this.filters.time_spent.hours * 60) + this.filters.time_spent.minutes;
    },
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'activities' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('activities'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'activities', filters: _.cloneDeep(this.filters) });
    },
    clearTimeSpent() {
      this.filters.time_spent.duration = 0;
      this.filters.time_spent.hours = 0;
      this.filters.time_spent.minutes = 0;
      this.filters.time_spent.status = null;
    },
  },
};
</script>
