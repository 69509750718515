<template>
  <div>
    <div v-if="loading">
      <spinner />
    </div>
    <div v-else>
      <PageNavigation page-title="Digital Documents" />
      <div class="bis-box full-height">
        <div class="flex" style="margin-bottom: 2rem">
          <div class="has-text-weight-semibold is-font-mont is-size-4">Total Document Usage</div>
        </div>
        <div class="flex" style="margin-right: 10rem">
          <div>
            <div class="has-text-weight-semibold is-font-mont">Actual Count:</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4">
              {{ totalActualCount.toLocaleString()}}
          </div>
          </div>
          <div>
            <div class="has-text-weight-semibold is-font-mont">Contract Count:</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4">
              {{ contractCount.toLocaleString() }}
          </div>
          </div>
          <div>
            <div class="has-text-weight-semibold is-font-mont">Remaining:</div>
            <div class="has-text-weight-bold is-font-mont has-text-info is-size-4">
              {{ totalRemaining.toLocaleString() }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <TablePaginated
      name="Digital Documents"
      :data="pandadocsWithKpis"
      :searchable="false"
      :filterable="false"
      sortable
      default-sort= "actual_count"
      default-sort-direction="desc">
      <template slot-scope="props">
        <b-table-column label="Club Name" field="club.name" sortable>
          {{ props.row.club.name }}
        </b-table-column>
        <b-table-column label="Date Signed"
          field="contract_date"
          :custom-sort="sortByDate"
          numeric
          sortable>
          {{ props.row.contract_date | formatDate }}
        </b-table-column>
        <b-table-column label="Actual Count"
          field="actual_count"
          numeric
          sortable >
          {{ props.row.actual_count.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Contract Count"
          field="annual_document_limit"
          numeric
          sortable>
          {{ props.row.annual_document_limit.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Remaining"
          field="remaining"
          numeric
          sortable>
          {{ props.row.remaining.toLocaleString() }}
        </b-table-column>
      </template>
    </TablePaginated>
  </div>
</template>
<script>
import PageNavigation from '@/components/layout/navigation/secondary/PageNavigation';
import spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';
import api from '@/http-playmetrics';

export default {
  components: {
    PageNavigation,
    spinner,
    TablePaginated },
  data() {
    return {
      pandadocs: [],
      contractCount: 100000,
      contractDate: '2022-03-10',
    };
  },
  computed: {
    totalActualCount() {
      return this.pandadocs.reduce((acc, acct) => acc + this.actualCount(acct, true), 0);
    },
    totalRemaining() {
      return this.contractCount - this.totalActualCount;
    },
    pandadocsWithKpis() {
      return this.pandadocs.map(pandadoc => ({
        ...pandadoc,
        actual_count: this.actualCount(pandadoc, false),
        remaining: this.remaining(pandadoc),
      }));
    },
  },
  created() {
    this.fetchPandadocs();
  },
  methods: {
    fetchPandadocs() {
      this.loading = true;
      api()
        .post('/cst/pandadoc_accounts/search?populate=club,club:kpis,club:kpis:10013')
        .then((res) => {
          if (res.status === 200) {
            this.pandadocs = res.data;
          }
        });
      this.loading = false;
    },
    actualCount(acct, pmTotal) {
      if (acct?.club?.kpis) {
        let stats = acct.club.kpis;
        if (pmTotal) {
          stats = acct.club.kpis.filter(stat => new Date(stat.captured_on) >= new Date(this.contractDate));
        } else if (acct.contract_date) {
          stats = acct.club.kpis.filter(stat => new Date(stat.captured_on) >= new Date(acct.contract_date));
        }
        return stats.reduce((acc, cat) => acc + cat.value, 0);
      }
      return 0;
    },
    remaining(acct) {
      return acct.annual_document_limit - this.actualCount(acct);
    },
    sortByDate(a, b, isAsc) {
      const date1 = new Date(a.contract_date);
      const date2 = new Date(b.contract_date);
      return (date1 < date2) === isAsc ? -1 : 1;
    },
  },
};
</script>

<style lang="sass" scoped>
.flex
  display: flex
  justify-content: space-between
  align-items: center
.counts
  display: flex
</style>
