var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.loading)?_c('Spinner'):_c('div',[_c('div',{staticClass:"table-header"},[_c('div',{staticClass:"level-item week-picker"},[_c('div',[_c('DateRangeDropdown',{attrs:{"presets":[
            { name: 'Last 30 Days', display: 'Last 30 Days', range: _vm.last30Days },
            { name: 'Last 3 Months', display: 'Last 3 Months', range: _vm.last3Months },
            { name: 'This Year', display: 'This Year', range: _vm.thisYear },
            { name: 'Last Year', display: 'Last Year', range: _vm.lastYear },
            { name: 'Lifetime', display: 'Lifetime', range: _vm.lifeTime },
          ]},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)]),_c('b-field',{attrs:{"label":"View Total As:"}},[_c('b-select',{model:{value:(_vm.viewTotalAs),callback:function ($$v) {_vm.viewTotalAs=$$v},expression:"viewTotalAs"}},[_c('option',{attrs:{"value":"overall"}},[_vm._v("Overall")]),_c('option',{attrs:{"value":"primary"}},[_vm._v("Primary")]),_c('option',{attrs:{"value":"secondary"}},[_vm._v("Secondary")])])],1)],1),_c('b-table',{staticClass:"day-schedule-table",attrs:{"name":"Staff by Time View","bordered":"","data":_vm.tableRows},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{attrs:{"label":"Staff Member","field":"name","header-class":"is-unselectable header-background"}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(props.row.name))+" ")]),_c('b-table-column',{attrs:{"label":"Onboarding","field":"onboarding","header-class":"is-unselectable header-background"}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.onboarding))+" ")])]),_c('b-table-column',{attrs:{"label":"Meetings","field":"meeting","header-class":"is-unselectable header-background"}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.meeting))+" ")])]),_c('b-table-column',{attrs:{"label":"Total","field":"total","header-class":"is-unselectable header-background"}},[_c('div',{staticClass:"has-text-right"},[_vm._v(" "+_vm._s(_vm.toHours(props.row.total))+" ")])])]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }