<template>
  <div>
    <SelectedObjectsBox
      :objs="numSelected"
      type="clubs"
      :title="`${clubs.length === 1 ? 'Club' : 'Clubs'}`"
      @select="selectObjs"/>
    <SelectMultipleClubs
      :data="clubs"
      type="clubs"
      @save="$event => handleSave($event)"
      @selected="numSelected = $event"
      ref="selectModal"/>
  </div>
</template>

<script>
import SelectedObjectsBox from '@/components/common/utils/SelectedObjectsBox';
import SelectMultipleClubs from './SelectMultipleClubs';

export default {
  components: {
    SelectedObjectsBox,
    SelectMultipleClubs,
  },
  props: {
    clubs: { type: Array, required: true },
  },
  data() {
    return {
      numSelected: [],
    };
  },
  computed: {

  },
  methods: {
    handleSave(event) {
      this.$emit('save', event);
    },
    selectObjs() {
      this.$refs.selectModal.showModal();
    },
  },
};
</script>
