<template>
<div>
  <FilterTray
    title="Filter Tickets"
    :filters-active="hasFiltersActive"
    @clear-filters="clearFilters"
  >
    <FilterSection label="Size" collapsible default-open>
      <b-field v-for="s in sizes" :key="s.value">
        <b-checkbox  v-model="filters.size" :native-value="s.value"> {{s.name}} </b-checkbox>
      </b-field>
    </FilterSection>
    <FilterSection label="Sports" collapsible default-open>
      <b-field v-for="s in sports" :key="s.value">
        <b-checkbox v-model="filters.sport"  :native-value="s.value">{{s.name}}</b-checkbox>
      </b-field>
    </FilterSection>
  </FilterTray>
  </div>
</template>
<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterSection,
    FilterTray,
  },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('ticket')),
    };
  },

  computed: {
    sports() {
      return this.$store.getters.clubSports;
    },
    sizes() {
      return this.$store.getters.clubSizes;
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('ticket'));
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'ticket' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('ticket'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'ticket', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>
