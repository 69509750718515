<template>
  <div class="player-modal">
    <Modal
      ref="modal"
      title="Update Customer Health"
      is-all-white >
      <div class="has-text-weight-semibold is-font-mont is-size-5"><br/> Customer Health </div>
      <div class="columns" style="margin-top: 0.6rem;">
        <div class="column is-one-quarter">
          <b-radio v-model="customerHealth.health_status" native-value="" @input="customerHealth.health_status = ''">
            No Concerns </b-radio>
        </div> <div class="column is-one-quarter">
          <b-radio v-model="customerHealth.health_status" native-value="concerns"> Concerns </b-radio>
        </div> <div class="column is-one-quarter">
          <b-radio v-model="customerHealth.health_status" native-value="at risk"> At Risk </b-radio>
        </div>
      </div>
      <b-field :type="noteInvalid ? 'is-danger' : ''">
        <div slot="label"> <div class="columns">
          <div class="column">Provide any additional comments: </div>
          <div class="column"> <a v-if="customerHealth.health_notes"
            class="clear-link"
            @click="clearComment()">Clear Comment</a> </div>
        </div></div>
        <b-input v-model="customerHealth.health_notes" type="textarea" />
      </b-field>
      <span :class="noteInvalid ? 'note-invalid' : ''">
        {{ customerHealth.health_notes.length }}
      </span> / 1000
      <template slot="footer">
        <b-button :disabled="noteInvalid" type="is-primary" @click="saveChanges()">Update</b-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import _ from 'lodash';
import Modal from '@/components/layout/modals/Modal';
import api from '@/http-playmetrics';

export default {
  components: { Modal },
  data() {
    return {
      customerHealth: { health_notes: '' },
    };
  },
  computed: {
    noteInvalid() {
      return this.customerHealth.health_notes.length > 1000;
    },
    submitFields() {
      return {
        type: this.customerHealth.type,
        size: this.customerHealth.size,
        health_status: !this.customerHealth.health_status && this.customerHealth.health_notes ? 'no concerns with comments' : this.customerHealth.health_status,
        health_notes: this.customerHealth.health_notes,
        risk_rating: this.customerHealth.risk_rating,
        expected_apv: this.customerHealth.expected_apv,
        expected_prepayment_coefficient: this.customerHealth.expected_prepayment_coefficient,
      };
    },
  },
  methods: {
    clearComment() {
      this.customerHealth.health_notes = '';
    },
    saveChanges() {
      const healthUrl = `/cst/club_internals/${this.customerHealth.id}/`;
      api().post(healthUrl, this.submitFields)
        .then(() => this.finishUpdate());
    },
    finishUpdate() {
      this.$store.commit('setNotification', { toast: true, success: true, message: 'Onboarding Health Status Updated' });
      this.dismissModal({ refreshData: true });
    },
    showModal(customerHealth) {
      this.customerHealth = _.cloneDeep(customerHealth);
      if (this.customerHealth.health_status === 'no concerns with comments') this.customerHealth.health_status = '';
      this.$refs.modal.showModal();
    },
    dismissModal(options = { refreshData: false }) {
      this.$emit('dismissed', options);
      this.$refs.modal.dismissModal();
    },
  },
};
</script>

<style lang="sass" scoped>
.clear-link
  color: $primary
  font-size: $size-6
  font-weight: $weight-normal
  float: right
  text-decoration: underline
.note-invalid
  color: $red
</style>
