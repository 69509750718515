<template>
  <Spinner v-if="loading"/>
  <div v-else>
    <div class="level-item">
      <div class="level-item week-picker">
        <WeekNavigationWithPicker
          v-model="currentDate"
          has-prev
          has-next />
      </div>
    </div>
    <b-table
      name="Schedule By Day"
      bordered
      :data="tableRows.length > 5 ? tableRows : noEvents"
      class="day-schedule-table">
      <template slot-scope="props">
        <b-table-column label=""
        field="user_name"
        :cell-class="props.row.is_day_label ? 'is-day' : 'is-user'"
          header-class="is-unselectable header-background">
          <a :class="!props.row.user_id ? 'no-highlight' : ''" @click="viewUser(props.row.user_id)">
            {{ props.row.user_name | capitalize }}
          </a>
          <StatusTag v-if="dayOfWeek && dayOfWeek === props.row.user_name" style="margin-left: .5rem;" :status="'today'" />
        </b-table-column>
        <b-table-column label="Club"
          field="club_name"
          :cell-class="props.row.is_day_label ? 'is-day' : 'is-user'"
          header-class="is-unselectable header-background">
          {{ props.row.club_name | capitalize }}
        </b-table-column>
        <b-table-column label="Status"
          field="club_status"
          :cell-class="props.row.is_day_label ? 'is-day' : 'is-user'"
          header-class="is-unselectable header-background">
          {{ props.row.club_status | capitalize }}
        </b-table-column>
        <b-table-column label="Category"
          field="category"
          :cell-class="props.row.is_day_label ? 'is-day' : 'is-user'"
          header-class="is-unselectable header-background">
          {{ props.row.category | capitalize }}
        </b-table-column>
        <b-table-column label="Milestone"
          field="milestone"
          :cell-class="props.row.is_day_label ? 'is-day' : 'is-user'"
          header-class="is-unselectable header-background">
          {{ props.row.milestone | capitalize }}
        </b-table-column>
        <b-table-column label="Role"
          field="role"
          :cell-class="props.row.is_day_label ? 'is-day' : 'is-user'"
          header-class="is-unselectable header-background">
          {{ props.row.role | capitalize }}
        </b-table-column>
        <b-table-column label="Hours"
          field="hours"
          :cell-class="props.row.is_day_label ? 'is-day' : 'is-user'"
          header-class="is-unselectable header-background">
          <div class="has-text-right"> {{ toHours(props.row.hours) }} </div>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import api from '@/http-playmetrics';
import StatusTag from '@/components/underwriting/StatusTag';
import Spinner from '@/components/common/Spinner';
import WeekNavigationWithPicker from '@/components/onboarding/reports/WeekNavigationWithPicker';

export default {
  components: { WeekNavigationWithPicker, StatusTag, Spinner },
  data() {
    return {
      loading: false,
      activities: [],
      currentDate: new Date(),
      today: new Date(),
      weekDays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday'],
    };
  },
  created() {
    this.fetchActivities();
  },
  computed: {
    clubs() {
      return this.$store.getters.clubs;
    },
    users() {
      return this.$store.getters.users;
    },
    week() {
      const arr = [];
      const dt = moment(this.currentDate).startOf('isoWeek');
      for (let i = 0; i < 5; i += 1) {
        arr.push((_.cloneDeep(dt).add(i, 'd')));
      }
      return arr;
    },
    dayOfWeek() {
      const curr = moment(this.today);
      if (this.week[0].isSame(curr, 'day')) return 'monday';
      if (this.week[1].isSame(curr, 'day')) return 'tuesday';
      if (this.week[2].isSame(curr, 'day')) return 'wednesday';
      if (this.week[3].isSame(curr, 'day')) return 'thursday';
      if (this.week[4].isSame(curr, 'day')) return 'friday';
      else return '';
    },
    mappedActivities() {
      return this.activities.filter(activity => activity.meeting_date).map(activity => ({ ...activity,
        club_name: this.clubsByID(activity.club_id).name,
        club_status: this.clubsByID(activity.club_id).config.onboarding_status,
      }));
    },
    mappedUsers() {
      return this.users.map(user => ({
        id: user.id,
        name: `${user.first_name} ${user.last_name}`,
        activities: this.activitiesByUser(user.id),
      })).filter(user => user.activities.length !== 0).sort((user1, user2) => ((user1.name < user2.name) ? -1 : 1));
    },
    userActivitiesByDay() {
      return this.mappedUsers.map(user => ({
        id: user.id,
        name: user.name,
        activities: [
          this.activitiesByDay(user, 0),
          this.activitiesByDay(user, 1),
          this.activitiesByDay(user, 2),
          this.activitiesByDay(user, 3),
          this.activitiesByDay(user, 4),
        ],
      }));
    },
    tableRows() {
      return [...this.getRows(0), ...this.getRows(1), ...this.getRows(2), ...this.getRows(3), ...this.getRows(4)];
    },
    noEvents() {
      return [{
        user_name: 'No Events Scheduled',
        club_name: '',
        club_status: '',
        category: '',
        milestone: '',
        role: '',
        hours: '',
      }];
    },
  },
  methods: {
    fetchActivities() {
      this.loading = true;
      return api().post('/cst/activities/search').then((res) => {
        this.activities = res.data;
        this.loading = false;
      });
    },
    viewUser(id) {
      if (id) {
        this.$router.push({ name: 'UserScheduleView', params: { userId: id, dateRange: 'default' } });
      }
    },
    isDay(name) {
      return this.weekDays.includes(name) || name.includes('No') ? 'is-day' : '';
    },
    isDayUser(name) {
      return this.weekDays.includes(name) || name.includes('No') ? 'is-day' : 'is-user';
    },
    toHours(m) {
      if (typeof m === 'string') return m;
      if (m) {
        const hrs = Math.round((m / 60) * 100) / 100;
        return Math.floor(hrs) === hrs ? `${hrs}.00 hrs` : `${hrs} hrs`;
      } else return '-';
    },
    activitiesByUser(id) {
      return [...this.mappedActivities.filter(activity => activity.lead_user_id === id),
        ...this.mappedActivities.filter(activity => activity.secondary_user_ids?.includes(id))];
    },
    activitiesByDay(user, day) {
      return user.activities.filter(act => this.week[day].isSame(act.meeting_date, 'day'));
    },
    clubsByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    getRows(day) {
      const rows = [];
      const users = this.userActivitiesByDay.filter(user => user.activities[day].length);
      rows.push({ user_name: this.weekDays[day], is_day_label: true, club_name: '', club_status: '', category: '', milestone: '', role: '', hours: '' });
      if (!users.length) return rows;
      else {
        for (let i = 0; i < users.length; i += 1) {
          for (let j = 0; j < users[i].activities[day].length; j += 1) {
            rows.push({
              user_id: j === 0 ? users[i].id : '',
              user_name: j === 0 ? users[i].name : '',
              club_name: users[i].activities[day][j].club_name ? users[i].activities[day][j].club_name : '-',
              club_status: users[i].activities[day][j].club_status ? users[i].activities[day][j].club_status : '-',
              category: users[i].activities[day][j].category ? users[i].activities[day][j].category : '-',
              milestone: users[i].activities[day][j].milestone ? users[i].activities[day][j].milestone : '-',
              role: users[i].activities[day][j].lead_user_id === users[i].id ? 'primary' : 'secondary',
              hours: users[i].activities[day][j].duration,
            });
          }
        }
      }
      return rows;
    },
  },
};
</script>

<style lang="sass" scoped>
.week-picker
  margin-top: 1rem
::v-deep .day-schedule-table
  font-family: $family-fira
  font-weight: $weight-normal
  padding-top: 1.5rem
  .header-background
    width: 175px
    font-family: $family-headers
    font-size: 1.1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
  .is-day
    background-color: #ebedf9
    font-family: $family-headers
    font-weight: $weight-bold
    color: $black
    border-color: #ebedf9
    padding: .75rem 0 .75rem .75rem
  .is-user
    color: $black
    background-color: $white-bis
    font-weight: $weight-medium
  .no-highlight
    color: inherit
    cursor: default
</style>
