<template>
  <Modal
    ref="modal"
    modal-class="background-check-modal"
    :title="`${editing ? 'Edit' : 'Enable'} Background Checks`"
    is-all-white >
    <div class="form-section">
      <p class="form-section__label">Club</p>
      <b-field>
        <b-input :value="clubName" v-if="editing" disabled />
        <b-autocomplete
          v-else
          v-model="query"
          :data="filteredClubs"
          field="name"
          append-to-body
          placeholder="Search for Club"
          :loading="loadingPackages"
          @select="assignClub($event)">
          <template #empty>No results found</template>
        </b-autocomplete>
      </b-field>
      <b-switch
        v-if="!editing || club.is_test_club"
        :disabled="club.is_test_club"
        v-model="showTestClubs">
        Show Test Clubs
      </b-switch>
    </div>
    <template v-if="clubName">
      <div class="form-section">
        <p class="form-section__label">Club's Mailing Address</p>
        <b-field label="Address Line 1">
          <b-input v-model="address.line1" maxlength="60" :disabled="editing" />
        </b-field>
        <b-field label="Address Line 2">
          <b-input v-model="address.line2" maxlength="60" :disabled="editing" />
        </b-field>
        <div class="columns">
          <div class="column">
            <b-field label="City">
              <b-input v-model="address.city" :disabled="editing" />
            </b-field>
          </div>
          <div class="column">
            <b-field label="State">
              <b-select v-model="address.state" :disabled="editing">
                <option
                  v-for="state in states"
                  :key="state.abbr"
                  :value="state.abbr">
                  {{ state.name }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Zip Code">
              <b-input v-model="address.zip_code" maxlength="5" :disabled="editing" />
            </b-field>
          </div>
        </div>
      </div>
      <div class="form-section">
        <p class="form-section__label">Yardstik Sub-Account Owner</p>
        <p class="form-section__subtitle">
          Specify who is the club's primary contact for background screenings.<br>
          <span class="has-text-weight-bold">Note:</span> This person will also be treated as a risk manager for the club.
        </p>
        <b-field>
          <b-select
            v-model="userID"
            :placeholder="sortedPossibleAccountOwners.length ? 'Select Sub-Account Owner' : 'No Admins found'"
            :disabled="sortedPossibleAccountOwners.length === 0"
            :loading="loadingControllers">
            <option v-for="pao in sortedPossibleAccountOwners" :key="pao.id" :value="pao.id">{{pao.name}} - {{pao.email}}</option>
          </b-select>
        </b-field>
      </div>
      <div class="form-section" v-if="editing">
        <p class="form-section__label">Additional Risk Managers</p>
        <b-select
          v-for="n in additionalRMIDs.length"
          :key="n - 1"
          class="additional-rm"
          v-model="additionalRMIDs[n - 1]"
          :placeholder="filteredPossibleAccountOwners(n - 1).length ?
            'Select Risk Manager' : 'No more Admins found'"
          :loading="loadingControllers">
          <option/>
          <option
            v-for="pao in filteredPossibleAccountOwners(n - 1)"
            :key="pao.id"
            :value="pao.id">
            {{pao.name}} - {{pao.email}}
          </option>
        </b-select>
        <button
          class="button"
          style="margin-top: 1rem"
          @click="handleAddRM">
          <span class="icon"><fa-icon :icon="['fal', 'plus']" /></span>
          <span>Add Risk Manager</span>
        </button>
      </div>
      <div class="form-section">
        <p class="form-section__label">Club's Support Information</p>
        <div class="columns">
          <div class="column">
            <b-field label="Support email">
              <b-input v-model="supportEmail" :disabled="editing" />
            </b-field>
          </div>
          <div class="column">
            <b-field label="Support phone number">
              <b-input v-model="supportPhoneNumber" :disabled="editing" />
            </b-field>
          </div>
        </div>
      </div>
    </template>
    <template slot="footer">
      <b-button type="is-primary" :disabled="invalid || saving" @click="save">Save</b-button>
    </template>
  </Modal>
</template>
<script>
import _ from 'lodash';
import api from '@/http-playmetrics';
import Utils from '@/models/Utils';
import Modal from '@/components/layout/modals/Modal';

export default {
  components: {
    Modal,
  },
  props: {
    clubs: { type: Array, default: () => [] },
  },
  data() {
    return {
      loadingPackages: false,
      packages: [],
      query: '',
      showTestClubs: false,
      clubID: null,
      clubName: null,
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip_code: '',
      },
      admins: [],
      loadingControllers: false,
      userID: null,
      saving: false,
      editing: false,
      additionalRMIDs: [],
      supportEmail: '',
      supportPhoneNumber: '',
    };
  },
  computed: {
    invalid() {
      return !this.userID ||
        (!this.editing &&
          (!this.address.line1 || !this.address.city || !this.address.state || !this.address.zip_code || !this.supportEmail)
        );
    },
    submitRMIDs() {
      return _.compact(this.additionalRMIDs).filter(rm => rm !== this.userID);
    },
    filteredClubs() {
      return _.sortBy(
        this.clubs.filter(
          club => (!club.is_test_club || this.showTestClubs) && club.name.toLowerCase().includes(this.query.toLowerCase()),
        ),
        'name',
      );
    },
    states() {
      return Utils.statesForCountry('US');
    },
    sortedPossibleAccountOwners() {
      let ret = [...this.admins];
      ret = _.uniqBy(ret, 'email');
      return _.sortBy(ret, 'name');
    },
    club() {
      return this.clubs.find(club => club.id === this.clubID) || {};
    },
  },
  methods: {
    showModal(row) {
      this.loadingPackages = true;
      api().get('/cst/yardstik/packages').then((resp) => {
        this.packages = resp.data;
        this.loadingPackages = false;
      });

      if (row) {
        this.editing = true;
        this.clubID = row.id;
        this.clubName = row.name;
        this.additionalRMIDs = [];
        _.forEach(row.club_yardstik.risk_managers, (rm) => {
          if (rm.is_primary_risk_manager) {
            this.userID = rm.user_id;
          } else {
            this.additionalRMIDs.push(rm.user_id);
          }
        });
        this.loadControllerOptions(row.id);
        this.fetchInfoFromYardstik(row.club_yardstik.id);
        this.showTestClubs = row.club_yardstik.club.is_test_club;
      } else {
        this.editing = false;
        this.clubID = null;
        this.clubName = null;
        this.address = {
          line1: '',
          line2: '',
          city: '',
          state: '',
          zip_code: '',
        };
        this.userID = null;
      }
      this.$refs.modal.showModal();
    },
    assignClub(club) {
      if (club) {
        this.clubID = club.id;
        this.clubName = club.name;
        this.supportEmail = club.config.help_email;
        this.supportPhoneNumber = club.config.help_phone_number;
        this.loadControllerOptions(club.id);
      }
    },
    loadControllerOptions(id) {
      this.loadingControllers = true;
      api().get(`/cst/clubs/${id}/admins`).then((res) => {
        this.admins = _.map(
          _.filter(
            res.data,
            a => a.registered_at !== null,
          ),
          a => ({ ...a, name: `${a.first_name} ${a.last_name}` }),
        );
      }).finally(() => {
        this.loadingControllers = false;
      });
    },
    handleAddRM() {
      this.additionalRMIDs.push(null);
    },
    filteredPossibleAccountOwners(forDropdownAtIndex) {
      return this.sortedPossibleAccountOwners.filter(
        // Always ignore the primary rm/owner
        // Then ignore other additional rms (we still need the one for the current index because the select needs to see the option)
        owner => owner.id !== this.userID &&
          (this.additionalRMIDs[forDropdownAtIndex] === owner.id || !this.additionalRMIDs.includes(owner.id)),
      );
    },
    fetchInfoFromYardstik(clubYardstikID) {
      api().get(`cst/yardstik/accounts/${clubYardstikID}/`).then((resp) => {
        const subAccount = resp.data;
        const address = subAccount.address;
        this.address.line1 = address.line1;
        this.address.line2 = address.line2;
        this.address.city = address.city;
        this.address.state = address.state;
        this.address.zip_code = address.zip_code;
        this.supportEmail = subAccount.support_email_address;
        this.supportPhoneNumber = subAccount.support_phone;
      });
    },
    toast(message, success = true) {
      this.$store.commit('setNotification', { toast: true, success, message });
    },
    save() {
      this.saving = true;
      if (this.editing) {
        const toPost = {
          club_id: this.clubID,
          user_ids: [this.userID, ...this.submitRMIDs],
        };
        api().post('cst/yardstik/risk_managers/set_rms', toPost).then((res) => {
          if (res.status === 200) {
            this.toast('Additional Risk Managers Created');
            this.dismiss({ refreshData: true });
          }
        }).finally(() => {
          this.saving = false;
        });
      } else {
        const toPost = {
          address: this.address,
          club_id: this.clubID,
          packages: _.filter(this.packages, pack => pack.name === 'Basic Criminal' || pack.name === 'Standard'),
          user_id: this.userID,
          support_email: this.supportEmail,
          support_phone_number: this.supportPhoneNumber,
        };
        api().post('cst/yardstik/accounts', toPost).then((res) => {
          if (res.status === 200) {
            this.toast('Yardstik Sub-Account Created');
            this.dismiss({ refreshData: true });
          }
        }).finally(() => {
          this.saving = false;
        });
      }
    },
    dismiss(options) {
      this.$refs.modal.dismissModal();
      this.$emit('dismissed', options);
    },
  },
};
</script>

<style lang="sass" scoped>
.background-check-modal
  .form-section
    &:not(:last-of-type)
      margin-bottom: 2rem
    &__label
      font-family: $family-headers
      font-weight: $weight-bold
      font-size: $size-5
      margin-bottom: 0.5rem
    &__subtitle
      font-family: $family-primary
      font-size: $size-6
      margin-bottom: 0.5rem
  .additional-rm
    &:not(:last-of-type)
      margin-bottom: 0.5rem
</style>
