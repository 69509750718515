<template>
  <div>
    <FilterTray
      title="Filter Merchant Accounts"
      :filters-active="filtersActive"
      @clear-filters="clearFilters"
    >
      <FilterSection label="Vendor" collapsible>
        <b-field v-for="v in vendors" :key="v.value">
          <b-checkbox v-model="filters.vendor.allow"  :native-value="v.value"> {{v.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Viability" collapsible>
        <b-field v-for="v in viability" :key="v.value">
          <b-checkbox  v-model="filters.viable.allow" :native-value="v.value"> {{v.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Country" collapsible>
        <b-field v-for="c in countries" :key="c.value">
          <b-checkbox v-model="filters.country.allow"  :native-value="c.value">{{c.name}}</b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Type" collapsible>
        <b-field v-for="t in types" :key="t.value">
          <b-checkbox v-model="filters.type.allow"  :native-value="t.value"> {{t.name}} </b-checkbox>
        </b-field>
      </FilterSection>
      <!--      <FilterSection label="Context" collapsible>-->
      <!--        <b-field v-for="c in contexts" :key="c.value">-->
      <!--          <b-checkbox v-model="filters.context.allow"  :native-value="c.value">{{c.name}}</b-checkbox>-->
      <!--        </b-field>-->
      <!--      </FilterSection>-->
      <FilterSection label="Advanced" collapsible>
        <b-switch @input="$emit('showTestClubs', $event)">
          Show Test Clubs
        </b-switch>
      </FilterSection>
    </FilterTray>
  </div>
</template>
<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterSection,
    FilterTray,
  },
  props: {
    filtersActive: { type: Boolean, required: true },
  },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('merchantAccounts')),
    };
  },
  computed: {
    vendors() {
      return [
        { name: 'Stripe', value: 'Stripe' }, { name: 'WePay', value: 'WePay' },
      ];
    },
    viability() {
      return [
        { name: 'Yes', value: 'Yes' }, { name: 'No', value: 'No' },
      ];
    },
    countries() {
      return [{ name: 'US', value: 'US' }, { name: 'CA', value: 'CA' }];
    },
    types() {
      return [
        { name: 'Primary', value: 'Primary' }, { name: 'Secondary', value: 'Secondary' },
      ];
    },
    contexts() {
      return [];
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'merchantAccounts' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('merchantAccounts'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'merchantAccounts', filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
