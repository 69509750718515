<template>
  <div>
    <FilterTray
      title="Filter Activities"
      :filters-active="filtersActive"
      @clear-filters="clearFilters">
      <FilterSection
        collapsible
        label="Category and Milestone">
        <div v-for="c in category" :key="c.value">
          <b-checkbox v-model="filters.category"
            style="font-size: 1.1rem;"
            :native-value="c.value"> {{ c.name }} </b-checkbox>
          <div style="padding-left: .5rem;" v-for="(milestone, cat) in milestonesSelect[c.value]" :key="cat">
            <b-checkbox v-model="filters.milestone"
              :native-value="milestone.value"> {{ milestone.name }} </b-checkbox>
          </div>
        </div>
      </FilterSection>
      <FilterSection
        collapsible
        label="Customer Success Staff">
        <b-field v-for="staff in csStaff" :key="staff.id">
          <b-checkbox v-model="filters.cs_staff" :native-value="staff.id">
            {{ staff.first_name }} {{ staff.last_name }}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection
        v-if="hasDateFilter"
        collapsible
        label="Activity Date">
        <b-field label="Date is">
          <div>
            <b-select
              v-model="filters.activity_date.status"
              expanded>
              <option
                v-for="(status, index) in activityDateStatuses"
                :key="index"
                :value="status">
                {{ status }}
              </option>
            </b-select>
          </div>
        </b-field>
        <b-field>
          <div>
            <div>
              <a
                @click="selectingDate = !selectingDate">
                <fa-icon :icon="['fas', 'calendar']"/>
                <span>
                  {{ titleDateFormatter }}
                </span>
              </a>
              <b-datepicker
                v-if="selectingDate && filters.activity_date.status === 'Between'"
                v-model="filters.activity_date.date_range"
                range
                inline
                size="is-small"
                style="margin-top: 1rem;"
                @input="selectingDate = false"/>
              <b-datepicker
                v-else-if="selectingDate"
                v-model="filters.activity_date.date"
                inline
                size="is-small"
                style="margin-top: 1rem;"
                @input="selectingDate = false"/>
            </div>
          </div>
        </b-field>
      </FilterSection>
      <FilterSection
        collapsible
        label="Time Spent">
        <b-field label="Status">
          <b-select v-model="filters.time_spent.status">
            <option
              v-for="status in timeStatus"
              :value="status"
              :key="status">
              {{ status }}
            </option>
          </b-select>
        </b-field>
        <div>
          <b-field label="Hours">
            <b-input v-model="filters.time_spent.hours"
              type="number"
              @input="updateDuration()"
              style="margin-right: 1rem;"/>
          </b-field>
          <b-field label="Minutes">
            <b-select v-model="filters.time_spent.minutes" @input="updateDuration()">
              <option
                v-for="time in timeMinutes"
                :value="time.value"
                :key="time.value">
                {{ time.name }}
              </option>
            </b-select>
          </b-field>
        </div>
        <button class="button is-danger is-small" style="margin-top: 1rem" @click="clearTimeSpent">Clear</button>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: {
    FilterSection,
    FilterTray,
  },
  props: {
    hasDateFilter: { type: Boolean, default: true },
  },
  data() {
    return {
      selectingDate: false,
      filters: _.cloneDeep(this.$store.getters.filtersFor('onboardingActivities')),
    };
  },
  computed: {
    timeStatus() {
      return ['Shorter', 'Longer'];
    },
    titleDateFormatter() {
      const format = 'MMM D, YYYY';
      if (this.filters.activity_date.status === 'Between') {
        const value = this.filters.activity_date.date_range;
        const start = value[0] ? moment(value[0]).format(format) : '\u2014';
        const end = value[1] ? moment(value[1]).format(format) : '\u2014';
        return `${start} - ${end}`;
      }
      return moment(this.filters.activity_date.date).format(format);
    },
    activityDateStatuses() {
      return ['Before', 'Between', 'After'];
    },
    filtersActive() {
      const filters = this.$store.getters.activeFiltersFor('onboardingActivities');
      return this.isFiltersActive(filters);
    },
    onboarding_type() {
      return this.$store.getters.activityOnboardingType;
    },
    category() {
      return this.$store.getters.activityOnboardingCategory;
    },
    milestone() {
      return this.$store.getters.activityMilestones;
    },
    milestonesSelect() {
      return _.pick(this.milestone, this.filters.category);
    },
    csStaff() {
      return this.$store.getters.usersByDepartments(['product', 'success'])
        .filter(staff => staff.first_name || staff.last_name);
    },
    timeMinutes() {
      return [
        {
          name: '0 mins',
          value: 0,
        },
        {
          name: '15 mins',
          value: 15,
        },
        {
          name: '30 mins',
          value: 30,
        },
        {
          name: '45 mins',
          value: 45,
        },
      ];
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  methods: {
    isFiltersActive(filters) {
      return Object.keys(filters).some((key) => {
        if (key === 'activity_date' || key === 'time_spent') {
          if (filters[key].status) {
            return true;
          }
          return false;
        }
        if (filters[key]) {
          return true;
        }
        return false;
      });
    },
    updateDuration() {
      this.filters.time_spent.duration = (this.filters.time_spent.hours * 60) + this.filters.time_spent.minutes;
    },
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'onboardingActivities' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('onboardingActivities'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'onboardingActivities', filters: _.cloneDeep(this.filters) });
    },
    clearTimeSpent() {
      this.filters.time_spent.duration = 0;
      this.filters.time_spent.hours = 0;
      this.filters.time_spent.minutes = 0;
      this.filters.time_spent.status = null;
    },
  },
};
</script>
