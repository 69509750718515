<template>
  <div>
    <FilterTray
      :title="title"
      :filters-active="filtersActive"
      @clear-filters="clearFilters">
      <FilterSection label="Club Size" collapsible default-open>
        <b-field v-for="size in sizes" :key="size.value">
          <b-checkbox v-model="filters.size.allow" :native-value="size.value">
            {{ size.name }}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection label="Sport" collapsible default-open>
        <b-field v-for="sport in sports" :key="sport.value">
          <b-checkbox v-model="filters.sport.allow" :native-value="sport.value">
            {{ sport.name }}
          </b-checkbox>
        </b-field>
      </FilterSection>
      <FilterSection v-if="showRemaining"
        label="Remaining"
        collapsible
        default-open>
        <b-field>
          <b-select class="comparision" v-model="filters.remaining.allow.comparison">
            <option value="more">More Than</option>
            <option value="equal">Equal To</option>
            <option value="less">Less Than</option>
          </b-select>
          <b-input class="amount" v-model.number="filters.remaining.allow.number" type="number"/>
        </b-field>
      </FilterSection>
      <FilterSection v-if="showOpen"
        label="Open Accounts"
        collapsible
        default-open>
        <b-field>
          <b-select class="comparision" v-model="filters.open.allow.comparison">
            <option value="more">More Than</option>
            <option value="equal">Equal To</option>
            <option value="less">Less Than</option>
          </b-select>
          <b-input class="amount" v-model.number="filters.open.allow.number" type="number"/>
        </b-field>
      </FilterSection>
    </FilterTray>
  </div>
</template>

<script>
import _ from 'lodash';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

const filterName = 'teamAccounts';

export default {
  components: {
    FilterTray,
    FilterSection,
  },
  props: {
    filtersActive: { type: Boolean, default: false },
    title: { type: String, default: 'Team Accounts Filter' },
    showRemaining: { type: Boolean, default: false },
    showOpen: { type: Boolean, default: false },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor(filterName)),
    };
  },
  computed: {
    sizes() {
      return this.$store.getters.clubSizes;
    },
    sports() {
      return this.$store.getters.clubSports;
    },
  },
  methods: {
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: filterName }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor(filterName));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: filterName, filters: _.cloneDeep(this.filters) });
    },
  },
};
</script>
