<template>
  <div>
    <spinner v-if="loading" />
    <FullScreenModal  v-else ref="modal">
      <template slot="header">
        <FormModalHeader
          :title="editing ? 'Edit Onboarding Activity' : 'New Onboarding Activity'"
          :wide-form="true"
          :actions="modalActions"
          @dismiss="dismiss"
        >
          <template slot="save">
            <b-button type="is-primary" @click="save">
              <span v-if="editing">Save Activity</span>
              <span v-else>Create Activity</span>
            </b-button>
          </template>
          <template slot="delete">
            <b-button type="ghost" @click="deleteActivity">
              <fa-icon style="margin-right: 0.25rem" class="is-danger" :icon="['far', 'trash-alt']"/>
              <span>Delete</span>
            </b-button>
          </template>
        </FormModalHeader>
      </template>
      <div v-if="activity" class="centered-tool activity-form">
        <div class="activity-form__section">
          <div class="activity-form__section__label">Meeting Details</div>
          <div class="columns">
            <div class="column is-two-fifths">
              <b-field :type="{'is-danger' : errors.club_id}" :message="errors.club_id" label="Club">
                <b-select disabled expanded v-model="activity.club_id">
                  <option
                    v-for="club in onboardingPlans"
                    :value="club.club_id"
                    :key="club.club_id">
                    {{ clubName(club.club_id) }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field :type="{'is-danger' : errors.category}" :message="errors.category" label="Category">
                <b-select expanded v-model="activity.category" @input="activity.milestone = null">
                  <option
                    v-for="category in activityCategories"
                    :value="category.value"
                    :key="category.value"
                  >
                    {{ category.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field :type="{'is-danger' : errors.milestone}" :message="errors.milestone" label="Milestone">
                <b-select expanded :disabled="!activity.category" v-model="activity.milestone">
                  <option
                    v-for="milestone in milestones(activity.category)"
                    :value="milestone.value"
                    :key="milestone.value"
                  >
                    {{ milestone.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
        <div class="activity-form__section">
          <div class="activity-form__section__label">Staff Details</div>
          <div class="columns">
            <div class="column is-one-quarter">
              <b-field :type="{'is-danger' : errors.lead_user_id}" :message="errors.lead_user_id" label="Lead">
                <AdminStaffDropdown
                  :disabled="!meetingDetailsComplete"
                  :deselect="true"
                  :departments="['product', 'success']"
                  :blacklist="activity.secondary_user_ids"
                  :value="activity.lead_user_id"
                  @input="activity.lead_user_id = $event.id"/>
              </b-field>
            </div>
              <b-field
                label="Secondary"
                v-for="(id, i) in staffTotal"
                :key="id"
                class="column is-one-quarter">
                <AdminStaffDropdown
                  :disabled="!meetingDetailsComplete"
                  :deselect="true"
                  :departments="['product', 'success']"
                  :blacklist="blacklistComp[i]"
                  :value="activity.secondary_user_ids[i]"
                  @input="blackListMethod($event.id, i)"/>
              </b-field>
              <div class="center-vertical">
                <a :class="addStaffDisabled" @click="addStaff">
                  <div v-if="staffTotal < 3"
                    :class="addSecColor">
                    + Add Secondary Staff
                  </div>
                </a>
              </div>
          </div>
        </div>
        <div class="activity-form__section">
          <div class="activity-form__section__label">Scheduling Details</div>
            <div class="columns">
              <div class="column is-two-fifths">
                <b-field :type="{'is-danger' : errors.meeting_date}" :message="errors.meeting_date">
                  <template slot="label">
                     <p class="label">Date <i>(optional)</i></p>
                  </template>
                  <DatePickerString
                    :disabled="!meetingDetailsComplete === undefined ? null : !meetingDetailsComplete"
                    :min-date="minDate"
                    v-model="activity.meeting_date"
                    icon-right="calendar"
                    placeholder ="mm/dd/yyyy"
                    editable>
                    <b-button
                      class="button is-danger"
                      @click="activity.meeting_date = null">
                      <span>Clear</span>
                    </b-button>
                  </DatePickerString>
                </b-field>
              </div>
              <div class="column is-one-fifth">
                <b-field label="Hours" :type="{'is-danger' : errors.duration}" :message="errors.duration">
                  <b-input v-model="hours" @input="updateDuration()" :disabled="!meetingDetailsComplete"/>
                </b-field>
              </div>
              <div class="column is-one-fifth">
                <b-field label="Minutes" :type="{'is-danger' : errors.duration}" :message="errors.duration">
                  <b-select expanded
                    v-model="minutes"
                    @input="updateDuration()"
                    :disabled="!meetingDetailsComplete">
                    <option
                      v-for="time in timeMinutes"
                      :value="time.value"
                      :key="time.value"
                    >
                      {{ time.name }}
                    </option>
                  </b-select>
                </b-field>
              </div>
            </div>
          </div>
        <div>
          <OnboardingActivityNotes ref="activityNotesModal"
            @noteCreated="noteCreated"
            @noteDeleted="noteDeleted"
            :activity="notesVal"/>
      </div>
    </div>
    </FullScreenModal>
  </div>
</template>

<script>
import moment from 'moment';
import FullScreenModal from '@/components/layout/modals/FullScreenModal';
import FormModalHeader from '@/components/layout/modals/FormModalHeader';
import spinner from '@/components/common/Spinner';
import AdminStaffDropdown from '@/components/users/AdminStaffDropdown';
import DatePickerString from '@/components/common/form-elements/DatePickerString';
import OnboardingActivityNotes from '@/components/onboarding/plans/OnboardingActivityNotes';
import api from '@/http-playmetrics';

const optionalFields = ['secondary_user_ids', 'meeting_date', 'notes'];

export default {
  components: { FullScreenModal, FormModalHeader, spinner, AdminStaffDropdown, DatePickerString, OnboardingActivityNotes },
  data() {
    return {
      onboardingPlans: null,
      hours: null,
      minDate: new Date('2018-01-18'),
      minutes: null,
      loading: false,
      editing: false,
      activity: {
        club_id: null,
        category: null,
        milestone: null,
        lead_user_id: 0,
        secondary_user_ids: [0, 0, 0],
        duration: 0,
        meeting_date: null,
      },
      errors: {},
      staffTotal: 0,
      blacklistList: [],
    };
  },
  props: {
    activityPull: { type: Object, required: false },
  },
  computed: {
    addSecColor() {
      if (!(this.meetingDetailsComplete)) {
        return 'column activity-form__section__add-secondary-staff__invalid';
      }
      return 'column activity-form__section__add-secondary-staff__valid';
    },
    addStaffDisabled() {
      if (!(this.meetingDetailsComplete)) {
        return 'disableClick';
      } else {
        return '';
      }
    },
    notesVal() {
      if (this.editing) {
        return this.activity;
      } else {
        return this.activityPull?.id;
      }
    },
    modalActions() {
      if (this.editing) {
        return ['delete', 'save'];
      }
      return ['save'];
    },
    toSave() {
      return {
        club_id: this.activity.club_id,
        lead_user_id: this.activity.lead_user_id,
        secondary_user_ids: this.activity.secondary_user_ids.filter(user => user !== 0),
        meeting_date: this.activity.meeting_date,
        duration: this.activity.duration,
        milestone: this.activity.milestone,
        category: this.activity.category,
      };
    },
    dateInvalid() {
      return (moment(this.activity.meeting_date)
        .isBefore(moment(this.minDate)) && this.activity.meeting_date);
    },
    meetingDetailsComplete() {
      return !!(this.activity.club_id && this.activity.milestone && this.activity.category);
    },
    blacklistComp() {
      const userIds = this.activity.secondary_user_ids;
      return [
        [this.activity.lead_user_id, userIds[1], userIds[2]],
        [this.activity.lead_user_id, userIds[0], userIds[2]],
        [this.activity.lead_user_id, userIds[0], userIds[1]]];
    },
    clubs() {
      return this.$store.getters.clubs;
    },
    timeMinutes() {
      return [
        {
          name: '0 mins',
          value: 0,
        },
        {
          name: '15 mins',
          value: 15,
        },
        {
          name: '30 mins',
          value: 30,
        },
        {
          name: '45 mins',
          value: 45,
        },
      ];
    },
    activityCategories() {
      return this.$store.getters.activityOnboardingCategory;
    },
  },
  methods: {
    noteCreated() {
      this.$emit('noteUpdate');
    },
    noteDeleted() {
      this.$emit('noteUpdate');
    },
    clubName(id) {
      return this.clubs.find(club => club.id === id)?.name || '';
    },
    deleteActivity() {
      this.$confirm.showModal({
        confirmText: 'Yes, Delete Activity',
        message: `You are about to delete this onboarding activity for
                  <strong>${this.clubName(this.activity.club_id)}</strong>. Are you sure
                  you want to continue?`,
        title: 'Delete Onboarding Activity',
        type: 'is-danger',
        icon: 'trash-alt',
        onConfirm: () => {
          api()
            .delete(`/cst/activities/${this.activity.id}`)
            .then(() => {
              this.$store.commit('setNotification', {
                toast: true,
                success: true,
                message: 'Activity Deleted',
              });
              this.dismiss();
            });
        },
      });
    },
    updateDuration() {
      this.activity.duration = (this.hours * 60) + this.minutes;
    },
    blackListMethod(id, i) {
      this.$set(this.activity.secondary_user_ids, i, id);
    },
    addStaff() {
      if (this.staffTotal < 3) {
        this.staffTotal += 1;
        this.activity.secondary_user_ids[this.staffTotal - 1] = 0;
      }
    },
    isRequiredField(key) {
      return !(optionalFields.includes(key));
    },
    isEmptyField(key) {
      return ((typeof this.activity[key] === 'object' && this.activity[key] === null) || (typeof this.activity[key] === 'number' && !this.activity[key]));
    },
    validate() {
      Object.keys(this.activity)
        .forEach((key) => {
          if (this.dateInvalid && key === 'meeting_date') {
            this.$set(this.errors, key, 'Value must be January, 10 2018 or later');
          } else if (key === 'lead_user_id') {
            if (this.isEmptyField(key) && this.meetingDetailsComplete) {
              this.$set(this.errors, key, 'This is a required field');
            } else {
              this.$delete(this.errors, key);
            }
          } else if (key === 'duration') {
            if (this.isEmptyField(key) && this.meetingDetailsComplete) {
              this.$set(this.errors, key, 'This is a required field');
            } else {
              this.$delete(this.errors, key);
            }
          } else if (this.isRequiredField(key)) {
            if (this.isEmptyField(key)) {
              this.$set(this.errors, key, 'This is a required field');
            } else {
              this.$delete(this.errors, key);
            }
          } else {
            this.$delete(this.errors, key);
          }
        });
    },
    show(activity) {
      api()
        .post('/cst/club_onboarding_plans/search?populate=users')
        .then((res) => {
          this.onboardingPlans = res.data.filter(plan => !plan.archived_at || !plan.status === 'completed');
        });
      this.loading = true;
      this.staffTotal = 1;
      this.minutes = 0;
      this.hours = 0;
      this.errors = [];
      if (activity) {
        this.editing = true;
        this.activity = { ...activity };
        this.staffTotal = this.activity.secondary_user_ids.length;
        this.minutes = this.getMinutes(this.activity.duration);
        this.hours = this.getHours(this.activity.duration);
      } else {
        this.editing = false;
        this.activity = {
          club_id: this.activityPull.club_id,
          category: null,
          milestone: null,
          lead_user_id: this.activityPull?.cs_lead_user_id || 0,
          secondary_user_ids: [this.activityPull?.cs_secondary_user_id],
          duration: 0,
          meeting_date: null,
        };
      }
      this.$refs.modal.show();
      this.loading = false;
    },
    formatDuration(time) {
      const hours = Math.floor(time / 60);
      const minutes = time % 60;
      let ret = '';
      if (hours > 0) {
        ret = ret.concat(`${hours} hr`);
      }
      if (minutes > 0) {
        ret = ret.concat(`${minutes}${hours > 0 ? ' ' : ''} mins`);
      }
      return ret;
    },
    getHours(time) {
      return Math.floor(time / 60);
    },
    getMinutes(time) {
      return time % 60;
    },
    dismiss(options = { refreshData: true }) {
      this.$refs.modal.dismiss();
      this.$emit('dismissed', options);
    },
    saveNew(activityUrl) {
      this.postActivity(activityUrl);
    },
    saveExisting(activityUrl) {
      this.postActivity(activityUrl);
    },
    postActivityNotes(id) {
      this.$refs.activityNotesModal.submitNote(id);
    },
    postActivity(url) {
      api()
        .post(url, this.toSave)
        .then((res) => {
          this.postActivityNotes(res.data.id);
          this.$store.commit('setNotification', {
            toast: true,
            success: true,
            message: 'Activity updated',
          });
          this.dismiss({ refreshData: true });
        });
    },
    save() {
      this.validate();
      if (Object.keys(this.errors).length === 0) {
        if (this.editing) {
          this.saveExisting(`/cst/activities/${this.activity.id}`);
        } else {
          this.saveNew('/cst/activities/');
        }
      }
    },
    milestones(category) {
      return this.$store.getters.activityMilestones[category] || [];
    },
  },
};
</script>

<style lang="sass" scoped>
.activity-form
  &__section
    margin-bottom: 3rem
    &__label
      font-family: $family-headers
      font-weight: $weight-semibold
      font-size: $size-4
      margin-bottom: 1rem
    &__sub-label
      font-family: $family-headers
      font-weight: $weight-semibold
      font-size: $size-5
      margin-bottom: 0.75rem
    &__sub-section
      margin-bottom: 1.5rem !important
    &__add-secondary-staff
      position: relative
      top: 2.5rem
      color: $blue-brand
      font-weight: $weight-medium
.center-vertical
  display: flex
  align-items: center
  justify-content: flex-start
  margin-top: 1rem
</style>
