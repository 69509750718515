const getDefaultState = () => ({
  overrideSectionLayout: false,
  hideNavigation: false,
  sidenavActive: false,
  device: 'desktop',
  preventScroll: false,
  isAppiOS: false,
  contextMenuOpen: null,
  bottomContextMenuOpen: null,
  secondBottomContextMenuOpen: null,
});

// initial state
const state = getDefaultState();

// getters
const getters = {
  overrideSectionLayout(state) {
    return state.overrideSectionLayout;
  },
  hideNavigation(state) {
    return state.hideNavigation;
  },
  sidenavActive(state) {
    return state.sidenavActive;
  },
  device(state) {
    return state.device;
  },
  isMobile(state) {
    return (state.device === 'mobile');
  },
  isTablet(state) {
    return (state.device === 'tablet');
  },
  isTouch(state) {
    return (state.device === 'tablet') || (state.device === 'mobile');
  },
  isDesktop(state) {
    return (state.device === 'desktop');
  },
  preventScroll(state) {
    return state.preventScroll;
  },
  isAppiOS(state) {
    return state.isAppiOS;
  },
  contextMenuOpen(state) {
    return state.contextMenuOpen;
  },
  bottomContextMenuOpen(state) {
    return state.bottomContextMenuOpen;
  },
  secondBottomContextMenuOpen(state) {
    return state.secondBottomContextMenuOpen;
  },
};

// mutations
const mutations = {
  // Currently only used by UserCalendar.vue
  overrideSectionLayout(state) {
    state.overrideSectionLayout = !state.overrideSectionLayout;
  },
  // Currently only used by ReceiptView.vue
  hideNavigation(state) {
    state.hideNavigation = !state.hideNavigation;
  },
  toggleSidenavActive(state) {
    state.sidenavActive = !state.sidenavActive;
  },
  hideSidenav(state) {
    state.sidenavActive = false;
  },
  setDevice(state, payload) {
    state.device = payload;
    state.isAppiOS = ((navigator.userAgent.includes('PlayMetricsiOS')) || (window.matchMedia('(display-mode: standalone)').matches));
  },
  preventScroll(state, payload) {
    state.preventScroll = payload;
  },
  // resetLayoutState(state) {
  //   Object.assign(state, getDefaultState());
  // },
  setContextMenuOpen(state, { context, value }) {
    if (value) {
      state.contextMenuOpen = context;
    } else if (state.contextMenuOpen === context) {
      state.contextMenuOpen = null;
    }
  },
  closeContextMenus(state) {
    state.contextMenuOpen = null;
  },
  setBottomContextMenuOpen(state, { context, value }) {
    if (value) {
      state.bottomContextMenuOpen = context;
    } else if (state.bottomContextMenuOpen === context) {
      state.bottomContextMenuOpen = null;
    }
  },
  closeBottomContextMenus(state) {
    state.bottomContextMenuOpen = null;
  },
  setSecondBottomContextMenuOpen(state, { context, value }) {
    if (value) {
      state.secondBottomContextMenuOpen = context;
    } else if (state.secondBottomContextMenuOpen === context) {
      state.secondBottomContextMenuOpen = null;
    }
  },
  closeSecondBottomContextMenus(state) {
    state.secondBottomContextMenuOpen = null;
  },
};

// actions
const actions = {
  overrideSectionLayout(context) {
    context.commit('overrideSectionLayout');
  },
  hideNavigation(context) {
    context.commit('hideNavigation');
  },
  toggleSidenavActive(context) {
    context.commit('toggleSidenavActive');
  },
  hideSidenav(context) {
    context.commit('hideSidenav');
  },
  setDevice(context, device) {
    context.commit('setDevice', device);
  },
  preventScroll(context, prevent = true) {
    context.commit('preventScroll', prevent);
  },
  // layoutReset(context) {
  //   context.commit('resetLayoutState');
  // },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
