<template>
 <spinner v-if="loading"/>
  <div id="onboarding-schedule" v-else>

    <TablePaginated
      name="Clubs Onboarding Schedule"
      :data="searchedReportsWithClubFields"
      :bordered="isBordered"
      :sticky-header="stickyHeaders"
      height="600"
      class="reports-club-onboarding-table"
      searchable
      scrollable
      :default-sort="['date', 'desc']"
      :filters-active="hasFiltersActive"
      customHeader
      @queryUpdated="searchQuery = $event">
      <template slot="header-left">
        <div style="display: flex;flex-direction: column">
          <b-switch v-model="showStaff" >Show staff members</b-switch>
        </div>
      </template>
      <template slot-scope="props">
        <b-table-column sticky
          label="Club"
          field="name"
          header-class="is-unselectable header-background counter"
          :custom-sort="sortByName"
          sortable>
        <a @click="viewPlan(props.row.id)">
         <div  class="club-name"> {{ props.row.club_name | capitalize }} </div>
         <div v-if="showStaff"> <i>{{ props.row.primary_CS_Staff_member | capitalize }}</i> </div>
         <div v-if="showStaff"> <i>{{ props.row.secondary_CS_Staff_member | capitalize }}</i> </div>
         </a>
        </b-table-column>
        <b-table-column label="Date Signed"
          field="date"
          header-class="is-unselectable header-background counter"
          :custom-sort="sortByDate"
          sortable>
          {{ props.row.club_date | formatDate }}
        </b-table-column>
        <b-table-column label="% Complete"
          field="percent_complete"
          header-class="is-unselectable header-background counter"
          :custom-sort="sortByPercent"
          sortable>
         <div class="club-percent"> {{ percentFormat(props.row.percent_complete) }} </div>
        </b-table-column>
        <b-table-column label="Kickoff"
          field="kickoff"
          header-class="is-unselectable header-background"
          :cell-class="colorClasses(props.row.kickoff)"
          :custom-sort="sortByKickoff"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.kickoff) }}</div>
        </b-table-column>
        <b-table-column label="Intro"
          field="intro"
          header-class="is-unselectable header-background"
          :cell-class="colorClasses(props.row.intro)"
          :custom-sort="sortByIntro"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.intro) }}</div>
        </b-table-column>
        <b-table-column label="Club Setup"
          field="clubSetup"
          header-class="is-unselectable header-background"
          :cell-class="colorClasses(props.row.club_setup)"
          :custom-sort="sortByClubSetup"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.club_setup) }}</div>
        </b-table-column>
        <b-table-column label="PA I"
          field="pai"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.pai)"
          :custom-sort="sortByPAI"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.pai) }}</div>
        </b-table-column>
        <b-table-column label="PA II Rec"
          field="paii"
          header-class="is-unselectable header-background "
          :cell-class="colorClasses(props.row.paii_rec)"
          :custom-sort="sortByPAIIRec"
          sortable>
         <template slot="header">
            <div class="counter">
              PA II
            </div>
            <div>
              Rec
            </div>
          </template>
          <div class="club-format">{{ dateFormat(props.row.paii_rec) }}</div>
        </b-table-column>
        <b-table-column label="PA II Comp"
          field="paii"
          header-class="is-unselectable header-background "
          :cell-class="colorClasses(props.row.paii_comp)"
          :custom-sort="sortByPAIIComp"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.paii_comp) }}</div>
        </b-table-column>
        <b-table-column label="PA III"
          field="paiii"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.paiii)"
          :custom-sort="sortByPAIII"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.paiii) }}</div>
        </b-table-column>
        <b-table-column label="Field Scheduling"
          field="fieldScheduling"
          header-class="is-unselectable header-background "
          :cell-class="colorClasses(props.row.field_scheduling)"
          :custom-sort="sortByFieldScheduling"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.field_scheduling) }}</div>
        </b-table-column>
        <b-table-column label="Directors"
          field="directors"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.directors)"
          :custom-sort="sortByDirectors"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.directors )}}</div>
        </b-table-column>
        <b-table-column label="Forms"
          field="forms"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.forms)"
          :custom-sort="sortByForms"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.forms) }}</div>
        </b-table-column>
         <b-table-column label="Website"
          field="website"
          header-class="is-unselectable header-background counter"
          :cell-class="colorClasses(props.row.website)"
          :custom-sort="sortByWebsite"
          sortable>
          <div class="club-format">{{ dateFormat(props.row.website) }}</div>
        </b-table-column>
       </template>
      <template slot="empty">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
      </template>
    </TablePaginated>
    <FilterTray title="Reports Filter"
      :filters-active="hasFiltersActive"
      @clear-filters="clearFilters">
      <template>
        <FilterSection label="CS Primary Lead" collapsible default-open>
          <b-select
          v-model="filters.primary_cs_staff_member_id.allow"
          placeholder="Select Primary Lead User"
          expanded>
            <option
              v-for="staff in csStaff"
              :key="staff.id"
              :value="staff.id">
                {{ staff.first_name }} {{ staff.last_name }}
            </option>
          </b-select>
        </FilterSection>
      </template>
    </FilterTray>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import Spinner from '@/components/common/Spinner';
import TablePaginated from '@/components/layout/TablePaginated';
import api from '@/http-playmetrics';
import FilterTray from '@/components/common/filter/FilterTray';
import FilterSection from '@/components/common/filter/FilterSection';

export default {
  components: { Spinner, TablePaginated, FilterTray, FilterSection },
  data() {
    return {
      filters: _.cloneDeep(this.$store.getters.filtersFor('onboardingSchedule')),
      counter: 0,
      loading: false,
      searchQuery: '',
      isBordered: true,
      showAlert: true,
      stickyHeaders: true,
      activeTab: 0,
      reports: [],
      customHeader: true,
      showStaff: false,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.filterChanged();
      },
    },
  },
  created() {
    this.fetchReports();
  },
  computed: {
    csStaff() {
      return this.$store.getters.usersByDepartments(['product', 'success'])
        .filter(staff => staff.first_name || staff.last_name);
    },
    hasFiltersActive() {
      return !_.isEmpty(this.activeFilters);
    },
    activeFilters() {
      return this.$store.getters.activeFiltersForContext('onboardingSchedule');
    },
    clubs() {
      return this.$store.getters.clubs;
    },
    clubsWithActivites() {
      return this.reports.filter(report => !(report.activities === null || report.archived_at || report.status === 'completed'));
    },
    reportsWithClubFields() {
      return this.clubsWithActivites.map(report => ({
        ...report,
        club_name: this.clubName(report.club_id),
        primary_cs_staff_member_id: report.cs_lead_user_id,
        primary_CS_Staff_member: report.cs_lead_user ? report.cs_lead_user.name : '',
        secondary_CS_Staff_member: report.cs_secondary_user ? report.cs_secondary_user.name : '',
        club_date: this.clubDate(report.club_id),
        kickoff: this.statusKickoff(report.activities),
        intro: this.statusIntro(report.activities),
        club_setup: this.statusClubSetup(report.activities),
        pai: this.statusPai(report.activities),
        paii_rec: this.statusPaiiRec(report.activities),
        paii_comp: this.statusPaiiComp(report.activities),
        paiii: this.statusPaiii(report.activities),
        field_scheduling: this.statusFieldScheduling(report.activities),
        directors: this.statusDirectors(report.activities),
        forms: this.statusForms(report.activities),
        website: this.statusWebsite(report.activities),
        percent_complete: this.completionPercent(report.activities),
      })).sort((report1, report2) =>
        ((report1.date > report2.date) ? -1 : 1));
    },
    searchedReportsWithClubFields() {
      return this.reportsWithClubFields.filter(report =>
        (this.matchesName(report, this.searchQuery) && report.percent_complete !== 100) && this.matchesScheduleFilter(report));
    },
  },
  methods: {
    matchesScheduleFilter(report) {
      if (!this.hasFiltersActive) return true;
      return Object.keys(this.activeFilters).every(key => this.activeFilters[key].matches(report));
    },
    clearFilters() {
      this.$store.dispatch('clearFiltersFor', { name: 'onboardingSchedule' }).then(() => {
        this.filters = _.cloneDeep(this.$store.getters.filtersFor('onboardingSchedule'));
      });
    },
    filterChanged() {
      this.$store.commit('setFiltersFor', { name: 'onboardingSchedule', filters: _.cloneDeep(this.filters) });
    },
    matchesName(reportsWithClubFields, searchQuery) {
      return reportsWithClubFields.club_name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    sortByName(a, b, isAsc) {
      if (a.club_name > b.club_name) return isAsc ? 1 : -1;
      if (a.club_name < b.club_name) return isAsc ? -1 : 1;
      return 0;
    },
    sortByWebsite(a, b, isAsc) {
      if (a.website === '-') return isAsc ? 1 : -1;
      if (b.website === '-') return isAsc ? -1 : 1;
      if (a.website === 'TBD') return isAsc ? 1 : -1;
      if (b.website === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.website, 'YYYY-MM-DD').isBefore(moment(b.website, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.website, 'YYYY-MM-DD').isAfter(moment(b.website, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByForms(a, b, isAsc) {
      if (a.forms === '-') return isAsc ? 1 : -1;
      if (b.forms === '-') return isAsc ? -1 : 1;
      if (a.forms === 'TBD') return isAsc ? 1 : -1;
      if (b.forms === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.forms, 'YYYY-MM-DD').isBefore(moment(b.forms, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.forms, 'YYYY-MM-DD').isAfter(moment(b.forms, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDirectors(a, b, isAsc) {
      if (a.directors === '-') return isAsc ? 1 : -1;
      if (b.directors === '-') return isAsc ? -1 : 1;
      if (a.directors === 'TBD') return isAsc ? 1 : -1;
      if (b.directors === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.directors, 'YYYY-MM-DD').isBefore(moment(b.directors, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.directors, 'YYYY-MM-DD').isAfter(moment(b.directors, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByFieldScheduling(a, b, isAsc) {
      if (a.field_scheduling === '-') return isAsc ? 1 : -1;
      if (b.field_scheduling === '-') return isAsc ? -1 : 1;
      if (a.field_scheduling === 'TBD') return isAsc ? 1 : -1;
      if (b.field_scheduling === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.field_scheduling, 'YYYY-MM-DD').isBefore(moment(b.field_scheduling, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.field_scheduling, 'YYYY-MM-DD').isAfter(moment(b.field_scheduling, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByPAIII(a, b, isAsc) {
      if (a.paiii === '-') return isAsc ? 1 : -1;
      if (b.paiii === '-') return isAsc ? -1 : 1;
      if (a.paiii === 'TBD') return isAsc ? 1 : -1;
      if (b.paiii === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.paiii, 'YYYY-MM-DD').isBefore(moment(b.paiii, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.paiii, 'YYYY-MM-DD').isAfter(moment(b.paiii, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByPAIIComp(a, b, isAsc) {
      if (a.paii_comp === '-') return isAsc ? 1 : -1;
      if (b.paii_comp === '-') return isAsc ? -1 : 1;
      if (a.paii_comp === 'TBD') return isAsc ? 1 : -1;
      if (b.paii_comp === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.paii_comp, 'YYYY-MM-DD').isBefore(moment(b.paii_comp, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.paii_comp, 'YYYY-MM-DD').isAfter(moment(b.paii_comp, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByPAIIRec(a, b, isAsc) {
      if (a.paii_rec === '-') return isAsc ? 1 : -1;
      if (b.paii_rec === '-') return isAsc ? -1 : 1;
      if (a.paii_rec === 'TBD') return isAsc ? 1 : -1;
      if (b.paii_rec === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.paii_rec, 'YYYY-MM-DD').isBefore(moment(b.paii_rec, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.paii_rec, 'YYYY-MM-DD').isAfter(moment(b.paii_rec, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByPAI(a, b, isAsc) {
      if (a.pai === '-') return isAsc ? 1 : -1;
      if (b.pai === '-') return isAsc ? -1 : 1;
      if (a.pai === 'TBD') return isAsc ? 1 : -1;
      if (b.pai === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.pai, 'YYYY-MM-DD').isBefore(moment(b.pai, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.pai, 'YYYY-MM-DD').isAfter(moment(b.pai, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByClubSetup(a, b, isAsc) {
      if (a.club_setup === '-') return isAsc ? 1 : -1;
      if (b.club_setup === '-') return isAsc ? -1 : 1;
      if (a.club_setup === 'TBD') return isAsc ? 1 : -1;
      if (b.club_setup === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.club_setup, 'YYYY-MM-DD').isBefore(moment(b.club_setup, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.club_setup, 'YYYY-MM-DD').isAfter(moment(b.club_setup, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByIntro(a, b, isAsc) {
      if (a.intro === '-') return isAsc ? 1 : -1;
      if (b.intro === '-') return isAsc ? -1 : 1;
      if (a.intro === 'TBD') return isAsc ? 1 : -1;
      if (b.intro === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.intro, 'YYYY-MM-DD').isBefore(moment(b.intro, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.intro, 'YYYY-MM-DD').isAfter(moment(b.intro, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByKickoff(a, b, isAsc) {
      if (a.kickoff === '-') return isAsc ? 1 : -1;
      if (b.kickoff === '-') return isAsc ? -1 : 1;
      if (a.kickoff === 'TBD') return isAsc ? 1 : -1;
      if (b.kickoff === 'TBD') return isAsc ? -1 : 1;
      if (moment(a.kickoff, 'YYYY-MM-DD').isBefore(moment(b.kickoff, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.kickoff, 'YYYY-MM-DD').isAfter(moment(b.kickoff, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    sortByPercent(a, b, isAsc) {
      if ((a.percent_complete) > (b.percent_complete)) return isAsc ? 1 : -1;
      if ((a.percent_complete) < (b.percent_complete)) return isAsc ? -1 : 1;
      return 0;
    },
    sortByDate(a, b, isAsc) {
      if (!a.club_date) return 1;
      if (!b.club_date) return -1;
      if (moment(a.club_date, 'YYYY-MM-DD').isAfter(moment(b.club_date, 'YYYY-MM-DD'))) return isAsc ? 1 : -1;
      if (moment(a.club_date, 'YYYY-MM-DD').isBefore(moment(b.club_date, 'YYYY-MM-DD'))) return isAsc ? -1 : 1;
      return 0;
    },
    percentFormat(percent) {
      return `${percent} %`;
    },
    dateFormat(date) {
      if (date === '-' || date === 'TBD') {
        return date;
      } else { return moment(date).format('MMM DD'); }
    },
    viewPlan(id) {
      this.$router.push({ name: 'PlanViewActivities', params: { plan: id } });
    },
    colorClasses(colorStatus) {
      if (colorStatus === 'TBD') {
        return 'warning';
      }
      if (colorStatus === '-') {
        return 'disabled';
      }
      if (this.dateHasPassed(colorStatus)) {
        return 'disabled';
      } return '';
    },
    statusKickoff(activities) {
      const activity = activities.find(act => act.milestone === 'Kickoff');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusIntro(activities) {
      const activity = activities.find(act => act.milestone === 'Intro to PM');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusClubSetup(activities) {
      const activity = activities.find(act => act.milestone === 'Club Setup');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusPai(activities) {
      const activity = activities.find(act => act.milestone === 'PAI');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusPaiiRec(activities) {
      const activity = activities.find(act => act.milestone === 'PAII Competitive');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusPaiiComp(activities) {
      const activity = activities.find(act => act.milestone === 'PAII Competitive');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusPaiii(activities) {
      const activity = activities.find(act => act.milestone === 'PAIII');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusFieldScheduling(activities) {
      const activity = activities.find(act => act.milestone === 'Field Scheduling');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusDirectors(activities) {
      const activity = activities.find(act => act.milestone === 'Directors');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusForms(activities) {
      const activity = activities.find(act => act.milestone === 'Forms');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    statusWebsite(activities) {
      const activity = activities.find(act => act.milestone === 'Website Builder');
      if (activity) {
        if (activity.meeting_date) {
          return activity.meeting_date;
        } else {
          return 'TBD';
        }
      } else return '-';
    },
    completionPercent(activities) {
      const oba = activities.filter(a => (a.category === 'setup' ||
        a.category === 'registration' ||
        a.category === 'operations' ||
        a.category === 'website'));
      if (oba.length === 0) return 100;
      const completed = oba.reduce(
        (compCount, activity) => compCount + (this.dateHasPassed(activity.meeting_date) ? 1 : 0),
        0);
      return Math.round((completed / oba.length) * 10000) / 100;
    },
    dateHasPassed(date) {
      return moment(date).isBefore(moment());
    },
    clubByID(id) {
      return this.clubs.find(club => club.id === id);
    },
    clubName(id) {
      return this.clubByID(id)?.name || '';
    },
    clubDate(id) {
      return this.clubByID(id)?.config.customer_since || '';
    },
    fetchReports() {
      this.loading = true;
      api().post('/cst/club_onboarding_plans/search?populate=users,activities').then((res) => {
        this.reports = res.data.filter(plan => !(this.clubs.find(club => club.id === plan.club_id).type === 'prospect'));
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="sass" scoped>
@import "~bulma/sass/utilities/mixins"
#onboarding-schedule
  ::v-deep .counter
    color: $white !important
    white-space: nowrap
  ::v-deep .club-name
    font-weight: bold
    white-space: nowrap
    align-items: center
  ::v-deep .club-format
    white-space: nowrap
    text-align: center
  .reports-club-onboarding-table
    margin-top: 1rem
  ::v-deep .club-percent
    padding-right: 1.25rem
    text-align: right
  ::v-deep .header-background
    vertical-align: bottom
    width: 180px
    font-family: $family-headers
    font-size: 1rem
    background-color: $blue-slate
    color: $white !important
    padding: .75rem 0 .75rem 1.25rem
  ::v-deep .number-input
    width: 60px
  ::v-deep .word-input
    width: 60px
  ::v-deep .success
    background-color: $success
  ::v-deep .warning
    background-color: rgba(#FFC000, 0.25)
  ::v-deep .danger
    background-color: rgba($danger, 0.1)
  ::v-deep .disabled
    background-color: #ebedf9
  ::v-deep .table-subheader
    background-color: LightGray
    padding-top: 1rem
    margin: 0 !important
    padding-bottom: 1rem
    color: black !important
    font-family: "Montserrat", sans-serif
    font-weight: 700 !important
    font-size: 0.85rem
    border-width: 1px
    text-align: left
</style>
