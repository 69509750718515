<template>
<b-select :disabled="disabled"
  :value="value"
  @input="handleInput"
  expanded>
    <option v-for="s in filteredStaff" :key="s.id" :value="s.id"> {{`${s.first_name} ${s.last_name}`}}</option>
</b-select>
</template>

<script>

export default {
  props: {
    departments: { type: Array },
    blacklist: { type: Array },
    value: { type: Number, required: true },
    disabled: { type: Boolean, default: false },
    isWebBuilderTeam: { type: Boolean, default: false },
    deselect: { type: Boolean, default: false },
  },
  computed: {
    noSelection() {
      return {
        id: 0,
        first_name: '',
        last_name: '',
      };
    },
    staff() {
      let staffToDisplay;
      if (this.departments) {
        if (this.isWebBuilderTeam) {
          staffToDisplay = this.$store.getters.usersByDepartments(this.departments).concat(this.$store.getters.webBuilderUsers);
        } else {
          staffToDisplay = this.$store.getters.usersByDepartments(this.departments);
        }
      } else if (this.isWebBuilderTeam) {
        staffToDisplay = this.$store.getters.webBuilderUsers;
      } else {
        staffToDisplay = this.$store.getters.users.filter(user => user.admin_permissions.filter(permission => permission.includes('cst_tool')).length > 0);
      }
      if (this.deselect && !staffToDisplay.some(staff => staff.id === 0)) {
        staffToDisplay.unshift(this.noSelection);
      }
      return staffToDisplay.filter(staff => staff.first_name || staff.last_name || staff.id === 0);
    },
    filteredStaff() {
      if (this.blacklist) {
        return this.staff.filter(user => !this.blacklist.includes(user.id) || user.id === 0);
      }
      return this.staff;
    },
    staffByID() {
      const ret = {};
      this.staff.forEach((staff) => {
        ret[staff.id] = staff;
      });
      return ret;
    },
  },
  methods: {
    handleInput(id) {
      this.$emit('input', this.staffByID[id]);
    },
  },

};
</script>

<style>

</style>
