import { render, staticRenderFns } from "./SetAsDefaultMerchantAccount.vue?vue&type=template&id=4a800a1e&scoped=true"
import script from "./SetAsDefaultMerchantAccount.vue?vue&type=script&setup=true&lang=js"
export * from "./SetAsDefaultMerchantAccount.vue?vue&type=script&setup=true&lang=js"
import style0 from "./SetAsDefaultMerchantAccount.vue?vue&type=style&index=0&id=4a800a1e&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a800a1e",
  null
  
)

export default component.exports