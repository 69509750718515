<template>
  <div class="page-navigation">
    <nav
      v-if="hasBreadcrumbs"
      class="breadcrumb page__breadcrumb">
      <ul>
        <li
          v-for="(crumb, idx) in breadCrumbs"
          :key="idx"
          :class="{ 'is-active': isLastBreadCrumb(idx) }">
          <router-link
            :to="crumb.link"
            tag="a">
            {{ crumb.name }}
          </router-link>
        </li>
      </ul>
    </nav>
    <nav
      v-if="hasBackButton"
      :class="{ 'nopadding': backButton.nopadding }"
      class="breadcrumb page__breadcrumb back-button">
      <a
        @click="goBack">
        <fa-icon :icon="['far', 'arrow-left']" style="margin-right: 0.5rem"/>
          {{ backButton.name }}
      </a>
    </nav>
    <nav
      :class="{'no-tabs': !hasTabs && (!searchable && !filterable)}"
      class="level">
      <slot name="title">
        <div class="level-left">
          <div class="level-item">
            <h1 class="title__page">{{ pageTitle }}</h1>
            <div v-if="canAlert && !isMobile && !season">
              <button
                slot="trigger"
                style="margin-left: 1.5rem"
                class="button is-medium is-borderless is-primary is-outlined"
                @click="$emit('openAlert')">
                <span class="icon">
                  <fa-icon :icon="['far', 'megaphone']"/>
                </span>
              </button>
            </div>
            <div
              v-if="season"
              class="season-tag">
              <b-tag
                rounded
                :class="season.class"
                style="margin-left: 1.5rem">
                <fa-icon :icon="['fas', 'archive']" />
                {{ season.label }}
              </b-tag>
            </div>
            <div
              v-else-if="showArchivedIndicator"
              class="archived-container"
            >
              <fa-icon :icon="['fas', 'archive']" />
              Archived
            </div>
          </div>
        </div>
      </slot>
      <div v-if="canAlert && !isMobile && season">
        <button
          slot="trigger"
          style="margin-right: 1.5rem"
          class="button is-medium is-borderless is-primary is-outlined"
          @click="$emit('openAlert')">
          <span class="icon">
            <fa-icon :icon="['far', 'megaphone']"/>
          </span>
        </button>
      </div>
      <div
        v-if="(hasCtas || hasCtasSlot) && !hasTabs"
        class="level-right">
        <div class="level-item">
          <slot v-if="hasCtasSlot" name="ctas" />
          <app-page-navigation-ctas
            :ctas="ctas"
            @ctaClicked="$emit('ctaClicked', $event)"
          />
          <slot v-if="hasCtasRightSlot" name="ctas-right" />
        </div>
      </div>
      <div
        v-if="tabsHaveCtas && tabRequiresCtas"
        class="level-right">
        <div
          :style="isMobile ? 'flex-wrap: wrap; width: 100%' : ''"
          class="level-item">
          <slot v-if="hasCtasSlot" name="ctas" />
          <app-page-navigation-ctas
            :ctas="ctasForActiveTab"
            @ctaClicked="$emit('ctaClicked', $event)"
          />
          <div v-if="canAlert && isMobile">
            <button
              slot="trigger"
              :style="ctasForActiveTab.length === 0 ? 'margin-top: 0.5rem' : ''"
              class="button is-primary is-outlined"
              @click="$emit('openAlert')">
              <span>
                <fa-icon :icon="['far', 'megaphone']"/>
              </span>
            </button>
          </div>
          <slot v-if="hasCtasRightSlot" name="ctas-right" />
        </div>
      </div>
    </nav>
    <nav
      v-if="hasLeftBottomSlot || searchable || filterable"
      class="level page__nav">
      <div class="level-left">
        <div class="level-item">
          <slot
            v-if="hasLeftBottomSlot"
            name="left-bottom-slot" />
        </div>
      </div>
      <div class="level-right mobile-alignment">
        <div
          v-if="searchable"
          class="level-item"
          style="margin-bottom:0;"
        >
          <b-input
            v-model="searchQuery"
            :placeholder="searchBarLabel"
            icon="search"
            @keyup.native="$emit('queryUpdated', searchQuery)" />
        </div>
        <div v-if="filterable" class="level-item with-mobile-sort">
          <app-filter-button :filters-active="filtersActive"/>
        </div>
      </div>
    </nav>
    <div
      v-if="hasTabs"
      class="columns is-narrow is-mobile tabs-with-actions">
      <div class="column">
        <div
          id="scroll-tabs"
          ref="scrollTabs"
          class="tabs tabs-mobile">
          <ul id="scroll-tabs-mobile" ref="scrollTabsMobile">
            <li
              v-for="(tab, idx) in tabsNotHidden"
              :key="idx"
              :class="{ 'is-active': activeTab === tab.name }"
              @click="$emit('tabClicked', tab.name)">
              <a>
                <span v-if="tab.nameToShow">{{ tab.nameToShow }}</span>
                <span v-else>{{ tab.name }}</span>
                <b-tag
                  v-if="indicatorCount(tab)"
                  class="indicator-tag has-text-weight-semibold"
                  rounded>
                  {{ indicatorCount(tab) > 99 ? '99+' : indicatorCount(tab) }}
                </b-tag>
                <b-tag
                  v-if="tab.tag"
                  class="indicator-tag is-primary"
                  rounded>
                  {{ tab.tag }}
                </b-tag>
              </a>
            </li>
          </ul>
        </div>
        <div
          v-if="showIndicator && isMobile"
          :class="showIndicator && isMobile ? 'gradient-background' : ''"
          class="tabs-mobile-indicator">
          <fa-icon v-if="showIndicator && isMobile" :icon="['far', 'arrow-right']" class="right-arrow"/>
        </div>
        <div v-if="!showIndicator && isMobile" style="display:block;position: relative;left: 50%;top: 23%" />
      </div>
    </div>
    <div v-if="tabRequiresSubHeader" class="columns is-narrow is-gapless is-mobile tabs-with-actions page__nav">
      <div class="column action-icons">
        <transition name="slide-fade">
          <span
            v-if="tabRequiresSearch"
            :class="{'search-bar__has-no-filter': !tabRequiresFilter}"
            class="control search-bar">
            <input
              v-model="searchQuery"
              :placeholder="searchBarLabel"
              class="input"
              type="text"
              @keyup="$emit('queryUpdated', searchQuery)">
          </span>
        </transition>
        <p class="buttons">
          <app-filter-button v-if="tabRequiresFilter" :filters-active="filtersActive"/>
        </p>
      </div>
    </div>
    <div
      v-else-if="!isMobile"
      style="margin-bottom:1rem;"/>
  </div>
</template>

<script>
import _ from 'lodash';
import FilterButton from '@/components/common/filter/FilterButton';
import PageNavigationCtas from './PageNavigationCtas';

export default {
  components: {
    'app-filter-button': FilterButton,
    'app-page-navigation-ctas': PageNavigationCtas,
  },

  props: {
    breadCrumbs: { type: Array, required: false, default: () => [] },
    backButton: { type: Object, required: false, default: () => {} },
    pageTitle: { type: String, required: true },
    ctas: { type: Array, required: false, default: () => [] },
    tabs: { type: Array, required: false, default: () => [] },
    activeTab: { type: String, required: false, default: '' },
    searchQueryGetter: { type: String, required: false, default: '' },
    searchBarLabel: { type: String, required: false, default: 'Search' },
    searchable: { type: Boolean, required: false, default: false },
    filterable: { type: Boolean, required: false, default: false },
    filtersActive: { type: Boolean, required: false, default: false },
    canAlert: { type: Boolean, required: false, default: false },
    showArchivedIndicator: { type: Boolean, required: false, default: false },
    season: { type: Object, required: false, default: () => {} },
    useMobileTitleBar: { type: Boolean, default: false },
    mobileProps: { type: Object, default: null },
  },

  data() {
    return {
      searchQuery: '',
      showIndicator: this.tabs && ((window.innerWidth < 340 && this.tabs.length > 3) ||
        (this.tabs.length > 4)),
    };
  },

  computed: {
    hasBreadcrumbs() {
      return this.breadCrumbs.length > 0;
    },
    hasBackButton() {
      return this.backButton;
    },
    hasCtas() {
      return this.ctas.length > 0;
    },
    hasTabs() {
      return this.tabs && this.tabs.length > 0;
    },
    hasCtasSlot() {
      return !!this.$slots.ctas;
    },
    hasCtasRightSlot() {
      return !!this.$slots['ctas-right'];
    },
    hasLeftBottomSlot() {
      return !!this.$slots['left-bottom-slot'];
    },
    activeTabWithInfo() {
      const tab = _.filter(this.tabs, t => t.name === this.activeTab)[0];
      return (tab || {});
    },
    tabsHaveCtas() {
      return this.hasTabs && _.includes(this.tabs.map(tab => _.isUndefined(tab.ctas)), false);
    },
    tabRequiresSearch() {
      if (!this.activeTab) return false;
      return this.activeTabWithInfo.searchable;
    },
    tabRequiresFilter() {
      if (!this.activeTab) return false;
      return this.activeTabWithInfo.filterable;
    },
    tabRequiresSubHeader() {
      return this.hasTabs && (this.tabRequiresSearch || this.tabRequiresFilter);
    },
    tabRequiresCtas() {
      return _.has(this.activeTabWithInfo, 'ctas');
    },
    ctasForActiveTab() {
      return this.activeTabWithInfo ? this.activeTabWithInfo.ctas : [];
    },
    isMobile() {
      return this.$store.getters.isMobile;
    },
    tabsNotHidden() {
      return this.tabs.filter(t => !t.hidden);
    },
  },

  watch: {
    activeTab() {
      this.searchQuery = '';
      this.$emit('queryUpdated', this.searchQuery);
    },
  },

  created() {
    if (this.searchQueryGetter) {
      const searchQueryFromStore = this.$store.getters[this.searchQueryGetter];
      if (searchQueryFromStore !== '') {
        this.searchQuery = searchQueryFromStore;
      }
    }
  },

  mounted() {
    if (this.$refs.scrollTabsMobile && this.$refs.scrollTabsMobile.clientWidth >= document.body.clientWidth) {
      document.getElementById('scroll-tabs').addEventListener('scroll', this.handleScroll);
    }
  },

  destroyed() {
    if (this.$refs.scrollTabsMobile && this.$refs.scrollTabsMobile.clientWidth > document.body.clientWidth) {
      document.getElementById('scroll-tabs').removeEventListener('scroll', this.handleScroll);
    }
  },

  methods: {
    isLastBreadCrumb(idx) {
      return this.breadCrumbs.length === (idx + 1);
    },
    handleScroll() {
      const scrolled = 100 * (this.$refs.scrollTabs.scrollLeft / (this.$refs.scrollTabsMobile.clientWidth - window.innerWidth));
      if (scrolled > 85) {
        this.showIndicator = false;
      } else if (this.tabs && this.tabs.length > 3) {
        this.showIndicator = true;
      }
    },
    goBack() {
      if (this.backButton.useRouter) {
        this.$router.back();
      } else {
        this.$store.dispatch('gobackTo', {
          names: this.backButton.routeNames,
          fallback: { name: this.backButton.routeFallback },
          params: this.backButton.params,
        });
      }
    },
    indicatorCount(tab) {
      if (tab.indicator) {
        const count = this.$store.getters[tab.indicator];
        if (typeof count === 'function') {
          return tab.id_for_indicator && count(tab.id_for_indicator);
        } else {
          return count;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="sass" scoped>
@import "~bulma/sass/utilities/mixins"
.page__breadcrumb
  margin-top: -2rem
  @media screen and (min-width: 1024px)
    margin-top: -3rem
  ul
    flex-wrap: initial
  &.back-button
    position: relative
    z-index: 1
    &.nopadding
      margin-bottom: 0
    a
      padding: 0.25rem 0
      justify-content: flex-start
      display: inline-flex
      font-family: $family-headers
      font-weight: $weight-medium
      font-size: $size-7
.page__nav
  position: relative
  margin-bottom: 3rem !important
.action-icons
  position: absolute
  right: 0
.search-bar
  position: absolute
  z-index: 1
  right: 87px
@media screen and (min-width: $tablet)
  .search-bar
    right: 85px
.level
  display: flex
  flex: 1
  +mobile
    display: flex
    &.is-mobile
      margin-top: -0.5rem
    &:not(.is-mobile)
      display: block
    .level-right
      .field.is-grouped
        margin-left: -1rem
        margin-top: 0.5rem
    &.is-mobile
      .title__page
        line-height: calc(45px + 0.5rem)
.level-right
  margin-top: 0
.level-left .level-item
  flex-shrink: 1
.level-left
  flex-grow: 1
  flex-shrink: 1
.level-item
  justify-content: flex-start
.search-bar__has-no-filter
  right: 0px
@media screen and (min-width: $tablet)
  .search-bar__has-no-filter
    right: 50px
.slide-fade-enter-active
  transition: all .1s ease
.slide-fade-leave-active
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0)
.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(6px)
  opacity: 0
.no-tabs
  margin-bottom: 2rem
@media screen and (max-width: 1088px)
  .tabs-mobile
    overflow-x: scroll
    width: 92vw
    ul li
      transform: translateZ(0)
  .tabs-mobile-indicator
    position: absolute
    display: block
    width: 10vw
    margin-top: -60px
    height: 40px
    right: 0
    z-index: 2
  .gradient-background
    background-image: linear-gradient(to right, rgba(255,255,255,0.3), rgba(255,255,255,1))
  .right-arrow
    position: relative
    left: 50%
    top: 23%
.tabs ul li .indicator-tag
  margin-left: .7rem
  font-size: .75rem
  padding: 0 .9rem
  background-color: $orange
  color: $white
  &.is-primary
    background-color: $primary
.with-mobile-sort
  +mobile
    margin-left: 0.5rem
.mobile-alignment
  +mobile
    display: flex
    margin-top: 10px
    justify-content: space-between
    > div:first-child
      flex: 1
      > div
        width: 100%
.archived-container
  background-color: $grey-light
  border-radius: 11.5px
  color: $white
  font-family: Montserrat
  font-size: 11px
  font-weight: 600
  line-height: 24px
  padding: 0 5px
  margin-left: 10px
.season-tag
  .current-season
    background-color: $blue-dodger
    color: $white
  .future-season
    background-color: $blue-slate
    color: $white
  .past-season
    background-color: $grey
    color: $white
</style>
