<template>
  <div>
    <TablePaginated
      name="Registrations"
      :data="searchedClubs"
      :filters-active="hasFiltersActive"
      searchable
      @queryUpdated="searchQuery = $event"
      class="registrations-table"
    >
      <template slot="table-header">
        <div class="block"> <strong>
          <span v-if="searchedClubs.length === 1"> {{ searchedClubs.length }} Club Found</span>
          <span v-else> {{ searchedClubs.length }} Clubs Found</span>
        </strong> </div>
      </template>
      <template slot-scope="props">
        <b-table-column label="Club Name"
          header-class="is-unselectable"
          class="align-bottom"
          field="name"
          sortable>
          {{ props.row.name }}
        </b-table-column>
        <b-table-column label="Club Size"
          header-class="is-unselectable"
          field="size"
          sortable>
          {{ props.row.size | capitalize }}
        </b-table-column>
        <b-table-column label="Customer Since"
          header-class="is-unselectable"
          field="customer_since"
          sortable
          :custom-sort="(club1, club2, isAsc) => sortDates(club1, club2, isAsc)">
          {{ props.row.customer_since | formatDate('ll z') }}
        </b-table-column>
        <b-table-column label="Total Open Programs"
          header-class="is-unselectable"
          numeric
          field="open_programs"
          sortable>
          {{ props.row.open_programs.toLocaleString() }}
        </b-table-column>
<!--        <b-table-column label="Open Programs Delta" header-class="is-unselectable"-->
<!--          numeric field="open_programs_delta" :class="deltaColor(props.row.open_programs_delta)" sortable>-->
<!--          {{ props.row.open_programs_delta > 0 ? `+${props.row.open_programs_delta.toLocaleString()}`-->
<!--            : props.row.open_programs_delta.toLocaleString() }}-->
<!--        </b-table-column>-->
        <b-table-column label="Transactions"
          header-class="is-unselectable"
          numeric
          field="transactions"
          sortable>
          {{ props.row.transactions.toLocaleString() }}
        </b-table-column>
        <b-table-column label="Revenue"
          header-class="is-unselectable"
          numeric
          field="revenue"
          sortable>
          {{ props.row.revenue | currency }}
        </b-table-column>
      </template>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>No Clubs Found</p>
          </div>
        </section>
      </template>
    </TablePaginated>
    <UsageFilter :filters-active="hasFiltersActive" :isSummary="false" @date="customerSince=$event"/>
  </div>
</template>

<script>
import _ from 'lodash';
import TablePaginated from '@/components/layout/TablePaginated';
import UsageFilter from './UsageFilter';

export default {
  props: {
    data: { type: Array, required: true },
  },
  components: { TablePaginated, UsageFilter },
  data() {
    return {
      searchQuery: '',
      customerSince: null,
    };
  },
  computed: {
    tableRows() {
      return this.data.filter(club => club.programs && club.transactions && club.revenue).map(club => ({
        id: club.id,
        name: club.name,
        size: club.size ? club.size : '-',
        customer_since: club.customer_since,
        onboarding_status: club.onboarding_status,
        onboarding_size: club.onboarding_size,
        cs_lead_user: club.cs_lead_user ? club.cs_lead_user : 0,
        cs_secondary_user: club.cs_secondary_user ? club.cs_secondary_user : 0,
        sales_contact_user_id: club.sales_contact_user_id ? club.sales_contact_user_id : 0,
        open_programs: club.programs.current ? club.programs.current.value : '-',
        open_programs_delta: (club.programs.current && club.programs.previous) ? (club.programs.current.value - club.programs.previous.value) : '-',
        transactions: club.transactions.current ? club.transactions.current.value : '-',
        revenue: club.revenue.current ? club.revenue.current.value : '-',
      })).sort((club1, club2) => (club1.open_programs > club2.open_programs ? -1 : 1));
    },
    searchedClubs() {
      return this.customerSince ? this.tableRows.filter(club =>
        this.matchesName(club.name, this.searchQuery) && this.matchesUsageFilter(club) && this.matchesDate(club))
        : this.tableRows.filter(club => this.matchesName(club.name, this.searchQuery) && this.matchesUsageFilter(club));
    },
    hasFiltersActive() {
      return !_.isEmpty(this.$store.getters.activeFiltersFor('usage')) || this.customerSince !== null;
    },
  },
  methods: {
    matchesDate(club) {
      return new Date(club.customer_since) > this.customerSince;
    },
    matchesName(name, searchQuery) {
      return name.toLowerCase().includes(searchQuery.toLowerCase());
    },
    deltaColor(delta) {
      if (delta > 0) return 'has-text-success';
      else if (delta < 0) return 'has-text-danger';
      else return '';
    },
    sortDates(club1, club2, isAsc) {
      const date1 = new Date(club1.customer_since);
      const date2 = new Date(club2.customer_since);
      return (date1 < date2) === isAsc ? -1 : 1;
    },
    matchesUsageFilter(club) {
      if (!this.hasFiltersActive) return true;
      const filters = this.$store.getters.activeFiltersFor('usage');
      if (Object.keys(filters).includes('cs_staff')) {
        return Object.keys(filters).filter(key => !key.includes('cs_staff')).every(key => filters[key].includes(club[key]))
          && Object.keys(filters).filter(key => key.includes('cs_staff')).every(key => filters[key].includes(club.cs_lead_user) || filters[key].includes(club.cs_secondary_user));
      } else return Object.keys(filters).every(key => filters[key].includes(club[key]));
    },
  },
};
</script>

<style lang="sass">
.registrations-table .table th
  vertical-align: bottom
</style>
